import {Plugin, PluginKey, PluginView} from 'prosemirror-state';
import {EditorView} from 'prosemirror-view';
import {AppContext, createVNode, getCurrentInstance, render, VNode} from 'vue';
import {autoUpdate, computePosition, shift, offset, flip} from '@floating-ui/vue';
import LinkMenu from './LinkMenu.vue';
import {getSelectedLinkPos} from '../utils/link-utils';

const linkEditorKey = new PluginKey('linkEditor');

export class VueLinkMenuView implements PluginView {
  appContext: AppContext;
  dom: HTMLElement;
  vnode: VNode | null;
  view: EditorView | null = null;
  cleanupFloatingUI: (() => void) | null = null;
  editing: HTMLElement | null = null;

  constructor() {
    const currentInstance = getCurrentInstance();
    this.appContext = currentInstance?.appContext!;
    this.vnode = null;
    this.dom = document.createElement('div');
    this.dom.style.display = 'none';
    this.dom.style.position = 'absolute';
    this.dom.style.width = 'max-content';
    this.dom.style.zIndex = '1000';
    this.dom.style.top = '0';
    this.dom.style.left = '0';
  }

  /**
   * Sets the EditorView and sets up the dom element.
   *
   * @param view
   */
  create(view: EditorView) {
    this.view = view;
    view.dom.parentNode?.appendChild(this.dom);
  }

  /**
   * Updates the EditorView
   *
   * @param view
   */
  update(view: EditorView) {
    this.view = view;

    let selectedLinkPos = getSelectedLinkPos(view.state);

    if (selectedLinkPos !== null) {
      this.beginEditing(selectedLinkPos);
    } else {
      this.stopEditing();
    }
  }

  /**
   *
   */
  destroy() {
    if (this.cleanupFloatingUI) {
      this.cleanupFloatingUI();
    }

    this.dom.remove();
  }

  beginEditing(pos: number) {
    const {node} = this.view!.domAtPos(pos);

    if (!node?.parentElement) {
      this.editing = null;
      return;
    }

    if (this.editing !== node.parentElement) {
      this.stopEditing();
    }

    this.editing = node.parentElement;
    this.positionDom(node.parentElement);
    this.render(pos);
  }

  stopEditing() {
    if (!this.editing) return;
    this.editing = null;

    /*
     * Cleanup Floating UI
     */
    if (this.cleanupFloatingUI) {
      this.cleanupFloatingUI();
    }

    /*
     * Hide DOM element
     */
    this.dom.style.display = 'none';

    /*
     * Unmount Vue component
     */
    if (this.vnode?.component) {
      render(null, this.dom);
    }
  }

  private positionDom(target: HTMLElement) {
    this.dom.style.display = 'block';

    this.cleanupFloatingUI = autoUpdate(target, this.dom, () => {
      computePosition(target, this.dom, {
        middleware: [offset(8), shift(), flip()],
      }).then(({x, y}) => {
        Object.assign(this.dom.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    });
  }

  private render(pos: number) {
    this.vnode = createVNode(LinkMenu as any, {
      pos,
      view: this.view,
      onClose: () => {
        this.stopEditing();
      },
    });

    this.vnode.appContext = this.appContext;

    render(this.vnode, this.dom);
  }

  toPlugin() {
    return new Plugin({
      key: linkEditorKey,
      view: (view) => {
        this.create(view);

        return this;
      },
    });
  }
}
