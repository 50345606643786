<script setup lang="ts">
import STaskGradePill from '../../../components/STaskGradePill.vue';
import SBadge from '../../../design-system/SBadge.vue';
import FeedbackByPart from './FeedbackByPart.vue';
import {TaskFeedback, TaskResponse} from '../../../types/entities/tasks';
import {Grade} from '../../../types/entities/base-grade';
import {useTaskResponseStateColors} from './task-response-state-colors';
import SLatex from '../../../components/SLatex.vue';
import SIcon from '../../../design-system/SIcon.vue';
import GradeDisplay = App.Enums.GradeDisplay;
import {useI18n} from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    attemptedTaskIds: number[];
    draftTaskIds: number[];
    studentAssignmentTaskGrades: Grade[];

    // Response
    storedResponse: TaskResponse | null;
    responseGrade: Grade | null;
    feedbackByPart: Record<string, TaskFeedback[]>;

    taskWeight: number;
    gradeDisplay: GradeDisplay;
  }>(),
  {
    attemptedTaskIds: () => [] as number[],
    draftTaskIds: () => [] as number[],
    studentAssignmentTaskGrades: () => [] as Grade[],
    gradeDisplay: 'FRACTION' as GradeDisplay,
  }
);

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const {determineFeedbackAreaBackgroundClass, determineFeedbackBorderBackgroundClass} =
  useTaskResponseStateColors(props);
</script>

<template>
  <section
    v-if="(storedResponse && !storedResponse.isDraft) || responseGrade"
    class="relative row-start-2 overflow-hidden rounded-lg shadow-card bg-white flex flex-col"
  >
    <!-- Top Gradient -->
    <div
      role="presentation"
      class="absolute top-0 left-0 w-full h-1/3 min-h-[6rem] max-h-[12rem] bg-gradient-to-b"
      :class="determineFeedbackAreaBackgroundClass(storedResponse, responseGrade)"
    ></div>
    <!-- Top Color Bar -->
    <div
      role="presentation"
      class="absolute top-0 left-0 w-full h-[0.375rem]"
      :class="determineFeedbackBorderBackgroundClass(storedResponse, responseGrade)"
    ></div>
    <!-- Content -->
    <div class="relative px-5 md:px-7 pt-6 pb-5 stack vertical gap-4">
      <div class="flex flex-wrap items-start justify-between gap-5">
        <div v-if="responseGrade" class="flex justify-start">
          <s-task-grade-pill
            :grade="responseGrade"
            :grade-display="gradeDisplay"
            :weight="taskWeight"
          />
        </div>
        <div class="flex-1 flex flex-wrap gap-3 flex-row-reverse">
          <s-badge v-if="storedResponse?.gradingStatus === 'failed'" color="red">
            <s-icon name="warning" size="16" class="opacity-70" />
            {{ t('autogradingFailed') }}
          </s-badge>
        </div>
      </div>
      <div>
        <feedback-by-part
          :feedback-by-part="feedbackByPart"
          :use-html-newlines="responseGrade?.isManuallyEntered"
        />
        <div v-if="responseGrade?.submittedByName" class="text-gray-600 font-medium mt-2">
          {{ t('gradeManuallyEnteredBy') }}
          <span class="font-bold text-gray-600">{{ responseGrade.submittedByName }}</span>
        </div>
      </div>
    </div>
  </section>
</template>
<i18n>
{
  "en": {
    "autogradingFailed": "Autograding Failed",
    "gradeManuallyEnteredBy": "Grade manually entered by"
  },
  "fr": {
    "autogradingFailed": "Échec de l'évaluation automatique",
    "gradeManuallyEnteredBy": "Note saisie manuellement par"
  }
}
</i18n>
