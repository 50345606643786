import {NodeSpec} from 'prosemirror-model';

export let heading_spec = {
  attrs: {level: {default: 1}},
  content: 'inline*',
  group: 'block',
  defining: true,
  parseDOM: [
    // Heading
    {tag: 'h1', attrs: {level: 2}},
    {tag: 'h2', attrs: {level: 2}},
    // Subheading
    {tag: 'h3', attrs: {level: 3}},
    {tag: 'h4', attrs: {level: 3}},
    {tag: 'h5', attrs: {level: 3}},
    {tag: 'h6', attrs: {level: 3}},
  ],
  toDOM(node) {
    return ['h' + node.attrs.level, 0];
  },
} as NodeSpec;
