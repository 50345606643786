<template>
  <Head :title="`Edit ${task.title}`"></Head>
  <s-page-block size="md">
    <s-page-header heading="Edit Task" class="pb-4">
      <template #actions>
        <s-btn v-if="formComponent" @click.prevent="copyJsonToClipboard" color="secondary">
          JSON Export
        </s-btn>
        <s-btn
          v-if="task.id && page.props.loggedInUser.isSuperUser"
          :href="route('admin.tasks.feedbacks.index', {task: task.id})"
          color="secondary"
          external
        >
          Edit Feedbacks
        </s-btn>
        <s-btn
          v-if="task.id && page.props.loggedInUser.isSuperUser"
          class="flex-1 max-w-[14rem]"
          color="blue-light"
          icon="eye"
          :href="route('admin.tasks.show', {task: task.id})"
        >
          {{ $t('actions.preview') }}
        </s-btn>
        <s-btn
          v-if="page.props.loggedInUser.isSuperUser"
          :href="route('admin.tasks.show', {task: task.id})"
          color="red-light"
          icon="cog"
        >
          {{ $t('superAdmin') }}
        </s-btn>
      </template>
    </s-page-header>

    <!--  Task Type Form -->
    <template v-if="formComponent">
      <component
        :is="formComponent"
        :institutions="institutions"
        :topics="topics"
        :modelsMetadata="modelsMetadata"
        :modelValue="task"
        :errors="errors"
      />
    </template>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {computed, defineAsyncComponent} from 'vue';
import {Institution} from '../../types/entities/institution';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import {Head, usePage} from '@inertiajs/vue3';
import {useFlash} from '../../composables/useFlash';
import {LlmMetadata} from '../../types/entities/llmMetadata';
import TaskTopicDto = App.DTOs.Tasks.TaskTopicDto;

const props = defineProps<{
  task: Record<string, any>;
  institutions: Institution[];
  topics: TaskTopicDto[];
  modelsMetadata: LlmMetadata[];
  errors?: Record<string, any>;
}>();

const page = usePage<{
  loggedInUser: {
    isSuperUser: boolean;
  };
}>();

const flash = useFlash();

const formComponent = computed(() => {
  return defineAsyncComponent(() => {
    return import(`./${props.task?.type}/Form.vue`).catch(() => {
      return import('./Edit/FallbackForm.vue');
    });
  });
});

const copyJsonToClipboard = () => {
  navigator.clipboard
    .writeText(
      JSON.stringify(
        props.task,
        (key, value) => {
          // Remove the root ID param
          if (key === 'id') {
            return undefined;
          }

          return value;
        },
        2
      )
    )
    .then(() => {
      flash?.value.set('success', 'Task JSON copied to clipboard');

      setTimeout(() => {
        flash?.value?.dismiss('success');
      }, 2500);
    });
};
</script>
