<template>
  <Head :title="t('page.title', {courseCode: course.code, assignmentName: assignment.name})"></Head>
  <main class="page-block max-w-screen-md align-left">
    <s-page-header :heading="t('page.heading')" class="mb-3" />
    <section class="w-full flex flex-col mb-4">
      <h2 class="heading md text-gray-600 mb-2">{{ t('participants') }}</h2>
      <s-placeholder v-if="!selectedParticipants.length" class="mb-4">
        {{ t('placeholders.noneSelected') }}
      </s-placeholder>
      <div v-else class="mb-4 card w-full divide-y divide-gray-200 max-h-[20rem] overflow-scroll">
        <div
          v-for="participant in selectedParticipants"
          :key="participant.id"
          class="flex gap-1 justify-between w-full items-stretch animate-slide-in-up"
        >
          <div class="px-5 py-3 flex flex-col gap-1">
            <p class="text-lg leading-tight text-gray-700">
              {{ participant.firstName }} {{ participant.lastName }}
            </p>
            <p class="text-sm leading-tight text-gray-600 flex flex-wrap gap-x-3 gap-y-1">
              <span class="flex items-center gap-1">
                <s-icon name="calendar" class="opacity-70" size="16" />
                {{ participant.sections }}
              </span>
              <span class="flex items-center gap-1">
                <s-icon name="email" class="opacity-70" size="16" />
                {{ participant.email }}
              </span>
            </p>
          </div>
          <button
            @click="removeParticipant(participant)"
            class="group flex gap-1 items-center text-xs px-5 py-3 hover:bg-red-500/5 border-l border-transparent hover:border-red-100 text-gray-400 hover:text-red-500 transition-all duration-150 ease-out"
            @focus="filterFocus = false"
          >
            <s-icon
              name="trash-can-outline"
              class="opacity-70 group-hover:opacity-100 transition-all duration-150 ease-out"
              size="16"
              aria-hidden="true"
            />
            {{ t('actions.remove') }}
          </button>
        </div>
      </div>
      <div
        class="flex flex-col items-stretch md:flex-row md:items-center justify-between gap-y-4 gap-x-3"
      >
        <div class="flex-1 z-10 relative">
          <div class="relative group">
            <s-input-field
              id="participantsFilterText"
              v-model="participantsFilterText"
              @focus="filterFocus = true"
              @keydown.esc="loseFocus"
              :placeholder="t('placeholders.search')"
              :inputClass="
                'pr-10 focus:pr-4 truncate' +
                (error ? 'ring-2 ring-red-400 border-red-400 focus:border-gray-200' : '')
              "
              autocomplete="off"
            />
            <s-icon
              name="magnify"
              class="absolute top-1/2 -translate-y-1/2 right-4 text-blue-500 opacity-70 group-focus-within:opacity-0 transition-all ease-out duration-150"
              size="20"
              aria-hidden="true"
            />
          </div>
          <div
            :class="
              filterFocus ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
            "
            class="card w-full divide-y absolute -bottom-2 translate-y-full max-h-[20rem] overflow-scroll transition-all ease-out duration-150 z-50"
            @keydown.esc="loseFocus"
          >
            <button
              v-for="participant in filteredParticipants"
              :key="participant.id"
              @click="addParticipant(participant)"
              class="group text-left hover:bg-gray-100 focus:bg-blue-50 text-gray-700 hover:text-blue-500 focus:text-blue-600 focus:outline-none px-5 py-2.5 flex gap-2 w-full items-center justify-between transition-all duration-150 ease-out"
              :tabindex="filterFocus ? 0 : -1"
              type="button"
            >
              <div class="flex flex-col gap-1">
                <p class="text-base leading-tight">
                  {{ participant.firstName }} {{ participant.lastName }}
                </p>
                <p class="text-sm leading-tight opacity-80 flex flex-wrap gap-x-3 gap-y-1">
                  <span class="flex items-center gap-1">
                    <s-icon name="calendar" class="opacity-70" size="16" />
                    {{ participant.sections }}
                  </span>
                  <span class="flex items-center gap-1">
                    <s-icon name="email" class="opacity-70" size="16" />
                    {{ participant.email }}
                  </span>
                </p>
              </div>
              <s-icon
                name="account-plus"
                class="opacity-20 group-hover:opacity-70 group-focus:opacity-80 transition-all ease-out duration-159"
                width="20"
                height="20"
              />
            </button>
            <div ref="loadMoreLandmark"></div>
            <button
              v-if="canLoadMoreItems"
              @click="loadMoreItems"
              class="btn secondary md w-full"
              :disabled="loading"
            >
              {{ t('buttons.loadMore') }}
            </button>
          </div>
        </div>
        <div
          v-if="filterFocus"
          @click="filterFocus = false"
          class="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-900/20 to-gray-700/20 transition-all ease-out duration-300"
        ></div>
      </div>
    </section>
    <section class="w-full flex flex-col">
      <h2 class="heading md text-gray-600 mb-2">
        {{ t('page.setDates') }}
      </h2>
      <s-placeholder v-if="!selectedParticipants.length">
        {{ t('placeholders.noneSelected') }}
      </s-placeholder>
      <div v-else class="w-full flex flex-col gap-6 pb-1">
        <div
          v-for="(section, index) in selectedSections"
          :key="section.name"
          class="animate-slide-in-up"
        >
          <extension-date-selector
            :section="section.name"
            :index="index"
            :totalExtensions="selectedSections.length"
            :participants="section.participants?.map((p) => p.firstName + ' ' + p.lastName) || []"
            :current-start-date="section.startDate"
            :current-due-date="section.dueDate"
            v-model="extensionDates[section.id]"
          />
        </div>
      </div>
    </section>
    <div v-if="error" class="animate-slide-in-up mt-4">
      <p
        class="flex items-center justify-start gap-1.5 px-4 py-3 border border-red-200 bg-gradient-to-r from-red-100/70 to-red-100 text-base leading-tight text-red-700 rounded-md shadow-soft"
      >
        <s-icon name="warning" class="opacity-70" width="18" height="18" />
        {{ error }}
      </p>
    </div>
    <div class="w-full flex justify-between mt-5 gap-3">
      <s-btn
        :href="
          (route().params.redirect as string) ??
          route('courses.assignments.extension.index', {
            course: course.id,
            assignment: assignment.id,
          })
        "
        type="button"
        color="white"
        icon="close"
      >
        {{ t('form.cancel') }}
      </s-btn>
      <s-btn type="submit" @click="grantExtensions" icon="calendar-plus">
        {{ t('buttons.grant') }}
      </s-btn>
    </div>
  </main>
</template>
<script setup lang="ts">
import SPageHeader from '../../design-system/SPageHeader.vue';
import SInputField from '../../design-system/SInputField.vue';
import SPlaceholder from '../../design-system/SPlaceholder.vue';
import {computed, ref, watch} from 'vue';
import SBtn from '../../design-system/SBtn.vue';
import {Head, router} from '@inertiajs/vue3';
import {Assignment} from '../../types/entities/assignment';
import {route} from 'ziggy-js';
import {Course} from '../../types/entities/course';
import axios from 'axios';
import {useInfiniteScroll} from '../../composables/useInfiniteScroll';
import {LaravelPaginator} from '../../types/laravel-paginator';
import {useIntersectionObserver} from '@vueuse/core/index';
import ExtensionDateSelector from './ExtensionDateSelector.vue';
import {useDebounceFn} from '@vueuse/core';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({useScope: 'local', inheritLocale: true});

const props = defineProps<{
  assignment: Assignment;
  participants: LaravelPaginator<any>;
  sections: Section[];
  course: Course;
  user: Participant | null;
}>();

const error = ref<string>('');

type Section = {
  id: number;
  courseLikeAssignmentId: number;
  name: string;
  startDate: string;
  dueDate: string;
  participants?: Participant[];
};

type Participant = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  sections: string;
  sectionIds: string;
};

const selectedParticipants = ref<Participant[]>([]);
const participantsFilterText = ref<string>('');
const filterFocus = ref<boolean>(false);
const selectedSections = ref<Section[]>([]);
const filteredParticipants = computed(() =>
  items.value.filter((participant) => !selectedParticipants.value.includes(participant))
);
const {items, loadMoreItems, canLoadMoreItems, reset, loading} = useInfiniteScroll(getParticipants);
const loadMoreLandmark = ref<HTMLElement | null>(null);

const debouncedFn = useDebounceFn(() => {
  reset();
  loadMoreItems();
}, 700);

watch(participantsFilterText, debouncedFn);

if (props.user) {
  addParticipant(props.user);
}

async function getParticipants(
  previousPaginator?: LaravelPaginator<any> | null
): Promise<LaravelPaginator<any>> {
  return axios
    .get(
      previousPaginator?.next_page_url! ??
        route('api.v1.course.assignment.participant.index', {
          course: props.course.id,
          assignment: props.assignment.id,
          searchText: participantsFilterText.value,
        })
    )
    .then((response) => {
      return response.data;
    });
}

useIntersectionObserver(loadMoreLandmark, loadMoreItems);

function loseFocus() {
  filterFocus.value = false;
}

function addParticipant(participant: Participant) {
  selectedParticipants.value.push(participant);
  participantsFilterText.value = '';
  filterFocus.value = false;
  const participantSectionIds = participant.sectionIds.split(',').map(Number);
  for (let section of props.sections) {
    if (participantSectionIds.includes(section.id)) {
      section.participants!.push(participant);
      if (selectedSections.value.indexOf(section) === -1) {
        selectedSections.value.push(section);
        break;
      }
    }
  }
}

function removeParticipant(participant: Participant) {
  selectedParticipants.value = selectedParticipants.value.filter((p) => p.id !== participant.id);
  const participantSectionIds = participant.sectionIds.split(',').map(Number);
  for (let section of props.sections) {
    if (participantSectionIds.includes(section.id)) {
      section.participants?.splice(section.participants?.indexOf(participant), 1);
      if (section.participants?.length === 0) {
        selectedSections.value.splice(selectedSections.value.indexOf(section), 1);
      }
    }
  }
}

const extensionDates = ref<{
  [key: number]: {newStartDate: string | null; newDueDate: string | null};
}>(
  props.sections.reduce((acc: any, section: Section) => {
    acc[section.id] = {
      newStartDate: section.startDate,
      newDueDate: section.dueDate,
    };
    return acc;
  }, {})
);

type Extension = {
  assignmentId: number;
  courseLikeAssignmentId: number;
  userId: number;
  newStartDate: string | null;
  newDueDate: string | null;
};

const extensions = computed<Extension[]>(() => {
  return selectedParticipants.value.flatMap((participant) => {
    const participantSectionIds = participant.sectionIds.split(',').map(Number);
    const matchingSectionId = +participantSectionIds[0];
    const matchingCourseLikeAssignmentId =
      props.sections.find((section) => section.id === matchingSectionId)?.courseLikeAssignmentId ??
      -1;

    return [
      {
        assignmentId: props.assignment.id,
        userId: participant.id,
        newStartDate: extensionDates.value[matchingSectionId]?.newStartDate,
        newDueDate: extensionDates.value[matchingSectionId]?.newDueDate,
        courseLikeAssignmentId: matchingCourseLikeAssignmentId,
      },
    ];
  });
});

watch(extensions, (newExtensions) => {
  if (newExtensions.length > 0) {
    error.value = '';
  }
});

function grantExtensions() {
  if (extensions.value.length === 0) {
    error.value = 'Please select at least one participant to grant an extension.';
    return;
  }
  router.post(
    route('courses.assignments.extension.store', {
      course: props.course.id,
      assignment: props.assignment.id,
      _query: {
        redirect: route().params.redirect,
      },
    }),
    {extensions: extensions.value}
  );
}
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Grant Extension",
      "heading": "Grant Extension",
      "setDates": "Set Modified Dates"
    },
    "placeholders": {
      "noneSelected": "No participants selected",
      "search": "Search Participants"
    },
    "buttons": {
      "grant": "Grant Extension",
      "loadMore": "Load more"
    }
  },
  "fr": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Accorder Extension",
      "heading": "Accorder Prolongation",
      "setDates": "Spécifier les dates modifiées"
    },
    "placeholders": {
      "noneSelected": "Aucun participant sélectionné",
      "search": "Rechercher des participants"
    },
    "buttons": {
      "grant": "Accorder Prolongation",
      "loadMore": "Charger plus"
    }
  }
}
</i18n>
