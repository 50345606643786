<template>
  <Listbox v-if="taskResponses.length" v-model="modelValue" class="w-full">
    <div class="relative flex" ref="reference">
      <ListboxButton
        class="relative rounded shadow-card-soft overflow-hidden pl-5 pr-9 py-2.5 flex items-center justify-between leading-tight border border-gray-200 bg-white hover:bg-gray-50 appearance-none text-blue-600 hover:text-blue-400 font-bold w-full cursor-pointer text-left transition-all ease-out duration-150"
      >
        <div
          v-if="modelValue && modelValue.id"
          class="absolute left-0 top-0 w-1.5 h-full border-r"
          :class="determineTaskResponseBarClasses(modelValue)"
        ></div>

        <div class="flex flex-col items-start justify-center">
          <!-- Response X of Y, Effective -->
          <div
            v-if="taskResponses.length > 0"
            class="w-full text-sm flex gap-1 items-center whitespace-nowrap"
            :class="{
              'text-blue-600': modelValue?.id && isEffective(modelValue.id),
              'text-gray-600': !modelValue?.id || !isEffective(modelValue.id),
            }"
          >
            <s-icon
              v-if="modelValue?.id && isEffective(modelValue.id)"
              name="check-bold"
              class="text-blue-500"
              size="18"
            />
            <span class="flex items-center gap-1 font-medium">
              <i18n-t keypath="responseXofY">
                <template #index>
                  <strong
                    class="font-black"
                    :class="{
                      'text-blue-800': modelValue?.id && isEffective(modelValue.id),
                      'text-gray-800': !modelValue?.id || !isEffective(modelValue.id),
                    }"
                  >
                    {{ attemptNumber }}
                  </strong>
                </template>
                <template #total>
                  <strong
                    class="font-black"
                    :class="{
                      'text-blue-800': modelValue?.id && isEffective(modelValue.id),
                      'text-gray-800': !modelValue?.id || !isEffective(modelValue.id),
                    }"
                  >
                    {{ taskResponses.length }}
                  </strong>
                </template>
              </i18n-t>
            </span>
            <span
              v-if="modelValue?.id && isEffective(modelValue.id)"
              class="uppercase text-sm font-bold"
            >
              &ndash; {{ t('badges.effective') }}
            </span>
          </div>

          <!-- Response Date, Late, Draft -->
          <s-tooltip :disabled="!showLateTooltip">
            <template v-if="showLateTooltip" #tooltip>
              {{
                t('badges.xLate', {
                  timeframe: formatDistance(getResponseTimestamp(response), dueDate, {
                    addSuffix: false,
                  }),
                })
              }}
            </template>
            <div
              class="flex items-center gap-1"
              :class="{
                'text-gray-400 italic': modelValue?.isDraft,
                'text-red-500': !modelValue?.isDraft && responseIsLate && dueDate,
              }"
            >
              <s-icon v-if="modelValue?.isDraft" name="pencil" class="opacity-70" size="18" />
              <s-icon
                v-if="!modelValue?.isDraft && responseIsLate && dueDate"
                name="clock-alert-outline"
                class="opacity-70"
                size="18"
              />
              <s-icon
                v-if="modelValue?.hasAttachments"
                name="paperclip"
                class="opacity-70"
                size="18"
              />
              <span v-if="modelValue?.isDraft">
                {{ t('draft') }}
              </span>
              <s-date
                v-else-if="modelValue && !modelValue?.isSelfSubmitted"
                :date="modelValue?.lastSavedAt ?? modelValue?.createdAt"
                inline
              />
              <s-date
                v-else-if="
                  modelValue?.submittedAt || modelValue?.updatedAt || modelValue?.createdAt
                "
                :date="modelValue?.submittedAt || modelValue?.updatedAt || modelValue?.createdAt"
                inline
              />
            </div>
          </s-tooltip>

          <!-- Draft Created & Updated -->
          <div
            v-if="modelValue?.isDraft"
            class="text-sm italic text-gray-600 font-medium leading-tight mt-1 w-full text-balance"
          >
            <i18n-t v-if="modelValue?.createdAt === modelValue?.updatedAt" keypath="draftCreated">
              <template #created>
                <s-date :date="modelValue?.createdAt" inline />
              </template>
            </i18n-t>
            <i18n-t v-else keypath="draftUpdated">
              <template #created>
                <s-date :date="modelValue?.createdAt" inline />
              </template>
              <template #updated>
                <s-date :date="modelValue?.updatedAt" inline />
              </template>
            </i18n-t>
          </div>

          <!-- Submitted by X on behalf of Y on Z -->
          <div
            v-if="response && !response?.isSelfSubmitted && modelValue?.submittedBy"
            class="text-sm italic text-gray-600 font-medium leading-tight mt-1 w-full text-balance"
          >
            <i18n-t keypath="submittedByPersonOn">
              <template #submittedBy>
                <span class="inline font-bold text-gray-600">
                  {{ modelValue?.submittedBy?.firstName }}
                  {{ modelValue?.submittedBy?.lastName }}
                </span>
              </template>

              <template #onBehalfOf>
                <span class="inline font-bold text-gray-600">
                  {{ modelValue?.user?.firstName }}
                  {{ modelValue?.user?.lastName }}
                </span>
              </template>

              <template #submissionTime>
                <span class="inline font-bold text-gray-600">
                  {{
                    monthDaySometimesYear(modelValue?.submittedAt, {
                      time: true,
                    })
                  }}
                </span>
              </template>
            </i18n-t>
          </div>
        </div>

        <!-- Dropdown Arrow -->
        <span class="pointer-events-none absolute top-1/2 -translate-y-1/2 right-0 pr-2">
          <s-icon name="chevron-down" class="h-5 w-5 text-gray-400" />
        </span>
      </ListboxButton>

      <Teleport to="body">
        <div ref="floating" class="absolute z-40" :style="floatingStyles">
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="max-h-60 w-full overflow-auto rounded bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm divide-y divide-gray-150"
            >
              <ListboxOption
                v-slot="{active, selected}"
                v-for="responseOption in taskResponses"
                :key="responseOption.id"
                :value="responseOption"
                as="template"
              >
                <li
                  class="relative leading-none cursor-pointer select-none py-2.5 pl-5 pr-4 transition"
                  :class="{
                    'bg-blue-50/50 text-blue-800': active,
                    'text-gray-900': !active,
                    'bg-gradient-to-r from-blue-50/50 to-blue-50 text-blue-800': selected,
                  }"
                >
                  <div
                    class="absolute left-0 top-0 w-1.5 h-full border-r"
                    :class="determineTaskResponseBarClasses(responseOption)"
                  ></div>
                  <div class="text-sm flex gap-1.5 items-center">
                    <span
                      v-if="responseOption.isDraft"
                      class="leading-none uppercase text-xs font-bold text-gray-600 flex items-center gap-1 mb-1"
                    >
                      <s-icon name="pencil" class="opacity-70" size="14" />
                      {{ t('draft') }}
                    </span>
                    <span
                      v-else-if="isEffective(responseOption.id)"
                      class="leading-none uppercase text-xs font-bold text-blue-600 flex items-center gap-1 mb-1"
                    >
                      <s-icon name="check-bold" class="opacity-70" size="14" />
                      {{ t('activeGrade') }}
                    </span>
                    <span
                      v-if="
                        !responseOption.isDraft &&
                        isResponseLate(dueDate, responseOption, assignmentExtension)
                      "
                      class="leading-none uppercase text-xs font-bold text-red-600 flex items-center gap-1 mb-1"
                    >
                      <s-icon name="clock-alert" class="opacity-70" size="14" />
                      {{ t('late') }}
                    </span>
                  </div>
                  <div class="flex flex-col gap-1">
                    <span
                      v-if="!responseOption.isDraft"
                      :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'flex items-center truncate',
                      ]"
                    >
                      <s-icon
                        v-if="responseOption.hasAttachments"
                        name="paperclip"
                        class="opacity-70 -ml-1 mr-1"
                        width="16"
                        height="16"
                      />
                      <s-date
                        v-if="!responseOption.isSelfSubmitted"
                        :date="responseOption?.lastSavedAt ?? responseOption?.createdAt"
                        inline
                      />
                      <s-date
                        v-else
                        :date="
                          responseOption?.submittedAt ||
                          responseOption?.updatedAt ||
                          responseOption?.createdAt
                        "
                        inline
                      />
                    </span>

                    <!-- Draft Created & Updated -->
                    <div
                      v-if="responseOption?.isDraft"
                      class="text-sm italic text-gray-600 font-medium leading-tight w-full text-balance"
                    >
                      <i18n-t
                        v-if="responseOption?.createdAt === responseOption?.updatedAt"
                        keypath="draftCreated"
                      >
                        <template #created>
                          <s-date :date="responseOption?.createdAt" inline />
                        </template>
                      </i18n-t>
                      <i18n-t v-else keypath="draftUpdated">
                        <template #created>
                          <s-date :date="responseOption?.createdAt" inline />
                        </template>
                        <template #updated>
                          <s-date :date="responseOption?.updatedAt" inline />
                        </template>
                      </i18n-t>
                    </div>
                  </div>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Teleport>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import SDate from '@/design-system/SDate.vue';
import STooltip from '@/design-system/STooltip.vue';
import {Grade} from '../../../types/entities/base-grade';
import {TaskResponse} from '../../../types/entities/tasks';
import {monthDaySometimesYear, formatDistance} from '../../../format/dates';
import {useTaskResponseStateColors} from './task-response-state-colors';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {useVModel} from '@vueuse/core';
import {isResponseLate, getResponseTimestamp} from '../../../util/response-timestamps';
import {AssignmentExtension} from '../../../types/entities/assignment-extension';
import SIcon from '../../../design-system/SIcon.vue';
import {autoUpdate, flip, offset, shift, size, useFloating} from '@floating-ui/vue';
import {useI18n} from 'vue-i18n';
import {computed, ref} from 'vue';

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
  | 'submittedBy'
  | 'user'
>;

const props = defineProps<{
  modelValue: PartialTaskResponse | null;

  dueDate: string | null | undefined;
  assignmentExtension?: AssignmentExtension | null;

  attemptedTaskIds: number[];
  draftTaskIds: number[];
  attemptNumber: number;
  responseIsLate: boolean;
  isEffective: (responseId: number) => boolean;
  response: TaskResponse | null;

  // Task
  taskResponses: PartialTaskResponse[];
  studentAssignmentTaskGrades: Grade[];
  taskNumber: number;
  taskWeight: number;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const modelValue = useVModel(props, 'modelValue');

const {determineTaskResponseBarClasses} = useTaskResponseStateColors(props);

const reference = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);
const middleware = ref([
  offset(8),
  flip(),
  shift(),
  size({
    apply({rects, elements}) {
      Object.assign(elements.floating.style, {
        width: `${rects.reference.width}px`,
      });
    },
  }),
]);

const {floatingStyles} = useFloating(reference, floating, {
  placement: 'bottom-end',
  middleware,
  whileElementsMounted: autoUpdate,
});

const showLateTooltip = computed(() => {
  return !modelValue.value?.isDraft && props.responseIsLate && props.dueDate;
});
</script>

<i18n>
{
  "en": {
    "badges": {
      "effective": "Active Grade",
      "xLate": "{timeframe} late",
    },
    "draft": "Draft",
    "draftCreated": "Created {created}",
    "draftUpdated": "Created {created} - Updated {updated})",
    "activeGrade": "Active Grade",
    "late": "Late",
    "submittedByPersonOn": "Submitted by {submittedBy} on behalf of {onBehalfOf} on {submissionTime}",
    "responseXofY": "Response {index} of {total}",
  },
  "fr": {
    "badges": {
      "effective": "Note active",
      "xLate": "{timeframe} en retard",
    },
    "draft": "Brouillon",
    "draftCreated": "Créé {created}",
    "draftUpdated": "Créé {created} - Mis à jour {updated}",
    "activeGrade": "Note active",
    "late": "En retard",
    "submittedByPersonOn": "Soumis par {submittedBy} au nom de {onBehalfOf} le {submissionTime}",
    "responseXofY": "Réponse {index} sur {total}"
  }
}
</i18n>
