<template>
  <Head
    :title="
      t('page.title', {
        courseCode: course.code,
      })
    "
  ></Head>
  <s-page-block size="md">
    <s-page-header :heading="t('page.header')" class="mb-2" />
    <div class="stack vertical">
      <s-select-field id="section" :label="t('section')" v-model="selected" class="mb-5">
        <option value="section">
          {{ t('actions.selectSection') }}
        </option>
        <option v-for="section in sections" :key="section?.id" :value="section?.id">
          {{ section?.name }}
        </option>
      </s-select-field>
      <div class="flex flex-wrap gap-2">
        <s-link
          v-if="selected !== 'section'"
          :href="
            route('courses.attendance.download', {
              course: (route().params as PageParams).course,
              section: selected,
              _query: {
                tz: new Date().getTimezoneOffset(),
              },
            })
          "
          external
          target="_blank"
          class="btn blue-light md flex-1 max-w-[18rem]"
        >
          <s-icon name="download" />
          <span>
            {{ t('actions.downloadCSV') }}
          </span>
        </s-link>
        <s-link
          v-if="selected !== 'section'"
          as="button"
          method="post"
          :data="{sectionId: selected}"
          :href="route('courses.roll-call.store', (route().params as PageParams).course)"
          class="btn primary md flex-1"
        >
          <div class="stack horizontal valign-center gap-2.5">
            <s-icon name="account-check" />
            <span>
              {{ t('actions.takeAttendance') }}
            </span>
          </div>
        </s-link>
      </div>
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import {defineProps, ref} from 'vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLink from '../../components/SLink.vue';
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import SSelectField from '../../design-system/SSelectField.vue';
import {Course} from '../../types/entities/course';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

type Section = {
  id: number;
  name: string;
};

type PageParams = {
  course: string;
};

defineProps<{
  sections: Section[];
  course: Course;
}>();

const selected = ref('section');

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "{courseCode}  - Roll Call",
      "header": "Attendance"
    },
    "actions": {
      "takeAttendance": "Take Attendance",
      "selectSection": "Select Section"
    }
  },
  "fr": {
    "page": {
      "title": "{courseCode}  - Appel",
      "header": "Présence"
    },
    "actions": {
      "takeAttendance": "Prendre la présence",
      "selectSection": "Sélectionner une section"
    }
  }
}
</i18n>
