<template>
  <div class="flex" ref="containerRef">
    <div
      class="flex flex-wrap p-1 shadow-btn-white bg-white border border-gray-300/50 outline outline-transparent outline-2 outline-offset-2 focus-within:outline-blue-400"
      :class="direction === 'row' ? 'flex-row rounded-full' : 'flex-col rounded-[1.25rem]'"
    >
      <label
        v-for="option in options"
        @click.prevent="model = option.value"
        :for="id + '-' + option.value"
        :class="{
          'green !shadow-inset-green before:hidden hide-next-before': model === option.value,
          'border border-transparent text-blue-500 hover:text-blue-400 after:absolute after:top-0 after:bottom-0 after:left-1 after:right-1 first:after:left-0 last:after:right-0 after:bg-blue-300/10 after:rounded-full after:opacity-0 hover:after:opacity-70':
            model !== option.value,
          'before:transition before:absolute before:-top-1 before:-bottom-1 before:left-0 before:w-px before:bg-gradient-to-t before:from-transparent before:via-gray-150 before:to-transparent first:before:opacity-0':
            direction === 'row',
        }"
        class="relative btn custom px-4 py-1.5 text-sm leading-tight font-medium min-w-[7rem] flex justify-center"
      >
        <input
          type="radio"
          :id="id + '-' + option.value"
          :name="id"
          :value="option.value"
          :checked="model === option.value"
          class="sr-only"
        />
        <s-icon
          name="check-bold"
          class="transition -ml-1 -mr-0.5 -mt-px"
          :class="model === option.value ? 'opacity-70' : 'opacity-0 translate-x-2'"
          size="18"
        />
        <span class="transition" :class="model === option.value ? '' : '-translate-x-2'">
          {{ option.label }}
        </span>
      </label>
    </div>
  </div>
</template>
<script setup lang="ts">
import {onMounted, onUnmounted, ref} from 'vue';
import SIcon from './SIcon.vue';

type Option = {
  value: string | number | boolean | null;
  label: string;
};

const model = defineModel<Option['value']>();

defineProps<{
  id: string;
  options: Option[];
}>();

const containerRef = ref<HTMLElement | null>(null);
const direction = ref('row');

const setDirection = () => {
  if (!containerRef.value) return;

  // Start by setting the direction to row for a test fit
  direction.value = 'row';

  // Wait for the DOM to update and modal to stabilize
  setTimeout(() => {
    if (!containerRef.value) return;

    // Get the parent width when the children are in a row
    const parentWidth = containerRef.value.clientWidth;
    const children = Array.from(containerRef.value.children[0].children);

    if (!children || children.length === 0) return;

    // Wiggle room needed to ensure a proper fit given the border and padding
    const wiggle = 10;
    let totalChildrenWidth = 0;

    // Get the total width of the children
    children.forEach((child) => {
      const childElement = child as HTMLElement;
      totalChildrenWidth += childElement.offsetWidth;
    });

    // If the children don't fit, set to column
    const shouldWrap = totalChildrenWidth + wiggle > parentWidth;
    direction.value = shouldWrap ? 'col' : 'row';
  }, 100); // Increased timeout to ensure modal is fully rendered
};

// Set the direction on mount, and listen for window resize
onMounted(() => {
  if (containerRef.value) {
    window.addEventListener('resize', setDirection);

    // Create a ResizeObserver to watch for size changes
    const resizeObserver = new ResizeObserver(() => {
      setDirection();
    });

    // Observe both the container and its parent
    resizeObserver.observe(containerRef.value);
    if (containerRef.value.parentElement) {
      resizeObserver.observe(containerRef.value.parentElement);
    }

    setDirection();

    onUnmounted(() => {
      window.removeEventListener('resize', setDirection);
      resizeObserver.disconnect();
    });
  }
});
</script>
<style scoped scss>
.hide-next-before {
  & + *:before {
    opacity: 0;
  }
}
</style>
