<template>
  <div class="topics-manager">
    <div v-if="!isChild" class="mb-4 space-y-4">
      <!-- Selected topics section -->
      <div v-if="selectedTopics.length > 0">
        <div class="text-sm font-medium mb-2">{{ t('labels.selectedTopics') }}</div>
        <div class="flex flex-wrap gap-2">
          <div
            v-for="topicId in selectedTopics"
            :key="topicId"
            class="rounded px-2 py-1 text-sm flex items-center gap-2 border"
          >
            {{ getTopicDescription(topicId) }}
          </div>
        </div>
      </div>

      <!-- Search section -->
      <div class="search-container">
        <s-input-field
          id="text"
          :label="t('labels.search')"
          v-model="searchText"
          @input="expandParentNodes"
        />
      </div>
    </div>

    <div class="topics-tree">
      <template v-for="topic in displayedTopics" :key="topic.id">
        <div class="topic-item flex items-center gap-2">
          <!-- Always show a placeholder for consistent indentation -->
          <div class="w-4 h-4 flex items-center justify-center">
            <s-icon
              v-if="hasChildren(topic)"
              name="chevron-right"
              class="transform transition-transform cursor-pointer"
              :class="{'rotate-90': isExpanded(topic.id)}"
              @click.prevent="toggleExpand(topic.id)"
            />
          </div>

          <label class="flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              v-model="selectedTopics"
              :value="topic.id"
              class="form-checkbox"
            />
            <span>{{ topic.description }}</span>
          </label>
        </div>

        <div v-if="hasChildren(topic) && isExpanded(topic.id)" class="children-container ml-6">
          <!-- Pass both the child topics and the complete set -->
          <TaskTopicsManager
            :topics="getChildTopics(topic)"
            :all-topics="completeTopicSet"
            v-model="selectedTopics"
            is-child
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import SIcon from '../../design-system/SIcon.vue';
import {computed, onMounted, ref, watch} from 'vue';
import SInputField from '../../design-system/SInputField.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  topics: TaskTopicDtoWithLineage[];
  allTopics?: TaskTopicDtoWithLineage[];
  isChild?: boolean;
}>();

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});

const searchText = ref('');

// Use allTopics if provided, otherwise use topics as the complete set
const completeTopicSet = computed(() => props.allTopics || props.topics);

// Helper function to check if a topic matches the search
const topicMatchesSearch = (topic: TaskTopicDtoWithLineage): boolean => {
  if (!searchText.value) return true;
  const searchLower = searchText.value.toLowerCase();
  return topic.description.toLowerCase().includes(searchLower);
};

// Helper function to check if a topic or any of its descendants match the search
const topicOrDescendantsMatchSearch = (
  topic: TaskTopicDtoWithLineage,
  visited = new Set<number>()
): boolean => {
  // Prevent infinite recursion
  if (visited.has(topic.id)) return false;
  visited.add(topic.id);

  // Check if current topic matches
  if (topicMatchesSearch(topic)) return true;

  // Check children recursively
  if (topic.childrenIds?.length) {
    const children = completeTopicSet.value.filter((t) => topic.childrenIds.includes(t.id));
    return children.some((child) => topicOrDescendantsMatchSearch(child, visited));
  }

  return false;
};

const getChildTopics = (topic: TaskTopicDtoWithLineage): TaskTopicDtoWithLineage[] => {
  if (!topic.childrenIds?.length) return [];

  const children = completeTopicSet.value.filter((t) => topic.childrenIds.includes(t.id));
  if (!searchText.value) return children;

  // Only return children that match the search or have matching descendants
  return children.filter((child) => topicOrDescendantsMatchSearch(child));
};

const displayedTopics = computed(() => {
  const baseTopics = props.isChild
    ? props.topics
    : props.topics.filter((topic) => topic.isRootNode);

  if (!searchText.value) return baseTopics;

  // Only return topics that match the search or have matching descendants
  return baseTopics.filter((topic) => topicOrDescendantsMatchSearch(topic));
});

// When searching, automatically expand nodes that have matching descendants
watch(searchText, () => {
  if (!searchText.value) return;

  const searchLower = searchText.value.toLowerCase();
  completeTopicSet.value.forEach((topic) => {
    if (topicOrDescendantsMatchSearch(topic)) {
      // If this topic has matching descendants, expand it
      expandedNodes.value.add(topic.id);
    }
  });
});

const selectedTopics = defineModel<number[]>({
  default: [],
});

const expandedNodes = ref<Set<number>>(new Set());

const expandParentNodes = () => {
  selectedTopics.value.forEach((topicId) => {
    // Find all parent topics that have this topic in their children
    completeTopicSet.value.forEach((topic) => {
      if (topic.childrenIds?.includes(topicId)) {
        expandedNodes.value.add(topic.id);
      }
    });
  });
};

// Expand parent nodes on mount
onMounted(() => {
  expandParentNodes();
});

const toggleExpand = (topicId: number) => {
  if (expandedNodes.value.has(topicId)) {
    expandedNodes.value.delete(topicId);
  } else {
    expandedNodes.value.add(topicId);
  }
};

const hasChildren = (topic: TaskTopicDtoWithLineage) =>
  topic.childrenIds && topic.childrenIds.length > 0;

const isExpanded = (topicId: number) => expandedNodes.value.has(topicId);

const getTopicDescription = (topicId: number): string => {
  const topic = completeTopicSet.value.find((t) => t.id === topicId);
  return topic?.description || '';
};
</script>
<style scoped>
.topics-manager {
  width: 100%;
}

.topic-item {
  padding: 0.5rem 0;
}

.expand-button {
  cursor: pointer;
  user-select: none;
}

.form-checkbox {
  @apply rounded border-gray-300;
}
</style>
<i18n>
{
  "en": {
    "labels.selectedTopics": "Selected Topics",
    "labels.search": "Search Topics"
  },
  "fr": {
    "labels.selectedTopics": "Sujets sélectionnés",
    "labels.search": "Rechercher des sujets"
  }
}
</i18n>
