import {NodeSpec} from 'prosemirror-model';

export const variable_spec: NodeSpec = {
  group: 'inline',
  inline: true,
  attrs: {
    name: {default: 'x'},
  },
  toDOM: (node) => [
    'span',
    {
      'data-variable-name': node.attrs.name,
    },
    node.attrs.name,
  ],
  parseDOM: [
    {
      tag: 'span[data-variable-name]',
      getAttrs: (dom) => {
        const name = dom.getAttribute('data-variable-name');
        return {name};
      },
    },
  ],
};
