<template>
  <s-btn @click="open = !open">{{ open ? 'Close' : 'Open' }}</s-btn>

  <s-dialog
    v-model:open="open"
    modal
    class="mr-0 my-0 h-screen overflow-visible animate-[slideInLeft_0.2s_ease-out_forwards]"
  >
    <div class="h-screen min-w-80 card padded !rounded-none">
      <h2 class="heading lg">This is a Drawer</h2>
      <p>Huzzah</p>
    </div>
  </s-dialog>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
import {ref} from 'vue';
import SDialog from '../../../../components/SDialog.vue';

const open = ref(false);
</script>
