import {NodeSpec, Schema} from 'prosemirror-model';
import {addListNodes} from 'prosemirror-schema-list';
import {paragraph_spec} from '../../prosemirror-editor/schema/node_specs/paragraph_spec';
import {blockquote_spec} from '../../prosemirror-editor/schema/node_specs/blockquote_spec';
import {horizontal_rule_spec} from '../../prosemirror-editor/schema/node_specs/horizontal_rule_spec';
import {heading_spec} from '../../prosemirror-editor/schema/node_specs/heading_spec';
import {code_block_spec} from '../../prosemirror-editor/schema/node_specs/code_block_spec';
import {text_spec} from '../../prosemirror-editor/schema/node_specs/text_spec';
import {hard_break_spec} from '../../prosemirror-editor/schema/node_specs/hard_break_spec';
import {math_inline_spec} from '../../prosemirror-editor/schema/node_specs/math_inline_spec';
import {math_display_spec} from '../../prosemirror-editor/schema/node_specs/math_display_spec';
import {link_spec} from '../../prosemirror-editor/schema/mark_specs/link_spec';
import {em_spec} from '../../prosemirror-editor/schema/mark_specs/em_spec';
import {strong_spec} from '../../prosemirror-editor/schema/mark_specs/strong_spec';
import {code_spec} from '../../prosemirror-editor/schema/mark_specs/code_spec';
import {variable_spec} from './node_specs/variable_spec';

const nodes = {
  doc: {content: 'block*'},
  paragraph: paragraph_spec,
  blockquote: blockquote_spec,
  horizontal_rule: horizontal_rule_spec,
  heading: heading_spec,
  code_block: code_block_spec,
  // image, // Removed until we can make it work inside modals
  text: text_spec,
  hard_break: hard_break_spec,
  math_inline: math_inline_spec,
  math_display: math_display_spec,
  variable: variable_spec,
};

export const marks = {
  link: link_spec,
  em: em_spec,
  strong: strong_spec,
  code: code_spec,
};

const baseSchema = new Schema({
  nodes,
  marks,
});

export const taskRichTextEditor = new Schema({
  nodes: addListNodes(baseSchema.spec.nodes, 'paragraph block*', 'block'),
  marks: baseSchema.spec.marks,
});
