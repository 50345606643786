<template>
  <Head :title="`Admin - Payment Requests`"></Head>
  <div class="page-block gap-5">
    <div>
      <s-link :href="route('admin.courses.show', course.id)" class="link">
        <small>{{ course.name }}</small>
      </s-link>
      <h1 class="heading xl">Payment Requests</h1>
    </div>
    <div class="stack vertical sm:horizontal gap-2">
      <button class="btn secondary md w-fit" @click.prevent="showPaymentRequestConfirmation = true">
        Send Payment Requests
      </button>
      <button class="btn secondary md w-fit" @click.prevent="showDisableUsersConfirmation = true">
        Deactivate Unpaid Users
      </button>
    </div>

    <s-search-section
      route-name="admin.courses.payment-requests.index"
      label="Search by user email"
    />

    <section class="stack vertical gap-3">
      <div
        v-for="paymentRequest in paymentRequests?.data"
        class="card padded stack horizontal align-between valign-center gap-3"
      >
        <!-- Left -->
        <div>
          <div>
            <s-link :href="`/users/${paymentRequest.user.id}`" external class="underline">
              {{ paymentRequest.user.email }}
            </s-link>
          </div>
          <small>Sent: {{ monthDayYear(paymentRequest.createdAt) }}</small>
        </div>
        <!-- Right -->
        <div class="stack vertical align-right gap-1">
          <payment-request-status-badge :payment-request="paymentRequest" />
          <s-link
            :href="
              route('admin.courses.payment-requests.destroy', {
                course: course.id,
                paymentRequest: paymentRequest.id,
              })
            "
            method="delete"
            class="btn secondary md w-fit mt-2"
          >
            Delete
          </s-link>
          <small>
            <span v-if="paymentRequest.paidOn">
              {{ format(new Date(paymentRequest.paidOn), 'MMMM do, yyyy') }}
            </span>
            <span v-else-if="paymentRequest.exemptedOn">
              {{ monthDayYear(paymentRequest.exemptedOn) }}
            </span>
            <span v-else-if="paymentRequest.dueDate">
              {{ monthDayYear(paymentRequest.dueDate) }}
            </span>
          </small>
        </div>
      </div>
      <div v-if="!paymentRequests?.data.length">No payment requests found.</div>
      <s-paginator :paginator="paymentRequests" />
    </section>
  </div>

  <s-modal
    v-model:open="showPaymentRequestConfirmation"
    title="Send Payment Request"
    :confirm="{
      label: 'Send Requests',
      icon: 'email-arrow-right',
      disabled: !course.priceId || !course.paymentDueDate,
    }"
    @confirm="() => router.post(route('admin.courses.payment-requests.sendRequest', course.id))"
  >
    <template #content>
      <p v-if="course.paymentDueDate || course.priceId">
        Are you sure you want to send payment requests to all unpaid users?
      </p>
      <p v-else>
        Make sure to add a
        <strong>Price Id</strong>
        and a
        <strong>Payment Due Date</strong>
        before doing this.
      </p>
    </template>
  </s-modal>

  <s-modal
    v-model:open="showDisableUsersConfirmation"
    title="Deactivate Unpaid Users"
    :confirm="{
      label: 'Deactivate Users',
      icon: 'account-cancel',
      color: 'red',
      disabled: !course.priceId || !course.paymentDueDate,
    }"
    @confirm="
      () => router.post(route('admin.courses.payment-requests.deactivateUnpaid', course.id))
    "
    cancellable
  >
    <template #content>
      <p v-if="course.paymentDueDate || course.priceId">
        Are you sure you want to disable all users who have not paid for this course?
      </p>
      <p v-else>
        Make sure to add a
        <strong>Price Id</strong>
        and a
        <strong>Payment Due Date</strong>
        before doing this.
      </p>
    </template>
  </s-modal>
</template>
<script setup lang="ts">
import {LaravelPaginator} from '../../../types/laravel-paginator';
import SLink from '../../../components/SLink.vue';
import {route} from 'ziggy-js';
import SPaginator from '../../../design-system/SPaginator.vue';
import {Course} from '../../../types/entities/course';
import {ref} from 'vue';
import SModal from '../../../design-system/SModal.vue';
import {PaymentRequest} from '../../../types/entities/payment-request';
import {format} from 'date-fns';
import PaymentRequestStatusBadge from '../../../components/payment-requests/PaymentRequestStatusBadge.vue';
import {monthDayYear} from '../../../format/dates';
import SSearchSection from '../../../design-system/SSearchSection.vue';
import {Head} from '@inertiajs/vue3';
import {router} from '@inertiajs/vue3';
import SModalContent from '../../../design-system/SModalContent.vue';

defineProps<{
  course: Course;
  paymentRequests: LaravelPaginator<PaymentRequest>;
}>();

const showPaymentRequestConfirmation = ref(false);
const showDisableUsersConfirmation = ref(false);
</script>
