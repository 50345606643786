<template>
  <s-btn @click="open = !open">{{ open ? 'Close' : 'Open' }}</s-btn>

  <s-dialog v-model:open="open" modal>
    <template #default="{close, forceClose}">
      <SCard heading="Confirming to Close">
        <template #actions>
          <s-btn color="secondary" @click="close" autofocus>Close</s-btn>
        </template>
        <p>
          When you try to close this modal, the
          <code>confirmClose</code>
          template will be rendered.
        </p>
        <p>
          That template is given
          <code>close</code>
          and
          <code>cancel</code>
          callbacks.
        </p>
        <p>You can also force close if desired.</p>
        <s-btn @click.prevent="forceClose">Close without Confirming</s-btn>
      </SCard>
    </template>

    <template #confirmClose="{close, cancel}">
      <s-dialog @close="cancel" class="flex justify-center items-center" modal>
        <SCard>
          Are you sure you want to close?

          <div class="mt-2 flex gap-2">
            <s-btn @click="close" color="danger">Yes close it!</s-btn>

            <s-btn @click="cancel" color="secondary">Continue Editing</s-btn>
          </div>
        </SCard>
      </s-dialog>
    </template>
  </s-dialog>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
import {ref} from 'vue';
import SCard from '../../../../design-system/SCard.vue';
import SDialog from '../../../../components/SDialog.vue';

const open = ref(false);
</script>
