import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import MultiSelectUiConfig = App.Entities.Tasks.Ui.MultiSelectUiConfig;
import MultiSelectComponentGrader = App.Entities.Tasks.ComponentGraders.MultiSelectComponentGrader;

export const multiSelect = defineComponentType<MultiSelectUiConfig, MultiSelectComponentGrader>({
  translationKey: 'Multi-Select',
  icon: 'format-list-checkbox',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    uiType: 'checkboxes',
    type: 'MultiSelect',
    name: 'Multi Select',
    label: '',
    options: [],
    randomizeOptionOrder: false,
    part: null,
  }),
  componentGraderFactory: (uuid) => ({
    uuid: uuidv4(),
    type: 'MultiSelect',
    name: 'Multi Select Grader',
    uiComponentUuid: uuid,
    correctAnswers: [],
    points: null,
  }),
});
