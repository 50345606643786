<template>
  <s-tooltip :text="t('discardDraft')">
    <s-btn
      method="delete"
      color="white"
      @click="showDeleteDraftConfirmation = true"
      :disabled="props.disabled"
    >
      <s-icon
        name="trash-can"
        class="text-red-600 group-hover:text-red-500 group-active:text-red-600 opacity-70 -ml-0.5"
        size="18"
      />
      <span class="text-gray-500">{{ t('discardDraft') }}</span>
    </s-btn>
  </s-tooltip>
  <s-modal
    v-model:open="showDeleteDraftConfirmation"
    :title="t('discardDraft')"
    :message="t('confirmMessage')"
    :confirm="{
      label: t('actions.discard'),
      icon: 'trash-can',
      color: 'red',
    }"
    @confirm="discardDraft"
    cancellable
  />
</template>
<script setup lang="ts">
import {ref} from 'vue';
import SBadge from '@/design-system/SBadge.vue';
import SBtn from '@/design-system/SBtn.vue';
import SIcon from '@/design-system/SIcon.vue';
import STooltip from '@/design-system/STooltip.vue';
import SModal from '@/design-system/SModal.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  discardDraft: () => void;
  disabled: boolean;
}>();

const showDeleteDraftConfirmation = ref(false);

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "discardDraft": "Discard Draft",
    "confirmMessage": "Are you sure you want to delete this draft? This action cannot be undone."
  },
  "fr": {
    "discardDraft": "Supprimer le brouillon",
    "confirmMessage": "Êtes-vous sûr de vouloir supprimer ce brouillon? Cette action ne peut pas être défaite."
  }
}
</i18n>
