<template>
  <Head :title="`${t('participants')} - ${course.code}`"></Head>
  <s-page-block-scrollable>
    <template #header>
      <s-page-header :heading="t('participants')" class="mb-4">
        <template #actions>
          <s-btn
            v-if="can.sendInvitations"
            color="white"
            icon="email-fast-outline"
            @click="sendInvitation()"
          >
            {{ t('actions.sendInvitations') }}
          </s-btn>
          <s-dropdown-menu
            v-if="can.updateIn"
            :label="t('links.importFromCsv')"
            icon="table-account"
            :items="[
              {
                href: route('courses.staff.create', course.id),
                icon: 'account-school',
                label: t('actions.manageStaff'),
              },
              {
                href: route('courses.students.create', course.id),
                icon: 'account-group',
                label: t('actions.manageStudents'),
              },
            ]"
          />
          <s-btn
            v-if="can.createIn"
            icon="plus"
            :href="route('courses.participants.create', course.id)"
          >
            {{ t('links.addParticipants') }}
          </s-btn>
        </template>
      </s-page-header>
      <s-search-section
        route-name="courses.participants.index"
        :label="t('aria-labels.search', {course: course.code})"
        :paginatedResults="participants?.data.length || 0"
        :totalResults="participants?.total || 0"
        :aria-label="t('aria-labels.search', {course: course.code})"
        class="mb-4"
      >
        <template #filters>
          <s-select-field
            id="section"
            :label="t('section')"
            v-model="form.sectionId"
            @change="filterChange()"
          >
            <option value="">{{ t('select.allSections') }}</option>
            <option v-for="section in sections" :key="section.id" :value="`${section.id}`">
              {{ section.name }}
            </option>
          </s-select-field>
          <s-select-field
            id="role"
            :label="t('role')"
            v-model="form.roleId"
            @change="filterChange()"
          >
            <option value="">{{ t('select.allRoles') }}</option>
            <option v-for="role in roles" :key="role.id" :value="`${role.id}`">
              {{ t('roleNames.' + role.name) }}
            </option>
          </s-select-field>
        </template>
        <template #badges>
          <s-search-badge
            v-if="orderBy"
            @clear="setOrderBy('')"
            :clearLabel="t('table.clearOrdering')"
          >
            {{ t('table.orderedBy') }}
            <strong class="text-blue-600 font-bold">
              {{
                orderBy === 'lastName'
                  ? t('lastName')
                  : orderBy === 'firstName'
                    ? t('firstName')
                    : orderBy === 'email'
                      ? t('email')
                      : orderBy
              }},
            </strong>
            <strong class="text-blue-600 font-bold">
              {{
                (route().params as PageParams).orderByDirection === 'ASC'
                  ? t('table.az')
                  : t('table.za')
              }}
            </strong>
          </s-search-badge>
        </template>
      </s-search-section>
    </template>
    <div class="participants-table-wrapper">
      <table class="participants-table min-w-full max-w-full">
        <thead class="">
          <tr>
            <th
              class="text-left whitespace-nowrap text-blue-600 hover:text-blue-500 cursor-pointer"
              @click="setOrderBy('lastName')"
            >
              <span class="flex items-center gap-1">
                {{ t('lastName') }}
                <table-sorting-icon
                  column="lastName"
                  :order-by="orderBy"
                  :order-by-direction="orderByDirection"
                  direction="ASC"
                />
              </span>
            </th>
            <th
              class="text-left whitespace-nowrap text-blue-600 hover:text-blue-500 cursor-pointer"
              @click="setOrderBy('firstName')"
            >
              <span class="flex items-center gap-1">
                {{ t('firstName') }}
                <table-sorting-icon
                  column="firstName"
                  :order-by="orderBy || ''"
                  :order-by-direction="orderByDirection"
                  :direction="orderByDirection"
                />
              </span>
            </th>
            <th
              class="text-left text-blue-600 hover:text-blue-500 cursor-pointer"
              @click="setOrderBy('email')"
            >
              <span class="flex items-center gap-1">
                {{ t('email') }}
                <table-sorting-icon
                  column="email"
                  :order-by="orderBy"
                  :order-by-direction="orderByDirection"
                  :direction="orderByDirection"
                />
              </span>
            </th>
            <th class="text-left text-gray-600">{{ t('role') }}</th>
            <th class="text-left text-gray-600">{{ t('section') }}</th>
            <th class="text-left text-gray-600" v-if="can.updateIn"></th>
          </tr>
        </thead>
        <tbody class="rounded-lg shadow">
          <tr v-if="participants?.data.length < 1">
            <td colspan="6" class="italic text-gray-400">{{ t('search.noResults') }}</td>
          </tr>
          <tr v-for="participant in participants?.data">
            <td class="text-left">
              <s-link
                :href="route('courses.participants.show', [course.id, participant.id])"
                class="link"
              >
                {{ participant.lastName }}
              </s-link>
            </td>
            <td class="text-left">
              <s-link
                :href="route('courses.participants.show', [course.id, participant.id])"
                class="link"
              >
                {{ participant.firstName }}
              </s-link>
            </td>
            <td class="text-left min-w-0">
              <div class="flex items-center gap-1.5">
                <span class="relative">
                  <s-email-status-icon
                    :verified="participant.hasVerifiedEmail"
                    :sent="!!participant.invitationCreatedAt"
                  />
                </span>
                <span class="">{{ participant.email }}</span>
              </div>
            </td>
            <td class="text-left">
              {{
                participant.roles
                  .split(',')
                  .map((name) => t('roleNames.' + name))
                  .join(', ')
              }}
            </td>
            <td class="text-left whitespace-nowrap">
              {{ participant.sections.split(',').join(', ') }}
            </td>
            <td v-if="can.updateIn">
              <s-link
                class="link whitespace-nowrap"
                :href="route('courses.participants.edit', [course.id, participant.id])"
              >
                {{ t('links.editRoles') }}
              </s-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <s-paginator :paginator="participants" />
    </template>
  </s-page-block-scrollable>
  <s-modal
    v-if="sendInvitationsConfirmationModal"
    :title="t('invitations.title')"
    v-model:open="sendInvitationsConfirmationModal"
    cancellable
  >
    <template #content>
      <p class="text-lg mb-2 text-gray-700">
        {{ t('invitations.description') }}
      </p>
      <div class="flex flex-col">
        <s-card-btn @click="sendInvites(false, false)" icon="email-alert" class="rounded-b-none">
          {{ t('invitations.without-invite') }}
        </s-card-btn>
        <s-card-btn
          @click="sendInvites(true, false)"
          icon="account-off"
          class="border-t-0 rounded-t-none"
        >
          {{ t('invitations.all-inactive') }}
        </s-card-btn>
        <s-card-btn
          @click="sendInvites(true, true)"
          icon="account-arrow-right"
          class="border-t-0 rounded-t-none"
        >
          {{ t('invitations.course-invites') }}
        </s-card-btn>
      </div>
    </template>
  </s-modal>
</template>
<script setup lang="ts">
import {Head, router, useForm, usePage} from '@inertiajs/vue3';
import {LaravelPaginator} from '../../types/laravel-paginator';
import SLink from '../../components/SLink.vue';
import SDropdownMenu from '../../design-system/SDropdownMenu.vue';
import {route, RouteParams} from 'ziggy-js';
import SPaginator from '../../design-system/SPaginator.vue';
import {Course} from '../../types/entities/course';
import SSelectField from '../../design-system/SSelectField.vue';
import SPageBlockScrollable from '../../design-system/SPageBlockScrollable.vue';
import SSearchBadge from '../../design-system/SSearchBadge.vue';
import SSearchSection from '../../design-system/SSearchSection.vue';
import TableSortingIcon from '../../design-system/TableSortingIcon.vue';
import SBtn from '../../design-system/SBtn.vue';
import SCardBtn from '../../design-system/SCardBtn.vue';
import CourseParticipantDto = App.DTOs.CourseParticipantDto;
import {useI18n} from 'vue-i18n';
import SEmailStatusIcon from '../../components/SEmailStatusIcon.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SModal from '../../design-system/SModal.vue';
import {ref, shallowRef} from 'vue';
import {usePageParams} from '../../composables/usePageParams';

type Role = {
  id: string;
  name: string;
};

type PageParams = {
  orderByDirection?: 'ASC' | 'DESC';
  orderBy?: string;
  search?: string;
  sectionId?: string;
  roleId?: string;
};

const props = defineProps<{
  course: Course;
  sections: {id: string; name: string}[];
  roles: Role[];
  participants: LaravelPaginator<CourseParticipantDto>;
  can: {
    createIn: boolean;
    updateIn: boolean;
    sendInvitations: boolean;
  };
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const sendInvitationsConfirmationModal = ref(false);
const {
  search: searchText,
  sectionId,
  roleId,
  orderBy,
  orderByDirection,
} = usePageParams<PageParams>({
  orderByDirection: 'ASC',
  orderBy: '',
  sectionId: '',
  roleId: '',
});

const form = useForm({
  searchText,
  sectionId,
  roleId,
  orderBy,
  orderByDirection,
});

const clearSearch = () => {
  form.searchText = '';
  submit();
};

const setOrderBy = (field: string) => {
  if (!field) {
    form.orderBy = '';
    form.orderByDirection = 'ASC';
  } else if (field === form.orderBy) {
    form.orderByDirection = form.orderByDirection === 'ASC' ? 'DESC' : 'ASC';
  } else {
    form.orderBy = field;
    form.orderByDirection = 'ASC';
  }
  submit();
};

const filterChange = () => {
  submit();
};

const submit = () => {
  router.visit(
    route('courses.participants.index', {
      course: props.course.id,
      search: form.searchText,
      sectionId: form.sectionId,
      roleId: form.roleId,
      orderBy: form.orderBy,
      orderByDirection: form.orderByDirection,
    } as RouteParams<any>)
  );
};

function sendInvitation() {
  sendInvitationsConfirmationModal.value = true;
}

function sendInvites(resend: boolean, newCourseNotification: boolean) {
  router.post(route('courses.send_invitations', [props.course.id]), {
    resend: resend,
    newCourseNotification: newCourseNotification,
  });
  sendInvitationsConfirmationModal.value = false;
}
</script>
<style scss>
.participants-table-wrapper {
  @apply shrink overflow-y-scroll relative w-full m-auto z-[1] rounded-lg shadow-card bg-white mt-1 mb-5;

  /* https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ */
  background: /* Shadow Cover RIGHT */
    linear-gradient(to left, white 1rem, rgba(255, 255, 255, 0)) center right,
    /* Shadow RIGHT */ linear-gradient(to left, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) center
      right,
    /* Shadow Cover BOTTOM */ linear-gradient(to top, white 1rem, rgba(255, 255, 255, 0)) bottom
      center,
    /* Shadow BOTTOM */ linear-gradient(to top, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) bottom
      center,
    white;

  background-repeat: no-repeat;
  background-size:
    3rem 100%,
    1rem 100%,
    100% 3rem,
    100% 1rem,
    100% 100%;
  background-attachment: local, scroll, local, scroll, local;
}

.participants-table {
  @apply border-separate border-spacing-0 w-full;

  thead {
    th {
      @apply shadow-sm relative px-2 first:pl-4 last:pr-4 md:px-3 py-3 leading-[1.125] bg-gray-50 sticky top-0 z-[10] border-y first:border-l last:border-r border-gray-300/50 border-b-gray-300/40 first:rounded-tl-lg  last:rounded-tr-lg;
    }
  }

  tbody {
    td {
      @apply relative px-2 first:pl-4 last:pr-4 md:px-3 py-3 leading-[1.125] border-b border-gray-400/15;
    }

    tr {
      &:nth-child(even) td {
        @apply bg-gray-300/10;
      }

      &:last-child {
        td {
          @apply first:rounded-bl-lg last:rounded-br-lg;
        }
      }
    }
  }
}
</style>
<i18n>
{
  "en": {
    "select": {
      "allSections": "All Sections",
      "allRoles": "All Roles"
    },
    "actions": {
      "sendInvitations": "Send Invitations",
      "manageStudents": "Manage Students",
      "manageStaff": "Manage Staff"
    },
    "links": {
      "addParticipants": "Add Participants",
      "importFromCsv": "Import from CSV",
      "editRoles": "Edit Roles"
    },
    "aria-labels": {
      "search": "Search for staff and students in {course}"
    },
    "invitations": {
      "title": "Send Invitations",
      "description": "What users would you like to send invitations to?",
      "without-invite": "Only New Users",
      "all-inactive": "All Non-Registered Users",
      "course-invites": "All Users"
    }
  },
  "fr": {
    "select": {
      "allSections": "Toutes les sections",
      "allRoles": "Tous les rôles"
    },
    "actions": {
      "sendInvitations": "Envoyer des invitations",
      "manageStudents": "Gérer les étudiants",
      "manageStaff": "Gérer le personnel"
    },
    "links": {
      "addParticipants": "Ajouter des participants",
      "importFromCsv": "Importer depuis CSV",
      "editRoles": "Modifier les rôles"
    },
    "aria-labels": {
      "search": "Rechercher du personnel et des étudiants dans {course}"
    },
    "invitations": {
      "title": "Envoyer des invitations",
      "description": "À quels utilisateurs souhaitez-vous envoyer des invitations?",
      "without-invite": "Uniquement les nouveaux utilisateurs",
      "all-inactive": "Tous les utilisateurs non inscrits",
      "course-invites": "Tous les utilisateurs"
    }
  }
}
</i18n>
