<template>
  <task-form :errors="errors" :form="form" class="fieldset">
    <s-input-field
      id="heading"
      label="Heading"
      description="Displayed at the top of the task in large font. This is the title visible to students."
      :required="false"
      v-model="form.ui.heading"
      :error="errors?.['ui.heading']"
    />
    <s-field id="uiContext" label="Context Items" :error="errors?.['ui.uiContext']">
      <fieldset class="flex flex-col mt-2">
        <div class="flex gap-2 items-end justify-between mb-4">
          <s-btn
            @click.prevent="addUiContext()"
            :disabled="form.ui.uiContext.length >= 25"
            class="mt-2"
            icon="plus"
            size="sm"
          >
            Add Displayed Context Item
          </s-btn>
        </div>
        <vue-draggable
          v-model="form.ui.uiContext"
          group="questions"
          tag="transition-group"
          animation="200"
          ghost-class="ghost-item"
          drag-class="dragging-item"
          handle=".cursor-grab"
          :component-data="{
            tag: 'div',
            class: 'card flex flex-col divide-y divide-gray-200 mb-2 overflow-hidden',
          }"
        >
          <template #item="{element, index}">
            <div
              :key="element.key"
              class="flex items-stretch divide-x divide-gray-150 bg-white even:bg-gray-50"
            >
              <div
                class="flex-1 flex items-center cursor-grab px-2.5 transition hover:text-blue-500 hover:bg-gray-200/30"
              >
                <s-icon name="drag-vertical" class="opacity-70" width="22" height="22" />
              </div>
              <div class="px-4 pt-2.5 pb-3.5 flex flex-col gap-2 w-full">
                <s-select-field
                  v-model="form.ui.uiContext[index].contextType"
                  @update:model-value="updateContextName(index)"
                  label="Context Type"
                  id="contextType"
                >
                  <option
                    v-for="(label, key) in UI_DISPLAYED_CONTEXT_LABELS"
                    :value="key"
                    :disabled="
                      (key === 'randomized_passage' && hasRandomizedPassage) ||
                      (key === 'file_upload' && hasFileUpload)
                    "
                  >
                    {{ label }}
                  </option>
                </s-select-field>
                <s-input-field
                  v-model="form.ui.uiContext[index].name"
                  id="name"
                  label="Context Name"
                  :error="errors?.['ui.uiContext.' + index + '.name']"
                />
                <div v-if="form.ui.uiContext[index].contextType == 'text_block'">
                  <s-textarea-field
                    class="mb-2"
                    id="text"
                    label="Text Displayed to Student"
                    v-model="form.ui.uiContext[index].text"
                    :error="errors?.['ui.uiContext.' + index + '.text']"
                  />
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'student_response'">
                  <s-field
                    class="mt-2 mb-2"
                    id="showCharacterLimit"
                    label="Character Limit to Student Response"
                  >
                    <div class="flex items-center space-x-2">
                      <s-button-toggle
                        id="showCharacterLimit"
                        :options="[
                          {value: null, label: 'No'},
                          {value: form.ui.uiContext[index].characterLimit ?? '0', label: 'Yes'},
                        ]"
                        v-model="form.ui.uiContext[index].characterLimit"
                      />
                      <template v-if="form.ui.uiContext[index].characterLimit !== null">
                        <s-input-field
                          id="characterLimit"
                          :required="false"
                          type="number"
                          min="0"
                          v-model="form.ui.uiContext[index].characterLimit"
                        />
                      </template>
                    </div>
                  </s-field>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'dynamic_table'">
                  <s-textarea-field
                    class="mb-2"
                    id="text"
                    label="Table Headers"
                    description="Comma separated list of headers. The list size will determine the initial number of columns."
                    v-model="form.ui.uiContext[index].text"
                    :error="errors?.ui?.uiContext?.[index]?.text"
                  ></s-textarea-field>
                  <div class="flex flex-wrap gap-4">
                    <s-field
                      id="allowEditableHeaders"
                      label="Allow student to edit table header titles"
                    >
                      <s-button-toggle
                        id="allowEditableHeaders"
                        :options="[
                          {value: false, label: 'No'},
                          {value: true, label: 'Yes'},
                        ]"
                        v-model="form.ui.uiContext[index].allowEditableHeaders"
                      ></s-button-toggle>
                    </s-field>
                    <s-field id="allowDynamicColumns" label="Allow student to add/remove columns">
                      <s-button-toggle
                        id="allowDynamicColumns"
                        :options="[
                          {value: false, label: 'No'},
                          {value: true, label: 'Yes'},
                        ]"
                        v-model="form.ui.uiContext[index].allowDynamicColumns"
                      ></s-button-toggle>
                    </s-field>
                  </div>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'file_upload'">
                  <div class="flex flex-wrap gap-4">
                    <s-textarea-field
                      class="mb-2"
                      id="text"
                      label="Allowed File Extensions"
                      description="A comma separated list of allowed file extensions. Leave this blank to allow all file types. Ex) '.pdf, ...'"
                      v-model="form.ui.uiContext[index].text"
                      :error="errors?.ui?.uiContext?.[index]?.text"
                    ></s-textarea-field>
                  </div>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'multiple_choice'">
                  <s-textarea-field
                    class="mb-2"
                    id="text"
                    label="Question"
                    description="The question to be displayed to the student."
                    v-model="form.ui.uiContext[index].text"
                    :error="errors?.ui?.uiContext?.[index]?.text"
                  />
                  <s-form-list
                    title="Options"
                    addLabel="Add Option"
                    :addItem="
                      () =>
                        form.ui.uiContext[index].multipleChoiceOptions.push({key: '', label: ''})
                    "
                    :items="form.ui.uiContext[index].multipleChoiceOptions"
                    :removeItem="
                      (item: any, itemIndex: number) =>
                        form.ui.uiContext[index].multipleChoiceOptions.splice(itemIndex, 1)
                    "
                    :sortable="true"
                    placeholder="No Options Added"
                  >
                    <template #item="{index: optIndex}">
                      <div class="p-5">
                        <div
                          class="flex-1 w-full flex flex-wrap gap-x-4 gap-y-3 justify-start mb-3"
                        >
                          <div class="flex-1 max-w-[10rem] grow">
                            <s-input-field
                              :id="`options.${optIndex}.key`"
                              label="Key"
                              v-model="form.ui.uiContext[index].multipleChoiceOptions[optIndex].key"
                              :error="
                                errors?.ui?.uiContext?.[index]?.multipleChoiceOptions?.[optIndex]
                                  ?.key
                              "
                            />
                          </div>
                          <div class="flex-1 grow-[2]">
                            <s-input-field
                              :id="`options.${optIndex}.label`"
                              label="Label"
                              v-model="
                                form.ui.uiContext[index].multipleChoiceOptions[optIndex].label
                              "
                              :error="
                                errors?.ui?.uiContext?.[index]?.multipleChoiceOptions?.[optIndex]
                                  ?.label
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                  </s-form-list>
                  <div class="flex flex-wrap gap-4">
                    <s-input-field
                      id="multipleChoicePointsAvailable"
                      v-model.number="form.ui.uiContext[index].weight"
                      type="number"
                      label="Points Available (optional)"
                      tooltip="When this is not set, the question will be worth 0 points."
                      :error="errors?.ui?.uiContext?.[index]?.weight"
                    />
                    <s-select-field
                      v-model="form.ui.uiContext[index].correctMultipleChoiceAnswer"
                      label="Correct Option"
                      id="correctMultipleChoiceAnswer"
                      :error="errors?.ui?.uiContext?.[index]?.correctMultipleChoiceAnswer"
                    >
                      <option
                        v-for="option in form.ui.uiContext[index].multipleChoiceOptions"
                        :value="option.key"
                      >
                        {{ option.label }}
                      </option>
                    </s-select-field>
                  </div>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'randomized_passage'">
                  <s-btn @click.prevent="addTextPassage(index)" class="mt-2" icon="plus" size="sm">
                    Add Passage
                  </s-btn>
                  <div
                    v-for="(textPassage, passageIndex) in form.ui.uiContext[index]
                      .randomTextPassages"
                    :key="index"
                  >
                    <s-textarea-field
                      class="mb-2 w-full"
                      id="text"
                      label="Text Passage"
                      v-model="form.ui.uiContext[index].randomTextPassages[passageIndex]"
                      :error="
                        errors?.['ui.uiContext.' + index + '.randomTextPassages.' + passageIndex]
                      "
                    />
                    <button
                      @click.prevent="removeTextPassage(index, passageIndex)"
                      class="flex items-center border-right-1 px-3 text-red-500 hover:bg-red-100/30 transition"
                    >
                      <s-icon name="trash-can" class="opacity-70" width="18" height="18" />
                    </button>
                  </div>
                </div>
              </div>
              <button
                @click.prevent="form.ui.uiContext.splice(index, 1)"
                class="flex items-center border-right-1 px-3 text-red-500 hover:bg-red-100/30 transition"
              >
                <s-icon name="trash-can" class="opacity-70" width="18" height="18" />
              </button>
            </div>
          </template>
        </vue-draggable>
      </fieldset>
    </s-field>
    <s-field id="graders" label="AI Graders" :error="errors?.['grader.contextGraders']">
      <div
        class="card padded"
        v-for="(contextGrader, graderIndex) in form.grader.contextGraders"
        :key="graderIndex"
      >
        <s-textarea-field
          class="mt-2 mb-2"
          id="rubric"
          label="Overall Rubric"
          description="The specific question being evaluated can be included here"
          v-model="form.grader.contextGraders[graderIndex].rubric"
          :error="errors?.['grader.contextGraders.' + graderIndex + '.rubric']"
        />
        <s-field
          id="linkedStudentAnswer"
          label="Linked Context/Response"
          description="The responses/context to expose to the Ai Grading"
          class="mb-4"
        >
          <div
            v-for="(uiContext, ctxIndex) in form.ui.uiContext"
            :key="ctxIndex"
            class="flex items-center"
          >
            <input
              type="checkbox"
              :value="uiContext.uuid"
              v-model="form.grader.contextGraders[graderIndex].linkedStudentAnswers"
            />
            <label class="ml-2">{{ uiContext.name }}</label>
          </div>
        </s-field>
        <s-input-field
          class="mt-2 mb-2"
          id="score"
          type="number"
          step="1"
          min="0"
          label="Question Weight"
          v-model="form.grader.contextGraders[graderIndex].scoreMaxValue"
          :error="errors?.['grader.contextGraders.' + graderIndex + '.scoreMaxValue']"
        />
        <div
          class="card padded"
          v-for="(criteria, criteriaIndex) in form.grader.contextGraders[graderIndex].criteria"
          :key="criteriaIndex"
        >
          <s-input-field
            id="text"
            label="Name"
            v-model="criteria.name"
            :error="
              errors?.[
                'grader.contextGraders.' + graderIndex + '.criteria' + criteriaIndex + '.name'
              ]
            "
          />
          <s-textarea-field
            id="text"
            label="Criteria Item Rubric"
            v-model="criteria.criteriaContent"
            :error="
              errors?.[
                'grader.contextGraders.' +
                  graderIndex +
                  '.criteria.' +
                  criteriaIndex +
                  '.criteriaContent'
              ]
            "
          />
          <div class="flex flex-wrap gap-4 mb-1 justify-between">
            <grade-increment-field
              v-model="criteria.gradeIncrement"
              :errors="
                errors?.[
                  'grader.contextGraders.' +
                    graderIndex +
                    '.criteria.' +
                    criteriaIndex +
                    '.gradeIncrement'
                ]
              "
            />
            <s-input-field
              id="score"
              type="number"
              step="1"
              min="0"
              label="Grade Weight"
              v-model="criteria.gradeWeight"
              :error="
                errors?.[
                  'grader.contextGraders.' +
                    graderIndex +
                    '.criteria.' +
                    criteriaIndex +
                    '.gradeWeight'
                ]
              "
            />
          </div>
          <s-btn
            @click.prevent="
              form.grader.contextGraders[graderIndex].criteria.splice(criteriaIndex, 1)
            "
            class="mt-2"
            icon="trash-can"
            size="sm"
            color="danger"
          >
            Remove
          </s-btn>
        </div>
        <s-field id="criteria" label="Criteria Items" :error="errors?.['grader.criteria']">
          <s-btn
            @click.prevent="
              form.grader.contextGraders[graderIndex].criteria.push({
                name: '',
                criteriaContent: '',
                gradeIncrement: 0.25,
                gradeWeight: 1,
              })
            "
            :disabled="form.grader.contextGraders[graderIndex].criteria.length >= 5"
            class="mt-2"
            icon="plus"
            size="sm"
          >
            Add Criteria Item
          </s-btn>
        </s-field>
        <s-btn
          @click.prevent="removeGrader(graderIndex)"
          class="mt-2"
          icon="trash-can"
          size="sm"
          color="danger"
        >
          Remove
        </s-btn>
      </div>
      <s-btn
        @click.prevent="addGrader()"
        :disabled="form.grader.contextGraders.length >= 10"
        class="mt-2"
        icon="plus"
        size="sm"
      >
        Add AI Grader
      </s-btn>
    </s-field>
    <div class="flex flex-wrap gap-4 mb-1">
      <s-field id="returnGrade" label="Return Student Grade">
        <s-button-toggle
          id="returnGrade"
          :options="[
            {value: false, label: 'No'},
            {value: true, label: 'Yes'},
          ]"
          v-model="form.grader.returnGrade"
          :error="errors?.['grader.returnGrade']"
        />
      </s-field>
      <template v-if="form.grader.returnGrade">
        <s-field id="showGradeBreakdown" label="Display Grade Breakdown">
          <s-button-toggle
            id="showGradeBreakdown"
            :options="[
              {value: false, label: 'No'},
              {value: true, label: 'Yes'},
            ]"
            v-model="form.grader.showGradeBreakdown"
            :error="errors?.['grader.showGradeBreakdown']"
          />
        </s-field>
      </template>
      <s-input-field
        id="Subject"
        label="Subject"
        placeholder="Chemistry"
        :required="false"
        v-model="form.grader.subject"
        :error="errors?.['grader.subject']"
      />
    </div>
    <s-field id="useManualFeedback" label="Manual Feedback">
      <s-button-toggle
        id="useManualFeedback"
        :options="[
          {value: false, label: 'No'},
          {value: true, label: 'Yes'},
        ]"
        v-model="form.grader.useManualFeedback"
      />
      <s-field id="manualFeedback" v-if="form.grader.useManualFeedback">
        <div
          class="card padded"
          v-for="(manualFeedback, index) in form.grader.manualFeedbacks"
          :key="index"
        >
          <s-input-field
            id="manualFeedbackText"
            label="Feedback Text"
            description="The actual feedback returned to a student."
            v-model="form.grader.manualFeedbacks[index].content"
            :error="errors?.['grader.manualFeedbacks.' + index + '.content']"
          />
          <s-input-field
            id="manualFeedbackTitle"
            label="Feedback Part Title"
            v-model="form.grader.manualFeedbacks[index].part"
            :error="errors?.['grader.manualFeedbacks.' + index + '.part']"
          />
          <s-btn
            @click.prevent="removeManualFeedback(index)"
            class="mt-2"
            icon="trash-can"
            size="sm"
            color="danger"
          >
            Remove
          </s-btn>
        </div>
        <s-btn
          @click.prevent="
            form.grader.manualFeedbacks.push({
              key: 'manualFeedback' + form.grader.manualFeedbacks.length,
              content: '',
              part: null,
            })
          "
          class="mt-2"
          icon="plus"
          size="sm"
        >
          Add Manual Feedback
        </s-btn>
      </s-field>
    </s-field>
    <div class="flex justify-between">
      <FeedbackInstructionsBuilder
        v-if="form.grader?.feedbackInstructions"
        v-model:modelValue="form.grader.feedbackInstructions"
        :errors="errors?.grader?.feedbackInstructions"
      />
    </div>

    <div class="flex justify-between" v-if="page.props.loggedInUser.isSuperUser">
      <s-field id="llmConfig" label="LLM Configuration" :errors="errors?.grader?.llmOptions" />
      <LlmOptionsConfiguration
        v-if="form.grader.llmOptions"
        v-model="form.grader.llmOptions"
        :modelsMetadata="modelsMetadata"
        :errors="errors?.grader?.llmOptions"
      />
    </div>
    <s-field
      id="useLevelledFeedback"
      label="Levelled Feedback"
      description="Only one additional feedback item is returned based on the determined grade. Grade Range to display feedback: Min < Grade <= Max."
    >
      <div class="flex flex-wrap gap-4 mb-1">
        <s-button-toggle
          id="useLevelledFeedback"
          :options="[
            {value: false, label: 'No'},
            {value: true, label: 'Yes'},
          ]"
          v-model="form.grader.useLevelledFeedback"
          :error="errors?.['grader.useLevelledFeedback']"
        />
        <template v-if="form.grader.useLevelledFeedback">
          <s-input-field
            id="levelledFeedbackPartTitle"
            label="Feedback Part Title"
            placeholder="General Assessment"
            :required="false"
            v-model="form.grader.levelledFeedbackPartTitle"
            :error="errors?.['grader.levelledFeedbackPartTitle']"
          />
        </template>
      </div>
      <s-field id="levelledFeedback" v-if="form.grader.useLevelledFeedback">
        <div
          class="card padded"
          v-for="(levelledFeedback, index) in form.grader.levelledFeedbacks"
          :key="index"
        >
          <s-input-field
            id="levelledFeedbackText"
            label="Feedback Text"
            description="The actual feedback returned to a student."
            v-model="form.grader.levelledFeedbacks[index].content"
            :error="errors?.['grader.levelledFeedbacks.' + index + '.content']"
          />
          <div class="flex flex-wrap gap-4 mb-1">
            <s-input-field
              id="levelMinGrade"
              type="number"
              step="0.01"
              min="0"
              max="1"
              label="Minimum Grade to Display This Levelled Feedback"
              v-model="levelledFeedback.levelMinGrade"
              :error="errors?.['grader.levelledFeedbacks.' + index + '.levelMinGrade']"
            />
            <s-input-field
              id="levelMaxGrade"
              type="number"
              step="0.01"
              min="0"
              max="1"
              label="Maximum Grade to Display This Levelled Feedback"
              v-model="levelledFeedback.levelMaxGrade"
              :error="errors?.['grader.levelledFeedbacks.' + index + '.levelMaxGrade']"
            />
          </div>
          <s-btn
            @click.prevent="removeLevelledFeedback(index)"
            class="mt-2"
            icon="trash-can"
            size="sm"
            color="danger"
          >
            Remove
          </s-btn>
        </div>
        <s-btn @click.prevent="addLevelledFeedback()" class="mt-2" icon="plus" size="sm">
          Add Levelled Feedback
        </s-btn>
      </s-field>
    </s-field>
  </task-form>
</template>
<script lang="ts" setup>
import {computed, watch} from 'vue';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import VueDraggable from 'vuedraggable';
import SSelectField from '../../../design-system/SSelectField.vue';
import {v4 as uuidV4} from 'uuid';
import SFormList from '../../../design-system/SFormList.vue';
import GradeIncrementField from '../../../components/tasks/ai-parts/GradeIncrementField.vue';
import SIcon from '../../../design-system/SIcon.vue';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm, usePage} from '@inertiajs/vue3';
import {LlmMetadata} from '../../../types/entities/llmMetadata';
import LlmOptionsConfiguration from '../../../components/tasks/ai-parts/LlmOptionsConfiguration.vue';
import FeedbackInstructionsBuilder from '../../../components/tasks/ai-parts/FeedbackInstructionsBuilder.vue';
import AiSelectableContextCriteriaQuestionFormDto = App.DTOs.Tasks.AiSelectableContextCriteriaQuestionFormDto;
import AiSelectableContextCriteriaGraderDto = App.DTOs.Tasks.AiSelectableContextCriteriaGraderDto;
import ManualFeedbackDto = App.DTOs.Tasks.ManualFeedbackDto;
import LevelledFeedbackDto = App.DTOs.Tasks.LevelledFeedbackDto;
import OptionDto = App.DTOs.Tasks.OptionDto;
import UiDisplayedContextDto = App.DTOs.Tasks.UiDisplayedContextDto;
import UiDisplayedContextType = App.Enums.UiDisplayedContextType;
import FeedbackLanguage = App.Enums.PromptBuildingPhrases.FeedbackLanguage;
import FeedbackInstructionsDto = App.DTOs.Tasks.FeedbackInstructionsDto;
import OpenAiRequestConfigDto = App.DTOs.OpenAiRequestConfigDto;

const props = defineProps<{
  modelValue?: AiSelectableContextCriteriaQuestionFormDto;
  isCopy?: boolean;
  modelsMetadata: LlmMetadata[];
  errors?: Record<string, any>;
}>();

const page = usePage<{
  loggedInUser: {
    isSuperUser: boolean;
  };
}>();

const UI_DISPLAYED_CONTEXT_LABELS: Record<UiDisplayedContextType, string> = {
  text_block: 'Text Block',
  student_response: 'Student Response Area',
  multiple_choice: 'Multiple Choice',
  randomized_passage: 'Randomized Passage',
  dynamic_table: 'Dynamic Table',
  file_upload: 'File Upload',
  image: 'Image',
};

const initialSubject = props.modelValue?.grader.subject || '';

const form = useForm<AiSelectableContextCriteriaQuestionFormDto>(
  () =>
    props.modelValue || {
      title: '',
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      type: 'AiSelectableContextCriteriaQuestion',
      status: 'draft',
      draftable: true,
      ui: {
        uiContext: [] as UiDisplayedContextDto[],
        heading: null,
      },
      grader: {
        contextGraders: [] as AiSelectableContextCriteriaGraderDto[],
        levelledFeedbackPartTitle: null,
        useLevelledFeedback: false,
        useManualFeedback: false,
        manualFeedbacks: [] as ManualFeedbackDto[],
        levelledFeedbacks: [] as LevelledFeedbackDto[],
        subject: null,
        returnGrade: true,
        showGradeBreakdown: false,
        feedbackInstructions: {
          subject: initialSubject,
          provideMistakeLabels: true,
          tone: null,
          formality: null,
          gradeTuning: null,
          improvementStrategy: null,
          gradeExplanation: null,
          answerRevealStrategy: 'Formative feedback',
          feedbackLength: null,
          useCustomBasePrompt: false,
          maxPastContextLength: 2,
          feedbackLanguagePhrases: [] as FeedbackLanguage[],
          customPhrases: [''],
        } as FeedbackInstructionsDto,
        llmOptions: defaultLlmOptions,
      },
      institutionId: null,
    }
);

const defaultModel =
  props.modelsMetadata.find((metadata) => metadata.model === 'gpt-4o-2024-08-06') || null;
const defaultLlmOptions: OpenAiRequestConfigDto = {
  modelId: Number(defaultModel?.id ?? 1),
  maxOutputTokens: 2000,
  temperature: 0.0,
  topP: 1.0,
  jsonResponse: true,
  jsonSchema: null,
};

// Keep the linkedStudentAnswers in sync with the available uiContext
watch(
  () => form.ui.uiContext,
  (newUiContextArray, oldUiContextArray) => {
    form.grader.contextGraders.forEach((contextGrader) => {
      contextGrader.linkedStudentAnswers = contextGrader.linkedStudentAnswers.filter(
        (linkedStudentAnswerId) =>
          newUiContextArray.some((uiContext, ctxIndex) => linkedStudentAnswerId === uiContext.uuid)
      );
    });
  },
  {deep: true}
);
// Ensure there are default values for the LLM options
watch(
  () => form.grader.llmOptions,
  (newVal) => {
    if (newVal === null) {
      form.grader.llmOptions = defaultLlmOptions;
    }
  },
  {immediate: true}
);
// Ensure there are default values for the LLM options
watch(
  () => form.grader.feedbackInstructions,
  (newVal) => {
    if (newVal === null) {
      form.grader.feedbackInstructions = {
        subject: initialSubject,
        provideMistakeLabels: true,
        tone: null,
        formality: null,
        gradeTuning: null,
        improvementStrategy: null,
        gradeExplanation: null,
        answerRevealStrategy: 'Formative feedback',
        feedbackLength: null,
        useCustomBasePrompt: false,
        maxPastContextLength: 2,
        feedbackLanguagePhrases: [] as FeedbackLanguage[],
        customPhrases: [''],
      } as FeedbackInstructionsDto;
    }
  },
  {immediate: true}
);
// Keep the feedbackInstructions subject in sync with the most recent subject
watch(
  () => form.grader.subject,
  (newSubject) => {
    if (
      newSubject &&
      form.grader.feedbackInstructions &&
      form.grader?.feedbackInstructions.subject !== newSubject
    ) {
      form.grader.feedbackInstructions.subject = newSubject;
    }
  }
);

const hasRandomizedPassage = computed(() =>
  form.ui.uiContext.some((context) => context.contextType === 'randomized_passage')
);

const hasFileUpload = computed(() =>
  form.ui.uiContext.some((context) => context.contextType === 'file_upload')
);

function removeGrader(index: number) {
  form.grader.contextGraders.splice(index, 1);
}

function addGrader() {
  form.grader.contextGraders.push({
    linkedStudentAnswers: [],
    rubric: '',
    scoreMaxValue: 1,
    criteria: [
      {
        name: '',
        criteriaContent: '',
        gradeIncrement: 0.25,
        gradeWeight: 1,
      },
    ],
  });
}

function generateContextName(contextType: UiDisplayedContextType, startingFrom = 1) {
  const cumulativeCounts = form.ui.uiContext.reduce(
    (countsPerType, item) => {
      countsPerType[item.contextType] += 1;
      return countsPerType;
    },
    {
      text_block: startingFrom,
      student_response: startingFrom,
      multiple_choice: startingFrom,
      randomized_passage: startingFrom,
      file_upload: startingFrom,
      dynamic_table: startingFrom,
    } as Record<UiDisplayedContextType, number>
  );

  return `${UI_DISPLAYED_CONTEXT_LABELS[contextType]} ${cumulativeCounts[contextType]}`;
}

function updateContextName(uiContextIndex: number) {
  const context = form.ui.uiContext[uiContextIndex];
  context.name = generateContextName(context.contextType, 0);
}

function addUiContext() {
  form.ui.uiContext.push({
    contextType: 'text_block',
    uuid: uuidV4(),
    name: generateContextName('text_block'),
    text: null,
    label: null,
    labelby: null,
    characterLimit: null,
    allowEditableHeaders: false,
    allowDynamicColumns: false,
    correctMultipleChoiceAnswer: null,
    weight: null,
    acceptedFileExtensions: null,
    maxFileSize: null,
    maxNumberOfFiles: null,
    randomTextPassages: [],
    multipleChoiceOptions: [] as OptionDto[],
    imageFileUploadId: null,
    imageAltText: null,
    imageWidth: null,
  });
}

function addTextPassage(ctxIndex: number) {
  const passages = form.ui.uiContext[ctxIndex].randomTextPassages;
  if (Array.isArray(passages)) {
    passages.push('');
  }
}

function removeTextPassage(ctxIndex: number, passageIndex: number) {
  const passages = form.ui.uiContext[ctxIndex].randomTextPassages;
  if (Array.isArray(passages)) {
    passages.splice(passageIndex, 1);
  }
}

function removeManualFeedback(index: number) {
  form.grader.manualFeedbacks.splice(index, 1);
  // Rename the keys of the manual feedbacks that come after the removed one.
  for (let i = index; i < form.grader.manualFeedbacks.length; i++) {
    form.grader.manualFeedbacks[i].key = `manualFeedback${i}`;
  }
}

function addLevelledFeedback() {
  const numberLevelledFeedbacks = form.grader.levelledFeedbacks.length;

  form.grader.levelledFeedbacks.push({
    key: 'levelledFeedback' + form.grader.levelledFeedbacks.length,
    content: '',
    levelMinGrade:
      numberLevelledFeedbacks >= 1
        ? form.grader.levelledFeedbacks[numberLevelledFeedbacks - 1].levelMaxGrade
        : 0.0,
    levelMaxGrade: 1.0,
  });
}

function removeLevelledFeedback(index: number) {
  form.grader.levelledFeedbacks.splice(index, 1);
  // Rename the keys of the affected levelled feedbacks
  for (let i = index; i < form.grader.levelledFeedbacks.length; i++) {
    form.grader.levelledFeedbacks[i].key = `levelledFeedback${i}`;
  }
}
</script>
