import {computed, Ref, shallowRef, watchEffect, WatchStopHandle} from 'vue';
import {useElementSize} from '@vueuse/core/index';
import {Task, TaskResponse, TaskState} from '../../types/entities/tasks';

export interface LegacyTaskWindow {
  stembleLegacyTaskComponent: LegacyTaskComponent;
  document: Document;
}

export interface LegacyTaskComponent {
  setIsSubmitting: (submitting: boolean) => void;
  setTaskAndState: (task: Task | null, taskState?: TaskState | null) => Promise<any>;
  setResponse: (response: TaskResponse | null) => void;
  triggerSubmitResponse: () => void;
  onSubmitResponse: (callback: (payload: TaskResponse) => void) => WatchStopHandle;
  onInputDataChange: (callback: (payload: TaskResponse['content']) => void) => WatchStopHandle;
  onTaskAttachmentsChange: (callback: (payload: File[]) => void) => WatchStopHandle;
  setShowRandomVariables: (show: boolean) => void;
  setSeed: (userId: number | null) => void;
  setLastName: (lastName: string | null) => void;
}

export interface LegacyTaskComponentReturn {
  height: Ref<number>;
}

export interface LegacyTaskComponentOptions {
  seed: Ref<number | null>;
  lastName: Ref<string | null>;
  frame: Ref<HTMLIFrameElement | null>;
  task: Ref<Task | null>;
  taskState: Ref<TaskState | null>;
  workingResponseCopy: Ref<TaskResponse | null>;
  isSubmitting: Ref<boolean>;
  showRandomVariables: Ref<boolean>;
  emit: any;
}

export function useLegacyTaskComponent({
  seed,
  lastName,
  frame,
  task,
  taskState,
  workingResponseCopy,
  isSubmitting,
  showRandomVariables,
  emit,
}: LegacyTaskComponentOptions): Pick<LegacyTaskComponentReturn, 'height'> {
  const legacyWindow = shallowRef<LegacyTaskWindow | null>(null);
  const taskComponent = shallowRef<LegacyTaskComponent | null>(null);

  function attemptTaskComponentConnection() {
    legacyWindow.value =
      (frame.value?.contentWindow as unknown as LegacyTaskWindow | undefined) ?? null;
    taskComponent.value = legacyWindow.value?.stembleLegacyTaskComponent ?? null;

    if (taskComponent.value) {
      emit('ready', taskComponent.value);
      return;
    }

    setTimeout(attemptTaskComponentConnection, 1000);
  }

  attemptTaskComponentConnection();

  const legacyRoot = computed(() => legacyWindow.value?.document.getElementById('app'));
  const size = useElementSize(legacyRoot);

  watchEffect(() => {
    if (legacyRoot.value) {
      legacyRoot.value.style.backgroundColor = 'transparent';
      legacyRoot.value.style.overflow = 'hidden';
    }
  });
  watchEffect(() => {
    taskComponent.value?.setTaskAndState(task.value, taskState.value);
  });
  watchEffect(() => {
    taskComponent.value?.setResponse(workingResponseCopy.value);
  });
  watchEffect(() => {
    taskComponent.value?.onSubmitResponse((payload: TaskResponse) =>
      emit('submit-response', payload)
    );
  });
  watchEffect(() => {
    taskComponent.value?.onInputDataChange((payload: TaskResponse['content']) =>
      emit('update:responseData', payload)
    );
  });
  watchEffect(() => {
    taskComponent.value?.onTaskAttachmentsChange((payload: File[]) =>
      emit('update:taskAttachments', payload)
    );
  });
  watchEffect(() => {
    taskComponent.value?.setIsSubmitting(isSubmitting.value);
  });
  watchEffect(() => {
    taskComponent.value?.setShowRandomVariables(showRandomVariables.value);
  });
  watchEffect(() => {
    taskComponent.value?.setSeed(seed.value);
  });
  watchEffect(() => {
    taskComponent.value?.setLastName(lastName.value);
  });

  return {
    height: size.height,
  };
}
