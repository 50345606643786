import {InputRule, textblockTypeInputRule} from 'prosemirror-inputrules';
import {Schema} from 'prosemirror-model';

/**
 * InputRules for creating variables:
 *
 * {{ x }}
 *
 *
 */
export const variableRules: (schema: Schema) => InputRule[] = (schema) => {
  if (!schema.nodes.variable) {
    return [];
  }

  return [
    new InputRule(/\{\{\s*(\w+)\s*}}$/, (state, match, start, end) => {
      const variableName = match[1];
      const tr = state.tr;
      const variable = schema.nodes.variable.create({name: variableName});
      tr.replaceWith(start, end, variable);
      return tr;
    }),
  ];
};
