<template>
  <Head :title="`Edit Task Feedback - ${task.title}`"></Head>
  <div class="page-block gap-5">
    <s-page-header :heading="`Edit Task Feedback - ${task.title}`">
      <template #actions>
        <s-btn
          :href="route('admin.tasks.feedbacks.index', {task: task.id})"
          color="secondary"
          icon="arrow-left"
        >
          Back to Feedbacks
        </s-btn>
      </template>
    </s-page-header>

    <s-card>
      <form
        @submit.prevent="
          form.put(
            route('admin.tasks.feedbacks.update', {
              task: task.id,
              feedback: feedback.id,
            })
          )
        "
        class="space-y-4"
      >
        <div class="grid gap-4">
          <s-input-field
            id="short-content"
            label="Short Content"
            v-model="form.shortContent"
            :error="form.errors.shortContent"
          />

          <s-textarea-field
            id="content"
            label="Content"
            v-model="form.content"
            :error="form.errors.content"
          />
        </div>

        <div class="flex justify-end gap-3">
          <s-btn
            type="button"
            color="secondary"
            :href="route('admin.tasks.feedbacks.index', {task: task.id})"
          >
            Cancel
          </s-btn>
          <s-btn type="submit" :processing="form.processing">Update Feedback</s-btn>
        </div>
      </form>
    </s-card>
  </div>
</template>

<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {Task} from '../../../types/entities/tasks';
import TaskFeedbackDto = App.DTOs.Entities.Tasks.TaskFeedbackDto;
import {route} from 'ziggy-js';
import SBtn from '../../../design-system/SBtn.vue';
import SCard from '../../../design-system/SCard.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SInputField from '../../../design-system/SInputField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';

const props = defineProps<{
  task: Task;
  feedback: TaskFeedbackDto;
}>();

const form = useForm({
  ...props.feedback,
});
</script>
