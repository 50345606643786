<template>
  <Head :title="t('page.title', {courseCode: course.code, assignmentName: assignment.name})"></Head>
  <div class="page-block">
    <s-page-header :heading="t('page.heading')">
      <template #actions>
        <s-link
          class="btn secondary md w-fit"
          :href="
            route('courses.assignments.extension.index', [course.id, assignment.id, extension.id])
          "
        >
          <s-icon name="close" class="opacity-70 -mt-px" size="16" />
          {{ t('form.cancel') }}
        </s-link>
      </template>
    </s-page-header>
    <div class="text-lg text-gray-800 pt-1">
      <i18n-t keypath="page.editExtension">
        <template #userFirstName>
          <span class="font-bold text-gray-700">
            {{ extension.user.firstName }}
          </span>
        </template>
        <template #userLastName>
          <span class="font-bold text-gray-700">
            {{ extension.user.lastName }}
          </span>
        </template>
        <template #section>
          <span class="font-bold text-gray-700">
            {{ section.name }}
          </span>
        </template>
      </i18n-t>
    </div>
    <div class="pt-5">
      <extension-date-selector
        :section="section.name"
        :index="0"
        :totalExtensions="1"
        :single="true"
        :participants="[extension.user.firstName + ' ' + extension.user.lastName]"
        :current-start-date="courseLikeAssignment.startDate"
        :current-due-date="courseLikeAssignment.dueDate"
        :mode="extensionMode"
        date-type="fixed"
        v-model="newExtensionDate"
      />
    </div>
    <div class="w-full flex justify-end pt-6 pb-3 gap-x-4">
      <s-link
        class="btn white lg flex items-center justify-center gap-2"
        :href="
          route().params.redirect
            ? decodeURIComponent(route().params.redirect as string)
            : route('courses.assignments.extension.index', {
                course: course.id,
                assignment: assignment.id,
              })
        "
      >
        <s-icon name="close" class="opacity-70" width="18" height="18" />
        {{ t('form.cancel') }}
      </s-link>
      <button
        type="submit"
        @click="grantExtensions"
        class="btn primary lg flex items-center justify-center gap-2 !px-8"
      >
        <s-icon name="calendar-plus" class="opacity-70" width="18" height="18" />
        {{ t('buttons.grant') }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import {User} from '@sentry/vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLink from '../../components/SLink.vue';
import {Extension} from '../../types/entities/extension';
import ExtensionDateSelector from './ExtensionDateSelector.vue';
import {computed, ref} from 'vue';
import {Head, router} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {CourseLike} from '../../types/entities/course-like';
import {CourseLikeAssignment} from '../../types/entities/course-like-assignment';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  extension: Extension & {user: User; createdBy: User};
  section: CourseLike;
  courseLikeAssignment: CourseLikeAssignment;
}>();

const extensionMode = computed(() => {
  if (props.extension.newStartDate && props.extension.newDueDate) {
    return 'both';
  } else if (props.extension.newStartDate) {
    return 'startDate';
  }
  return 'dueDate';
});

const {t} = useI18n({useScope: 'local', inheritLocale: true});

const newExtensionDate = ref<{newStartDate: string | null; newDueDate: string | null}>({
  newStartDate: props.extension.newStartDate,
  newDueDate: props.extension.newDueDate,
});

function grantExtensions() {
  router.post(
    route('courses.assignments.extension.update', {
      course: props.course.id,
      assignment: props.assignment.id,
      extension: props.extension.id,
    }),
    {
      extension: {
        assignmentId: props.assignment.id,
        userId: props.extension.user.id,
        ...newExtensionDate.value,
        extensionId: props.extension.id,
      },
    }
  );
}

console.log(route().params.redirect);
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Edit Extension",
      "heading": "Edit Extension",
      "editExtension": "Edit extension for {userFirstName} {userLastName}, Section {section}."
    },
    "buttons": {
      "grant": "Grant New Extension"
    }
  },
  "fr": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Modifier la prolongation",
      "heading": "Modifier la prolongation",
      "editExtension": "Modifier la prolongation pour {userFirstName} {userLastName}, Section {section}."
    },
    "buttons": {
      "grant": "Accorder une nouvelle prolongation"
    }
  }
}
</i18n>
