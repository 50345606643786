<template>
  <div class="criteria-dashboard">
    <section class="mt-4">
      <h2 class="heading lg mb-2">Criteria Grade Analytics</h2>

      <s-select-field
        class="mb-4"
        id="selectCriterion"
        label="Criteria"
        v-model="selectedCriterion"
      >
        <option
          v-for="criterion in props.criteriaGradeStatistics"
          :key="criterion.criterionName"
          :value="criterion"
        >
          {{ criterion.criterionName }}
        </option>
      </s-select-field>

      <div v-if="selectedCriterion" class="card padded">
        <task-evaluation-grade-charts
          :gradeStatistics="selectedCriterion.gradeStatistics"
          :chart-title="selectedCriterion.criterionName"
        />
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import TaskEvaluationGradeCharts from './TaskEvaluationGradeCharts.vue';
import {ref} from 'vue';
import SSelectField from '../../../../design-system/SSelectField.vue';
import CriterionGradeStatisticsDto = App.DTOs.EvaluationAnalytics.CriterionGradeStatisticsDto;

const props = defineProps<{
  criteriaGradeStatistics: Array<CriterionGradeStatisticsDto>;
}>();

const selectedCriterion = ref(props.criteriaGradeStatistics[0] ?? null);
</script>

<style scoped>
.criteria-dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
