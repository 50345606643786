import {NodeSpec} from 'prosemirror-model';

export let paragraph_spec: NodeSpec = {
  content: 'inline*',
  group: 'block',
  parseDOM: [{tag: 'p'}],
  toDOM() {
    return ['p', 0];
  },
};
