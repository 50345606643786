import {router} from '@inertiajs/vue3';
import {readonly, ref, Ref, watch} from 'vue';
import {route} from 'ziggy-js';
import {useCancellableDebounceFn} from '../../../composables/useCancellableDebounceFn';
import Vapor from 'laravel-vapor';
import axios from 'axios';
import {Task} from '../../../types/entities/tasks';
import TemporaryFileUploadDto = App.DTOs.FileUploads.TemporaryFileUploadDto;
import TaskResponseAttachmentDto = App.DTOs.Tasks.TaskResponseAttachmentDto;

export interface StoreSubmitResponsePayload {
  // Properties to identify what we are storing
  courseId: number;
  assignmentId: number;
  taskId: number;
  // Existing state for reference
  gradingRunId: number | null;
  taskStateId: number | null | undefined;
  currentlyStoredAttachments: TaskResponseAttachmentDto[];
  // Data to store
  data: Record<string, any>;
  files: File[];

  onSuccess(): void;
}

export function useStoreSubmitResponse(
  currentTask: Ref<Task>,
  autoDraftStoreIsEnabled: Ref<boolean>,
  isStoreSubmitDisabled: Ref<boolean>,
  saveProps: string[],
  useVaporFileHandling: boolean
) {
  const storingDraft = ref(false);
  const uploadingFiles = ref(false);
  const experiencedFileUploadFailure = ref(false);
  const submittingResponse = ref(false);

  const userHasNavigatedToAnotherTask = (taskId: number) => {
    return currentTask.value.id !== taskId;
  };

  const processAttachmentsForSubmission = async (
    currentlyStoredAttachments: TaskResponseAttachmentDto[],
    files: File[]
  ): Promise<File[] | TemporaryFileUploadDto[]> => {
    experiencedFileUploadFailure.value = false;
    // If we're not using vapor handling, just return the attachments for upload
    if (!useVaporFileHandling) {
      return files;
    }

    const newFiles = files.filter((file) => !fileIsAlreadyStored(file, currentlyStoredAttachments));

    if (!newFiles.length) {
      return [];
    }

    return await uploadToS3(newFiles);
  };

  const uploadToS3 = async (files: File[]): Promise<TemporaryFileUploadDto[]> => {
    try {
      uploadingFiles.value = true;
      const temporaryFileReferences = await Promise.all(files.map((file) => Vapor.store(file)));

      return temporaryFileReferences.map((reference, i) => ({
        ...reference,
        originalFilename: files[i].name,
        size: files[i].size,
        mimeType: files[i].type,
      }));
    } catch (error) {
      experiencedFileUploadFailure.value = true;
      throw error;
    } finally {
      uploadingFiles.value = false;
    }
  };

  const fileIsAlreadyStored = (
    file: File,
    alreadyStoredAttachments: TaskResponseAttachmentDto[]
  ) => {
    return alreadyStoredAttachments.some(
      (a) => a.filename === file.name && a.filesize === file.size && a.mimeType == file.type
    );
  };

  const storeDraftResponse = async (responsePayload: StoreSubmitResponsePayload) => {
    // If the submit button is disabled o, exit early.
    if (isStoreSubmitDisabled.value || !autoDraftStoreIsEnabled.value) {
      return;
    }

    const processedAttachments = await processAttachmentsForSubmission(
      responsePayload.currentlyStoredAttachments,
      responsePayload.files
    );

    const storeRoute = route('web.courses.assignments.tasks.responses.store', {
      course: responsePayload.courseId,
      assignment: responsePayload.assignmentId,
      task: responsePayload.taskId,
    });

    const dataToStore = {
      createdFromId: responsePayload.gradingRunId,
      taskStateId: responsePayload.taskStateId,
      ...responsePayload.data,
      attachments: processedAttachments,
    };

    // IMPORTANT!
    // If the user has navigated to another task since starting the save, we want to store the response in the background
    // If they are on the same task page, we want to use the Inertia router to store the response and reload the props.
    if (userHasNavigatedToAnotherTask(responsePayload.taskId)) {
      return axios.post(storeRoute, dataToStore).then(responsePayload.onSuccess);
    } else {
      return router.post(storeRoute, dataToStore, {
        only: saveProps,
        preserveState: true,
        preserveScroll: true,
        onStart: () => (storingDraft.value = true),
        onSuccess: responsePayload.onSuccess,
        onFinish: () => (storingDraft.value = false),
      });
    }
  };

  const {
    cancel: cancelStoreDraft,
    debounced: debouncedStoreDraft,
    resume: resumeStoreDraft,
  } = useCancellableDebounceFn(storeDraftResponse, 1500);

  const submitResponse = async (responsePayload: StoreSubmitResponsePayload) => {
    cancelStoreDraft();

    let processedAttachments: File[] | TemporaryFileUploadDto[] = [];
    try {
      submittingResponse.value = true;
      processedAttachments = await processAttachmentsForSubmission(
        responsePayload.currentlyStoredAttachments,
        responsePayload.files
      );
    } catch (e) {
      // If there was an error processing the attachments, revert the indicator and stop the process
      submittingResponse.value = false;
      throw e;
    }

    const submitRoute = route('web.courses.assignments.tasks.responses.submit', {
      course: responsePayload.courseId,
      assignment: responsePayload.assignmentId,
      task: responsePayload.taskId,
    });

    const dataToSubmit = {
      createdFromId: responsePayload.gradingRunId,
      taskStateId: responsePayload.taskStateId,
      ...responsePayload.data,
      attachments: processedAttachments,
    };

    // IMPORTANT!
    // If the user has navigated to another task since starting the submission process, we want to submit the response in the background
    // If they are on the same task page, we want to use the Inertia router to submit the response and reload the props.
    if (userHasNavigatedToAnotherTask(responsePayload.taskId)) {
      // We re-enable draft saving here as the user has navigated away from the task and will now be working on another task.
      return axios
        .post(submitRoute, dataToSubmit)
        .then(responsePayload.onSuccess)
        .finally(() => {
          submittingResponse.value = false;
        });
    } else {
      return router.post(submitRoute, dataToSubmit, {
        onSuccess: responsePayload.onSuccess,
        onFinish: () => {
          resumeStoreDraft();
          submittingResponse.value = false;
        },
        only: saveProps,
        preserveState: true,
        preserveScroll: true,
      });
    }
  };

  // If the user switches task, we reset the state of our flags and make sure draft storing is re-enabled.
  watch(currentTask, (currentValue, oldValue) => {
    if (currentValue.id !== oldValue.id) {
      storingDraft.value = false;
      uploadingFiles.value = false;
      submittingResponse.value = false;
      resumeStoreDraft();
    }
  });

  return {
    storingDraft: readonly(storingDraft),
    uploadingFiles: readonly(uploadingFiles),
    submittingResponse: readonly(submittingResponse),
    experiencedFileUploadFailure: readonly(experiencedFileUploadFailure),
    submitResponse,
    debouncedStoreDraft,
  };
}
