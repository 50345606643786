<template>
  <s-field v-bind="props">
    <template v-slot="a11yProps">
      <s-select v-bind="{...$attrs, ...a11yProps}" v-model="model" :required="required">
        <slot />
      </s-select>
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="tooltip || $slots.tooltip" #tooltip>
      <slot name="tooltip" />
    </template>
  </s-field>
</template>
<script setup lang="ts">
import {type VNode} from 'vue';
import SField from './SField.vue';
import SSelect from './SSelect.vue';

defineOptions({
  inheritAttrs: false,
});

const model = defineModel();

const props = defineProps<{
  id: string;
  label?: string | null;
  description?: string | null;
  tooltip?: string | VNode | null;
  error?: string | null;
  required?: boolean;
  class?: string | Record<string, boolean | string>;
}>();
</script>
