<template>
  <img
    v-if="!invalidUrl && node.attrs.src && !imgError"
    :src="node.attrs.src"
    :alt="node.attrs.alt"
    :width="node.attrs.width || 'auto'"
    style="max-width: 100%"
    class="inline-block border border-gray-200 rounded-md"
    :class="{
      'cursor-pointer hover:shadow hover:border-primary-400': view.editable,
    }"
    @click.prevent="editorModalOpen = true"
    @error="onImageError"
  />
  <s-btn
    v-else-if="view.editable"
    type="button"
    @click.prevent="editorModalOpen = true"
    :color="!node.attrs.src ? 'white' : 'red-light'"
    :icon="!node.attrs.src ? 'plus' : 'alert'"
  >
    <div v-if="!node.attrs.src && view.editable">
      {{ t('label.addImageUrl') }}
    </div>
    <div v-else>
      {{ t('label.invalidUrl') }}
    </div>
  </s-btn>

  <s-modal
    v-if="view.editable"
    v-model:open="editorModalOpen"
    :title="t('modal.title')"
    confirmable
  >
    <div class="p-4 flex flex-col gap-4 w-full">
      <s-input-field
        id="src"
        :label="t('label.imageUrl')"
        :model-value="node.attrs.src"
        @update:model-value="updateSrc"
        class="mb-4"
      />
      <s-input-field
        id="alt"
        :label="t('label.alt')"
        :model-value="node.attrs.alt"
        @update:model-value="updateAlt"
        class="mb-4"
      />
      <s-input-field
        id="width"
        :label="t('label.width')"
        :model-value="node.attrs.width"
        @update:model-value="updateWidth"
      />
    </div>
  </s-modal>
</template>
<script setup lang="ts">
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {computed, ref, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import SModal from '../../../design-system/SModal.vue';
import {VueNodeViewProps} from './VueNodeView';

const props = defineProps<VueNodeViewProps>();

const editorModalOpen = ref(false);

const {t} = useI18n();

const updateSrc = (src: string | number | null | undefined) => {
  const {view, getPos} = props;
  const {state, dispatch} = view;
  const {tr} = state;

  const pos = getPos();
  if (pos === undefined) {
    return;
  }

  const node = tr.doc.nodeAt(pos);
  if (!node) {
    return;
  }

  props.view.dispatch(
    props.view.state.tr.setNodeMarkup(pos, null, {
      ...node.attrs,
      src,
    })
  );
};

const updateAlt = (alt: string | number | null | undefined) => {
  const {view, getPos} = props;
  const {state, dispatch} = view;
  const {tr} = state;

  const pos = getPos();
  if (pos === undefined) {
    return;
  }

  const node = tr.doc.nodeAt(pos);
  if (!node) {
    return;
  }

  props.view.dispatch(
    props.view.state.tr.setNodeMarkup(pos, null, {
      ...node.attrs,
      alt,
    })
  );
};

const updateWidth = (width: string | number | null | undefined) => {
  const {view, getPos} = props;
  const {state, dispatch} = view;
  const {tr} = state;

  const pos = getPos();
  if (pos === undefined) {
    return;
  }

  const node = tr.doc.nodeAt(pos);
  if (!node) {
    return;
  }

  props.view.dispatch(
    props.view.state.tr.setNodeMarkup(pos, null, {
      ...node.attrs,
      width,
    })
  );
};

const imgError = ref(false);

function isValidUrl(urlString: string) {
  try {
    new URL(urlString);
    return true;
  } catch {
    return false;
  }
}

const invalidUrl = computed(() => {
  const src = props.node.attrs.src;
  if (!src) return true;
  return !isValidUrl(src);
});

function onImageError() {
  imgError.value = true;
}

watch(
  () => props.node.attrs.src,
  () => {
    imgError.value = false;
  }
);
</script>
<i18n>
{
  "en": {
    "modal": {
      "title": "Edit Image"
    },
    "label": {
      "alt": "Alt Text",
      "imageUrl": "Image URL",
      "width": "Width",
      "addImageUrl": "Add Image URL",
      "invalidUrl": "Invalid Image URL"
    }
  },
  "fr": {
    "modal": {
      "title": "Modifier l'image"
    },
    "label": {
      "alt": "Texte alternatif",
      "imageUrl": "URL de l'image",
      "width": "Largeur",
      "addImageUrl": "Ajouter l'URL de l'image",
      "invalidUrl": "URL d'image invalide"
    }
  }
}
</i18n>
