<template>
  <Head :title="`${assignment.name} - ${course.code}`" />
  <s-page-block class="!pb-5">
    <s-page-header :heading="assignment.name" :description="assignment.overview">
      <template #description>
        <s-card v-if="overview?.state" padding="sm" class="mt-4 mb-2">
          <assignment-overview-editor :model-value="overview.state" read-only />
        </s-card>
        <div v-else-if="assignment.overviewText" class="card mt-4 px-4 py-3">
          <s-latex :content="assignment.overviewText" />
        </div>
        <div class="mt-3">
          <s-badge-group>
            <s-badge>
              <s-icon name="clipboard-edit-outline" size="18" class="opacity-70" />
              <strong class="!font-black">{{ assignedTasks.length }}</strong>
              {{ t('tasks') }}
            </s-badge>
            <s-badge>
              <s-icon name="trophy-outline" size="18" class="opacity-70" />
              <strong class="!font-black">
                {{ assignedTasks.reduce((acc, task) => acc + task.pointValue, 0) }}
              </strong>
              {{ t('totalPoints') }}
            </s-badge>
            <s-badge>
              <s-icon name="calendar" size="18" class="opacity-70" />
              <span v-if="clas.length === 1 && clas[0].courseLike.id === course.id">
                {{ t('entireCourse') }}
              </span>
              <span v-else-if="clas.length">
                <strong class="!font-black">{{ clas.length }}</strong>
                {{ t('sections') }}
              </span>
              <span v-else>
                {{ t('notAssigned') }}
              </span>
            </s-badge>
          </s-badge-group>
        </div>
      </template>
      <template #actions>
        <s-btn
          v-if="can.update"
          icon="pencil"
          :href="route('courses.assignments.edit', {course: course.id, assignment: assignment.id})"
          color="white"
        >
          {{ t('actions.edit') }}
        </s-btn>
        <s-btn
          v-if="timelimitEnabled && can.viewAnyTimerExtensions"
          icon="timer-outline"
          :href="route('courses.assignments.timer-extensions.index', [course.id, assignment.id])"
          color="white"
        >
          {{ t('timerExtensions') }}
        </s-btn>
        <s-btn
          v-if="can.grantExtensions"
          icon="calendar-arrow-right"
          :href="route('courses.assignments.extension.index', [course.id, assignment.id])"
          color="white"
        >
          {{ t('extensions') }}
        </s-btn>
        <s-btn
          v-if="can.viewAnalytics"
          icon="chart-bar"
          :href="route('courses.assignments.analytics.grades', [course.id, assignment.id])"
          color="white"
        >
          {{ t('analytics') }}
        </s-btn>
        <s-btn
          v-if="can.accessSuperAdmin"
          icon="lock"
          color="danger-white"
          :href="route('admin.assignments.show', {assignment: assignment.id})"
        >
          {{ t('superAdmin') }}
        </s-btn>
        <s-btn
          icon="table-check"
          :href="route('courses.assignments.students.index', [course.id, assignment.id])"
        >
          {{ t('grades') }}
        </s-btn>
      </template>
    </s-page-header>
  </s-page-block>
  <s-page-block class="!pt-0">
    <div class="flex flex-col lg:flex-row flex-wrap gap-y-8 gap-x-6 lg:gap-x-8">
      <div id="tasks" class="flex-1">
        <div class="flex flex-wrap items-end gap-2 mb-2">
          <h2 class="heading lg flex-1 flex items-center gap-1.5">
            <s-icon name="clipboard-edit-outline" size="24" class="opacity-70" />
            {{ t('page.tasks.title') }}
          </h2>
          <div class="flex gap-2">
            <s-btn
              v-if="can.updateTasks && features.assignmentTasksRebuild"
              icon="pencil"
              :href="route('courses.assignments.tasks.index', [course.id, assignment.id])"
              color="secondary"
              size="sm"
            >
              {{ t('actions.editTasks') }}
            </s-btn>
            <s-btn
              v-if="can.updateTasks && !features.assignmentTasksRebuild"
              icon="pencil"
              :href="route('legacy.courses.assignments.overview', [course.id, assignment.id])"
              color="secondary"
              size="sm"
              external
            >
              {{ t('actions.editTasks') }}
            </s-btn>
            <s-btn
              icon="magnify"
              color="primary-light"
              size="sm"
              :href="route('web.courses.assignments.tasks.first', [course.id, assignment.id])"
            >
              {{ t('actions.previewAssignment') }}
            </s-btn>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th class="!align-bottom"></th>
              <th class="!align-bottom">
                {{ t('table.title') }}
              </th>
              <th class="!align-bottom" v-if="assignment.reviewRequired">
                <span class="whitespace-pre-wrap">{{ t('table.reviewRequired') }}</span>
              </th>
              <!-- Max Attempts -->
              <th class="!align-bottom">
                <s-tooltip>
                  <template #tooltip>
                    {{ t('table.maxAttemptsTooltip') }}
                  </template>
                  {{ t('table.maxAttempts') }}
                </s-tooltip>
              </th>
              <th class="!align-bottom">
                {{ t('table.points') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(assigned, index) in assignedTasks">
              <td class="w-0">
                <strong>{{ index + 1 }}</strong>
              </td>
              <td>
                <template v-if="assigned.taskPool.tasks.length === 1">
                  <s-latex :content="assigned.taskPool.tasks[0].title" responsive />
                </template>
                <template v-else>
                  <table class="w-full task-pool-table">
                    <tbody>
                      <tr v-for="task in assigned.taskPool.tasks" :key="task.id">
                        <td class="text-left">
                          <s-latex :content="task.title" responsive />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </td>
              <td v-if="assignment.reviewRequired" class="w-0">
                <s-icon
                  v-if="assigned.reviewRequired"
                  name="check"
                  :title="t('table.reviewRequired')"
                  class="text-blue-600"
                />
              </td>
              <td class="w-0" :class="{'text-gray-400': !assigned.maxAttempts}">
                {{ assigned.maxAttempts || '∞' }}
              </td>
              <td class="w-0">
                {{ assigned.pointValue }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="assigned-to" class="min-w-[45%]">
        <div class="flex flex-wrap items-end gap-2 mb-2">
          <h2 class="heading lg flex-1 flex items-center gap-1.5">
            <s-icon name="calendar" size="24" class="opacity-70" />
            {{ t('page.sections.title') }}
          </h2>
          <div>
            <s-btn
              v-if="can.assign"
              icon="pencil"
              :href="route('courses.assignments.settings.edit', [course.id, assignment.id])"
              size="sm"
              color="secondary"
              class="w-fit"
            >
              {{ t('actions.editSectionDates') }}
            </s-btn>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>
                {{ t('section') }}
              </th>
              <th>
                {{ t('startDate') }}
              </th>
              <th>
                {{ t('dueDate') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cla in clas" :key="cla.id">
              <td>{{ cla.courseLike.name }}</td>
              <td class="min-w-[10rem]">
                <s-date :date="cla.startDate" />
              </td>
              <td class="min-w-[10rem]">
                <s-date :date="cla.dueDate" />
              </td>
            </tr>
            <tr v-if="!clas.length">
              <td colspan="3" class="!text-center text-gray-600">
                {{ t('notAssigned') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import SDate from '../../design-system/SDate.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {Course} from '../../types/entities/course';
import SPageBlock from '../../design-system/SPageBlock.vue';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import SCard from '../../design-system/SCard.vue';
import SBadge from '../../design-system/SBadge.vue';
import STooltip from '../../design-system/STooltip.vue';
import SBadgeGroup from '../../design-system/SBadgeGroup.vue';
import SLatex from '../../components/SLatex.vue';
import {CourseLikeAssignment} from '../../types/entities/course-like-assignment';
import {Task} from '../../types/entities/tasks';
import SIcon from '../../design-system/SIcon.vue';
import AssignmentOverviewEditor from '../../editors/assignment-overview-editor/AssignmentOverviewEditor.vue';
import {RichText} from '../../types/entities/rich-text';
import AssignmentDto = App.DTOs.AssignmentDto;
import {LoadedTaskAssignmentDto} from '../../types/entities/task-assignment';

const props = defineProps<{
  course: Course;
  assignment: AssignmentDto;
  overview: RichText | null;
  clas: (CourseLikeAssignment & {courseLike: CourseLike})[];
  assignedTasks: LoadedTaskAssignmentDto[];
  can: {
    update: boolean;
    assign: boolean;
    updateTasks: boolean;
    grantExtensions: boolean;
    viewAnalytics: boolean;
    viewAnyTimerExtensions: boolean;
    accessSuperAdmin: boolean;
  };
  features: {
    assignmentTasksRebuild: boolean;
  };
  timelimitEnabled: boolean;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

useBreadcrumbs([
  {
    label: props.course.code,
    href: route('courses.show', {course: props.course.id}),
  },
  {
    label: t('assignments'),
    href: route('courses.assignments.index', {course: props.course.id}),
  },
]);
</script>
<i18n>
{
  "en": {
    "page": {
      "tasks": {
        "title": "Tasks"
      },
      "sections": {
        "title": "Assigned Sections"
      }
    },
    "actions": {
      "editTasks": "Edit Tasks",
      "previewAssignment": "Preview Assignment",
      "editSectionDates": "Edit Section Dates"
    },
    "table": {
      "title": "Title",
      "reviewRequired": "Review\nRequired",
      "points": "Points",
      "maxAttempts": "Attempts",
      "maxAttemptsTooltip": "Maximum student attempts; additional attempts may be granted."
    },
    "notAssigned": "Not Assigned"
  },
  "fr": {
    "page": {
      "tasks": {
        "title": "Tâches"
      },
      "sections": {
        "title": "Sections assignées"
      }
    },
    "actions": {
      "editTasks": "Modifier les tâches",
      "previewAssignment": "Aperçu du devoir",
      "editSectionDates": "Modifier les dates des sections"
    },
    "table": {
      "title": "Titre",
      "reviewRequired": "Révision\nrequise",
      "points": "Points",
      "maxAttempts": "Tentatives",
      "maxAttemptsTooltip": "Nombre maximum de tentatives par étudiant; des tentatives supplémentaires peuvent être accordées."
    },
    "notAssigned": "Non assigné"
  }
}
</i18n>
<style>
.table .task-pool-table {
  tbody {
    tr {
      td {
        @apply first:pl-4;
      }
    }

    tr:not(:first-child) {
      td {
        @apply !border-t border-gray-200;
      }
    }
  }
}
</style>
