<template>
  <assignment-analytics-header
    :course="course"
    :assignment="assignment"
    :task-analytics-enabled="taskAnalyticsEnabled"
  />

  <section class="section-block">
    <div v-if="reviewers.length" class="flex items-stretch gap-x-4 gap-y-4 flex-wrap mb-4">
      <form class="flex-1 w-full flex items-stretch">
        <div class="flex-1">
          <s-multi-select-field
            id="reviewer"
            v-model="form.reviewerIds"
            @confirm:selection="router.visit(filterReviewerHref(form.reviewerIds))"
            :options="reviewers"
            value-key="id"
            :empty-label="t('reviewers.empty')"
          >
            <template #selected="{selected: reviewers}: {selected: Participant[]}">
              <div class="comma-separated">
                <span v-for="reviewer in reviewers">
                  {{ reviewer.firstName }} {{ reviewer.lastName }}
                </span>
              </div>
            </template>

            <template #option="{option: reviewer}: {option: Participant}">
              <div class="flex justify-between gap-2">
                <span class="text-sm font-medium">
                  {{ reviewer.firstName }} {{ reviewer.lastName }}
                </span>
                <span class="text-xs text-gray-600">
                  {{ t('sections') }}: {{ reviewer.sections.split(',').join(', ') }}
                </span>
              </div>
            </template>
          </s-multi-select-field>
        </div>
      </form>
      <div class="flex-shrink flex justify-between">
        <s-btn
          class="px-6 py-2 text-base leading-tight font-medium rounded-r-none"
          color="secondary"
          :href="
            filterReviewerHref(
              previousReviewer ? [previousReviewer] : [reviewers[reviewers.length - 1].id]
            )
          "
          size="custom"
        >
          <s-icon name="arrow-left" size="18" class="opacity-70" />
          {{ t('previousReviewer') }}
        </s-btn>
        <s-btn
          class="px-6 py-2 text-base leading-tight font-medium rounded-l-none !border-l-0"
          color="secondary"
          :href="filterReviewerHref(nextReviewer ? [nextReviewer] : [reviewers[0].id])"
          size="custom"
        >
          {{ t('nextReviewer') }}
          <s-icon name="arrow-right" size="18" class="opacity-70" />
        </s-btn>
      </div>
    </div>
    <div class="flex items-center justify-start flex-1 gap-2 mt-2 mb-2">
      <h2 class="heading">{{ t('averageGradeByTask') }}</h2>
      <s-help-button>
        <div class="prose">
          <p>
            {{ t('avgGrade.interpretations.nicerGrader') }}
          </p>
          <p>
            {{ t('avgGrade.interpretations.betterTeaching') }}
          </p>
          <p>
            {{ t('avgGrade.interpretations.smarterStudents') }}
          </p>
          <ul class="list list-disc list-inside">
            <li>{{ t('avgGrade.bulletPoints.lenientReviewer') }}</li>
            <li>{{ t('avgGrade.bulletPoints.worseTeaching') }}</li>
            <li>{{ t('avgGrade.bulletPoints.lessAptitude') }}</li>
          </ul>
        </div>
      </s-help-button>
      <s-modal :title="t('averageGradeByTask')" v-model:open="showModal.info"></s-modal>
    </div>

    <div
      v-if="!reviewers.length"
      class="bg-gray-500/5 rounded-lg shadow-inset border border-gray-250 w-full bg-gray-1000 flex justify-center items-center py-5"
    >
      <p class="text-gray-400 italic">{{ t('noDataToDisplay') }}</p>
    </div>
    <template v-if="reviewers.length">
      <div class="card p-6">
        <div v-if="selectedReviewers.length > 0" class="flex justify-start text-sm -my-2">
          <div class="analytics-filter-pill mb-1">
            <p>
              {{ t('filteredTo') }}
              <strong class="text-blue-600">
                {{ selectedReviewers.map((r) => r.firstName + ' ' + r.lastName).join(', ') }}
              </strong>
            </p>
            <a :href="filterReviewerHref([])" class="analytics-filter-pill-button">
              <s-icon name="close" width="18" height="18" />
              <span class="sr-only">{{ t('clearFiltering') }}</span>
            </a>
          </div>
        </div>
        <average-grade-chart
          :reviewers="reviewers"
          :tasks="tasks"
          :tasks-by-reviewer="tasksByReviewer"
        />
      </div>
    </template>
  </section>

  <section class="section-block pt-6 pb-4">
    <div class="flex items-center justify-start flex-1 gap-2 mt-2 mb-2">
      <h2 class="heading">{{ t('manualGrading') }}</h2>
      <s-help-button>
        <div class="prose">
          <p>{{ t('help.chartShowPercentages') }}</p>
          <p>{{ t('help.percentagesCloseTo100') }}</p>
          <p>
            {{ t('help.higherForAI') }}
          </p>
        </div>
      </s-help-button>
    </div>
    <div class="card padded">
      <average-overrides-chart
        :reviewers="reviewers"
        :tasks="tasks"
        :tasks-by-reviewer="tasksByReviewer"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {Assignment} from '../../types/entities/assignment';
import {Course} from '../../types/entities/course';
import {usePageParams} from '../../composables/usePageParams';
import SBtn from '../../design-system/SBtn.vue';
import SModal from '../../design-system/SModal.vue';
import {computed, ref} from 'vue';
import SMultiSelectField from '../../design-system/SMultiSelectField.vue';
import {router, useForm} from '@inertiajs/vue3';
import AverageGradeChart, {Participant, TaskPerformanceData} from './Grades/AverageGradeChart.vue';
import AverageOverridesChart from './Grades/AverageOverridesChart.vue';
import SHelpButton from '../../design-system/SHelpButton.vue';
import AssignmentAnalyticsHeader from './Index/AssignmentAnalyticsHeader.vue';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  reviewers: Participant[];
  tasks: TaskPerformanceData[];
  tasksByReviewer: {
    [key: number]: TaskPerformanceData[];
  };
  taskAnalyticsEnabled: boolean;
}>();

const params = usePageParams<{reviewerIds: string[]}>();

const form = useForm({
  reviewerIds: params.reviewerIds?.map((id) => ~~id) || [],
});

const showModal = ref({
  info: false,
  analytics: false,
});

const nextReviewer = computed<number | undefined>(() => {
  if (!params.reviewerIds?.length) return props.reviewers[0]?.id;
  if (params.reviewerIds?.length > 1) return;
  let reviewerId = ~~params.reviewerIds[0];

  const currentReviewerIndex = props.reviewers.findIndex((reviewer) => reviewer.id == reviewerId);
  return props.reviewers[currentReviewerIndex + 1]?.id;
});

const previousReviewer = computed<number | undefined>(() => {
  if (!params.reviewerIds?.length) return props.reviewers[0]?.id;
  if (params.reviewerIds?.length > 1) return;
  let reviewerId = ~~params.reviewerIds[0];

  const currentReviewerIndex = props.reviewers.findIndex((reviewer) => reviewer.id === reviewerId);
  return props.reviewers[currentReviewerIndex - 1]?.id;
});

const selectedReviewers = computed(() => {
  if (!props.tasksByReviewer) return [];
  const taskIds = Object.keys(props.tasksByReviewer);
  return props.reviewers.filter((r) => taskIds?.includes(r.id.toString()));
});

function filterReviewerHref(reviewerIds: number[]) {
  return route('courses.assignments.analytics.grades', {
    course: props.course.id,
    assignment: props.assignment.id,
    reviewerIds,
  });
}

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<style scss>
.analytics-filter-pill {
  @apply flex items-center gap-2 leading-tight bg-gray-150 border border-gray-300/50 text-gray-800 rounded-full px-4 py-1.5;
}

.analytics-filter-pill-button {
  @apply text-blue-500 w-fit inline-block opacity-70 hover:opacity-100 py-1 px-1.5 -my-1 -ml-1 -mr-2.5 transition-opacity ease-out duration-100;
}
</style>
<i18n>
{
  "en": {
    "reviewers":{
      "empty": "Compare up to 5 reviewers"
    },
    "sections": "Sections",
    "previousReviewer": "Previous Reviewer",
    "nextReviewer": "Next Reviewer",
    "averageGradeByTask": "Average Grade by Task",
    "noDataToDisplay": "No data to display.",
    "filteredTo": "Filtered To",
    "clearFiltering": "Clear Filtering",
    "manualGrading": "Manual Grading",
    "avgGrade": {
      "interpretations": {
        "nicerGrader": "From this chart, you can see how the selected reviewer's students are performing compared to the rest of the class.",
        "betterTeaching": "This chart shows the average student grade for each question in the assignment. The dark blue dots represent the average grade for all students. The purple bars represent the average grade for students in the selected reviewer's sections.",
        "smarterStudents": "If the purple bars are consistently higher or lower than the dark blue dots that means the students in the selected reviewer's sections are performing better or worse than the rest of the class. There are a few reasons why this might be the case:"
      },
      "bulletPoints": {
        "lenientReviewer": "The reviewer may be more lenient or harsh when grading.",
        "worseTeaching": "The instructor's explanation may be better or worse.",
        "lessAptitude": "The student's may have more or less aptitude for the material."
      }
    },
    "help": {
      "chartShowPercentages": "This chart shows what percentage of grades were assigned manually by the reviewer.",
      "percentagesCloseTo100": "The percentage will be close to 100% for tasks that are only graded manually.",
      "higherForAI": "It should also be higher for AI graded tasks with rubrics that are not well defined."
    }
  },
  "fr": {
    "reviewers":{
      "empty": "Comparer jusqu'à 5 évaluateurs"
    },
    "sections": "Sections",
    "previousReviewer": "Évaluateur précédent",
    "nextReviewer": "Évaluateur suivant",
    "averageGradeByTask": "Note moyenne par tâche",
    "noDataToDisplay": "Aucune donnée à afficher.",
    "filteredTo": "Filtré à",
    "clearFiltering": "Effacer le filtrage",
    "manualGrading": "Évaluation manuelle",
    "avgGrade": {
      "interpretations": {
        "nicerGrader": "Dans ce graphique, vous pouvez voir comment les étudiants de l'évaluateur sélectionné performent comparé au reste de la classe.",
        "betterTeaching": "Ce graphique montre la note moyenne des étudiants pour chaque question du devoir. Les points en bleu foncé représentent la note moyenne de tous les étudiants. Les barres violettes représentent la note moyenne des étudiants dans les sections de l'évaluateur sélectionné.",
        "smarterStudents": "Si les barres violettes sont systématiquement plus élevées ou plus basses que les points en bleu foncé, cela signifie que les étudiants dans les sections de l'évaluateur sélectionné obtiennent de meilleurs ou de moins bons résultats que le reste de la classe. Il y a plusieurs raisons pour cela :"
      },
      "bulletPoints": {
        "lenientReviewer": "L'évaluateur peut être plus indulgent ou sévère lors de la notation.",
        "worseTeaching": "L'explication de l'instructeur peut être meilleure ou pire.",
        "lessAptitude": "Les étudiants peuvent avoir plus ou moins d'aptitude pour le sujet."
      }
    },
    "help": {
      "chartShowPercentages": "Ce graphique montre quel pourcentage des notes a été attribué manuellement par l'évaluateur.",
      "percentagesCloseTo100": "Le pourcentage sera proche de 100 % pour les tâches qui ne sont notées que manuellement.",
      "higherForAI": "Il devrait également être plus élevé pour les tâches notées par IA avec des rubriques qui ne sont pas bien définies."
    }
  }
}
</i18n>
