<template>
  <s-btn @click="openDialog = !openDialog">{{ openDialog ? 'Close Dialog' : 'Open Dialog' }}</s-btn>
  <s-btn @click="openModal = !openModal" color="secondary">
    {{ openModal ? 'Close Modal' : 'Open Modal' }}
  </s-btn>

  <s-dialog v-model:open="openDialog">
    <SCard>
      <h2 class="heading lg">This is a Dialog</h2>
      <p>Huzzah</p>
    </SCard>
  </s-dialog>

  <s-dialog v-model:open="openModal" modal class="my-0 mx-auto w-screen overflow-visible">
    <div class="px-4 py-5 md:py-7 pointer-events-none overflow-y-auto h-full max-h-screen">
      <SCard
        class="pointer-events-auto w-full mx-auto max-w-screen-md animate-[slideIn_0.2s_ease-out_forwards]"
      >
        <h2 class="heading lg">This is a Dialog</h2>
        <p>Huzzah</p>
        <p>Huzzah</p>
        <p>Huzzah</p>
        <p>Huzzah</p>
        <p>Huzzah</p>
        <p>Huzzah</p>
      </SCard>
    </div>
  </s-dialog>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
import {ref} from 'vue';
import SDialog from '../../../../components/SDialog.vue';
import SCard from '../../../../design-system/SCard.vue';

const openDialog = ref(false);
const openModal = ref(false);
</script>
