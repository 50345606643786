<template>
  <Head :title="t('page.title', {courseCode: course.code, assignmentName: assignment.name})"></Head>
  <main class="page-block max-w-screen-md align-left gap-4">
    <s-page-header :heading="t('page.heading')">
      <template #actions>
        <s-link
          class="btn md white w-fit flex items-center gap-1"
          :href="`/courses/${course.id}/assignments/${assignment.id}/extensions`"
          external
        >
          <s-icon name="arrow-left" class="opacity-70 -mt-px" size="16" />
          {{ t('back') }}
        </s-link>
      </template>
    </s-page-header>

    <form @submit.prevent="grantExtensions" class="pt-1">
      <SFileUpload
        v-model="form.extensionsCsvFile"
        :file-type-message="t('page.csvOnly')"
        accept="text/csv"
        @errorChange="error = $event"
      ></SFileUpload>

      <div v-if="error" class="pt-5 animate-slide-in-up">
        <p
          class="flex items-center justify-start gap-1.5 px-4 py-3 border border-red-200 bg-gradient-to-r from-red-100/70 to-red-100 text-base leading-tight text-red-700 rounded-md shadow-soft"
        >
          <s-icon name="warning" class="opacity-70" width="18" height="18" />
          {{ error }}
        </p>
      </div>
      <div class="w-full flex flex-wrap items-start justify-start pt-5 pb-3 gap-4">
        <div class="flex-1 flex justify-start">
          <details class="flex flex-col items-start">
            <summary class="list-none cursor-pointer flex justify-start select-none">
              <span class="btn sm gray">
                {{ t('page.viewFormat') }}
              </span>
            </summary>
            <pre
              class="bg-white border border-gray-200 px-4 py-3 rounded-md text-base text-gray-600 mt-3 shadow-soft"
            ><code>{{ exampleCSVText }}</code></pre>
            <pre
              class="bg-white border border-gray-200 px-4 py-3 rounded-md text-base text-gray-600 mt-3 shadow-soft"
            ><code>{{ secondExampleCSVText }}</code></pre>
          </details>
        </div>
        <div class="flex-1 flex flex-wrap items-end justify-end gap-x-4 gap-y-2">
          <s-link
            type="submit"
            class="btn white lg flex items-center justify-center gap-2"
            :href="`/courses/${course.id}/assignments/${assignment.id}/extensions`"
            external
          >
            <s-icon name="close" class="opacity-70" width="18" height="18" />
            {{ t('form.cancel') }}
          </s-link>
          <button
            type="submit"
            class="btn primary lg flex items-center justify-center gap-2"
            :disabled="form.processing"
          >
            <s-icon
              v-if="form.processing"
              name="loading"
              class="opacity-70 animate-spin"
              width="18"
              height="18"
            />
            <s-icon v-else name="calendar-plus" class="opacity-70" width="18" height="18" />
            {{ t('buttons.grant') }}
          </button>
        </div>
      </div>
    </form>
  </main>
</template>

<script setup lang="ts">
import SFileUpload from '../../components/SFileUpload.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLink from '../../components/SLink.vue';
import {Assignment} from '../../types/entities/assignment';
import {Course} from '../../types/entities/course';
import {Head, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {ref} from 'vue';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({useScope: 'local', inheritLocale: true});

const {assignment, course} = defineProps<{
  assignment: Assignment;
  course: Course;
}>();

const error = ref<string>('');

const exampleCSVText = `Email, New Due Date
student@example.org,2:HOURS
another.student@example.org,2:DAYS
yetanother.student@example.org,1:WEEK`;

const secondExampleCSVText = `Email, New Start Date, New Due Date
student@example.org,1:HOURS,1:HOURS
another.student@example.org,2:DAYS,2:DAYS
yetanother.student@example.org,-1:WEEK,-1:WEEK
friend@example.org,,1:HOURS
acquaintance@example.org,null,2:DAYS
stranger@example.org,none,1:WEEK`;

const form = useForm({
  extensionsCsvFile: null as null | File,
});

function grantExtensions() {
  if (!form.extensionsCsvFile) {
    error.value = 'Please upload a CSV file.';
    return; // Prevent form submission
  }

  form.post(
    route('courses.assignments.extension.bulk.store', {
      course: course.id,
      assignment: assignment.id,
    })
  );
}
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Bulk Grant Extension",
      "heading": "Grant Extensions from CSV",
      "csvOnly": "CSV files only",
      "viewFormat": "View CSV Format"
    },
    "buttons": {
      "grant": "Grant Extensions"
    }
  },
  "fr": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Accorder Plusieures Prolongations",
      "heading": "Accorder des prolongations à partir d'un fichier CSV",
      "csvOnly": "Fichiers CSV seulement",
      "viewFormat": "Afficher le format CSV"
    },
    "buttons": {
      "grant": "Accorder des prolongations"
    }
  }
}
</i18n>
