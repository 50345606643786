import {Plugin} from 'prosemirror-state';
import {getCurrentInstance} from 'vue';
import {VueNodeView} from '../views/VueNodeView';

export class VueNodeViewPlugin extends Plugin {
  constructor(
    name: string,
    component: any,
    props: Record<string, any> = {},
    wrapperTagName: string = 'span'
  ) {
    const currentInstance = getCurrentInstance();

    super({
      props: {
        nodeViews: {
          [name]: (node, view, getPos) => {
            return new VueNodeView(
              currentInstance!.appContext,
              component,
              {
                view,
                node,
                getPos,
                ...props,
              },
              wrapperTagName
            );
          },
        },
      },
    });
  }
}
