<template>
  <task-form :form="form" :errors="errors" class="fieldset">
    <s-input-field
      id="heading"
      label="Heading"
      description="Displayed at the top of the task in large font. This is the title visible to students."
      :required="false"
      v-model="form.ui.heading"
      :error="errors?.['ui.heading']"
    />
    <s-textarea-field
      id="text"
      label="Question"
      v-model="form.ui.text"
      :error="errors?.['ui.text']"
    />
    <s-field class="mt-2 mb-2" id="showCharacterLimit" label="Character Limit to Student Response">
      <div class="flex items-center space-x-2">
        <s-button-toggle
          id="showCharacterLimit"
          :options="[
            {value: null, label: 'No'},
            {value: form.ui.characterLimit ?? 0, label: 'Yes'},
          ]"
          v-model="form.ui.characterLimit"
        />
        <template v-if="form.ui.characterLimit !== null">
          <s-input-field
            id="characterLimit"
            :required="false"
            type="number"
            min="0"
            v-model="form.ui.characterLimit"
          />
        </template>
      </div>
    </s-field>
    <s-textarea-field
      id="rubric"
      label="Rubric"
      v-model="form.grader.rubric"
      :error="errors?.['grader.rubric']"
    />
    <div class="flex flex-wrap gap-4 mb-1">
      <s-input-field
        id="scoreMaxValue"
        type="number"
        step="1"
        min="0"
        label="Total Points Available"
        v-model="form.grader.scoreMaxValue"
        :error="errors?.['grader.scoreMaxValue']"
      />
      <s-input-field
        id="Subject"
        label="Subject"
        :required="false"
        v-model="form.grader.subject"
        :error="errors?.['grader.subject']"
      />
    </div>
    <s-field
      class="mt-2"
      id="showGradeIncrement"
      description="The smallest increment that can be used to grade the task. Default is 0.05."
      label="Grading Increment"
      :error="errors?.['grader.gradeIncrement']"
    >
      <s-button-toggle
        id="showGradeIncrement"
        :options="[
          {value: false, label: 'Default Precision &ndash; 0.05'},
          {
            value: true,
            label: !useCustomGradeIncrement
              ? 'Custom'
              : 'Custom &ndash; ' +
                ((increment) => {
                  const fixedThree = (increment ?? 0.05).toFixed(3);
                  return fixedThree.charAt(fixedThree.length - 1) === '0'
                    ? fixedThree.slice(0, -1)
                    : fixedThree;
                })(customGradeIncrement),
          },
        ]"
        v-model="useCustomGradeIncrement"
      />
      <div v-if="useCustomGradeIncrement" class="pt-2">
        <grade-increment-slider v-model="customGradeIncrement" />
      </div>
    </s-field>
  </task-form>
</template>
<script lang="ts" setup>
import {defineProps, ref, watch} from 'vue';
import SInputField from '../../../design-system/SInputField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import GradeIncrementSlider from '../../../components/tasks/ai-parts/GradeIncrementSlider.vue';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';
import SingleAiTaskFormDto = App.DTOs.Tasks.SingleAiTaskFormDto;

const props = defineProps<{
  modelValue?: SingleAiTaskFormDto;
  isCopy?: boolean;
  errors?: Record<string, string>;
}>();

const form = useForm<SingleAiTaskFormDto>(
  () =>
    props.modelValue || {
      title: '',
      type: 'SingleAiTask',
      status: 'draft',
      ui: {
        heading: '',
        text: '',
        characterLimit: null,
      },
      grader: {
        rubric: '',
        scoreMaxValue: 1,
        gradeIncrement: DEFAULT_GRADE_INCREMENT,
        subject: 'Chemistry',
      },
      draftable: true,
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      institutionId: null,
    }
);

const DEFAULT_GRADE_INCREMENT = 0.05;
const useCustomGradeIncrement = ref(form.grader.gradeIncrement !== DEFAULT_GRADE_INCREMENT);
const customGradeIncrement = ref(form.grader.gradeIncrement || DEFAULT_GRADE_INCREMENT);

watch(
  [useCustomGradeIncrement, customGradeIncrement],
  ([useCustomGradeIncrement, customGradeIncrement]) => {
    if (useCustomGradeIncrement) {
      form.grader.gradeIncrement = customGradeIncrement;
    } else {
      form.grader.gradeIncrement = DEFAULT_GRADE_INCREMENT;
    }
  }
);
</script>
