import {NodeSpec} from 'prosemirror-model';

export let math_display_spec: NodeSpec = {
  // important!
  group: 'block math',
  content: 'text*', // important!
  atom: true, // important!
  code: true, // important!
  toDOM: () => ['math-display', {class: 'math-node'}, 0],
  parseDOM: [
    {
      tag: 'math-display', // important!
    },
  ],
};
