<template>
  <legacy-task
    v-model:responseData="form.responseData"
    :seed="seed || 1"
    :task="previewTask"
    :task-state="previewTaskState"
    :rubric
    :stored-response-id="storedResponse?.id || null"
    :stored-response-content="storedResponse?.content || null"
    :stored-response-feedback-by-parts="feedbackByPart"
    :stored-response-part-grades="partGrades"
    class="mb-3"
    :can="{
      viewAnyPartGrade: true,
    }"
  />
  <div class="flex flex-wrap gap-2 mb-4">
    <div class="flex-1 flex gap-2 items-center flex-wrap">
      <s-textbook-link :task="previewTask" />
      <s-btn
        v-if="previewTask.taskType !== 'WatchVideoTask' && previewTask.youtubeVideoId"
        color="gray"
        size="sm"
        icon="youtube"
        target="_blank"
        :href="`https://www.youtube.com/watch?v=${previewTask.youtubeVideoId}`"
        external
      >
        {{ $t('video') }}
      </s-btn>
    </div>
    <s-btn
      v-if="isSubmittableTask(previewTask)"
      type="button"
      @click.prevent="submitResponse"
      class="self-end"
      color="primary"
      :processing="submitting"
    >
      {{ t('form.submit') }}
    </s-btn>
  </div>
  <student-feedback-area
    v-if="grade || Object.keys(feedbackByPart).length"
    :response-grade="grade"
    :stored-response="storedResponse"
    :feedback-by-part="feedbackByPart"
    :task-weight="100"
  />
</template>
<script setup lang="ts">
import LegacyTask from '../components/tasks/LegacyTask.vue';
import SBtn from '../design-system/SBtn.vue';
import {Task, TaskResponse} from '../types/entities/tasks';
import {useI18n} from 'vue-i18n';
import assignmentTasksTranslations from '../pages/AssignmentTasks/assignmentTasks.json';
import StudentFeedbackArea from '../pages/AssignmentTask/Show/StudentFeedbackArea.vue';
import {reactive, ref, watch} from 'vue';
import axios from 'axios';
import {route} from 'ziggy-js';
import STextbookLink from '../components/STextbookLink.vue';
import Rubric = App.DTOs.Rubrics.Rubric;

const props = defineProps<{
  seed?: number;
  rubric?: Rubric | null;
  previewTask: Task;
  previewTaskState?: any;
}>();

const form = reactive({
  taskId: props.previewTask.id,
  taskStateId: props.previewTaskState?.id,
  responseData: {},
});

const storedResponse = ref<TaskResponse | null>(null);
const submitting = ref(false);
const grade = ref(null);
const feedbackByPart = ref({});
const partGrades = ref([]);

watch(
  () => props.previewTask.id,
  () => {
    grade.value = null;
    feedbackByPart.value = {};
    partGrades.value = [];
    storedResponse.value = null;
  }
);

async function submitResponse() {
  if (submitting.value) return;

  try {
    submitting.value = true;
    const {data} = await axios.post(
      route('api.v1.tasks.preview', {task: props.previewTask.id}),
      form
    );
    submitting.value = false;
    storedResponse.value = data.response;
    grade.value = data.grade;
    feedbackByPart.value = data.feedbackByPart;
    partGrades.value = data.partGrades;
  } catch (e) {
    submitting.value = false;
  }
}

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
  messages: assignmentTasksTranslations,
});

const isSubmittableTask = (task: Task) => {
  if (!task) return false;
  if (task.taskType === 'WatchVideoTask') return false;
  if (task.taskType === 'DynamicQuestion' && !task.content.hasGrader) return false;
  return true;
};
</script>
