<template>
  <Head :title="`${t('heading')} - ${user.email}`"></Head>
  <s-page-block-scrollable>
    <template #header>
      <s-page-header :heading="t('heading')" class="mb-4"></s-page-header>
    </template>

    <fieldset v-if="false" class="flex flex-wrap gap-3 mb-4">
      <s-multi-select
        id="activityTypes"
        :label="t('search.activityTypes')"
        :model-value="selectedActivityTypes"
        value-key="value"
        label-key="description"
        :empty-label="t('search.activityTypes')"
        :options="translatedActivityTypes"
        @update:model-value="
          params.activityTypes = $event;
          debouncedSubmitSearch({activityTypes: $event});
        "
      >
        <template #option="{option}">
          <div class="flex items-center gap-1">
            <activity-icon :activity-type-name="option.name" />
            <span>{{ option.description }}</span>
          </div>
        </template>
      </s-multi-select>
    </fieldset>

    <UserActivityLogTable :user-activities="userActivities.data" />
    <template #footer>
      <s-paginator :paginator="userActivities" :only="reloadProps" />
    </template>
  </s-page-block-scrollable>
</template>
<script setup lang="ts">
import {Head, router} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import UserActivityLogDto = App.DTOs.UserActivityLogDto;
import ShowParticipantDto = App.DTOs.CourseParticipant.ShowParticipantDto;
import {computed} from 'vue';
import IntegerEnumDto = App.DTOs.Users.IntegerEnumDto;
import {usePageParams} from '../../../composables/usePageParams';
import {useCancellableDebounceFn} from '../../../composables/useCancellableDebounceFn';
import SPaginator from '../../../design-system/SPaginator.vue';
import {LaravelPaginator} from '../../../types/laravel-paginator';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SMultiSelect from '../../../design-system/SMultiSelect.vue';
import ActivityIcon from '../../CourseParticipantActivity/Index/ActivityIcon.vue';
import SPageBlockScrollable from '../../../design-system/SPageBlockScrollable.vue';
import UserActivityLogTable from '../../../components/user-activity-logs/UserActivityLogTable.vue';

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const props = defineProps<{
  user: ShowParticipantDto;
  userActivities: LaravelPaginator<UserActivityLogDto>;
  activityTypes: IntegerEnumDto[];
}>();

type PropKeys = keyof typeof props;
const reloadProps: PropKeys[] = ['userActivities'];

const translatedActivityTypes = computed<{value: number; name: string; description: string}[]>(
  () => {
    return props.activityTypes.map((activityType) => ({
      ...activityType,
      description: t(`activities.titles.${activityType.name}`),
    }));
  }
);

type PageParams = {
  activityTypes?: string[];
  assignmentId?: string;
  taskId?: string;
};

const params = usePageParams<PageParams>();

const selectedAssignmentId = computed(() => {
  return params.assignmentId ? parseInt(params.assignmentId) : undefined;
});

const selectedTaskId = computed(() => {
  return params.taskId ? parseInt(params.taskId) : undefined;
});

const selectedActivityTypes = computed(() => {
  return params.activityTypes?.map((x) => parseInt(x));
});

const getRouteParams = () => {
  return {
    ...params,
  };
};

const submitSearch = (params: Partial<PageParams>) => {
  router.visit(
    route('admin.users.activity.index', {
      user: props.user.id,
      // Get current route params
      ...getRouteParams(),
      // Empty page when changing filters
      page: undefined,
      // Merge in latest changes.
      ...params,
    }),
    {
      only: reloadProps,
    }
  );
};

const {debounced: debouncedSubmitSearch} = useCancellableDebounceFn(submitSearch, 1500);
</script>
<i18n>
{
  "en": {
    "heading": "Activity Log",
    "search": {
      "activityTypes": "Activity Types"
    }
  },
  "fr": {
    "heading": "Journal d'activités",
    "search": {
      "activityTypes": "Types d'activités"
    }
  }
}
</i18n>
