<template>
  <s-textarea-field
    id="label"
    label="Label"
    description="The label that will be presented above the options."
    :model-value="label ?? ''"
    :error="errors?.label"
    @update:modelValue="emit('update:label', $event)"
    class="mb-4"
  />
  <s-form-list
    title="Options"
    addLabel="Add Option"
    :addItem="addOption"
    :items="clonedOptions"
    :removeItem="(item, index) => removeOption(index)"
    :sortable="true"
    placeholder="No Options Added"
    @update:items="
      clonedOptions = $event;
      syncOptions();
    "
  >
    <template #item="{index}">
      <div class="flex-1 w-full flex flex-wrap gap-x-3 gap-y-2 justify-start p-5">
        <div class="flex-1 max-w-[10rem] grow">
          <s-input-field
            :id="`options.${index}.key`"
            label="Key"
            :model-value="clonedOptions[index].key"
            :error="errors?.options?.[index]?.key"
            @update:modelValue="
              clonedOptions[index].key = $event;
              syncOptions();
            "
          />
        </div>
        <div class="flex-1 grow-[2]">
          <s-input-field
            :id="`options.${index}.label`"
            label="Label"
            :model-value="clonedOptions[index].label"
            :error="errors?.options?.[index]?.label"
            @update:modelValue="
              clonedOptions[index].label = $event;
              syncOptions();
            "
          />
        </div>
      </div>
    </template>
  </s-form-list>
  <s-toggle-field
    id="randomizeOptionOrder"
    v-model="randomizeOptionOrder"
    label="Randomize Option Order"
  />
  <s-select-field
    id="multiSelectUiType"
    v-model="uiType"
    label="Display Type"
    :error="errors?.uiType"
  >
    <option value="checkboxes">Checkboxes</option>
    <option value="list">Dropdown List</option>
  </s-select-field>
  <s-input-field
    id="multiSelectPointsAvailable"
    v-model.number="points"
    type="number"
    label="Points Available (optional)"
    tooltip="When this is not set, the points available will be the number of correct answers."
    :error="errors?.points"
  />
  <s-multi-select-field
    v-model="correctAnswers"
    :options="options ?? []"
    label-key="label"
    value-key="key"
    label="Correct Answers"
    id="multipleSelectCorrectOption"
    empty-label="Options"
    :error="errors?.correctAnswers"
  />
</template>

<script setup lang="ts">
import SInputField from '../../../../design-system/SInputField.vue';
import SMultiSelectField from '../../../../design-system/SMultiSelectField.vue';
import STextareaField from '../../../../design-system/STextareaField.vue';
import SFormList from '../../../../design-system/SFormList.vue';
import OptionDto = App.DTOs.Tasks.OptionDto;
import {ref} from 'vue';
import {useVModels} from '@vueuse/core';
import MultiSelectUiType = App.Enums.Tasks.Ui.MultiSelectUiType;
import SSelectField from '../../../../design-system/SSelectField.vue';
import SToggleField from '../../../../design-system/SToggleField.vue';

const props = defineProps<{
  label: string | null;
  correctAnswers: (string | number)[];
  options: OptionDto[] | null;
  randomizeOptionOrder: boolean;
  uiType: MultiSelectUiType;
  errors?: Record<string, any>;
  points: number | null;
}>();

const emit = defineEmits([
  'update:label',
  'update:options',
  'update:correctAnswers',
  'update:randomizeOptionOrder',
  'update:points',
  'update:uiType',
]);
const {correctAnswers, points, label, uiType, randomizeOptionOrder} = useVModels(props, emit);

const clonedOptions = ref<OptionDto[]>(JSON.parse(JSON.stringify(props.options ?? [])));

const syncOptions = () => {
  emit('update:options', clonedOptions.value);
};

const addOption = () => {
  clonedOptions.value.push({key: '', label: ''});
  syncOptions();
};

const removeOption = (index: number) => {
  clonedOptions.value.splice(index, 1);
  syncOptions();
};
</script>
