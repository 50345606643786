export function formatLatexNumberValue(numberValue: any) {
  if (numberValue.isScientificNotation) {
    let result = numberValue.scientificNotationNumber;
    if (numberValue.scientificNotationExponent) {
      result += ` \\times 10^{${numberValue.scientificNotationExponent}}`;
    }
    return result;
  }

  const sign = numberValue.number < 0 ? '-' : '';
  const strInt = Math.abs(numberValue.number).toString();
  const decimalPointLoc = strInt.length + numberValue.shift;

  if (decimalPointLoc >= strInt.length) {
    // Needs right-side zero padding
    return sign + strInt + '0'.repeat(numberValue.shift);
  } else if (decimalPointLoc > 0) {
    // Needs no zero padding
    return sign + strInt.substring(0, decimalPointLoc) + '.' + strInt.substring(decimalPointLoc);
  }

  const decimal = '0.' + '0'.repeat(Math.abs(decimalPointLoc));
  return sign + decimal + strInt;
}
