<template>
  <s-field v-bind="$props">
    <!-- Pass label and tooltip slots through to the SField component -->
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="$slots.tooltip" #tooltip>
      <slot name="tooltip" />
    </template>
    <!-- Main body of Input -->
    <template v-slot="a11yProps">
      <div :class="`relative flex items-stretch justify-between ${sizeClasses[size].text}`">
        <div
          v-if="prefix || $slots.prefix"
          :class="`flex gap-1.5 items-center justify-end leading-tight rounded-l-md border border-r-0 border-gray-200 bg-gray-100 ${sizeClasses[size].padding}`"
        >
          <slot name="prefix">
            {{ prefix }}
          </slot>
        </div>
        <s-input
          v-bind="{...a11yProps, ...$attrs}"
          :type="inputType"
          v-model="model"
          :required="required"
          class="z-10"
          :class="{
            '!rounded-r-none': !!(suffix || $slots.suffix),
            '!rounded-l-none': !!(prefix || $slots.prefix),
            [inputClass || '']: !!inputClass,
            '!pr-10': isPasswordField,
          }"
          :size="size"
        />
        <button
          v-if="isPasswordField"
          type="button"
          class="absolute flex items-center justify-center gap-2 opacity-50 hover:opacity-70 active:opacity-100 transition top-1/2 right-3.5 -translate-y-1/2"
          :aria-label="showPassword ? t('hidePassword') : t('showPassword')"
          @click.prevent="showPassword = !showPassword"
        >
          <s-icon :name="showPassword ? 'eye' : 'eye-off'" :size="sizeClasses[size].icon" />
        </button>
        <div
          v-if="suffix || $slots.suffix"
          :class="`flex gap-1.5 items-center justify-start leading-tight rounded-r-md border border-l-0 border-gray-200 bg-gray-100 ${sizeClasses[size].padding}`"
        >
          <slot name="suffix">
            {{ suffix }}
          </slot>
        </div>
      </div>
    </template>
  </s-field>
</template>
<script setup lang="ts">
import SField from './SField.vue';
import SInput from './SInput.vue';
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import SIcon from './SIcon.vue';

const model = defineModel<string | number | null>();

type Size = 'sm' | 'md' | 'lg';

const {type, size = 'md'} = defineProps<{
  id: string;
  label?: string | null;
  description?: string;
  tooltip?: string | null;
  prefix?: string;
  suffix?: string;
  error?: string;
  required?: boolean;
  size?: Size;
  inputClass?: string;
  type?: string;
}>();

const isPasswordField = computed(() => type === 'password');
const showPassword = ref(false);

const inputType = computed(() => {
  if (!isPasswordField.value) return type;
  return showPassword.value ? 'text' : 'password';
});

const sizeClasses = {
  sm: {
    padding: 'px-2.5 py-1.5',
    text: 'text-base',
    icon: '18',
  },
  md: {
    padding: 'px-3.5 py-2.5',
    text: 'text-base',
    icon: '20',
  },
  lg: {
    padding: 'px-3.5 py-2.5',
    text: 'text-lg',
    icon: '22',
  },
};

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<i18n>
{
  "en": {
    "showPassword": "Show Password",
    "hidePassword": "Hide Password"
  },
  "fr": {
    "showPassword": "Afficher le mot de passe",
    "hidePassword": "Masquer le mot de passe"
  }
}
</i18n>
