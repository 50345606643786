import {NodeType} from 'prosemirror-model';
import {Command, EditorState} from 'prosemirror-state';
import {setBlockType} from 'prosemirror-commands';

/**
 * Toggles the block between the given node type and the paragraph node type.
 *
 * @param nodeType
 * @param attrs
 */
export function toggleBlockType(nodeType: NodeType, attrs: any): Command {
  const setToGivenType = setBlockType(nodeType, attrs);
  const setToParagraph = setBlockType(nodeType.schema.nodes.paragraph);

  return (state, dispatch) => {
    if (setToGivenType(state, dispatch)) {
      return true;
    } else {
      return setToParagraph(state, dispatch);
    }
  };
}
