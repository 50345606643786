<template>
  <template v-if="disabled">
    <slot></slot>
  </template>
  <span
    v-else
    :id
    :class="['inline-block cursor-help', wrapperClass]"
    ref="wrapperRef"
    @mouseenter="openTooltip()"
    @mouseleave="hideTooltipIfNeitherElIsHovered()"
  >
    <slot></slot>
    <span class="sr-only">{{ text }}</span>
  </span>
  <Teleport to="body">
    <TransitionRoot
      appear
      :show="!disabled && showTooltip"
      as="template"
      enter="transition-opacity duration-100 ease-out"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150 ease-in"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div
        @mouseleave="hideTooltipIfNeitherElIsHovered()"
        aria-hidden="true"
        ref="tooltipRef"
        :style="floatingStyles"
        class="z-50"
        :class="$props['class']"
      >
        <slot name="tooltip" />
      </div>
    </TransitionRoot>
  </Teleport>
</template>
<script setup lang="ts">
import {TransitionRoot} from '@headlessui/vue';
import {defineProps, ref, onMounted, onBeforeUnmount} from 'vue';
import {useFloating, autoUpdate, offset, shift, flip} from '@floating-ui/vue';

const {disabled, wrapperClass} = defineProps<{
  id?: string;
  text?: string;
  disabled?: boolean;
  class?: string | Record<string, boolean> | string[];
  wrapperClass?: string | Record<string, boolean> | string[];
}>();

const DELAY = 200;

let openTooltipTimeout: NodeJS.Timeout | null = null;
let closeTooltipTimeout: NodeJS.Timeout | null = null;

const showTooltip = ref(false);
const wrapperRef = ref<HTMLElement | null>(null);
const tooltipRef = ref<HTMLElement | null>(null);
const middleware = ref([offset(8), shift(), flip()]);

const {floatingStyles} = useFloating(wrapperRef, tooltipRef, {
  placement: 'bottom',
  middleware,
  whileElementsMounted: autoUpdate,
});

const openTooltip = () => {
  // Delay opening the tooltip to prevent flickering
  openTooltipTimeout = setTimeout(() => {
    if (!wrapperRef.value) {
      return;
    }
    const wrapperIsHovered = wrapperRef.value.matches(':hover');
    if (wrapperIsHovered) {
      showTooltip.value = true;
    }
  }, DELAY);
};

const hideTooltipIfNeitherElIsHovered = () => {
  // Delay closing the tooltip to prevent flickering
  closeTooltipTimeout = setTimeout(() => {
    if (!wrapperRef.value || !tooltipRef.value) {
      return;
    }

    const wrapperIsHovered = wrapperRef.value.matches(':hover');
    const tooltipIsHovered = tooltipRef.value.matches(':hover');

    if (!wrapperIsHovered && !tooltipIsHovered) {
      showTooltip.value = false;
    }
  }, DELAY);
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    showTooltip.value = false;
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
  if (closeTooltipTimeout) {
    clearTimeout(closeTooltipTimeout);
  }
  if (openTooltipTimeout) {
    clearTimeout(openTooltipTimeout);
  }
});
</script>
