<template>
  <div class="mb-4 flex justify-end">
    <s-field
      id="markingExamples"
      :label="t('fields.markingExamples')"
      :error="formatHiddenErrors(errors)"
    />
    <s-btn
      :icon="markingExamples.length ? 'pencil' : 'plus'"
      @click.prevent="openBuilderModal"
      :disabled="!criteria.every((c) => c.criteriaName)"
      :color="markingExamples.length ? 'secondary' : 'primary-light'"
    >
      {{ markingExamples.length ? t('fields.edit') : t('fields.add') }}
    </s-btn>
  </div>

  <div v-for="(questionExample, exampleIndex) in markingExamples" :key="exampleIndex">
    <s-card>
      <div class="flex justify-between">
        <div>{{ t('fields.exampleStudentResponse') }}: {{ questionExample.studentResponse }}</div>
      </div>
      <s-table>
        <template #header>
          <tr>
            <th>{{ t('fields.criteriaName') }}</th>
            <th>{{ t('fields.exampleGrade') }}</th>
            <th>{{ t('fields.exampleFeedback') }}</th>
          </tr>
        </template>
        <template #body>
          <tr
            v-for="(criterionExample, criterionIdx) in questionExample.criterionExamples"
            :key="criterionIdx"
          >
            <td>{{ criterionExample.criteriaName }}</td>
            <td>{{ criterionExample.examplePercentGrade + '%' }}</td>
            <td>{{ criterionExample.exampleFeedback }}</td>
          </tr>
        </template>
      </s-table>
    </s-card>
  </div>

  <s-modal
    :open="showBuilderModal"
    width="lg"
    :title="t('title')"
    @confirm="confirmModalAction"
    @cancel="cancelBuilderModal"
  >
    <s-modal-content>
      <div v-for="(questionExample, exampleIndex) in tempMarkingExamples" :key="exampleIndex">
        <s-card class="mb-2">
          <s-field
            class="mb-4"
            id="exampleStudentResponse"
            :label="t('fields.exampleStudentResponse')"
          >
            <s-textarea
              id="exampleStudentResponse"
              v-model="questionExample.studentResponse"
              :error="errors?.markingExamples?.[exampleIndex]?.['studentResponse']"
            />
          </s-field>

          <s-table>
            <template #header>
              <tr>
                <th>{{ t('fields.criteriaName') }}</th>
                <th>
                  {{ t('fields.exampleGrade') }}
                  <s-tooltip>
                    <template #tooltip>
                      <slot name="tooltip">
                        {{ t('exampleGradeTooltip') }}
                      </slot>
                    </template>
                    <s-icon
                      name="information"
                      width="16"
                      height="16"
                      class="text-blue-500 opacity-70"
                      role="presentation"
                    />
                  </s-tooltip>
                </th>
                <th>
                  {{ t('fields.exampleFeedback') }}
                  <s-tooltip>
                    <template #tooltip>
                      <slot name="tooltip">
                        {{ t('exampleFeedbackTooltip') }}
                      </slot>
                    </template>
                    <s-icon
                      name="information"
                      width="16"
                      height="16"
                      class="text-blue-500 opacity-70"
                      role="presentation"
                    />
                  </s-tooltip>
                </th>
              </tr>
            </template>
            <template #body>
              <tr
                v-for="(criterionExample, criterionIdx) in questionExample.criterionExamples"
                :key="criterionIdx"
              >
                <td>{{ criterionExample.criteriaName }}</td>
                <td>
                  <s-input-field
                    id="examplePercentGrade"
                    type="number"
                    :step="criteria[criterionIdx].gradeIncrement * 100"
                    min="0"
                    max="100"
                    v-model="criterionExample.examplePercentGrade"
                    :error="
                      errors?.markingExamples?.[exampleIndex]?.criterionExamples?.[criterionIdx]?.[
                        'examplePercentGrade'
                      ]
                    "
                  >
                    <template #suffix>{{ t('exampleGradeUnit') }}</template>
                  </s-input-field>
                </td>
                <td>
                  <s-textarea
                    id="exampleFeedback"
                    v-model="criterionExample.exampleFeedback"
                    :error="
                      errors?.markingExamples?.[exampleIndex]?.criterionExamples?.[criterionIdx]?.[
                        'exampleFeedback'
                      ]
                    "
                  />
                </td>
              </tr>
            </template>
          </s-table>
          <s-btn
            class="mt-2"
            @click.prevent="tempMarkingExamples.splice(exampleIndex, 1)"
            icon="trash-can"
            color="danger"
            size="sm"
          />
        </s-card>
      </div>
      <s-btn @click.prevent="addMarkingExample()" icon="plus" color="primary-light" class="mt-4">
        {{ t('actions.addMarkingExample') }}
      </s-btn>
    </s-modal-content>
  </s-modal>
</template>

<script setup lang="ts">
import {useVModel} from '@vueuse/core';
import {defineEmits, defineProps, ref, watch} from 'vue';
import SInputField from '../../../design-system/SInputField.vue';
import SField from '../../../design-system/SField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';
import SModal from '../../../design-system/SModal.vue';
import SModalContent from '../../../design-system/SModalContent.vue';
import STable from '../../../design-system/STable.vue';
import STextarea from '../../../design-system/STextarea.vue';
import SCard from '../../../design-system/SCard.vue';
import STooltip from '../../../design-system/STooltip.vue';
import SIcon from '../../../design-system/SIcon.vue';
import AiGradingCriteriaDto = App.DTOs.Tasks.AiGradingCriteriaDto;
import AiQuestionMarkingExampleDto = App.DTOs.Tasks.AiQuestionMarkingExampleDto;

const props = defineProps<{
  modelValue: Array<AiQuestionMarkingExampleDto>;
  criteria: Array<AiGradingCriteriaDto>;
  errors?: Record<string, any>;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

watch(
  () => props.criteria,
  (newCriteria, oldCriteria) => {
    markingExamples.value.forEach((example) => {
      // Handle criteria deletion
      if (newCriteria.length < oldCriteria.length) {
        example.criterionExamples = example.criterionExamples.filter((criterionExample) =>
          newCriteria.some((criterion) => criterion.criteriaName === criterionExample.criteriaName)
        );
      }

      // Handle criteria addition
      if (newCriteria.length > oldCriteria.length) {
        newCriteria.forEach((criterion) => {
          const criterionIsNew = !oldCriteria.some(
            (oldCriterion) => oldCriterion.criteriaName === criterion.criteriaName
          );

          if (criterionIsNew) {
            example.criterionExamples.push({
              criteriaName: criterion.criteriaName,
              examplePercentGrade: 100,
              exampleFeedback: '',
            });
          }
        });
      }

      // Handle criteria name change
      if (newCriteria.length === oldCriteria.length) {
        example.criterionExamples.forEach((criterionExample, idx) => {
          if (criterionExample.criteriaName !== newCriteria[idx].criteriaName) {
            criterionExample.criteriaName = newCriteria[idx].criteriaName;
          }
        });
      }
    });
  },
  {deep: true}
);

const formatHiddenErrors = (
  errors: Record<string, any> | undefined,
  prefix = ''
): string | null => {
  if (!errors) return null;

  return Object.entries(errors)
    .flatMap(([key, value]) => {
      const fullKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && !Array.isArray(value)) {
        return formatHiddenErrors(value, fullKey);
      }
      return `${fullKey}: ${Array.isArray(value) ? value.join(', ') : value}`;
    })
    .join('; ');
};

const showBuilderModal = ref(false);
const emit = defineEmits(['update:modelValue']);
const markingExamples = useVModel(props, 'modelValue', emit);

const tempMarkingExamples = ref<Array<AiQuestionMarkingExampleDto>>(markingExamples.value);

const openBuilderModal = () => {
  tempMarkingExamples.value = markingExamples.value.map((example) => ({...example})); // Deep copy
  showBuilderModal.value = true;
};

const confirmModalAction = () => {
  markingExamples.value = tempMarkingExamples.value;
  showBuilderModal.value = false;
};

const cancelBuilderModal = () => {
  tempMarkingExamples.value = [];
  showBuilderModal.value = false;
};

function addMarkingExample() {
  const exampleGrade = 100;
  tempMarkingExamples.value.push({
    studentResponse: '',
    criterionExamples: props.criteria.map((criterion) => ({
      criteriaName: criterion.criteriaName,
      examplePercentGrade: exampleGrade,
      exampleFeedback: '',
    })),
  });
}
</script>
<i18n>
{
  "en": {
    "title": "Marking Examples Builder",
    "exampleGradeUnit": " / 100%",
    "exampleGradeTooltip": "Provide a percent grade for each marking criterion to exemplify the expected grading behavior.",
    "exampleFeedbackTooltip": "(Optional) Provide feedback for each marking criterion to demonstrate the expected feedback style.",
    "fields": {
      "markingExamples": "Marking Examples",
      "add": "Add Marking Examples",
      "edit": "Edit Marked Examples",
      "exampleGrade": "Example Grade",
      "exampleStudentResponse": "Example Answer",
      "exampleFeedback": "Example Feedback",
      "criteriaName": "Criteria Name"
    },
    "actions": {
      "addMarkingExample": "Add Marking Example"
    }
  },
  "fr": {
    "title": "Constructeur d'exemples de notation",
    "exampleGradeUnit": " / 100%",
    "exampleGradeTooltip": "Fournissez une note en pourcentage pour chaque critère de notation pour illustrer le comportement de notation attendu.",
    "exampleFeedbackTooltip": "(Facultatif) Fournissez des commentaires pour chaque critère de notation pour démontrer le style de commentaires attendu.",
    "fields": {
      "markingExamples": "Exemples de notation",
      "add": "Ajouter des exemples de notation",
      "edit": "Modifier les exemples de notation",
      "exampleGrade": "Note d'exemple",
      "exampleStudentResponse": "Réponse de l'élève",
      "exampleFeedback": "Commentaires d'exemple",
      "criteriaName": "Nom du critère"
    },
    "actions": {
      "addMarkingExample": "Ajouter un exemple de notation"
    }
  }
}
</i18n>
