<template>
  <span>
    <template v-for="(part, index) in parsedContent" :key="index">
      <component v-if="part.isSlot" :is="part.content" />
      <s-latex v-else :content="part.content" />
    </template>
  </span>
</template>

<script setup lang="ts">
import {computed, useSlots, h, defineProps} from 'vue';
import SLatex from '../components/SLatex.vue';

const props = defineProps<{text: string}>();
const slots = useSlots();

const parsedContent = computed(() => {
  const regex = /\{\{\s*(\w+)\s*\}\}/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(props.text)) !== null) {
    const [fullMatch, key] = match;

    // Push the static text before the match
    if (match.index > lastIndex) {
      parts.push({isSlot: false, content: props.text.slice(lastIndex, match.index)});
    }

    // Push slot content if exists, otherwise keep mustache placeholder
    if (slots[key]) {
      parts.push({isSlot: true, content: slots[key]});
    } else {
      parts.push({isSlot: false, content: fullMatch});
    }

    lastIndex = match.index + fullMatch.length;
  }

  // Push remaining text after the last match
  if (lastIndex < props.text.length) {
    parts.push({isSlot: false, content: props.text.slice(lastIndex)});
  }

  return parts;
});
</script>
