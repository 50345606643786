import {Node} from 'prosemirror-model';
import {NodeView, EditorView} from 'prosemirror-view';
import {Component, createVNode, render, AppContext} from 'vue';

export type VueNodeViewProps = {
  view: EditorView;
  node: Node;
  getPos(): number | undefined;
};

export class VueNodeView<T> implements NodeView {
  dom: HTMLElement;
  component: any;

  constructor(
    appContext: AppContext,
    component: Component,
    props: VueNodeViewProps,
    wrapperTagName: string = 'div'
  ) {
    // Create wrapper element to mount Vue component
    this.dom = document.createElement(wrapperTagName);

    // Create the Vue component instance
    const vnode = createVNode(component, props);

    vnode.appContext = appContext;

    // Render the component into the wrapper
    render(vnode, this.dom);

    this.component = vnode.component;
  }

  update(node: Node): boolean {
    if (this.component) {
      this.component.props.node = node;

      return true;
    }
    return false;
  }

  destroy() {
    // Clean up Vue component
    if (this.component) {
      render(null, this.dom);
    }
  }

  stopEvent(event: Event): boolean {
    // Prevent ProseMirror from handling events inside Vue components
    if (event.type === 'dragstart') {
      return false;
    }
    return true;
  }
}
