<template>
  <div
    class="flex flex-col rounded-b-lg bg-gradient-to-b from-gray-100 via-gray-50 to-gray-50 border-t border-gray-150 border-t-gray-200 overflow-hidden py-3.5 pl-4 pr-5 md:pr-7"
  >
    <div class="flex flex-col md:flex-row flex-wrap md:items-start justify-between gap-4">
      <response-listbox
        v-if="taskResponses.length"
        :draft-task-ids="draftTaskIds"
        :task-responses="taskResponses"
        v-model="selectedResponse"
        :student-assignment-task-grades="studentAssignmentTaskGrades"
        :attempted-task-ids="attemptedTaskIds"
        :due-date="dueDate"
        :assignment-extension="assignmentExtension"
        :task-number="taskNumber"
        :task-weight="taskWeight"
        :attempt-number="attemptNumber"
        :response-is-late="responseIsLate"
        :is-effective="isTaskResponseEffective"
        :response="response"
        class="flex-1"
      />
      <div v-else class="pl-1 md:pl-3 flex items-center">
        <span
          class="px-4 py-1.5 text-sm leading-tight font-medium bg-gray-100 border border-gray-200 italic rounded-full text-gray-600"
        >
          {{ t('noResponses') }}
        </span>
      </div>
      <div class="flex-1 flex flex-col items-end gap-1">
        <span class="text-sm flex gap-1 items-center whitespace-nowrap">
          <span class="text-gray-700 font-bold">{{ $t('task') }} {{ taskNumber }}</span>
          <span class="font-medium text-gray-600">&ndash;</span>
          <span class="font-medium text-gray-600">
            {{ t('badges.worthPoints', {points: taskWeight}) }}
          </span>
        </span>
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {Grade} from '../../../types/entities/base-grade';
import {Task, TaskResponse} from '../../../types/entities/tasks';
import ResponseListbox from './ResponseListbox.vue';
import {useVModel} from '@vueuse/core';
import {isResponseLate} from '../../../util/response-timestamps';
import {AssignmentExtension} from '../../../types/entities/assignment-extension';
import {Course} from '../../../types/entities/course';
import {Assignment} from '../../../types/entities/assignment';
import {useI18n} from 'vue-i18n';

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
  | 'submittedBy'
  | 'user'
>;

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  dueDate: string | null | undefined;
  assignmentExtension?: AssignmentExtension | null;
  assignmentTasks: {
    id: number;
    orderingIndex: number;
    pointValue: number;
  }[];

  // Student
  attemptedTaskIds: number[];
  draftTaskIds: number[];

  // Task
  task: Task;
  taskResponses: PartialTaskResponse[];
  studentAssignmentTaskGrades: Grade[];

  // Response
  response: TaskResponse | null;

  selectedResponse: PartialTaskResponse | null;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const selectedResponse = useVModel(props, 'selectedResponse');

const taskNumber = computed(() => {
  return props.assignmentTasks.findIndex((task) => task.id === props.task.id) + 1;
});

const currentTask = computed(() => props.assignmentTasks[taskNumber.value - 1]);

const taskWeight = computed(() => currentTask.value?.pointValue || 0);

const attemptNumber = computed(() => {
  if (!props.response) {
    return 1;
  }

  return (
    props.taskResponses.length -
    props.taskResponses.findIndex((response) => response.id === props.response?.id)
  );
});

const responseIsLate = computed(() =>
  isResponseLate(props.dueDate, props.response, props.assignmentExtension)
);

function isTaskResponseEffective(responseId: number) {
  const grade = props.studentAssignmentTaskGrades
    .filter((grade) => grade.responseId === responseId)
    .sort((a, b) => b.id - a.id)[0];

  return grade?.isEffective;
}
</script>
<i18n>
  {
    "en": {
      "noResponses": "No responses yet",
      "badges": {
        "effective": "Active Grade",
        "xLate": "{timeframe} late",
        "worthPoints": "Worth {points} points"
      },
      "submittedByPersonOn": "Submitted by {submittedBy} on behalf of {onBehalfOf} on {submissionTime}",
      "responseXofY": "Response {index} of {total}"
    },
    "fr": {
      "noResponses": "Aucune réponse pour le moment",
      "badges": {
        "effective": "Note active",
        "xLate": "{timeframe} en retard",
        "worthPoints": "Vaut {points} points"
      },
      "submittedByPersonOn": "Soumis par {submittedBy} au nom de {onBehalfOf} le {submissionTime}",
      "responseXofY": "Réponse {index} sur {total}"
    }
  }
  </i18n>
