import {Plugin} from 'prosemirror-state';

/**
 * A Plugin which intercepts the paste event for text.
 *
 * If the pasted text is a valid URL, it will immediately convert
 * that text into a link.
 */
export const pasteLinkPlugin = new Plugin({
  props: {
    handlePaste(view, event, slice) {
      let text = event.clipboardData?.getData('text/plain') || '';

      if (!isValidURL(text)) {
        return false;
      }

      const {schema, tr} = view.state;
      const {from} = view.state.selection;

      const linkMark = schema.marks.link.create({href: text});
      const textNode = schema.text(text);

      // Paste the plain text
      tr.replaceSelectionWith(textNode);

      // Add the link mark to the pasted text
      tr.addMark(from, from + text.length, linkMark);

      view.dispatch(tr);
      return true; // Prevent default pasting behavior
    },
  },
});

// Utility function to detect if a string is a URL
function isValidURL(str: string) {
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (e) {
    return false;
  }
}
