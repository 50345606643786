<template>
  <form @submit.prevent="submit">
    <s-tabs
      :tabs="[
        {
          label: t('tabs.general'),
          icon: 'pencil',
        },
        {
          label: t('tabs.media'),
          icon: 'image-multiple',
          // Disable media until the task is initially created (needed for now as we need a task id to associate the file against)
          disabled: !allowMedia || !form.id,
        },
        {
          label: t('tabs.topics'),
          icon: 'shape',
          // Disable topics editing until the task is created.
          disabled: !topics?.length || !form.id,
        },
      ]"
      v-model="selectedTab"
    >
      <template #tab-0-content>
        <fieldset class="flex flex-col gap-4 mb-4">
          <s-input-field
            id="text"
            :label="t('labels.title')"
            :tooltip="t('tooltips.title')"
            v-model="form.title"
            :error="form.errors?.title"
          />

          <s-input-field
            v-if="!hideYoutubeId"
            id="youtubeId"
            :label="t('labels.youTubeId')"
            :tooltip="t('tooltips.youtubeId')"
            v-model="form.youtubeId"
            :error="form.errors?.youtubeId"
          />
        </fieldset>

        <fieldset class="flex flex-col gap-4 mb-4">
          <slot />
        </fieldset>

        <fieldset class="flex flex-col gap-5">
          <s-field
            v-if="institutions?.length"
            id="institutionId"
            :label="t('labels.institutionId')"
            :errors="form.errors?.institutionId"
          >
            <template v-slot="a11yAttrs">
              <s-combobox
                v-bind="a11yAttrs"
                :options="[{id: null, name: t('labels.anyInstitution')}, ...institutions]"
                id-key="id"
                :display-value="(i: Institution) => i.name"
                v-model="form.institutionId"
                nullable
                :empty-label="t('labels.anyInstitution')"
                class="w-fit"
              />
            </template>
          </s-field>
          <s-toggle-field id="draftable" :label="t('labels.draftable')" v-model="form.draftable" />
          <s-details>
            <template #summary>{{ t('labels.textbookLink') }}</template>
            <div class="flex flex-col gap-4 mt-4">
              <s-input-field
                id="textbookLink"
                :label="t('labels.textbookLinkUrl')"
                :tooltip="t('tooltips.textbookLinkUrl')"
                v-model="form.textbookLink"
                :error="form.errors?.textbookLink"
              />
              <s-input-field
                id="textbookLinkText"
                :label="t('labels.textbookLinkText')"
                v-model="form.textbookLinkText"
                :error="form.errors?.textbookLinkText"
              />
              <s-input-field
                id="textbookLinkIcon"
                :label="t('labels.textbookLinkIcon')"
                :tooltip="t('tooltips.textbookLinkIcon')"
                v-model="form.textbookLinkIcon"
                :error="form.errors?.textbookLinkIcon"
              />
            </div>
          </s-details>
          <task-status-field v-model="form.status" />
        </fieldset>
      </template>
      <template #tab-1-content>
        <task-media-manager v-model="form.files" :task-id="form.id" />
      </template>
      <template v-if="topics?.length" #tab-2-content>
        <task-topics-manager v-model="form.topicIds" :topics="topics" :task-id="form.id" />
      </template>
      <template #tab-footer>
        <div class="flex-grow flex gap-3 justify-end">
          <s-badge
            v-if="form.isDirty"
            color="yellow"
            size="custom"
            class="animate-slide-in-up px-5 py-2 text-sm leading-none font-bold"
          >
            <s-icon name="alert" size="18" class="opacity-70" />
            <span class="hidden md:inline-block">
              {{ t('form.unsavedChanges') }}
            </span>
          </s-badge>
          <s-btn
            :processing="form.processing"
            class="flex-1 max-w-[14rem]"
            :icon="form.id ? 'content-save' : 'plus'"
          >
            <span v-if="!form.id">
              {{ t('actions.create') }}
            </span>
            <span v-else>
              {{ t('actions.update') }}
            </span>
          </s-btn>
        </div>
      </template>
    </s-tabs>
  </form>
  <s-modal
    v-model:open="showConfirmLeaveModal"
    :title="t('form.unsavedChanges')"
    :message="t('form.pageUnsavedChangesConfirmation')"
    :confirm="{
      label: t('form.discardChanges'),
      icon: 'trash-can',
      color: 'red',
    }"
    @confirm="discardChanges"
    @cancel="keepEditing"
  />
</template>
<script lang="ts" setup>
import {defineProps, onBeforeUnmount, onMounted, ref} from 'vue';
import SInputField from '../design-system/SInputField.vue';
import TaskStatusField from '../components/TaskStatusField.vue';
import SToggleField from '../design-system/SToggleField.vue';
import STabs from '../design-system/STabs.vue';
import SBtn from '../design-system/SBtn.vue';
import SBadge from '../design-system/SBadge.vue';
import SModal from '../design-system/SModal.vue';
import TaskMediaManager from './TaskForm/TaskMediaManager.vue';
import TaskTopicsManager from './TaskForm/TaskTopicsManager.vue';
import {useI18n} from 'vue-i18n';
import {InertiaForm} from '@inertiajs/vue3';
import {Institution} from '../types/entities/institution';
import SCombobox from '../design-system/SCombobox.vue';
import SField from '../design-system/SField.vue';
import SDetails from '../design-system/SDetails.vue';
import TaskFormDto = App.DTOs.Tasks.TaskFormDto;

type CommonFields = Pick<
  TaskFormDto,
  | 'id'
  | 'title'
  | 'youtubeId'
  | 'draftable'
  | 'status'
  | 'textbookLink'
  | 'textbookLinkText'
  | 'textbookLinkIcon'
  | 'files'
  | 'institutionId'
  | 'topicIds'
>;

const {form} = defineProps<{
  form: InertiaForm<CommonFields>;
  hideYoutubeId?: boolean;
  allowMedia?: boolean;
  institutions?: Institution[];
  topics?: TaskTopicDtoWithLineage[];
}>();

const selectedTab = ref(0);

const submit = () => {
  if (form.id) {
    form.put(route('tasks.update', {task: form.id}), {
      onSuccess: () => {
        form.reset();
      },
    });
  } else {
    form.post(route('tasks.store'));
  }
};

const {t} = useI18n();

// If form is dirty, prevent navigation
const showConfirmLeaveModal = ref(false);
let pendingNavigationEvent: (Event & {detail?: any}) | null = null;

function confirmNavigation(event: any) {
  const method = event.detail?.visit?.method?.toLowerCase?.() || 'get';

  if (method !== 'get' || !form.isDirty) {
    return;
  }

  event.preventDefault();
  pendingNavigationEvent = event;
  showConfirmLeaveModal.value = true;
}

function discardChanges() {
  if (pendingNavigationEvent && pendingNavigationEvent.detail?.visit) {
    const {url} = pendingNavigationEvent.detail.visit;

    form.reset();
    showConfirmLeaveModal.value = false;
    window.location.href = url.href;
  }
}

function keepEditing() {
  showConfirmLeaveModal.value = false;
  pendingNavigationEvent = null;
}

onMounted(() => {
  document.addEventListener('inertia:before', confirmNavigation, true);
});

onBeforeUnmount(() => {
  document.removeEventListener('inertia:before', confirmNavigation, true);
});
</script>
<i18n>
{
  "en": {
    "actions.create": "Create Task",
    "actions.update": "Update Task",
    "tabs.general": "General",
    "tabs.media": "Media",
    "tabs.topics": "Topics",
    "tooltips.title": "Displayed when browsing the Task Gallery.",
    "tooltips.textbookLinkUrl": "Offers the student a link to a relevant section in their textbook",
    "tooltips.textbookLinkIcon": "https://pictogrammers.com/library/mdi/ for available icons",
    "tooltips.youtubeId": "Offers the student a link to a relevant YouTube video",
    "labels.draftable": "Draftable",
    "labels.title": "Title",
    "labels.institutionId": "Institution",
    "labels.anyInstitution": "All institutions",
    "labels.textbookLink": "Help Link",
    "labels.textbookLinkUrl": "Link",
    "labels.textbookLinkText": "Text",
    "labels.textbookLinkIcon": "Icon",
    "labels.youTubeId": "YouTube ID",
    "form.unsavedChanges": "Unsaved Changes",
    "form.pageUnsavedChangesConfirmation": "You have unsaved changes. Are you sure you want to leave this page?",
    "form.discardChanges": "Discard Changes"
  },
  "fr": {
    "actions.create": "Créer une tâche",
    "actions.update": "Mettre à jour la tâche",
    "tabs.general": "Général",
    "tabs.media": "Médias",
    "tabs.topics": "Sujets",
    "tooltips.title": "Affiché lors de la navigation dans la galerie de tâches.",
    "tooltips.textbookLinkUrl": "Donne à l'élève un lien vers une section du manuel scolaire pertinente",
    "tooltips.textbookLinkIcon": "https://pictogrammers.com/library/mdi/ pour les icônes disponibles",
    "tooltips.youtubeId": "Offre à l'élève un lien vers une vidéo YouTube pertinente",
    "labels.draftable": "Les brouillons peuvent être enregistrés",
    "labels.title": "Titre",
    "labels.institutionId": "Institution",
    "labels.anyInstitution": "Toute institution",
    "labels.textbookLink": "Lien d'aide",
    "labels.textbookLinkUrl": "Lien",
    "labels.textbookLinkText": "Texte",
    "labels.textbookLinkIcon": "Icône",
    "labels.youTubeId": "ID YouTube",
    "form.unsavedChanges": "Modifications non enregistrées",
    "form.pageUnsavedChangesConfirmation": "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter cette page?",
    "form.discardChanges": "Annuler les modifications"
  }
}
</i18n>
