import {MarkType, ResolvedPos} from 'prosemirror-model';
import {EditorState} from 'prosemirror-state';

/**
 * Returns the CURRENT position if cursor is inside a link.
 * Returns the NEXT position if cursor is just-before a link.
 * Returns null if cursor is not in or before a link.
 *
 * @param state
 */
export const getSelectedLinkPos = (state: EditorState) => {
  try {
    const {link} = state.schema.marks;
    const {$anchor} = state.selection;

    if (link.isInSet($anchor.marks())) {
      return $anchor.pos;
    }

    const nextPos = state.doc.resolve($anchor.pos + 1);
    if (link.isInSet(nextPos.marks())) {
      return nextPos.pos;
    }
    return null;
  } catch {
    return null;
  }
};

/**
 * Returns true if the cursor is inside, or just before, a link.
 *
 * @param state
 */
export const isLinkSelected = (state: EditorState) => {
  return getSelectedLinkPos(state) !== null;
};
