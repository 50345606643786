<template>
  <Head :title="`${t('page.title')} - ${course.code}`"></Head>
  <div class="page-block narrow max-w-screen-md">
    <s-page-header :heading="t('page.title')" />

    <form @submit.prevent="createAssignment" class="flex flex-col gap-4 py-4">
      <s-input-field
        id="name"
        :label="t('form.title')"
        v-model="form.title"
        :error="form.errors.title"
      />

      <s-textarea-field
        id="overviewText"
        :label="t('form.overview')"
        v-model="form.overview"
        :error="form.errors.overview"
      />

      <div class="flex flex-wrap justify-between gap-2 py-2">
        <s-btn
          icon="close"
          color="secondary"
          :href="route('courses.assignments.index', [course.id])"
        >
          {{ t('cancel') }}
        </s-btn>

        <s-btn icon="plus" type="submit" class="!px-7" :processing="form.processing">
          {{ t('form.createAssignment') }}
        </s-btn>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {Course} from '../../types/entities/course';
import SInputField from '../../design-system/SInputField.vue';
import {Head, useForm} from '@inertiajs/vue3';
import STextareaField from '../../design-system/STextareaField.vue';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';

const props = defineProps<{course: Course}>();

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});

useBreadcrumbs([
  {
    label: props.course.code,
    href: `/courses/${props.course.id}`,
    external: true,
  },
  {
    label: t('assignments'),
    href: route('courses.assignments.index', {course: props.course.id}),
  },
]);

const form = useForm({
  title: '',
  overview: '',
});

function createAssignment() {
  form.post(route('courses.assignments.store', {course: props.course.id}));
}
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Create Assignment",
    },
    "form": {
      "createAssignment": "Create Assignment",
      "title": "Title",
      "overview": "Overview",
    }
  },
  "fr": {
    "page": {
      "title": "Créer un devoir",
    },
    "form": {
      "createAssignment": "Créer un devoir",
      "title": "Titre",
      "overview": "Description",
    }
  },
}
</i18n>
