<template>
  <Head :title="t('page.title')" />
  <section class="section-block pt-4 pb-1">
    <s-page-header :heading="t('page.heading')" />
  </section>

  <s-tab-nav :tabs="navTabs" class="mb-5" />
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import STabNav from '../../../design-system/STabNav.vue';
import {Course} from '../../../types/entities/course';
import {Assignment} from '../../../types/entities/assignment';
import {useI18n} from 'vue-i18n';
import {Head} from '@inertiajs/vue3';
import {computed} from 'vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  taskAnalyticsEnabled: boolean;
}>();

const {t} = useI18n();

useBreadcrumbs([
  {
    label: props.course.code,
    href: `/courses/${props.course.id}`,
    external: true,
  },
  {
    label: t('assignments'),
    href: route('courses.assignments.index', [props.course.id]),
  },
  {
    label: props.assignment.name,
    href: `/courses/${props.assignment.course.id}/assignments/${props.assignment.id}`,
    external: true,
  },
]);

const navTabs = computed(() => {
  const tabs = [
    {
      label: t('tabs.grades'),
      icon: 'percent',
      href: route('courses.assignments.analytics.grades', {
        course: props.course.id,
        assignment: props.assignment.id,
      }),
    },
  ];

  if (props.taskAnalyticsEnabled) {
    tabs.push({
      label: t('tabs.tasks'),
      icon: 'clipboard-check-outline',
      href: route('courses.assignments.analytics.tasks', {
        course: props.course.id,
        assignment: props.assignment.id,
      }),
    });
  }

  return tabs;
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Assignment Analytics",
      "heading": "Assignment Analytics"
    },
    "tabs": {
      "grades": "Grades",
      "tasks": "Tasks"
    }
  },
  "fr": {
    "page": {
      "title": "Analyses de Devoir",
      "heading": "Analyses de Devoir"
    },
    "tabs": {
      "grades": "Notes",
      "tasks": "Tâches"
    }
  }
}

</i18n>
