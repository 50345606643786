<template>
  <s-table
    :items="userActivities"
    :getItemKey="(item: UserActivityLogDto) => item.id"
    class="mb-5"
    density="denser"
  >
    <template #header>
      <th></th>
      <th>{{ t('activity') }}</th>
      <th>{{ t('timestamp') }}</th>
    </template>
    <template #body>
      <template v-if="userActivities.length">
        <tr
          v-for="activity in userActivities"
          :key="activity.id"
          :class="activityTableRowClassMap[activity.activityTypeName]"
        >
          <td class="!pr-0 w-0" :class="activityTableCellClassMap[activity.activityTypeName]">
            <activity-icon :activity-type-name="activity.activityTypeName" />
          </td>
          <td
            class="activity-cell-green"
            :class="activityTableCellClassMap[activity.activityTypeName]"
          >
            <s-link
              class="text-inherit hover:text-blue-500 underline underline-offset-2 decoration-transparent hover:decoration-blue-200/70 active:decoration-blue-200 cursor-pointer"
              :href="
                getActivityLink({
                  activity,
                })
              "
            >
              <i18n-t :keypath="`activities.messages.${activity.activityTypeName}`">
                <template v-slot:succeeded>
                  <strong>{{ t('succeeded') }}</strong>
                </template>
                <template v-slot:failed>
                  <strong>{{ t('failed') }}</strong>
                </template>
                <template v-slot:assignmentName>
                  <strong>
                    {{ activity.displayContext.assignmentName }}
                  </strong>
                </template>
                <template v-slot:taskNumber>
                  <strong>
                    {{
                      t('taskX', {
                        taskNumber: activity.displayContext.taskNumber,
                      })
                    }}
                  </strong>
                </template>
                <template v-slot:itemTaskContent>
                  <strong v-if="activity.displayContext.itemTaskContent">
                    {{ activity.displayContext.itemTaskContent.slice(0, 35) + '...' }}
                  </strong>
                </template>
              </i18n-t>
            </s-link>
            <div v-if="activity.displayContext.reasons" class="text-sm leading-tight">
              <ul class="inline comma-separated oxford-comma">
                <li v-for="reason in activity.displayContext.reasons" :key="reason" class="inline">
                  <span class="opacity-80 font-medium">{{ reason }}</span>
                </li>
              </ul>
            </div>
          </td>
          <td :class="activityTableCellClassMap[activity.activityTypeName]">
            <span class="whitespace-nowrap leading-tight">
              {{ monthDaySometimesYear(activity.createdAt) }}
              {{ timeOfDay(activity.createdAt) }}
            </span>
          </td>
        </tr>
      </template>
      <tr v-else>
        <td colspan="100%" class="italic text-gray-400">{{ t('noResults') }}</td>
      </tr>
    </template>
  </s-table>
</template>
<script setup lang="ts">
import STable from '../../design-system/STable.vue';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import {monthDaySometimesYear, timeOfDay} from '../../format/dates';
import ActivityIcon from '../../pages/CourseParticipantActivity/Index/ActivityIcon.vue';
import SLink from '../SLink.vue';
import UserActivityLogDto = App.DTOs.UserActivityLogDto;

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const props = defineProps<{
  userActivities: UserActivityLogDto[];
}>();

const activityTableRowClassMap: {[key: string]: string} = {
  TASK_RESPONSE_SUBMIT_SUCCEEDED:
    'bg-gradient-to-r from-green-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
  TASK_RESPONSE_SUBMIT_FAILED:
    'bg-gradient-to-r from-red-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
  TASK_RESPONSE_DRAFT_STORE_SUCCEEDED:
    'bg-gradient-to-r from-green-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
  TASK_RESPONSE_DRAFT_STORE_FAILED:
    'bg-gradient-to-r from-red-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
};

const activityTableCellClassMap: {[key: string]: string} = {
  TASK_RESPONSE_SUBMIT_SUCCEEDED: 'text-green-700 !bg-transparent font-medium',
  TASK_RESPONSE_SUBMIT_FAILED: 'text-red-700 !bg-transparent font-medium',
  TASK_RESPONSE_DRAFT_STORE_SUCCEEDED: 'text-green-700 !bg-transparent font-medium',
  TASK_RESPONSE_DRAFT_STORE_FAILED: 'text-red-700 !bg-transparent font-medium',
};

const getActivityLink = ({activity}: {activity: UserActivityLogDto}): string | undefined => {
  // Quick and dirty version. Events with tasks have links.
  // This can get more complicated when it needs to in the future.
  if (!activity.taskId) {
    return undefined;
  }

  return route('courses.assignments.marking.assignmentTasks.show', {
    course: activity.courseId,
    assignment: activity.assignmentId,
    user: activity.userId,
    taskNumber: activity.displayContext?.taskNumber ?? 1,
    _query: {
      response: activity.responseId,
    },
  });
};
</script>
<i18n>
{
  "en": {
    "activity": "Activity",
    "timestamp": "Timestamp",
    "taskX": "Task {taskNumber}",
    "succeeded": "Succeeded",
    "failed": "Failed",
    "noResults": "No results found",
    "activities": {
      "messages": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Submit attempted for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Submit succeeded for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_SUBMIT_FAILED": "Submit failed for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Draft save attempted for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Draft save succeeded for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Draft save failed for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_DELETED": "Deleted Draft for {taskNumber} on {assignmentName}",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigated to {taskNumber} on {assignmentName}",
        "LAB_SIM_ITEM_TASK_COMPLETED": "Completed step {itemTaskContent} for {taskNumber} on {assignmentName}",
        "LOGIN": "Logged in to Stemble",
        "LOGOUT": "Logged out of Stemble"
      }
    }
  },
  "fr": {
    "activity": "Activité",
    "timestamp": "Horodatage",
    "taskX": "Tâche {taskNumber}",
    "succeeded": "Réussi",
    "failed": "Échoué",
    "noResults": "Aucun résultat trouvé",
    "activities": {
      "messages": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Envoi tenté pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Envoi réussi pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_SUBMIT_FAILED": "Échec de l'envoi pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Brouillon sauvegardé pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Brouillon sauvegardé pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Échec de la sauvegarde du brouillon pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_DELETED": "Brouillon supprimé pour la tâche {taskNumber} sur {assignmentName}",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigué vers {taskNumber} sur {assignmentName}",
        "LAB_SIM_ITEM_TASK_COMPLETED": "Étape {itemTaskContent} terminée pour {taskNumber} sur {assignmentName}",
        "LOGIN": "Connecté à Stemble",
        "LOGOUT": "Déconnecté de Stemble"
      }
    }
  }
}
</i18n>
