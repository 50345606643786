<template>
  <component
    :is="componentType"
    v-if="componentType"
    v-bind="{...writablePropsData, ...$attrs}"
    v-on="{...listeners}"
    :files="files"
    :variables
  />
</template>

<script setup lang="ts">
import {computed, nextTick} from 'vue';
import {computedAsync} from '@vueuse/core';
import TaskFileUploadDto = App.DTOs.Tasks.TaskFileUploadDto;
import NumberVariableDto = App.DTOs.Tasks.Variables.NumberVariableDto;

const props = defineProps<{
  files: TaskFileUploadDto[];
  componentModule: Promise<{default: {emits: string[] | Record<string, any>}}>;
  allData?: Record<string, any> | null;
  variables?: NumberVariableDto[];
}>();

const emit = defineEmits();

const uiComponentMetadata = computedAsync(async () => {
  const {default: component} = await props.componentModule;

  const emits: string[] = Array.isArray(component?.emits)
    ? component?.emits
    : Object.keys(component?.emits ?? {});

  const writablePropNames = emits
    .filter((evt) => evt.startsWith('update:'))
    .map((evt) => evt.replace('update:', ''));

  return {
    component,
    writablePropNames,
  };
}, null);

const componentType = computed(() => uiComponentMetadata.value?.component ?? null);

const listeners = computed(() =>
  Object.fromEntries(
    uiComponentMetadata.value?.writablePropNames.map((propName) => [
      `update:${propName}`,
      (v: any) => emitValueChanged(propName, v),
    ]) ?? []
  )
);

const writablePropsData = computed(() => {
  const writablePropNames = uiComponentMetadata.value?.writablePropNames ?? [];

  return Object.fromEntries(writablePropNames.map((k) => [k, props.allData?.[k] ?? null]));
});

/*
 * We keep track of any changes that happen within a single reactivity tick
 * so that we can emit the proper updated payload.
 *
 * If the events happened separately, the old data for non-updated properties would
 * be out-of-date until the next tick has a chance to update them. This would result in
 * only the *latest* event being saved rather than all updates.
 */
let queuedChanges: Record<string, any> = {};

const emitValueChanged = (key: string, value: any) => {
  queuedChanges[key] = value;

  emit(`update:${key}`, value);
  const newValue = {
    ...props.allData,
    ...queuedChanges,
  };
  emit('update:allData', newValue);

  nextTick(() => {
    // Once the previous tick has had a chance to update all props updated by events, we can clear the queued changes
    queuedChanges = {};
  });
};
</script>
