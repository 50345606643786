<template>
  <s-btn @click="open = !open">{{ open ? 'Close' : 'Open' }}</s-btn>

  <s-dialog v-model:open="open" @close="form.reset()">
    <template v-slot="{close, forceClose}">
      <SCard heading="Example Modal">
        <form
          @submit.prevent="
            form.defaults();
            forceClose();
          "
          class="flex flex-col gap-4"
        >
          <s-input-field id="name" label="Name" v-model="form.name" />
          <div class="flex gap-2">
            <s-btn>Submit</s-btn>
            <s-btn color="secondary" @click.prevent="close()" type="button">Cancel</s-btn>
          </div>
        </form>
      </SCard>
    </template>

    <template v-if="form.isDirty" #confirmClose="{close, cancel}">
      <s-dialog open @close="cancel">
        <SCard>
          Are you sure you want to close?

          <div class="mt-2 flex gap-2">
            <s-btn @click="close" color="danger">Yes close it!</s-btn>

            <s-btn @click="cancel" color="secondary">Continue Editing</s-btn>
          </div>
        </SCard>
      </s-dialog>
    </template>
  </s-dialog>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
import {ref} from 'vue';
import SCard from '../../../../design-system/SCard.vue';
import SDialog from '../../../../components/SDialog.vue';
import {useForm} from '@inertiajs/vue3';
import SInputField from '../../../../design-system/SInputField.vue';

const open = ref(false);
const form = useForm({
  name: '',
});
</script>
