<template>
  <span class="assignment-grade-wrapper">
    <span v-if="!adjustment">{{ currentGradeDisplay }}</span>
    <span v-else class="">
      <s-tooltip id="tooltip-icon">
        <template v-if="tooltip" #tooltip>{{ t('tooltips.adjusted') }}</template>
        <button class="assignment-grade" @click.stop.prevent="overlayOpen = true">
          <span class="font-weight-medium" style="margin-bottom: -2px">
            {{ currentGradeDisplay }}
          </span>

          <s-icon name="mdi-alert" size="1.15em" class="text-red-500" />
        </button>
      </s-tooltip>
      <Teleport to="body">
        <div
          class="info-overlay"
          :class="overlayOpen ? 'open' : ''"
          :tabindex="overlayOpen ? 0 : -1"
        >
          <div class="info-wrapper">
            <div class="info-modal">
              <p class="info-author">
                {{
                  t('labels.adjustedBy', {
                    name: adjustment?.submittedByName ?? '',
                  })
                }}
              </p>
              <p class="info-reason">
                {{ adjustment?.reason || t('fallbackReason') }}
              </p>
              <div class="info-grades">
                <div class="info-grade original">
                  <span class="label">{{ t('originalGrade') }}</span>
                  <span data-test-id="tooltip-original-grade" class="value">
                    {{ originalGradeDisplay }}
                  </span>
                </div>
                <div class="info-grade adjustment" :class="{positive: +gradeAdjustment > 0}">
                  <span class="label">{{ t('labels.adjustment') }}</span>
                  <span data-test-id="tooltip-grade-adjustment" class="value">
                    {{ gradeAdjustment }}
                  </span>
                </div>
                <div class="info-grade new">
                  <span class="label">{{ t('newGrade') }}</span>
                  <span data-test-id="tooltip-current-grade" class="value">
                    {{ currentGradeDisplay }}
                  </span>
                </div>
              </div>
              <div class="modal-actions">
                <button class="btn secondary sm" @click="overlayOpen = false">
                  {{ t('close') }}
                </button>
              </div>
            </div>
          </div>
          <div class="overlay-background" @click="overlayOpen = false"></div>
        </div>
      </Teleport>
    </span>
  </span>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {AssignmentGrade} from './SGrade';
import {useI18n} from 'vue-i18n';
import SIcon from '../design-system/SIcon.vue';
import {formatFraction, formatPercentage} from '../format/grades';
import STooltip from '../design-system/STooltip.vue';

const props = defineProps<{
  grade?: AssignmentGrade | null;
  tooltip: boolean;
  gradeDisplay: string;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const overlayOpen = ref(false);
const notStarted = computed(() => {
  if (props.gradeDisplay === 'PERCENTAGE') {
    return '- - %';
  } else {
    return '- / -';
  }
});
const pointsAvailable = props.grade?.pointsAvailable || 1;
const currentGrade = computed(() => {
  if (!props.grade) return 0;
  return (props.grade.exactNumerator / props.grade.exactDenominator) * 100;
});
const originalGrade = computed(() => {
  if (!props.grade) return 0;
  return (props.grade.originalNumerator / props.grade.originalDenominator) * 100;
});
const currentGradeDisplay = computed(() => {
  if (props.grade?.isEffective) {
    if (props.gradeDisplay === 'PERCENTAGE') {
      return formatPercentage(currentGrade.value);
    }
    if (props.gradeDisplay === 'FRACTION') {
      return formatFraction(
        Math.round(pointsAvailable * currentGrade.value) / 100,
        pointsAvailable
      );
    }
  }

  return notStarted.value;
});

const originalGradeDisplay = computed(() => {
  if (props.grade?.isEffective) {
    if (props.gradeDisplay === 'PERCENTAGE') {
      return formatPercentage(originalGrade.value);
    }
    if (props.gradeDisplay === 'FRACTION') {
      return formatFraction(
        Math.round(pointsAvailable * originalGrade.value) / 100,
        pointsAvailable
      );
    }
  }

  return notStarted.value;
});
const gradeAdjustment = computed(() => {
  // TODO: this assumes relative adjustments, so it will have to change if we do absolute
  if (!props.grade) {
    return notStarted.value;
  }
  return formatPercentage(currentGrade.value - originalGrade.value);
});
const adjustment = computed(() => {
  return props.grade?.adjustment ?? null;
});
</script>
<style scoped>
.assignment-grade-wrapper {
  font-family: 'Roboto', sans-serif;
}

.assignment-grade {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  gap: 0.25em;
  line-height: 1;
}

.tooltip-wrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  z-index: 100;
  left: -1rem;
  top: 50%;
  transform: translate3d(calc(-100% + 1rem), -50%, 0);
  font-size: 0.875rem;
  text-align: center;
  line-height: 1.25;
  white-space: normal;
  text-wrap: pretty;
  padding: 0.5rem 0.75rem;
  width: 200px;
  background: hsla(254, 47%, 14%, 1);
  color: white;
  border-radius: 0.5rem;
  box-shadow:
    1px 3px 10px 0px rgba(44, 32, 80, 0.04),
    0px 1px 2px 0px rgba(46, 36, 81, 0.03);
  pointer-events: none;
  opacity: 0;
  transition: all ease-out 150ms;
  transition-delay: 300ms;
}

.assignment-grade:hover .tooltip,
.assignment-grade:active .tooltip {
  opacity: 0.8;
  transform: translate3d(-100%, -50%, 0);
  transition-delay: 0ms;
}

.tooltip:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0.75rem;
  height: 0.75rem;
  transform: translate3d(50%, -50%, 0) rotate(45deg);
  background: hsla(254, 47%, 14%, 1);
}

.info-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  transition: all ease-out 150ms;
  font-family: 'Roboto', sans-serif;
}

.info-overlay.open {
  pointer-events: all;
  opacity: 1;
}

.info-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.info-modal {
  text-align: left;
  line-height: 1.5;
  background: white;
  color: #262626;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  box-shadow:
    inset 0 0 0 1px rgba(147, 127, 116, 0.13),
    0px 1px 2px 0px rgba(18, 11, 40, 0.05),
    1px 4px 14px 0px rgba(44, 32, 80, 0.06);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 50rem;
}

.info-overlay.open .info-modal {
  pointer-events: all;
}

@media (min-width: 768px) {
  .info-modal {
    padding: 1.75rem 2rem;
  }
}

.info-reason {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
}

.info-author {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
}

.info-author strong {
  font-weight: bold;
}

.info-grades {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0.5rem;
}

.info-grade {
  flex: 0 0 auto;
  color: #404040;
  line-height: 1.2;
}

.info-grade.adjustment {
  color: #b91c1c;
}

.info-grade.adjustment.positive {
  color: #15803d;
}

.info-grade .label {
  font-size: 1rem;
  opacity: 0.6;
  font-weight: bold;
  display: block;
  margin-bottom: 0.125rem;
}

.info-grade .value {
  font-size: 1.5rem;
  display: block;
}

.modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-actions button {
  margin: 0 !important;
}

.overlay-background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
}
</style>
<i18n>
{
  "en": {
    "tooltips": {
      "adjusted": " This grade includes an adjustment. Click for more details."
    },
    "close": "close",
    "labels": {
      "adjustedBy": "Grade adjusted by:",
      "adjustment": "Adjustment"
    },
    "fallbackReason": "No further information about the adjustment was provided."
  },
  "fr": {
    "tooltips": {
      "adjusted": "Cette note a été ajustée. Cliquez pour plus de détails."
    },
    "labels": {
      "adjustedBy": "Note ajustée par:",
      "adjustment": "Ajustement"
    },
    "close": "Fermer",
    "fallbackReason": "Aucune information supplémentaire sur l'ajustement n'a été fournie."
  }
}
</i18n>
