<template>
  <s-btn @click="open = !open">{{ open ? 'Close' : 'Open' }}</s-btn>

  <s-dialog v-model:open="open" modal>
    <template #default="{close, forceClose}">
      <SCard heading="Conditional Confirming to Close">
        <p>
          You can conditionally confirm closing the dialog simply by putting a
          <code>v-if</code>
          on the
          <code>confirmClose</code>
          template.
        </p>
        <s-input-field id="huh" v-model="huh" label="Huh?" />
        <label for="should-confirm" class="block mt-2">Should confirm close?</label>
        <input id="should-confirm" type="checkbox" v-model="shouldConfirm" />
        {{ huh }}
      </SCard>
    </template>

    <template v-if="shouldConfirm" #confirmClose="{close, cancel}">
      <s-dialog @close="cancel" class="flex justify-center items-center" modal>
        <SCard>
          Are you sure you want to close?

          <div class="mt-2 flex gap-2">
            <s-btn @click="close" color="danger">Yes close it!</s-btn>

            <s-btn @click="cancel" color="secondary">Continue Editing</s-btn>
          </div>
        </SCard>
      </s-dialog>
    </template>
  </s-dialog>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
import {ref} from 'vue';
import SCard from '../../../../design-system/SCard.vue';
import SDialog from '../../../../components/SDialog.vue';
import SCheckbox from '../../../../design-system/SCheckbox.vue';
import SInputField from '../../../../design-system/SInputField.vue';

const open = ref(false);
const shouldConfirm = ref(true);
const huh = ref('');
</script>
