import {Plugin} from 'prosemirror-state';

/**
 * Prevents the user from dropping files and images onto the editor.
 *
 * Without this plugin, the raw binary of the image will just get
 * inserted into the `src` attribute of the image node, making
 * the document gigantic.
 */
export const fileDropHandlerPlugin = new Plugin({
  props: {
    handleDOMEvents: {
      drop(view, event) {
        const items = event.dataTransfer?.items;
        if (items) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].kind === 'file') {
              event.preventDefault();
              return true;
            }
          }
        }
        return false;
      },
    },
  },
});
