<template>
  <Head :title="`Admin - Act As`"></Head>
  <div class="page-block gap-5">
    <s-page-header heading="Act As" />
    <div class="flex flex-col flex-wrap align-start gap-1">
      <form @submit.prevent="form.post(route('admin.act-as.store'))" class="flex flex-col gap-4">
        <s-input-field id="email" label="Email to Act As" v-model="form.email" />
        <s-input-field
          id="password"
          label="Your Password"
          type="password"
          v-model="form.password"
        />
        <s-btn :processing="form.processing">Act As</s-btn>
      </form>
    </div>
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Head, useForm} from '@inertiajs/vue3';
import SInputField from '../../../design-system/SInputField.vue';
import {route} from 'ziggy-js';
import SBtn from '../../../design-system/SBtn.vue';

const props = defineProps<{
  email: string | null;
}>();

const form = useForm({
  email: props.email || '',
  password: '',
});
</script>
