<template>
  <Head :title="`Admin - Assignment ${assignment.name}`"></Head>
  <div class="page-block gap-5">
    <s-page-header :heading="assignment.name">
      <template #actions>
        <s-link
          class="btn md primary w-fit"
          :href="`/courses/${course.id}/assignments/${assignment.id}`"
          external
        >
          Go to assignment
        </s-link>
      </template>
    </s-page-header>
    <div class="grid md:grid-cols-[auto_1fr] items-center gap-3 md:gap-y-5">
      <s-btn
        color="danger"
        @click="
          confirmModalAction = triggerRecalculation;
          taskSelectionModal = true;
        "
      >
        Trigger Task Grade Re-calculation
      </s-btn>
      <p class="text-balance mb-2 md:mb-0">
        Re-adjust all task grades for this assignment according to new parameters (e.g. due date).
        Does not re-grade, but is still a heavy operation.
      </p>
      <s-btn
        :href="
          route('admin.assignments.trigger_assignment_grade_recalculation', {
            assignment: assignment.id,
          })
        "
        method="post"
      >
        Trigger Assignment Grade Re-calculation
      </s-btn>
      <p class="text-balance mb-2 md:mb-0">
        Re-calculate the assignment grades for this assignment. Use when the assignment grades are
        out of sync with the task grades.
      </p>
      <s-btn
        color="danger"
        @click="
          confirmModalAction = triggerRegrade;
          taskSelectionModal = true;
        "
      >
        Trigger Re-grade for All Sections
      </s-btn>
      <p class="text-balance mb-2 md:mb-0">
        Re-grade all responses for this section. This is a very heavy operation. Use with caution.
      </p>
    </div>
    <s-modal
      v-if="confirmModalAction"
      title="Select Parameters"
      v-model:open="taskSelectionModal"
      @confirm="confirmModalAction"
      cancellable
    >
      <template #content>
        <p class="text-lg">Choose a list of tasks:</p>
        <select v-model="taskIdsForOperation" multiple>
          <template v-for="taskAssignment in taskAssignments" :key="taskAssignment.id">
            <!-- If this is a single task pool task assignment, inline it -->
            <option
              v-if="taskAssignment.taskPool.tasks.length === 1"
              :value="taskAssignment.taskPool.tasks[0].id"
            >
              {{ taskAssignment.orderingIndex }}. {{ taskAssignment.taskPool.tasks[0].title }}
            </option>
            <!-- Otherwise, add an indentation for the pool -->
            <template v-else>
              <option disabled>{{ taskAssignment.orderingIndex }}. Task Pool</option>
              <option v-for="task in taskAssignment.taskPool.tasks" :key="task.id" :value="task.id">
                &nbsp;&nbsp;&nbsp;{{ task.title }}
              </option>
            </template>
          </template>
        </select>
        <div>
          <label>
            <input type="checkbox" v-model="regradeOnlyFailedTasks" />
            Regrade Only Failed Tasks
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" v-model="allowOverrideOfManualGrades" />
            Allow Override of Manual Grades (USE WITH CAUTION!)
          </label>
        </div>
      </template>
    </s-modal>
  </div>
</template>

<script setup lang="ts">
import {route} from 'ziggy-js';
import SLink from '../../../components/SLink.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Assignment} from '../../../types/entities/assignment';
import {computed, ref, shallowRef, watch} from 'vue';
import {Head, router} from '@inertiajs/vue3';
import SBtn from '../../../design-system/SBtn.vue';
import SModal from '../../../design-system/SModal.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import {LoadedTaskAssignmentDto} from '../../../types/entities/task-assignment';

const props = defineProps<{
  assignment: Assignment;
  taskAssignments: LoadedTaskAssignmentDto[];
}>();

useBreadcrumbs([{label: 'Assignments', href: route('admin.assignments.index')}]);

const confirmModalAction = shallowRef<(() => void) | null>(null);

const taskSelectionModal = ref(false);
const taskIdsForOperation = ref<number[]>([]);
const regradeOnlyFailedTasks = ref(false);
const allowOverrideOfManualGrades = ref(false);

watch(taskSelectionModal, (newValue) => {
  if (!newValue) {
    taskIdsForOperation.value = [];
    regradeOnlyFailedTasks.value = false;
    allowOverrideOfManualGrades.value = false;
  }
});

const course = computed(() => props.assignment.course);

const triggerRegrade = () => {
  router.post(route('admin.assignments.trigger_regrade', {assignment: props.assignment.id}), {
    taskIds: taskIdsForOperation.value,
    regradeOnlyFailedTasks: regradeOnlyFailedTasks.value,
    allowOverrideOfManualGrades: allowOverrideOfManualGrades.value,
  });
};

const triggerRecalculation = () => {
  router.post(
    route('admin.assignments.trigger_task_grade_recalculation', {assignment: props.assignment.id}),
    {taskIds: taskIdsForOperation.value}
  );
};
</script>
