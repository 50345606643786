import {Task} from '../../../types/entities/tasks';
import UiConfig = App.Entities.Tasks.Ui.UiConfig;

/**
 * Makes sure that the Form Input object for a DataDrivenTask is
 * initialized with an empty object for each of it's parts
 *
 * @param task
 * @param input
 */
export function normalizeDataDrivenTaskInput(task: Task, input?: any) {
  const parts: UiConfig[] = task.content.uiConfigs || [];
  let normalizedInput = input || {data: {}};

  parts.forEach((part: UiConfig) => {
    let currentPartData = normalizedInput.data[part.uuid];

    if (!currentPartData || (Array.isArray(currentPartData) && !currentPartData.length)) {
      normalizedInput.data[part.uuid] = {};
    }
  });

  return normalizedInput;
}
