<template>
  <div v-if="page.props.loggedInUser.isSuperUser && !props.isCopy">
    <s-btn @click.prevent="openAiAssistanceModal()" color="secondary" class="mb-4">
      <s-icon name="shimmer" size="14" class="-m-0.5" />
      Speed Up Task Creation with AI
    </s-btn>
  </div>
  <s-modal
    title="AI Assisted Task Creation"
    v-model:open="showAiAssistanceModal"
    :confirm="{
      label: 'Generate Question(s)',
      icon: 'shimmer',
    }"
    @confirm="generateSyntheticQuestions"
    :processing="fetchingSyntheticQuestions"
    cancellable
    width="md"
  >
    <div class="card padded mb-10">
      <p>
        Automatically complete this using AI, guided by your context, marking rubrics, and
        instructions.
      </p>
      <div class="mt-4">
        <s-textarea-field id="context" v-model="taskContext" label="Context/Instructions" />
      </div>
      <div class="mt-2">
        <s-input-field
          class="w-1/4"
          id="exampleTaskId"
          label="Example Task Id"
          v-model="exampleTaskId"
          :error="generateQuestionsErrors.exampleTaskId"
          tooltip="(Optional) Reference another AiAllPurposeQuestion task by it's ID (can be found in the URL). The generated questions will be emulated with respect to the provided task."
        />
      </div>
    </div>
  </s-modal>
  <task-form :errors="errors" :form="form" class="fieldset">
    <s-input-field
      id="heading"
      :label="t('form.heading.label')"
      :description="t('form.heading.description')"
      :required="false"
      v-model="form.ui.heading"
      :error="errors?.ui?.heading"
    />
    <s-select-field
      v-model="form.grader.questionType"
      @update:model-value="updateQuestionType()"
      id="questionType"
      :label="t('form.questionType.label')"
      :description="t('form.questionType.description')"
      :error="errors?.grader?.questionType"
    >
      <option v-for="(label, key) in AiQuestionTypeOptions" :value="key">
        {{ label }}
      </option>
    </s-select-field>

    <div class="flex justify-between" v-if="form.grader.questionType">
      <FeedbackInstructionsBuilder
        v-model:modelValue="form.grader.feedbackInstructions"
        :errors="errors?.grader?.feedbackInstructions"
      />
    </div>

    <div
      class="flex justify-between"
      v-if="form.grader.questionType && page.props.loggedInUser.isSuperUser"
    >
      <s-field
        id="llmConfig"
        :label="t('form.showLlmConfig.label')"
        :errors="errors?.grader?.llmOptions"
      />
      <LlmOptionsConfiguration
        v-if="form.grader.llmOptions"
        v-model="form.grader.llmOptions"
        :modelsMetadata="modelsMetadata"
        :errors="errors?.grader?.llmOptions"
      />
    </div>

    <s-select-field
      v-model="form.grader.gradeCalculationStrategy"
      :label="t('form.gradeCalculationStrategy.label')"
      :description="t('form.gradeCalculationStrategy.description')"
      id="gradeCalculationStrategy"
      :error="errors?.grader?.gradeCalculationStrategy"
    >
      <option v-for="(label, key) in gradeCalculationStrategies" :value="key">
        {{ label }}
      </option>
    </s-select-field>
    <s-field
      v-if="form.grader.gradeCalculationStrategy"
      id="questions"
      :label="t('form.questions.label')"
      :error="errors?.grader?.questions"
    >
      <s-btn
        @click.prevent="addQuestion()"
        :disabled="form.grader.questions.length >= 15"
        class="mt-2"
        icon="plus"
        size="sm"
      >
        {{ t('form.questions.action') }}
      </s-btn>
    </s-field>
    <div
      class="card padded"
      v-for="(question, questionIdx) in form.grader.questions"
      :key="questionIdx"
    >
      <s-input-field
        class="mt-2 mb-2"
        id="questionText"
        :label="t('form.question.label')"
        v-model="form.ui.questions[questionIdx].questionText"
        :error="errors?.ui?.questions?.[questionIdx]?.questionText"
      />
      <s-field
        class="mb-4"
        id="criteria"
        :label="t('form.criteria.label')"
        :error="errors?.['grader.criteria']"
      >
        <div
          class="card padded"
          v-for="(criteria, criteriaIdx) in question.criteria"
          :key="criteriaIdx"
        >
          <s-input-field
            id="text"
            :label="
              question.criteria.length === 1
                ? t('form.criteria.nameSingle')
                : t('form.criteria.nameMultiple')
            "
            v-model="criteria.criteriaName"
            :error="errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]?.criteriaName"
          />
          <s-textarea-field
            id="text"
            :label="
              question.criteria.length === 1
                ? t('form.criteria.descriptionSingle')
                : t('form.criteria.descriptionMultiple')
            "
            v-model="criteria.criteriaDescription"
            :error="
              errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]?.criteriaDescription
            "
          />
          <div class="flex flex-wrap gap-4">
            <div class="min-w-52 flex-1">
              <grade-increment-field
                v-if="
                  form.grader.gradeCalculationStrategy === 'standard_with_grade_breakdown' ||
                  form.grader.gradeCalculationStrategy === 'standard'
                "
                v-model="criteria.gradeIncrement"
                :errors="
                  errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]?.gradeIncrement
                "
              />
              <s-input-field
                v-if="form.grader.gradeCalculationStrategy === 'specification'"
                id="score"
                type="number"
                step="0.01"
                min="0"
                max="1"
                :label="t('form.specificationGrading.label')"
                :description="t('form.specificationGrading.description')"
                v-model="criteria.binaryGradeThreshold"
                :error="
                  errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]
                    ?.binaryGradeThreshold
                "
              />
            </div>
            <div class="flex-0 w-40">
              <s-input-field
                id="score"
                type="number"
                step="1"
                min="0"
                :label="t('form.criteria.gradeWeight')"
                v-model="criteria.gradeWeight"
                :error="
                  errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]?.gradeWeight
                "
              />
            </div>
          </div>
          <s-btn
            @click.prevent="
              () => {
                const newCriteria = [...form.grader.questions[questionIdx].criteria];
                newCriteria.splice(criteriaIdx, 1);
                form.grader.questions[questionIdx].criteria = newCriteria;
              }
            "
            class="mt-2"
            icon="trash-can"
            size="sm"
            color="danger"
            :disabled="form.grader.questions[questionIdx].criteria.length === 1"
          >
            {{ t('form.criteria.remove') }}
          </s-btn>
        </div>
        <s-btn
          @click.prevent="
            () => {
              const newCriteria = [...form.grader.questions[questionIdx].criteria];
              newCriteria.push({
                criteriaName: '',
                criteriaDescription: '',
                gradeIncrement: 0.25,
                gradeWeight: 1,
                binaryGradeThreshold: null,
                assignedGrade: null,
                comment: null,
              });
              form.grader.questions[questionIdx].criteria = newCriteria;
            }
          "
          :disabled="form.grader.questions[questionIdx].criteria.length > 15"
          class="mt-2"
          icon="plus"
          size="sm"
        >
          {{
            form.grader.questions[questionIdx].criteria.length > 1
              ? t('form.criteria.add')
              : t('form.criteria.addMultiple')
          }}
        </s-btn>
      </s-field>
      <marking-examples
        v-model="form.grader.questions[questionIdx].markingExamples"
        :criteria="form.grader.questions[questionIdx].criteria"
        :errors="errors?.grader?.questions?.[questionIdx]?.markingExamples"
      />
      <s-field class="mt-2 mb-2" id="showCharacterLimit" :label="t('form.characterLimit.label')">
        <div class="flex items-center space-x-2">
          <s-button-toggle
            id="showCharacterLimit"
            :options="[
              {value: null, label: 'No'},
              {value: form.ui.questions[questionIdx].characterLimit ?? 0, label: 'Yes'},
            ]"
            v-model="form.ui.questions[questionIdx].characterLimit"
          />
          <template v-if="form.ui.questions[questionIdx].characterLimit !== null">
            <s-input-field
              id="characterLimit"
              :required="false"
              type="number"
              min="0"
              v-model="form.ui.questions[questionIdx].characterLimit"
            />
          </template>
        </div>
      </s-field>
      <s-btn
        @click.prevent="removeQuestion(questionIdx)"
        class="mt-2"
        icon="trash-can"
        size="sm"
        color="danger"
        :disabled="form.grader.questions.length === 1"
      >
        {{ t('form.question.remove') }}
      </s-btn>
    </div>
  </task-form>
</template>
<script lang="ts" setup>
import {reactive, ref, Ref, watch} from 'vue';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import FeedbackInstructionsBuilder from '../../../components/tasks/ai-parts/FeedbackInstructionsBuilder.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import GradeIncrementField from '../../../components/tasks/ai-parts/GradeIncrementField.vue';
import {useValidationErrors} from '../../../util/validation-errors';
import TaskForm from '../../../forms/TaskForm.vue';
import {useI18n} from 'vue-i18n';
import {useForm, usePage} from '@inertiajs/vue3';
import axios from 'axios';
import {route} from 'ziggy-js';
import SModal from '../../../design-system/SModal.vue';
import SIcon from '../../../design-system/SIcon.vue';
import LlmOptionsConfiguration from '../../../components/tasks/ai-parts/LlmOptionsConfiguration.vue';
import {LlmMetadata} from '../../../types/entities/llmMetadata';
import MarkingExamples from '../../../components/tasks/ai-parts/MarkingExamples.vue';
import AiAllPurposeQuestionFormDto = App.DTOs.Tasks.AiAllPurposeQuestionFormDto;
import AiWrittenQuestionUIDto = App.DTOs.Tasks.AiWrittenQuestionUIDto;
import FeedbackInstructionsDto = App.DTOs.Tasks.FeedbackInstructionsDto;
import AiQuestionType = App.Enums.AiQuestionType;
import AiGradeCalculationStrategy = App.Enums.AiGradeCalculationStrategy;
import FeedbackLanguage = App.Enums.PromptBuildingPhrases.FeedbackLanguage;
import AiAllPurposeQuestionGenerationPayloadDto = App.DTOs.Tasks.AiAllPurposeQuestionGenerationPayloadDto;
import OpenAiRequestConfigDto = App.DTOs.OpenAiRequestConfigDto;
import AiAllPurposeQuestionDto = App.DTOs.Tasks.AiAllPurposeQuestionDto;

const props = defineProps<{
  modelValue?: AiAllPurposeQuestionFormDto;
  isCopy?: boolean;
  modelsMetadata: LlmMetadata[];
  errors?: Record<string, any>;
}>();

const page = usePage<{
  loggedInUser: {
    isSuperUser: boolean;
  };
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const {errors} = useValidationErrors(props, 'errors');

const form = useForm<AiAllPurposeQuestionFormDto>(
  () =>
    props.modelValue || {
      title: '',
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      type: 'AiAllPurposeQuestion',
      status: 'draft',
      ui: {
        heading: '',
        questions: [] as AiWrittenQuestionUIDto[],
      },
      grader: {
        questions: [] as AiAllPurposeQuestionDto[],
        feedbackInstructions: {
          subject: '',
          provideMistakeLabels: true,
          tone: null,
          formality: null,
          gradeTuning: null,
          improvementStrategy: null,
          gradeExplanation: null,
          answerRevealStrategy: 'Formative feedback',
          feedbackLength: null,
          maxPastContextLength: 2,
          useCustomBasePrompt: false,
          feedbackLanguagePhrases: [] as FeedbackLanguage[],
          customPhrases: [''],
        } as FeedbackInstructionsDto,
        gradeCalculationStrategy: 'standard_with_grade_breakdown',
        questionType: 'custom',
        llmOptions: defaultLlmOptions,
      },
      draftable: true,
      institutionId: null,
    }
);

const miniModel =
  props.modelsMetadata.find((metadata) => metadata.model === 'gpt-4o-mini-2024-07-18') || null;
const defaultModel =
  props.modelsMetadata.find((metadata) => metadata.model === 'gpt-4o-2024-08-06') || null;
const defaultLlmOptions: OpenAiRequestConfigDto = {
  modelId: Number(defaultModel?.id ?? 1),
  maxOutputTokens: 2000,
  temperature: 0.0,
  topP: 1.0,
  jsonResponse: true,
  jsonSchema: null,
};

const gradeCalculationStrategies: Record<AiGradeCalculationStrategy, string> = {
  standard: 'Standard grading',
  standard_with_grade_breakdown: 'Standard grading with displayed grade breakdown',
  specification: 'Specification grading (Pass/Fail)',
  no_grade: 'No grade - Only provide feedback (Defaults to 100% grade)',
};

const AiQuestionTypeOptions: Record<AiQuestionType, string> = {
  written: 'Written Question',
  criteria: 'Criteria Question',
  specification: 'Specification Question',
  fill_in_the_blank: 'Fill in the Blank Question',
  custom: 'Custom',
};

function updateQuestionType() {
  form.grader.llmOptions = defaultLlmOptions;
  switch (form.grader.questionType) {
    case 'written':
      form.grader.feedbackInstructions.tone = 'Authoritative';
      form.grader.feedbackInstructions.formality = 'Formal';
      form.grader.feedbackInstructions.gradeTuning = null;
      form.grader.feedbackInstructions.improvementStrategy = null;
      form.grader.feedbackInstructions.gradeExplanation = null;
      form.grader.feedbackInstructions.answerRevealStrategy = 'Formative feedback';
      form.grader.feedbackInstructions.feedbackLength = 'Concise writing';
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
    case 'criteria':
      form.grader.llmOptions.maxOutputTokens = 4000;
      form.grader.gradeCalculationStrategy = 'standard_with_grade_breakdown';
      form.grader.feedbackInstructions.tone = null;
      form.grader.feedbackInstructions.formality = null;
      form.grader.feedbackInstructions.gradeTuning = null;
      form.grader.feedbackInstructions.improvementStrategy = null;
      form.grader.feedbackInstructions.gradeExplanation = null;
      form.grader.feedbackInstructions.answerRevealStrategy = 'Formative feedback';
      form.grader.feedbackInstructions.feedbackLength = 'Concise writing';
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
    case 'specification':
      form.grader.gradeCalculationStrategy = 'specification';
      form.grader.feedbackInstructions.tone = null;
      form.grader.feedbackInstructions.formality = null;
      form.grader.feedbackInstructions.improvementStrategy = 'Inquiry feedback';
      form.grader.feedbackInstructions.gradeExplanation = 'Explain the grade';
      form.grader.feedbackInstructions.answerRevealStrategy = 'Formative feedback';
      form.grader.feedbackInstructions.feedbackLength = 'Concise writing';
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
    case 'fill_in_the_blank':
      form.grader.llmOptions.maxOutputTokens = 400;
      form.grader.feedbackInstructions.tone = 'Authoritative';
      form.grader.feedbackInstructions.formality = 'Formal';
      form.grader.feedbackInstructions.gradeTuning = null;
      form.grader.feedbackInstructions.improvementStrategy = null;
      form.grader.feedbackInstructions.gradeExplanation = null;
      form.grader.feedbackInstructions.useCustomBasePrompt = true;
      form.grader.feedbackInstructions.provideMistakeLabels = false;
      form.grader.feedbackInstructions.answerRevealStrategy = 'Formative feedback';
      form.grader.feedbackInstructions.feedbackLength = 'Concise writing';
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      form.grader.feedbackInstructions.customPhrases.push(
        "You are an automated Fill in the Blank question grader. Your job is to evaluate the student's answer against the supplied correct answer(s)."
      );
      form.grader.feedbackInstructions.customPhrases.push(
        'ANY and ALL spelling mistakes must be accepted without any grade deductions.'
      );
      form.grader.feedbackInstructions.customPhrases.push(
        "Use reasonable judgment to determine if the student's answer is sufficient depending on the marking criteria."
      );
      form.grader.feedbackInstructions.customPhrases.push(
        'If appropriate, directly inform the student to be careful of spelling errors. Capitalization does not matter.'
      );
      form.grader.gradeCalculationStrategy = 'standard_with_grade_breakdown';
      form.grader.questions.push({
        question: 'Question Placeholder', // Grader and student page will use UI config to access question
        criteria: [
          {
            criteriaName: '',
            criteriaDescription: 'The specific answer to look for is: <ENTER_CORRECT_ANSWER>',
            gradeIncrement: 1,
            gradeWeight: 1,
            binaryGradeThreshold: null,
            assignedGrade: null,
            comment: null,
          },
        ],
        markingExamples: [],
      });

      form.ui.questions.push({
        questionText: '',
        characterLimit: 30,
      });
      break;
    case 'custom':
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
  }
}

function addQuestion() {
  form.grader.questions.push({
    question: 'Question Placeholder', // Grader and student page will use UI config to access question
    criteria: [
      {
        criteriaName: '',
        criteriaDescription: '',
        gradeIncrement: 0.05,
        gradeWeight: 1,
        binaryGradeThreshold: null,
        assignedGrade: null,
        comment: null,
      },
    ],
    markingExamples: [],
  });

  form.ui.questions.push({
    questionText: '',
    characterLimit: null,
  });
}

// Ensure there are default values for the LLM options
watch(
  () => form.grader.llmOptions,
  (newVal) => {
    if (newVal === null) {
      form.grader.llmOptions = defaultLlmOptions;
    }
  },
  {immediate: true}
);

const showAiAssistanceModal = ref<boolean>(false);
const syntheticQuestions: Ref<AiAllPurposeQuestionGenerationPayloadDto | null> = ref(null);
const fetchingSyntheticQuestions = ref(false);
const taskContext = ref('');
const exampleTaskId = ref(null);
const generateQuestionsErrors = reactive<Record<string, string>>({});

const openAiAssistanceModal = () => {
  showAiAssistanceModal.value = true;
};

function populateFromGeneratedQuestions() {
  if (!syntheticQuestions.value) return;

  form.ui.heading = syntheticQuestions.value.heading;
  form.grader.feedbackInstructions.subject = syntheticQuestions.value.subject;
  form.grader.questionType = 'criteria';
  updateQuestionType();

  form.grader.questions = JSON.parse(JSON.stringify(syntheticQuestions.value.questions));
  form.ui.questions = syntheticQuestions.value.questions.map((q) => {
    return {
      questionText: q.question,
      characterLimit: null,
      markingExamples: [],
    };
  });
}

async function generateSyntheticQuestions() {
  fetchingSyntheticQuestions.value = true;

  try {
    const response = await axios.post(
      route('api.v1.tasks.generate', {
        ...route().params,
        type: form.type ?? 'AiAllPurposeQuestion',
        context: taskContext.value,
        exampleTaskId: exampleTaskId.value,
      })
    );

    const payload = response.data.data;
    syntheticQuestions.value = {
      subject: payload.subject,
      heading: payload.heading,
      questions: payload.questions.data.map((q: any) => {
        return {
          ...q,
          criteria: q.criteria.data,
          markingExamples: [],
        };
      }),
    };

    populateFromGeneratedQuestions();
  } catch (error: any) {
    if (error.response && error.response.status === 422) {
      generateQuestionsErrors.exampleTaskId = error.response.data.errors.exampleTaskId;
    }
  } finally {
    fetchingSyntheticQuestions.value = false;
    showAiAssistanceModal.value = false;
  }
}

function removeQuestion(index: number) {
  form.grader.questions.splice(index, 1);
  form.ui.questions.splice(index, 1);
}
</script>
<i18n>
{
  "en": {
    "form": {
      "heading": {
        "label": "Heading",
        "description": "Displayed at the top of the task in large font. This is the title visible to students."
      },
      "questionType": {
        "label": "Choose Preset Feedback Instructions",
        "description": "After selecting a preset, feedback instructions can be viewed and altered."
      },
      "showLlmConfig": {
        "label": "LLM Configuration"
      },
      "gradeCalculationStrategy": {
        "label": "Grade Calculating Strategy",
        "description": "After selecting a strategy, questions and grading instructions can be added."
      },
      "question": {
        "label": "Question",
        "remove": "Remove Question"
      },
      "questions": {
        "label": "Questions",
        "action": "Add Question"
      },
      "criteria": {
        "label": "Grading Instructions",
        "nameSingle": "Feedback Part Title",
        "nameMultiple": "Criteria Name",
        "descriptionSingle": "Grading Rubric",
        "descriptionMultiple": "Criteria Item Rubric",
        "gradeWeight": "Grade Weight",
        "add": "Add Criteria Item",
        "addMultiple": "Evaluate This Question On Multiple Rubric Items (Criteria)",
        "remove": "Remove Criteria Item"
      },
      "specificationGrading": {
        "label": "",
        "description": "Range: [0,1]. Ex) 0.5 implies >50% correct answers are given full mark."
      },
      "characterLimit": {
        "label": "Character Limit to Student Response"
      }
    }
  }
}
</i18n>
