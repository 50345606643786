<template>
  <s-field
    id="feedbackInstructions"
    :label="t('fields.showFeedbackInstructions')"
    :error="formatHiddenErrors(errors)"
  />
  <div class="justify-end">
    <s-btn icon="pencil" @click.prevent="openBuilderModal" color="secondary">
      {{ t('fields.edit') }}
    </s-btn>
  </div>

  <s-modal
    v-model:open="showBuilderModal"
    width="xl"
    :title="t('title')"
    @confirm="confirmModalAction"
    @cancel="cancelBuilderModal"
  >
    <s-modal-content>
      <s-input-field
        class="mb-4"
        id="subject"
        :label="t('fields.subject')"
        :placeholder="t('placeholders.subject')"
        v-model="feedbackInstructions.subject"
        :error="errors?.subject"
      />
      <s-field class="mb-4" id="tone" :label="t('fields.tone')" :error="errors?.tone">
        <s-button-toggle id="tone" :options="toneOptions" v-model="feedbackInstructions.tone" />
      </s-field>
      <s-field
        class="mb-4"
        id="formality"
        :label="t('fields.formality')"
        :error="errors?.formality"
      >
        <s-button-toggle
          id="formality"
          :options="formalityOptions"
          v-model="feedbackInstructions.formality"
        />
      </s-field>
      <s-field
        class="mb-4"
        id="gradeTuning"
        :label="t('fields.gradingSeverity')"
        :error="errors?.gradeTuning"
      >
        <s-button-toggle
          id="gradeTuning"
          :options="gradeTuningOptions"
          v-model="feedbackInstructions.gradeTuning"
        />
      </s-field>
      <s-field
        class="mb-4"
        id="improvementStrategy"
        :label="t('fields.improvementStrategy')"
        :error="errors?.improvementStrategy"
      >
        <s-button-toggle
          id="improvementStrategy"
          :options="improvementStrategiesOptions"
          v-model="feedbackInstructions.improvementStrategy"
        />
      </s-field>
      <s-field
        class="mb-4"
        id="gradeExplanation"
        :label="t('fields.gradeExplanation')"
        :error="errors?.gradeExplanation"
      >
        <s-button-toggle
          id="gradeExplanation"
          :options="gradeExplanationOptions"
          v-model="feedbackInstructions.gradeExplanation"
        />
      </s-field>
      <s-field
        class="mb-4"
        id="answerRevealStrategy"
        :label="t('fields.answerRevealStrategy')"
        :error="errors?.answerRevealStrategy"
      >
        <s-button-toggle
          id="answerRevealStrategy"
          :options="answerRevealStrategyOptions"
          v-model="feedbackInstructions.answerRevealStrategy"
        />
      </s-field>
      <s-field
        class="mb-4"
        id="feedbackLength"
        :label="t('fields.feedbackLength')"
        :error="errors?.feedbackLength"
      >
        <s-button-toggle
          id="feedbackLength"
          :options="feedbackLengthOptions"
          v-model="feedbackInstructions.feedbackLength"
        />
      </s-field>
      <s-field
        id="feedbackLanguagePhrases"
        :label="t('fields.feedbackLanguagePhrases')"
        class="mb-4"
        :error="errors?.feedbackLanguagePhrases"
      >
        <div
          v-for="(option, index) in feedbackLanguageOptions"
          :key="index"
          class="flex items-center"
        >
          <input
            type="checkbox"
            :value="option.value"
            v-model="feedbackInstructions.feedbackLanguagePhrases"
          />
          <label class="ml-2">{{ option.label }}</label>
        </div>
      </s-field>

      <div class="flex flex-wrap gap-4">
        <s-field
          id="provideMistakeLabels"
          :label="t('fields.provideMistakeLabels')"
          :tooltip="t('provideMistakeLabelsTooltip')"
          class="mb-4"
          :error="errors?.provideMistakeLabels"
        >
          <s-button-toggle
            id="provideMistakeLabels"
            :options="[
              {value: true, label: t('options.provideMistakeLabels.yes')},
              {value: false, label: t('options.provideMistakeLabels.no')},
            ]"
            v-model="feedbackInstructions.provideMistakeLabels"
          />
        </s-field>
        <s-input-field
          id="maxPastContextLength"
          :tooltip="t('maxPastContextLengthTooltip')"
          type="number"
          max="10"
          step="1"
          min="0"
          :label="t('fields.maxPastContextLength')"
          v-model="feedbackInstructions.maxPastContextLength"
          :error="errors?.maxPastContextLength"
        />
      </div>
      <s-field
        id="useCustomBasePrompt"
        :label="t('fields.useCustomBasePrompt')"
        :tooltip="t('useCustomBasePromptTooltip')"
        class="mb-4"
        :error="errors?.useCustomBasePrompt"
      >
        <s-button-toggle
          id="useCustomBasePrompt"
          :options="[
            {value: true, label: t('options.useCustomBasePrompt.yes')},
            {value: false, label: t('options.useCustomBasePrompt.no')},
          ]"
          v-model="feedbackInstructions.useCustomBasePrompt"
        />
      </s-field>
      <div
        class="card padded"
        v-for="(customPhrase, customPhraseIdx) in feedbackInstructions.customPhrases"
        :key="customPhraseIdx"
      >
        <s-input-field
          id="customPhrases"
          :label="t('fields.customPhrases')"
          :placeholder="t('placeholders.customPhrases')"
          v-model="feedbackInstructions.customPhrases[customPhraseIdx]"
          :error="errors?.customPhrases"
        />
        <s-btn
          @click.prevent="feedbackInstructions.customPhrases.splice(customPhraseIdx, 1)"
          class="mt-2"
          icon="trash-can"
          size="sm"
          color="danger"
        >
          {{ t('actions.removeCustomPhrase') }}
        </s-btn>
      </div>
      <s-btn @click.prevent="addCustomPhrase()" class="mt-2" icon="plus" size="sm">
        {{ t('actions.addCustomPhrase') }}
      </s-btn>
    </s-modal-content>
  </s-modal>
</template>

<script setup lang="ts">
import {useVModel} from '@vueuse/core';
import {defineEmits, defineProps, ref} from 'vue';
import SInputField from '../../../design-system/SInputField.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';
import SModal from '../../../design-system/SModal.vue';
import SModalContent from '../../../design-system/SModalContent.vue';
import FeedbackInstructionsDto = App.DTOs.Tasks.FeedbackInstructionsDto;
import AnswerRevealStrategy = App.Enums.PromptBuildingPhrases.AnswerRevealStrategy;
import FeedbackLanguage = App.Enums.PromptBuildingPhrases.FeedbackLanguage;
import FeedbackLength = App.Enums.PromptBuildingPhrases.FeedbackLength;
import Tone = App.Enums.PromptBuildingPhrases.Tone;
import Formality = App.Enums.PromptBuildingPhrases.Formality;
import GradeTuning = App.Enums.PromptBuildingPhrases.GradeTuning;
import ImprovementStrategy = App.Enums.PromptBuildingPhrases.ImprovementStrategies;
import GradeExplanation = App.Enums.PromptBuildingPhrases.GradeExplanation;

const props = defineProps<{
  modelValue: FeedbackInstructionsDto;
  errors?: Record<string, any>;
}>();

type Option<V = string> = {value: V; label: string};

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const formatHiddenErrors = (errors: Record<string, any> | undefined): string | null => {
  if (!errors) return null;
  return Object.entries(errors)
    .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
    .join('; ');
};

const answerRevealStrategyOptions: Option<AnswerRevealStrategy | null>[] = [
  {value: 'Explanatory feedback', label: t('options.answerReveal.explanatoryFeedback')},
  {value: 'Formative feedback', label: t('options.answerReveal.formativeFeedback')},
  {
    value: 'Partially explanatory feedback',
    label: t('options.answerReveal.partiallyExplanatoryFeedback'),
  },
  {value: null, label: t('options.answerReveal.notSpecified')},
];

const feedbackLanguageOptions: Option<FeedbackLanguage>[] = [
  {value: 'Use simple language', label: t('options.feedbackLanguage.useSimpleLanguage')},
  {
    value: 'Use professional language',
    label: t('options.feedbackLanguage.useProfessionalLanguage'),
  },
  {value: 'Avoid jargon', label: t('options.feedbackLanguage.avoidJargon')},
  {value: 'No juxtapositions', label: t('options.feedbackLanguage.noJuxtapositions')},
  {value: 'No empty compliments', label: t('options.feedbackLanguage.noEmptyCompliments')},
];

const feedbackLengthOptions: Option<FeedbackLength | null>[] = [
  {value: 'Concise writing', label: t('options.feedbackLength.concise')},
  {value: 'Detailed writing', label: t('options.feedbackLength.detailed')},
  {value: null, label: t('options.feedbackLength.notSpecified')},
];

const formalityOptions: Option<Formality | null>[] = [
  {value: 'Formal', label: t('options.formality.formal')},
  {value: 'Informal', label: t('options.formality.informal')},
  {value: 'Semi-Formal', label: t('options.formality.semiFormal')},
  {value: null, label: t('options.formality.notSpecified')},
];

const toneOptions: Option<Tone | null>[] = [
  {value: 'Authoritative', label: t('options.tone.authoritative')},
  {value: 'Encouraging', label: t('options.tone.encouraging')},
  {value: 'Supportive', label: t('options.tone.supportive')},
  {value: 'Neutral', label: t('options.tone.neutral')},
  {value: null, label: t('options.tone.notSpecified')},
];

const gradeExplanationOptions: Option<GradeExplanation | null>[] = [
  {value: 'Use examples', label: t('options.gradeExplanation.useExamples')},
  {value: 'Explain the grade', label: t('options.gradeExplanation.explainGrade')},
  {value: null, label: t('options.gradeExplanation.notSpecified')},
];

const gradeTuningOptions: Option<GradeTuning | null>[] = [
  {value: 'Strict grading', label: t('options.gradeTuning.strict')},
  {value: 'Neutral grading', label: t('options.gradeTuning.neutral')},
  {value: 'Generous grading', label: t('options.gradeTuning.generous')},
  {value: null, label: t('options.gradeTuning.notSpecified')},
];

const improvementStrategiesOptions: Option<ImprovementStrategy | null>[] = [
  {value: 'Inquiry feedback', label: t('options.improvementStrategies.inquiry')},
  {value: 'Actionable feedback', label: t('options.improvementStrategies.actionable')},
  {value: 'No actionable feedback', label: t('options.improvementStrategies.noActionable')},
  {value: null, label: t('options.improvementStrategies.notSpecified')},
];

function addCustomPhrase() {
  if (!feedbackInstructions.value.customPhrases) {
    feedbackInstructions.value.customPhrases = [''];
  }
  feedbackInstructions.value.customPhrases.push('');
}

const showBuilderModal = ref(false);
const emit = defineEmits(['update:modelValue']);
const feedbackInstructions = useVModel(props, 'modelValue', emit);

const tempFeedbackInstructions = ref<FeedbackInstructionsDto>(feedbackInstructions.value);

const openBuilderModal = () => {
  tempFeedbackInstructions.value = {...feedbackInstructions.value};
  showBuilderModal.value = true;
};
const confirmModalAction = () => {
  showBuilderModal.value = false;
};

const cancelBuilderModal = () => {
  feedbackInstructions.value = tempFeedbackInstructions.value;
  showBuilderModal.value = false;
};
</script>
<i18n>
{
  "en": {
    "title": "Feedback Instructions Builder",
    "provideMistakeLabelsTooltip": "This option will request AI to diagnostically label student mistakes for analytical purposes.",
    "useCustomBasePromptTooltip": "This option will allow you to override the default prompt instructions. Enter your custom prompt instructions below, under 'Custom Phrases'. The default instructions are optimized for most assessment needs. Use with discretion.",
    "maxPastContextLengthTooltip": "This option will limit the number of previous student submissions and connected grade/feedback that the AI will be exposed to during assessment. Note that this is only the max, the actual number of past submissions may be less depending on Assignment policy.",
    "fields": {
      "showFeedbackInstructions": "Feedback Instructions",
      "edit": "Edit Instructions",
      "useCustomBasePrompt": "Override Prompt Default Instructions",
      "provideMistakeLabels": "Request Diagnostic Labels Of Student Mistakes",
      "subject": "Subject",
      "tone": "Tone",
      "formality": "Formality",
      "gradingSeverity": "Grading Severity",
      "improvementStrategy": "Improvement Strategy",
      "gradeExplanation": "Grade Explanation",
      "answerRevealStrategy": "Answer Reveal Strategy",
      "feedbackLength": "Feedback Length",
      "feedbackLanguagePhrases": "Linked Feedback Language Phrases",
      "customPhrases": "Custom Phrases",
      "maxPastContextLength": "Maximum Past Context Length"
    },
    "placeholders": {
      "subject": "Chemistry",
      "customPhrases": "Enter custom feedback instructions..."
    },
    "actions": {
      "removeCustomPhrase": "Remove Custom Phrase",
      "addCustomPhrase": "Add Custom Feedback Instruction Phrase"
    },
    "options": {
      "provideMistakeLabels": {
        "yes": "Yes",
        "no": "No"
      },
      "useCustomBasePrompt": {
        "yes": "Yes",
        "no": "No"
      },
      "answerReveal": {
        "explanatoryFeedback": "Explanatory feedback",
        "formativeFeedback": "Formative feedback",
        "partiallyExplanatoryFeedback": "Partially explanatory feedback",
        "notSpecified": "Not specified"
      },
      "feedbackLanguage": {
        "useSimpleLanguage": "Use simple language that is easy to understand",
        "useProfessionalLanguage": "Use professional language that is appropriate for the context",
        "avoidJargon": "Avoid using jargon or overly technical language",
        "noJuxtapositions": "Avoid juxtapositions such as, 'Good job, but..', or 'You did well, however..'. Instead, provide straightforward constructive feedback",
        "noEmptyCompliments": "Avoid useless niceties such as, 'Excellent work', or 'Keep it up!' in your comment"
      },
      "feedbackLength": {
        "concise": "Concise writing",
        "detailed": "Detailed writing",
        "notSpecified": "Not specified"
      },
      "formality": {
        "formal": "Formal",
        "informal": "Informal",
        "semiFormal": "Semi-Formal",
        "notSpecified": "Not specified"
      },
      "tone": {
        "authoritative": "Authoritative",
        "encouraging": "Encouraging",
        "supportive": "Supportive",
        "neutral": "Neutral",
        "notSpecified": "Not specified"
      },
      "gradeExplanation": {
        "useExamples": "Use examples from the student in feedback",
        "explainGrade": "Explain the reasoning behind assigned grade",
        "notSpecified": "Not specified"
      },
      "gradeTuning": {
        "strict": "Strict grading",
        "neutral": "Neutral grading",
        "generous": "Generous grading",
        "notSpecified": "Not specified"
      },
      "improvementStrategies": {
        "inquiry": "Inquiry feedback",
        "actionable": "Actionable feedback",
        "noActionable": "No actionable feedback",
        "notSpecified": "Not specified"
      }
    }
  },
  "fr": {
    "title": "Constructeur d'instructions de rétroaction",
    "provideMistakeLabelsTooltip": "Cette option demandera à l'IA d'étiqueter diagnostiquement les erreurs des étudiants à des fins analytiques.",
    "useCustomBasePromptTooltip": "Cette option vous permettra de remplacer les instructions de base par défaut. Vos instructions personnalisées peuvent être saisies ci-dessous, sous 'Phrases personnalisées'. Les instructions par défaut ont été soigneusement conçues pour couvrir la plupart des objectifs d'évaluation. Veuillez les utiliser à votre discrétion.",
    "maxPastContextLengthTooltip": "Cette option limitera le nombre de soumissions précédentes des étudiants auxquelles l'IA sera exposée pendant l'évaluation.",
    "fields": {
      "showFeedbackInstructions": "Instructions de rétroaction",
      "edit": "Modifier les instructions",
      "useCustomBasePrompt": "Remplacer les instructions par défaut",
      "provideMistakeLabels": "Fournir des étiquettes de diagnostic des erreurs des étudiants",
      "subject": "Sujet",
      "tone": "Ton",
      "formality": "Formalité",
      "gradingSeverity": "Gravité de la notation",
      "improvementStrategy": "Stratégie d'amélioration",
      "gradeExplanation": "Explication de la note",
      "answerRevealStrategy": "Stratégie de révélation des réponses",
      "feedbackLength": "Longueur du commentaire",
      "feedbackLanguagePhrases": "Phrases liées au commentaire",
      "customPhrases": "Phrases personnalisées",
      "maxPastContextLength": "Longueur maximale du contexte passé"
    },
    "placeholders": {
      "subject": "Chimie",
      "customPhrases": "Entrez des instructions personnalisées pour le commentaire..."
    },
    "actions": {
      "removeCustomPhrase": "Supprimer la phrase personnalisée",
      "addCustomPhrase": "Ajouter une phrase personnalisée pour les instructions du commentaire"
    },
    "options": {
      "provideMistakeLabels": {
        "yes": "Oui",
        "no": "Non"
      },
      "useCustomBasePrompt": {
        "yes": "Oui",
        "no": "Non"
      },
      "answerReveal": {
        "explanatoryFeedback": "Retour explicatif",
        "formativeFeedback": "Retour formatif",
        "partiallyExplanatoryFeedback": "Retour partiellement explicatif",
        "notSpecified": "Non spécifié"
      },
      "feedbackLanguage": {
        "useSimpleLanguage": "Utiliser un langage simple et facile à comprendre",
        "useProfessionalLanguage": "Utiliser un langage professionnel adapté au contexte",
        "avoidJargon": "Éviter d'utiliser du jargon ou un langage trop technique",
        "noJuxtapositions": "Éviter les juxtapositions comme, 'Bon travail, mais..', ou 'Vous avez bien fait, cependant..'. Fournir plutôt un ccommentaire constructif direct",
        "noEmptyCompliments": "Éviter les compliments inutiles comme, 'Excellent travail', ou 'Continuez comme ça!' dans le commentaire"
      },
      "feedbackLength": {
        "concise": "Écriture concise",
        "detailed": "Écriture détaillée",
        "notSpecified": "Non spécifié"
      },
      "formality": {
        "formal": "Formel",
        "informal": "Informel",
        "semiFormal": "Semi-formel",
        "notSpecified": "Non spécifié"
      },
      "tone": {
        "authoritative": "Autoritaire",
        "encouraging": "Encourageant",
        "supportive": "Soutenant",
        "neutral": "Neutre",
        "notSpecified": "Non spécifié"
      },
      "gradeExplanation": {
        "useExamples": "Utiliser des exemples de l'étudiant dans le commentaire",
        "explainGrade": "Expliquer le raisonnement derrière la note attribuée",
        "notSpecified": "Non spécifié"
      },
      "gradeTuning": {
        "strict": "Notation stricte",
        "neutral": "Notation neutre",
        "generous": "Notation généreuse",
        "notSpecified": "Non spécifié"
      },
      "improvementStrategies": {
        "inquiry": "Commentaire interrogatoire",
        "actionable": "Commentaire exploitable",
        "noActionable": "Pas de commentaire exploitable",
        "notSpecified": "Non spécifié"
      }
    }
  }
}
</i18n>
