<template>
  <s-page-block>
    <s-page-header :heading="t('page.heading')" class="mb-3">
      <template #actions>
        <s-btn
          v-if="can.grantAttempts"
          icon="plus"
          :href="
            route('courses.assignments.students.assignmentTasks.attempts-grants.create', {
              course: course.id,
              assignment: assignment.id,
              user: student.id,
              assignmentTask: taskNumber,
            })
          "
        >
          {{ t('actions.grantAttempt') }}
        </s-btn>
      </template>
    </s-page-header>
    <s-placeholder v-if="!grants.data.length">
      {{ t('placeholders.noAttemptsGranted') }}
    </s-placeholder>
    <s-card v-else padding="none">
      <ul>
        <li
          v-for="grant in grants.data"
          class="px-4 py-2 first:pt-3 last:pb-3 border-b last:border-b-0"
        >
          <i18n-t keypath="xAttemptsGrantedBySomeoneOnDate">
            <template #attempts>
              <span class="font-bold">{{ grant.count }}</span>
            </template>
            <template #grantedBy>
              <span class="font-bold">
                {{ grant.grantedBy.firstName }} {{ grant.grantedBy.lastName }}
              </span>
            </template>
            <template #date>
              <span class="font-bold">{{ monthDaySometimesYear(grant.createdAt) }}</span>
            </template>
          </i18n-t>
        </li>
      </ul>
    </s-card>
    <s-paginator :paginator="grants" class="mt-5" />
  </s-page-block>
</template>
<script setup lang="ts">
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import {User} from '../../types/entities/user';
import {Task} from '../../types/entities/tasks';
import {LaravelPaginator} from '../../types/laravel-paginator';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SCard from '../../design-system/SCard.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SPlaceholder from '../../design-system/SPlaceholder.vue';
import SPaginator from '../../design-system/SPaginator.vue';
import {monthDaySometimesYear} from '../../format/dates';
import {useI18n} from 'vue-i18n';
import SBtn from '../../design-system/SBtn.vue';
import {route} from 'ziggy-js';

defineProps<{
  course: Course;
  assignment: Assignment;
  student: User;
  task: Task;
  taskNumber: number;
  grants: LaravelPaginator<any>;
  can: {
    grantAttempts: boolean;
  };
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "actions.grantAttempt": "Grant Attempt",
    "page.heading": "Attempt Grants",
    "placeholders.noAttemptsGranted": "No attempts granted yet",
    "xAttemptsGrantedBySomeoneOnDate": "{attempts} attempt granted by {grantedBy} on {date} | {attempts} attempts granted by {grantedBy} on {date}"
  },
  "fr": {
    "actions.grantAttempt": "Accorder une tentative",
    "page.heading": "Attributions de tentatives",
    "placeholders.noAttemptsGranted": "Aucune subvention de tentative effectuée",
    "xAttemptsGrantedBySomeoneOnDate": "{attempts} tentative accordée par {grantedBy} le {date} | {attempts} tentatives accordées par {grantedBy} le {date}"
  }
}
</i18n>
