<template>
  <div class="flex shrink max-w-full flex-wrap w-full items-end lg:items-end gap-4">
    <div class="flex flex-1 w-full max-w-full items-center justify-start gap-y-3 gap-x-4 text-base">
      <!-- @vue-ignore -->
      <s-combobox
        v-model="selectedSectionId"
        :displayValue="(section?: any) => section?.name"
        :options="visibleSections"
        id-key="id"
        :emptyLabel="t('allMySections')"
        nullable
        :filter-by="['name']"
      />
      <div class="flex-1 min-w-0 flex items-center gap-2">
        <s-tooltip :text="t('link.toStudent', {name: `${student.firstName} ${student.lastName}`})">
          <s-link
            :href="`/web/courses/${assignment.course.id}/participants/${student.id}`"
            class="group link flex items-center opacity-70 group-hover:opacity-100"
            preserve-state
            :aria-label="t('link.toStudent', {name: `${student.firstName} ${student.lastName}`})"
          >
            <s-icon name="account" size="22" />
          </s-link>
        </s-tooltip>
        <s-combobox
          v-model="selectedStudentId"
          :displayValue="
            (student?: any) => `${student?.firstName} ${student?.lastName} (${student?.email})`
          "
          :options="visibleStudents"
          id-key="id"
          :filter-by="['firstName', 'lastName', 'email']"
          empty-label=""
          class="min-w-0"
        />
      </div>
    </div>
    <div class="flex w-full flex-1 xl:max-w-md">
      <s-key-tooltip class="flex-1 flex" keys="," position="bottom">
        <button
          class="btn md white flex-1 flex items-center gap-2 rounded-r-none"
          :disabled="previousStudentIndex === -1"
          @click="goToPreviousStudent"
        >
          <s-icon name="arrow-left" size="16" class="opacity-70" />
          {{ t('previousStudent') }}
        </button>
      </s-key-tooltip>
      <s-key-tooltip class="flex-1 flex" keys="." position="bottom">
        <button
          class="btn md primary flex-1 flex items-center gap-2 rounded-l-none"
          :disabled="nextStudentIndex === -1"
          @click="goToNextStudent"
        >
          {{ t('nextStudent') }}

          <s-icon name="arrow-right" size="16" class="opacity-70" />
        </button>
      </s-key-tooltip>
    </div>
  </div>
</template>
<script setup lang="ts">
import {router} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {computed, ref, Ref, watch, watchEffect} from 'vue';
import {User} from '../../../types/entities/user';
import {Assignment} from '../../../types/entities/assignment';
import {CourseLike} from '../../../types/entities/course-like';
import STooltip from '../../../design-system/STooltip.vue';
import SLink from '../../../components/SLink.vue';
import SCombobox from '../../../design-system/SCombobox.vue';
import SKeyTooltip from '../../../design-system/SKeyTooltip.vue';
import {useKeyboardShortcuts} from '../../../composables/useKeyboardShortcut';
import SIcon from '../../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const emit = defineEmits(['update:current-section-id']);

const {t} = useI18n();

const studentProps = [
  'assignmentTasks',
  'student',
  'attemptedTaskIds',
  'draftTaskIds',
  'attachmentTasksIds',
  'courseLikeAssignment',
  'studentAssignmentTaskGrades',
  'assignmentGrade',
  'extension',
  'task',
  'taskResponses',
  'taskRubric',
  'taskState',
  'response',
  'responseGrade',
  'partGrades',
  'feedbackByPart',
  'originalGrade',
  'originalGradeFeedback',
  'timer',
  'maxAttempts',
  'additionalAttempts',
];

const props = defineProps<{
  currentSectionId: string;
  assignment: Assignment;
  courseSections: CourseLike[];
  taskNumber: number;
  student: User;
  students: any[];
}>();

const selectedSectionId = computed<number | null>({
  get: () => ~~props.currentSectionId,
  set: (value) => emit('update:current-section-id', value?.toString() || null),
});
const selectedStudentId = ref<string | number>(props.student.id);

watchEffect(() => {
  selectedStudentId.value = props.student.id;
});

const goToPreviousStudent = () => navigateStudent(previousStudentIndex.value);
const goToNextStudent = () => navigateStudent(nextStudentIndex.value);

const keyboardShortcuts = useKeyboardShortcuts();
keyboardShortcuts.register({key: ',', label: t('previousStudent'), onPress: goToPreviousStudent});
keyboardShortcuts.register({key: '.', label: t('nextStudent'), onPress: goToNextStudent});

watch(selectedSectionId, () => {
  navigateToFirstStudentInCurrentSection();
});

watch(selectedStudentId, () => {
  goToStudentAssignment(selectedStudentId.value);
});

const visibleStudents = computed(() => {
  if (selectedSectionId.value) {
    return props.students.filter((student) => student.sectionIds.includes(selectedSectionId.value));
  }
  return props.students;
});

function useStudentNavigation(students: Ref<(User & {sectionIds: string})[]>) {
  const previousStudentIndex = ref(-1);
  const nextStudentIndex = ref(-1);

  watchEffect(() => {
    const currentIndex = students.value.findIndex(
      (student) => student.id === +selectedStudentId.value
    );
    if (currentIndex - 1 < 0) {
      previousStudentIndex.value = -1;
      nextStudentIndex.value = currentIndex + 1;
    } else if (currentIndex + 1 >= students.value.length) {
      previousStudentIndex.value = currentIndex - 1;
      nextStudentIndex.value = -1;
    } else {
      previousStudentIndex.value = currentIndex - 1;
      nextStudentIndex.value = currentIndex + 1;
    }
  });

  return {previousStudentIndex, nextStudentIndex};
}

function goToStudentAssignment(studentId: string | number, message?: string) {
  let _query = {};
  if (selectedSectionId.value) {
    _query = {
      sectionFilter: selectedSectionId.value || '',
    };
  }
  router.visit(
    route('courses.assignments.marking.assignmentTasks.show', {
      course: props.assignment.course.id,
      assignment: props.assignment.id,
      user: studentId,
      taskNumber: props.taskNumber,
      _query,
    }),
    {
      only: studentProps,
      preserveState: true,
    }
  );
}

const {previousStudentIndex, nextStudentIndex} = useStudentNavigation(visibleStudents);

function navigateStudent(index: number) {
  let student = visibleStudents.value[index];
  if (!student) return;
  router.visit(
    //@ts-ignore
    route('courses.assignments.marking.assignmentTasks.show', {
      course: props.assignment.course.id,
      assignment: props.assignment.id,
      user: student.id,
      taskNumber: props.taskNumber,
      sectionFilter: selectedSectionId.value,
    }),
    {
      only: studentProps,
      preserveState: true,
    }
  );
}

// TODO: Sections should be filtered on the backend.
const visibleSections = computed(() => [
  {
    id: undefined,
    name: t('allMySections'),
  },
  ...props.courseSections
    .filter((section) => {
      return props.students.some((student) => student.sectionIds.includes(section.id));
    })
    .sort((a, b) => a.name.localeCompare(b.name)),
]);

function navigateToFirstStudentInCurrentSection() {
  if (!selectedSectionId.value) {
    return;
  }

  const currentStudentIsInSelectedSection = visibleStudents.value.some(
    (student) => student.id === +selectedStudentId.value
  );

  if (currentStudentIsInSelectedSection) {
    return;
  }

  let firstStudent = visibleStudents.value[0];

  if (firstStudent) {
    selectedStudentId.value = firstStudent.id;
  } else {
    // There's no students in this section.
  }
}
</script>
<i18n>
{
  "en": {
    "allMySections": "All My Sections",
    "nextStudent": "Next Student",
    "previousStudent": "Previous Student",
    "link": {
      "toStudent": "Visit {name}'s profile"
    }
  },
  "fr": {
    "allMySections": "Toutes mes sections",
    "nextStudent": "Étudiant suivant",
    "previousStudent": "Étudiant précédent",
    "link": {
      "toStudent": "Visiter le profil de {name}"
    }
  }
}
</i18n>
