<template>
  <Head :title="t('page.title', {courseCode: course.code, assignmentName: assignment.name})"></Head>
  <s-page-block class="@container">
    <s-page-header class="mb-3" :heading="t('page.heading')" />
    <div class="mb-5 flex flex-wrap-reverse gap-x-3 gap-y-4 items-end justify-between">
      <div class="text-sm">
        <s-select-field id="section" v-model="sectionFilter">
          <option value="">{{ $t('allSections') }}</option>
          <option v-for="courseLike in courseLikes" :key="courseLike.id" :value="courseLike.id">
            {{ courseLike.name }}
          </option>
        </s-select-field>
      </div>
      <s-search-section
        route-name="courses.assignments.extension.index"
        :label="t('search.byNameOrEmail')"
        class="flex-1"
      />
      <div class="flex-1 flex items-center justify-end gap-x-3 gap-y-2">
        <s-link
          class="btn md white"
          :href="route('courses.assignments.extension.bulk.create', [course.id, assignment.id])"
        >
          <s-icon name="table" class="opacity-70" size="20" />
          {{ t('buttons.addByCSV') }}
        </s-link>
        <s-link
          class="btn md primary"
          :href="route('courses.assignments.extension.create', [course.id, assignment.id])"
        >
          <s-icon name="plus" class="opacity-70" size="20" />
          {{ t('buttons.addExtensions') }}
        </s-link>
      </div>
    </div>
    <section class="mb-5 flex flex-col gap-4">
      <s-placeholder v-if="!extensions.data.length">
        {{ t('placeholders.noExtensions') }}
      </s-placeholder>
      <div
        v-for="(item, index) in extensions.data"
        :key="index"
        class="flex flex-col xl:flex-row xl:divide-x xl:divide-gray-200 bg-gradient-to-t from-gray-50 to-white rounded-lg shadow-card-soft border border-gray-200"
      >
        <div
          class="flex-1 flex flex-col 2xl:grid 2xl:grid-cols-[2fr_3fr] gap-y-1 gap-x-4 px-4 py-2.5 2xl:items-end"
        >
          <div class="flex flex-col md:col-span-2 2xl:col-span-1">
            <p class="text-lg leading-6">{{ item.studentFirstName }} {{ item.studentLastName }}</p>
            <span class="text-gray-600/85 font-medium text-sm leading-tight">
              {{ item.studentEmail }}
            </span>
            <p
              class="flex items-center gap-4 text-xs font-bold text-gray-600 uppercase tracking-slight leading-5"
            >
              <span class="flex items-center gap-1">
                <span class="font-bold opacity-70">
                  {{ t('section') }}
                </span>
                {{ item.sectionName }}
              </span>
              <span class="flex items-center gap-1">
                <span class="font-bold opacity-70">
                  {{ t('page.grantedBy') }}
                </span>
                <span class="">{{ item.createdByFirstName }} {{ item.createdByLastName }}</span>
              </span>
            </p>
          </div>
          <div class="flex gap-y-1 gap-x-4 2xl:items-end">
            <s-label-value
              v-if="item.extension.newStartDate"
              :label="t('labels.newStartDate')"
              class="flex-1"
            >
              <div class="leading-none">
                {{ monthDayYear(item.extension.newStartDate, {time: false}) }}
              </div>
              <span class="text-sm text-gray-600 leading-none font-bold uppercase tracking-slight">
                {{ timeOfDay(item.extension.newStartDate) }}
              </span>
            </s-label-value>
            <s-label-value
              v-if="item.extension.newDueDate"
              :label="t('labels.newDueDate')"
              class="flex-1"
            >
              <div class="leading-none">
                {{ monthDayYear(item.extension.newDueDate, {time: false}) }}
              </div>
              <span class="text-sm text-gray-600 leading-none font-bold uppercase tracking-slight">
                {{ timeOfDay(item.extension.newDueDate) }}
              </span>
            </s-label-value>
            <p class="flex-1" v-if="item.extension.deletedAt">{{ t('page.removed') }}</p>
          </div>
        </div>
        <div
          class="overflow-hidden bg-gradient-to-b xl:bg-gradient-to-r from-gray-400/10 xl:from-gray-300/10 via-gray-200/10 to-gray-200/10 border-t border-gray-200 xl:border-t-0 grid grid-cols-[auto_auto] @sm:grid-cols-2 divide-y divide-x divide-gray-200"
        >
          <button
            @click.prevent="
              router.delete(
                route('courses.assignments.extension.destroy', [
                  course.id,
                  assignment.id,
                  item.extension.id,
                ])
              )
            "
            class="-mt-px px-4 xl:px-6 pt-2 pb-3 hover:bg-gray-50 text-center link-subtle red flex items-center justify-center gap-1.5"
          >
            <s-icon name="delete" class="opacity-70" />
            {{ t('buttons.remove') }}
          </button>
          <s-link
            :href="
              route('courses.assignments.extension.edit', [
                course.id,
                assignment.id,
                item.extension.id,
              ])
            "
            class="-mt-px px-4 xl:px-6 pt-2 pb-3 hover:bg-gray-50 text-center link-subtle flex items-center justify-center gap-1.5"
          >
            <s-icon name="pencil" class="opacity-70" />
            {{ t('buttons.edit') }}
          </s-link>
        </div>
      </div>
    </section>
    <s-paginator v-if="extensions.data.length > 0" :paginator="extensions" />
  </s-page-block>
</template>
<script setup lang="ts">
import SLink from '../../components/SLink.vue';
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import {LaravelPaginator} from '../../types/laravel-paginator';
import {monthDayYear, timeOfDay} from '../../format/dates';
import {Extension} from '../../types/entities/extension';
import SPaginator from '../../design-system/SPaginator.vue';
import SPlaceholder from '../../design-system/SPlaceholder.vue';
import SLabelValue from '../../design-system/SLabelValue.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import {route} from 'ziggy-js';
import SSearchSection from '../../design-system/SSearchSection.vue';
import {Head, router} from '@inertiajs/vue3';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SSelectField from '../../design-system/SSelectField.vue';
import {CourseLike} from '../../types/entities/course-like';
import {computed} from 'vue';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({useScope: 'local', inheritLocale: true});

type ExtensionWithUsers = {
  extension: Extension;
  studentFirstName: string;
  studentLastName: string;
  studentEmail: string;
  createdByFirstName: string;
  createdByLastName: string;
  sectionId: string;
  sectionName: string;
};

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  extensions: LaravelPaginator<ExtensionWithUsers>;
  courseLikes: CourseLike[];
}>();

const sectionFilter = computed<string>({
  get: () => (route().params as any).section || '',
  set: (sectionId) => {
    const current = route().current();
    if (!current) {
      return;
    }

    router.visit(
      route(current, {
        ...route().params,
        _query: {section: sectionId || undefined},
      })
    );
  },
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Extensions",
      "heading": "Extensions",
      "removed": "REMOVED",
      "grantedBy": "Granted by"
    },
    "labels": {
      "newStartDate": "New Start Date",
      "newDueDate": "New Due Date"
    },
    "placeholders": {
      "noExtensions": "Currently there are no extensions for this assignment."
    },
    "buttons": {
      "addByCSV": "Add by CSV",
      "addExtensions": "Add Extensions",
      "remove": "Remove",
      "edit": "Edit"
    }
  },
  "fr": {
    "page": {
      "title": "{courseCode} - {assignmentName} - Prolongations",
      "heading": "Prolongations",
      "removed": "SUPPRIMÉ",
      "grantedBy": "Accordé par"
    },
    "labels": {
      "newStartDate": "Nouvelle date de début",
      "newDueDate": "Nouvelle date d'échéance"
    },
    "placeholders": {
      "noExtensions": "Il n'y a actuellement aucune prolongation pour ce devoir."
    },
    "buttons": {
      "addByCSV": "Ajouter par CSV",
      "addExtensions": "Ajouter des prolongations",
      "remove": "Supprimer",
      "edit": "Modifier"
    }
  }
}
</i18n>
