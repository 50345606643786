<template>
  <div
    v-if="user"
    class="relative z-10 flex-1 w-0 transition-bg ease-out duration-150"
    :open="isOpen"
    ref="element"
  >
    <button
      id="user-menu-toggle"
      class="relative group w-full cursor-pointer list-none flex items-center justify-between gap-2.5 transition pr-2 hover:text-blue-100 z-[2]"
      @click.prevent="isOpen = !isOpen"
      :aria-expanded="isOpen"
      :aria-label="t('aria-label.menu')"
      aria-controls="user-menu"
    >
      <div class="-m-px w-11 h-11 flex p-px bg-blue-300/50 rounded-full z-10" role="presentation">
        <img
          v-if="user.avatar"
          :src="user.avatar"
          class="w-full h-full object-cover rounded-full"
          role="presentation"
        />
        <span
          v-else
          class="flex-1 flex items-center justify-center text-xl font-medium tracking-wide bg-gradient-to-t from-blue-900 to-blue-1000 via-blue-950 rounded-full text-blue-100 group-hover:text-blue-150 transition"
          aria-hidden="true"
        >
          {{ user.name.split(' ')[0].charAt(0) }}{{ user.name.split(' ')[1]?.charAt(0) }}
        </span>
      </div>
      <span class="flex-1 text-left leading-tight">{{ user.name }}</span>
      <div
        class="absolute -left-1.5 -top-1.5 -right-1.5 bottom-0 rounded-t-[1.6rem] border bg-blue-900/70 border-blue-900 border-b-transparent z-[-1]"
        :class="isOpen ? 'opacity-100' : 'opacity-0'"
      ></div>
    </button>
    <nav
      id="user-menu"
      ref="navRef"
      class="absolute z-[-1] bottom-0 -left-1.5 -right-1.5 translate-y-full z-10 transition-[grid-template-rows] ease-out duration-150 grid min-h-0 overflow-hidden"
      :class="isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'"
      :aria-hidden="!isOpen"
      aria-labelledby="user-menu-toggle"
      :hidden="!isOpen"
    >
      <div
        class="bg-blue-900/70 border border-t-0 border-blue-900 backdrop-blur w-full overflow-hidden transition-opacity ease-out duration-150 flex flex-col rounded-b-[1.4rem] shadow-xl"
        :class="isOpen ? 'opacity-100' : 'opacity-0'"
      >
        <div class="px-1.5 pt-2 pb-3">
          <s-link
            :tabindex="isOpen ? 0 : -1"
            href="/profile"
            class="w-full flex items-center gap-2.5 px-3.5 py-2 text-base font-normal leading-tight text-left transition text-white/80 hover:text-white rounded-full hover:bg-blue-900 text-balance"
          >
            <s-icon name="account" size="26" class="text-blue-200" />
            {{ t('links.profile') }}
          </s-link>
          <s-link
            :tabindex="isOpen ? 0 : -1"
            :href="route('billing.index')"
            class="w-full flex items-center gap-2.5 px-3.5 py-2 text-base font-normal leading-tight text-left transition text-white/80 hover:text-white rounded-full hover:bg-blue-900 text-balance"
          >
            <s-icon name="cash-multiple" size="26" class="text-blue-200" />
            {{ t('links.billing') }}
          </s-link>
          <s-link
            :tabindex="isOpen ? 0 : -1"
            :href="route('password.edit')"
            class="w-full flex items-center gap-2.5 px-3.5 py-2 text-base font-normal leading-tight text-left transition text-white/80 hover:text-white rounded-full hover:bg-blue-900 text-balance"
          >
            <s-icon name="reload" class="text-blue-200" />

            {{ t('links.changePassword') }}
          </s-link>
          <s-link
            :tabindex="isOpen ? 0 : -1"
            :href="route('logout')"
            method="delete"
            class="w-full flex items-center gap-2.5 px-3.5 py-2 text-base font-normal leading-tight text-left transition text-white/80 hover:text-white rounded-full hover:bg-blue-900 text-balance"
          >
            <s-icon name="exit-to-app" class="text-blue-200" />
            {{ t('links.logout') }}
          </s-link>
        </div>
      </div>
    </nav>
  </div>
  <div v-else class="flex-1"></div>
</template>
<script setup lang="ts">
import {onClickOutside} from '@vueuse/core';
import {computed, ref, watch, onMounted, onBeforeUnmount} from 'vue';
import SLink from '../SLink.vue';
import {usePage} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import SIcon from '../../design-system/SIcon.vue';
import {route} from 'ziggy-js';

interface LoggedInUser {
  name: string;
  avatar: string;
}

const isOpen = ref(false);
const page = usePage();
const user = page?.props?.loggedInUser as LoggedInUser;
const path = computed(() => page.url.split('?')[0]);

watch(path, () => {
  isOpen.value = false;
});

const element = ref(null);

onClickOutside(element, (event) => {
  if (isOpen.value) {
    isOpen.value = false;
  }
});

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

/**
 * # Focus Management: Tabbing
 *
 * When the user menu is open, we want to close it
 * if the user switches focus to something outside of it.
 *
 * There are two cases where this will occur:
 * 1. the user clicks outside of it
 * 2. the user tabs out of it
 *
 * This is required to adhere to WCAG 2.2 guideline 2.4.11 and 2.4.12
 *
 * https://www.w3.org/TR/WCAG22/#focus-not-obscured-minimum
 */
const navRef = ref<HTMLElement | null>(null);
const onFocusIn = (event: FocusEvent) => {
  if (!navRef.value) return;

  const focusHasLeftNav = !navRef.value.contains(event.target as Node);

  if (isOpen.value && focusHasLeftNav) {
    isOpen.value = false;
  }
};
onMounted(() => document.addEventListener('focusin', onFocusIn));
onBeforeUnmount(() => document.removeEventListener('focusin', onFocusIn));
</script>
<i18n>
{
  "en": {
    "aria-label": {
      "menu": "Account management"
    },
    "links": {
      "billing": "Billing",
      "changePassword": "Change Password",
      "logout": "Logout",
      "profile": "My Profile"
    }
  },
  "fr": {
    "aria-label": {
      "menu": "Gestion du compte"
    },
    "links": {
      "billing": "Facturation",
      "changePassword": "Changer le mot de passe",
      "logout": "Se déconnecter",
      "profile": "Mon profil"
    }
  }
}
</i18n>
