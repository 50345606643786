<template>
  <details ref="detailsRef" @toggle="updateHeading" class="@container">
    <summary
      class="cursor-pointer flex flex-wrap items-center justify-between gap-2 px-5 @2xl:px-7 py-3"
    >
      <h3 class="text-base font-medium tracking-slight flex items-center gap-1 text-blue-600">
        <s-icon :name="isOpen ? 'eye-off' : 'eye'" class="opacity-70" size="22" />
        {{ isOpen ? t('hideTaskState') : t('viewTaskState') }}
      </h3>
      <s-btn
        id="showRandomVariables"
        @click="toggleShowRandomVariables"
        :icon="showRandomVariables ? 'eye-off' : 'marker'"
        :color="showRandomVariables ? 'secondary' : 'blue-light'"
        size="sm"
      >
        {{ showRandomVariables ? t('hide') : t('highlight') }}
      </s-btn>
    </summary>
    <div class="px-5 md:px-7 pt-1 pb-4">
      <div class="scrolling-table-wrapper max-h-[16rem]">
        <table class="table denser text-sm">
          <thead>
            <tr>
              <th>{{ t('variable') }}</th>
              <th>{{ t('value') }}</th>
            </tr>
          </thead>
          <tbody class="max-h-[10rem] overflow-y-scroll">
            <tr v-for="(symbol, index) of taskState?.symbols" :key="symbol">
              <td>{{ symbol }}</td>

              <td>
                <s-task-state
                  v-if="taskState.values[index]"
                  :key="symbol"
                  :value="/***@ts-ignore***/ taskState.values[index]"
                />
                <span v-else>–</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </details>
</template>
<script setup lang="ts">
import {ref} from 'vue';
import SIcon from '../../../design-system/SIcon.vue';
import SBtn from '../../../design-system/SBtn.vue';
import STaskState from '../../../components/STaskState.vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n();

const props = defineProps<{
  taskState: {
    symbols: string[];
    values: {
      isScientificNotation: boolean;
      number: number;
      scientificNotationExponent: number;
      scientificNotationNumber: string;
      shift: number;
      sigFigs: number;
      sympyValue: string;
      valueType: string;
    }[];
  };
  showRandomVariables: boolean;
}>();

const emit = defineEmits(['update:showRandomVariables']);

const isOpen = ref(false);
const detailsRef = ref<HTMLDetailsElement | null>(null);

const updateHeading = (event: Event) => {
  if (detailsRef.value) {
    isOpen.value = detailsRef.value.open;
  }
};

const toggleShowRandomVariables = () => {
  emit('update:showRandomVariables', !props.showRandomVariables);
};
</script>
<i18n>
{
  "en": {
    "hideTaskState": "Hide Task State",
    "viewTaskState": "View Task State",
    "hide": "Remove Highlights",
    "highlight": "Highlight Random Variables",
    "variable": "Variable",
    "value": "Value"

  },
  "fr": {
    "hideTaskState": "Masquer l'état de la tâche",
    "viewTaskState": "Afficher l'état de la tâche",
    "hide": "Désaccentuer les variables aléatoires",
    "highlight": "Accentuer les variables aléatoires",
    "variable": "Variable",
    "value": "Valeur"
  }
}
</i18n>
