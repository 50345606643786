<template>
  <edit-assignment-header :course="course" :assignment="assignment" :can="can" />
  <form
    id="settings-form"
    @submit.prevent="form.put(route('courses.assignments.update', [course.id, assignment.id]))"
    class="flex-1 flex flex-col"
  >
    <s-page-block size="lg" class="flex-1">
      <div class="mb-4 flex flex-col gap-3">
        <s-input-field
          id="name"
          :label="t('labels.name')"
          v-model="form.name"
          :error="errors.name"
        ></s-input-field>

        <s-field
          v-if="page.props.features.visualEditorForAssignmentOverview"
          id="overview"
          :label="t('labels.overview')"
        >
          <assignment-overview-editor v-model="form.overview" />
        </s-field>

        <s-textarea-field
          v-else
          id="overview"
          :label="t('labels.overview')"
          v-model="form.overviewText"
          :error="errors.overviewText"
        ></s-textarea-field>
      </div>
    </s-page-block>
    <s-form-footer
      :cancelRoute="
        route('courses.assignments.show', {
          course: course.id,
          assignment: assignment.id,
        })
      "
      :formProcessing="form.processing"
      :formIsDirty="form.isDirty"
    />
  </form>
</template>
<script setup lang="ts">
import {useForm, usePage} from '@inertiajs/vue3';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SFormFooter from '../../design-system/SFormFooter.vue';
import SInputField from '../../design-system/SInputField.vue';
import {Course} from '../../types/entities/course';
import {computed} from 'vue';
import EditAssignmentHeader from '../../layouts/EditAssignmentHeader.vue';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import SField from '../../design-system/SField.vue';
import AssignmentOverviewEditor from '../../editors/assignment-overview-editor/AssignmentOverviewEditor.vue';
import {RichText} from '../../types/entities/rich-text';
import STextareaField from '../../design-system/STextareaField.vue';
import {useLeavingFormConfirmation} from '../../composables/useLeavingFormConfirmation';
import AssignmentDto = App.DTOs.AssignmentDto;

const props = defineProps<{
  course: Course;
  assignment: AssignmentDto;
  overview: RichText | null;
  can: {
    update: boolean;
  };
}>();

const page: any = usePage();

const form = useForm<{name: string; overviewText: string; overview: any | null}>(() => {
  if (page.props.features.visualEditorForAssignmentOverview) {
    return {
      name: props.assignment.name,
      overviewText: '',
      overview: props.overview?.state || props.assignment.overviewText || null,
    };
  }
  return {
    name: props.assignment.name,
    overviewText: props.assignment.overviewText || '',
    overview: props.overview?.state || null,
  };
});

useLeavingFormConfirmation(form);

const errors = computed(() => form.errors as any);

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "labels": {
      "name": "Name",
      "overview": "Overview"
    }
  },
  "fr": {
    "labels": {
      "name": "Nom",
      "overview": "Description"
    }
  }
}
</i18n>
