<template>
  <s-modal
    v-model:open="internalOpen"
    :confirm="{
      label: 'Save',
      icon: 'floppy',
    }"
    :cancel="{
      label: 'Cancel',
      icon: 'close',
    }"
    @confirm="save"
    @cancel="close"
    width="xl"
  >
    <template #header="{close}">
      <div class="w-full px-4 md:px-5 lg:px-6 pb-3 pt-5 flex gap-2 items-start justify-between">
        <div class="flex-1 flex flex-col">
          <h1 class="text-sm leading-snug font-medium text-gray-600">Create DataDrivenTask</h1>
          <h2 class="text-xl leading-snug sm">
            {{ `Edit ${uiConfig.name}` }}
          </h2>
        </div>
        <div class="flex items-start">
          <s-badge
            v-if="variables && variables.length"
            size="lg"
            class="flex flex-wrap leading-none gap-[0.375em]"
          >
            <span class="font-bold opacity-60">Variables:</span>
            <div class="comma-separated oxford-comma">
              <span v-for="(variable, index) in variables" :key="index">
                {{ variable.name }}
              </span>
            </div>
          </s-badge>
          <s-badge v-else class="leading-none text-gray-400 italic">
            <span class="opacity-70">No Variables</span>
          </s-badge>
          <button
            @click="close"
            aria-label="Close"
            class="transition-opacity ease-out duration-150 opacity-50 hover:opacity-80 active:opacity-100 p-2 -mt-2 -mr-2"
          >
            <s-icon name="close" size="18" />
          </button>
        </div>
      </div>
    </template>
    <s-modal-content>
      <div class="flex flex-col gap-4 pb-1">
        <div class="flex w-full flex-wrap justify-between gap-x-4 gap-y-3">
          <s-input-field
            :id="`name-${uiConfig.uuid}`"
            label="Name"
            v-model="clonedUiConfig.name"
            class="flex-1 w-full"
          />
          <div class="flex-1 w-full max-w-[18rem]">
            <s-input-field
              :id="`part-${uiConfig.uuid}`"
              label="Part"
              v-model="clonedUiConfig.part"
            />
          </div>
        </div>
        <data-driven-component
          :task-id="taskId"
          :files="files"
          :component-module="import(`./ComponentForms/${uiConfig.type}.vue`)"
          :all-data="mergedData"
          :errors="mergedErrors"
          :variables
          @update:all-data="updateMergedData($event)"
        />
      </div>
    </s-modal-content>

    <template v-if="isDirty" #confirmClose="{close, cancel}">
      <s-modal
        :open="true"
        title="Unsaved Changes"
        message="You have unsaved changes. Are you sure you want to close this form?"
        :confirm="{
          label: 'Discard Changes',
          icon: 'trash-can',
          color: 'red',
        }"
        @confirm="
          () => {
            close();
            emit('close');
          }
        "
        @cancel="cancel"
      />
    </template>
  </s-modal>
</template>

<script setup lang="ts">
import SInputField from '../../../design-system/SInputField.vue';
import SBadge from '../../../design-system/SBadge.vue';
import DataDrivenComponent from '../../../components/tasks/DataDrivenComponent.vue';
import UiConfig = App.Entities.Tasks.Ui.UiConfig;
import TaskComponentGrader = App.Entities.Tasks.ComponentGraders.TaskComponentGrader;
import {computed, toRef, ref} from 'vue';
import {useCloned, useVModel} from '@vueuse/core';
import NumberVariableDto = App.DTOs.Tasks.Variables.NumberVariableDto;
import isEqual from 'lodash/isEqual';
import SModal from '../../../design-system/SModal.vue';
import SModalContent from '../../../design-system/SModalContent.vue';
import SIcon from '../../../design-system/SIcon.vue';
import TaskFileUploadDto = App.DTOs.Tasks.TaskFileUploadDto;

const props = defineProps<{
  taskId?: number;
  files: TaskFileUploadDto[];
  uiConfig: UiConfig;
  componentGrader?: TaskComponentGrader;
  errors?: Record<string, any>;
  variables?: NumberVariableDto[];
  open: boolean;
}>();

const emit = defineEmits(['close', 'update:uiConfig', 'update:componentGrader', 'update:open']);
const internalOpen = useVModel(props, 'open', emit);

const {cloned: clonedUiConfig} = useCloned(toRef(props, 'uiConfig'));
const {cloned: clonedComponentGrader} = useCloned(toRef(props, 'componentGrader'));

const mergedData = computed(() => ({
  ...clonedComponentGrader.value,
  ...clonedUiConfig.value,
}));

const mergedErrors = computed(() => ({
  ...props.errors?.componentGrader,
  ...props.errors?.uiConfig,
}));

const isDirty = computed(() => {
  const isUiDirty = !isEqual(props.uiConfig, clonedUiConfig.value);
  const isGraderDirty = !isEqual(props.componentGrader, clonedComponentGrader.value);
  return isUiDirty || isGraderDirty;
});

function updateMergedData(allData: Record<string, any>) {
  Object.entries(allData).forEach(([key, value]) => {
    if (clonedUiConfig.value.hasOwnProperty(key)) {
      clonedUiConfig.value[key as keyof UiConfig] = value;
    } else if (clonedComponentGrader.value?.hasOwnProperty(key)) {
      clonedComponentGrader.value[key as keyof TaskComponentGrader] = value;
    }
    emit(`update:${key}` as any, [value]);
  });
}

const save = () => {
  emit('update:uiConfig', clonedUiConfig.value);
  emit('update:componentGrader', clonedComponentGrader.value);
  emit('close');
};

const close = () => {
  emit('close');
};
</script>
