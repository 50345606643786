<template>
  <s-btn @click="open = !open">{{ open ? 'Close' : 'Open' }}</s-btn>

  <n-modal v-model:open="open">
    <template #default="{close}">
      <SCard heading="Basic Dialog">
        <template #actions>
          <s-btn color="secondary" @click="close">Close</s-btn>
        </template>
        This here is a non-modal dialog.
      </SCard>
    </template>
  </n-modal>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
import {ref} from 'vue';
import SCard from '../../../../design-system/SCard.vue';
import NModal from '../../../../components/SDialog.vue';

const open = ref(false);
</script>
