import {useDebounceFn, useEventBus} from '@vueuse/core';
import {Ref, ref} from 'vue';
import {Assignment} from '../types/entities/assignment';
import {Task} from '../types/entities/tasks';
import axios from 'axios';
import {useFlash} from './useFlash';
import {useI18n} from 'vue-i18n';

type ItemTaskCompletedDetails = {
  content: string;
  stepIndex?: number;
};

// Get the bus instance for subscribing to events
export const useSimEventBus = () => {
  // For now, we will only be using the event bus for the ItemTaskCompletedEvent.
  return useEventBus<ItemTaskCompletedDetails>('stemble:sim-event-bus');
};

export const useUserActivityLoggingForSimEvents = (
  assignment: Ref<Assignment>,
  task: Ref<Task>
) => {
  const {t} = useI18n({
    inheritLocale: true,
    useScope: 'local',
  });
  const eventBus = useSimEventBus();
  // Buffer to store events before sending
  const eventBuffer = ref<ItemTaskCompletedDetails[]>([]);
  const flash = useFlash();

  const storeEvents = (
    courseId: number,
    assignmentId: number,
    taskId: number,
    eventsToStore: ItemTaskCompletedDetails[]
  ) =>
    axios
      .post(
        route('api.v1.course.assignment.task.sim-activity-logs', {
          course: courseId,
          assignment: assignmentId,
          task: taskId,
        }),
        {
          itemTasks: eventsToStore,
        }
      )
      .then(() => {
        // Clear flash on success
        flash?.value?.dismiss('error');
      })
      .catch((err) => {
        // But flash it on error.
        flash?.value?.set('error', t('sims.failedToSaveActivityLogsError'));
      });

  // Create debounced function to send events
  const debouncedSendEvents = useDebounceFn(() => {
    if (eventBuffer.value.length > 0) {
      // Send buffered events and clear buffer
      storeEvents(assignment.value.course.id, assignment.value.id, task.value.id, [
        ...eventBuffer.value,
      ]);
      eventBuffer.value = [];
    }
  }, 2000); // Debounce for 2 seconds

  // Subscribe to the ItemTaskCompletedEvent
  eventBus.on((event) => {
    // Add event to buffer
    eventBuffer.value.push(event);
    // Trigger debounced send
    debouncedSendEvents();
  });
};
