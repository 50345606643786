import {SidebarItem} from '../components/sidebar/types';
import {route} from 'ziggy-js';

export const items: SidebarItem[] = [
  {
    type: 'link',
    label: 'Back to Dashboard',
    href: route('dashboard'),
    icon: 'view-dashboard',
  },
  {
    type: 'link',
    label: 'Admin',
    href: route('admin.index', {}, false),
    icon: 'cog',
  },
  {
    type: 'link',
    label: 'Analytics',
    href: route('admin.analytics.index', {}, false),
    icon: 'chart-line',
  },
  {
    type: 'link',
    label: 'Ai Analytics',
    href: route('admin.ai-analytics.index', {}, false),
    icon: 'shimmer',
  },
  {
    type: 'link',
    label: 'Feature Flags',
    href: route('admin.features.index', {}, false),
    icon: 'ab-testing',
  },
  {type: 'divider'},
  {
    type: 'link',
    label: 'Access Codes',
    href: route('admin.access-codes.index', {}, false),
    icon: 'key',
  },
  {
    type: 'link',
    label: 'Assignment',
    href: route('admin.assignments.index', {}, false),
    icon: 'book-edit',
  },
  {
    type: 'link',
    label: 'Courses',
    href: route('admin.courses.index', {}, false),
    icon: 'book-open-variant',
  },
  {
    type: 'link',
    label: 'Institutions / Schools',
    href: route('admin.institutions.index', {}, false),
    icon: 'school',
  },
  {
    type: 'link',
    label: 'Tasks',
    href: route('admin.tasks.index', {}, false),
    icon: 'clipboard-check',
  },
  {
    type: 'link',
    label: 'Users',
    href: route('admin.users.index', {}, false),
    icon: 'account-group',
  },
  {
    type: 'link',
    label: 'User Invitations',
    href: route('admin.user-invites.index', {}, false),
    icon: 'email-plus',
  },
  {
    type: 'link',
    label: 'Roll Calls',
    href: route('admin.roll-calls.index', {}, false),
    icon: 'calendar-check',
  },
  {type: 'divider'},
  {
    type: 'link',
    label: 'Act as User',
    href: route('admin.act-as.show', {}, false),
    icon: 'account-switch',
  },
  {type: 'divider'},
  {
    type: 'link',
    label: 'Laravel Telescope',
    href: '/telescope',
    icon: 'telescope',
    external: true,
  },
];
