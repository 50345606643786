<template>
  <Head :title="`${t('page.heading')} - ${assignment.name} - ${course.code}`"></Head>
  <s-page-block class="@container">
    <s-page-header :heading="t('page.heading')" class="mb-4">
      <template #actions>
        <s-btn
          icon="table-arrow-down"
          :href="route('courses.assignments.issued-task-states.download', getRouteParams())"
          :only="dataThatChanges"
          preserve-state
          external
          target="_blank"
          color="blue-light"
        >
          {{ t('exportTaskStates') }}
        </s-btn>
        <s-btn
          v-if="canViewAnalytics"
          :href="route('courses.assignments.analytics.grades', getRouteParams())"
          color="secondary"
          icon="poll"
        >
          {{ t('viewAnalytics') }}
        </s-btn>
        <s-btn
          icon="table-arrow-down"
          :href="route('courses.assignments.grades.download', getRouteParams())"
          :only="dataThatChanges"
          preserve-state
          external
          target="_blank"
          color="blue-light"
        >
          {{ t('exportGrades') }}
        </s-btn>
        <!-- TODO probably just want to show this if the assignment policy is to review tasks -->
        <s-btn
          v-if="assignment.reviewRequired"
          icon="pencil"
          :href="
            route('courses.assignments.marking.review.last', [props.course.id, props.assignment.id])
          "
          preserve-state
        >
          {{ t('goToLastReviewed') }}
        </s-btn>
      </template>
    </s-page-header>

    <s-search-section
      route-name="courses.assignments.students.index"
      :label="t('search.byNameOrEmail')"
      :paginatedResults="participants?.data.length || 0"
      :totalResults="participants?.total || 0"
      class="mb-3"
    >
      <template #filters>
        <s-select-field
          id="section"
          :label="t('section')"
          v-model="params.sectionId"
          @update:modelValue="submitSearch({sectionId: $event})"
        >
          <option value="">{{ t('allSections') }}</option>
          <option v-for="section in sections" :key="section.id" :value="`${section.id}`">
            {{ section.name }}
          </option>
        </s-select-field>
        <s-select-field
          id="progress"
          :label="t('studentProgress')"
          v-model="params.progress"
          @update:modelValue="submitSearch({progress: $event})"
        >
          <option value="">{{ t('all') }}</option>
          <option
            v-for="studentProgress in statusList"
            :key="studentProgress.key"
            :value="studentProgress.key"
          >
            {{ studentProgress.label }}
          </option>
        </s-select-field>
        <s-select-field
          v-if="assignment.reviewRequired"
          id="review"
          :label="t('reviewStatus')"
          v-model="params.review"
          @update:modelValue="submitSearch({review: $event})"
        >
          <option value="">{{ t('all') }}</option>
          <option
            v-for="reviewStatus in statusList"
            :key="reviewStatus.key"
            :value="reviewStatus.key"
          >
            {{ reviewStatus.label }}
          </option>
        </s-select-field>
      </template>
      <template #badges>
        <s-search-badge v-if="params.orderBy" @clear="setOrderBy('')">
          {{ t('table.orderedBy') }}
          <strong class="text-blue-600">{{ orderByLabel }},</strong>
          <strong class="text-blue-600">
            {{ params.orderByDirection === 'ASC' ? t('A-Z') : t('Z-A') }}
          </strong>
        </s-search-badge>
      </template>
    </s-search-section>

    <section class="stack vertical">
      <div class="w-[calc(100%_+_1.5rem)] overflow-auto -mx-3 p-3 -mt-3 mb-3">
        <table class="table">
          <thead class="">
            <tr class="">
              <th class="">
                <button @click="setOrderBy('lastName')" class="link-subtle flex items-center gap-1">
                  {{ t('lastName') }}
                  <table-sorting-icon
                    column="lastName"
                    :order-by="params.orderBy"
                    :order-by-direction="params.orderByDirection"
                    direction="ASC"
                  />
                </button>
              </th>
              <th class="">
                <button
                  @click="setOrderBy('firstName')"
                  class="link-subtle flex items-center gap-1"
                >
                  {{ t('firstName') }}
                  <table-sorting-icon
                    column="firstName"
                    :order-by="params.orderBy || ''"
                    :order-by-direction="params.orderByDirection"
                    :direction="params.orderByDirection"
                  />
                </button>
              </th>
              <th class="">
                <button @click="setOrderBy('email')" class="link-subtle flex items-center gap-1">
                  {{ t('email') }}
                  <table-sorting-icon
                    column="email"
                    :order-by="params.orderBy"
                    :order-by-direction="params.orderByDirection"
                    :direction="params.orderByDirection"
                  />
                </button>
              </th>
              <th class="">
                <button @click="setOrderBy('sections')" class="link-subtle flex items-center gap-1">
                  {{ t('section') }}
                  <table-sorting-icon
                    column="sections"
                    :order-by="params.orderBy"
                    :order-by-direction="params.orderByDirection"
                    :direction="params.orderByDirection"
                  />
                </button>
              </th>
              <th class="">{{ t('grade') }}</th>
              <th class="">
                <button
                  @click="setOrderBy('numTasksSubmitted')"
                  class="link-subtle flex items-center gap-1"
                >
                  {{ t('tableHeaders.submitted') }}
                  <table-sorting-icon
                    column="numTasksSubmitted"
                    :order-by="params.orderBy"
                    :order-by-direction="params.orderByDirection"
                    :direction="params.orderByDirection"
                  />
                </button>
              </th>
              <th v-if="assignment.reviewRequired" class="cell">
                <button
                  @click="setOrderBy('numReviewedGrades')"
                  class="link-subtle flex items-center gap-1"
                >
                  {{ t('tableHeaders.reviewed') }}
                  <table-sorting-icon
                    column="numReviewedGrades"
                    :order-by="params.orderBy"
                    :order-by-direction="params.orderByDirection"
                    :direction="params.orderByDirection"
                  />
                </button>
              </th>
            </tr>
          </thead>
          <tbody class="">
            <tr v-if="participants?.data.length < 1" class="italic text-gray-400">
              <td colspan="100%">{{ t('noResultsToDisplay') }}</td>
            </tr>

            <tr
              v-for="participant in participants.data"
              :key="participant.id"
              class="cursor-pointer"
            >
              <td class="">
                <s-link
                  :href="
                    route('courses.assignments.marking.tasks.first', {
                      course: course.id,
                      assignment: assignment.id,
                      user: participant.id,
                      _query: {sectionFilter: params.sectionId},
                    })
                  "
                  class="link"
                >
                  {{ participant.lastName }}
                </s-link>
              </td>
              <td class="">
                <s-link
                  :href="
                    route('courses.assignments.marking.tasks.first', {
                      course: course.id,
                      assignment: assignment.id,
                      user: participant.id,
                      _query: {sectionFilter: params.sectionId},
                    })
                  "
                  class="link"
                >
                  {{ participant.firstName }}
                </s-link>
              </td>
              <td class="min-w-0" @click="navigateToFirstTaskForStudent($event, participant.id)">
                {{ participant.email }}
              </td>
              <td
                class="whitespace-nowrap"
                @click="navigateToFirstTaskForStudent($event, participant.id)"
              >
                {{ participant.sections }}
              </td>
              <td class="">
                <s-link
                  class="z-100"
                  :href="
                    participant.grade?.id
                      ? route('assignment_grades.adjustments.create', {
                          grade: participant.grade?.id,
                        })
                      : route('courses.assignments.marking.tasks.first', {
                          course: course.id,
                          assignment: assignment.id,
                          user: participant.id,
                          _query: {sectionFilter: params.sectionId},
                        })
                  "
                  :disabled="!participant.grade?.id"
                >
                  <span class="tracking-wide flex items-center gap-1">
                    <s-grade
                      :grade="participant.grade"
                      :grade-display="course.gradeDisplay"
                      tooltip
                    ></s-grade>
                    <s-icon
                      v-if="participant.grade"
                      class="font-bold text-blue-500 tracking-wide flex items-center gap-1"
                      name="pencil"
                      size="14"
                    />
                  </span>
                </s-link>
              </td>
              <td class="" @click="navigateToFirstTaskForStudent($event, participant.id)">
                <div class="flex justify-start -my-1.5">
                  <s-badge
                    :color="progressStatus(participant.numTasksSubmitted, totalTasks)"
                    class="leading-tight"
                  >
                    <span :class="participant.numTasksSubmitted! < 1 && 'text-gray-400'">
                      {{ participant.numTasksSubmitted ?? 0 }} / {{ totalTasks ?? 0 }}
                    </span>
                  </s-badge>
                </div>
              </td>
              <td v-if="assignment.reviewRequired" class="">
                <div class="flex justify-start -my-1.5">
                  <s-badge
                    :color="progressStatus(participant.numReviewedGrades, numTasksToReview)"
                    class="leading-tight"
                  >
                    <span :class="participant.numReviewedGrades! < 1 && 'text-gray-400'">
                      {{ participant.numReviewedGrades ?? 0 }} / {{ numTasksToReview ?? 0 }}
                    </span>
                  </s-badge>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <s-paginator :paginator="participants" :only="dataThatChanges" preserve-state />
    </section>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head, router, usePage} from '@inertiajs/vue3';
import SLink from '../../components/SLink.vue';
import {route} from 'ziggy-js';
import {computed, reactive, ref} from 'vue';
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SSelectField from '../../design-system/SSelectField.vue';
import SSearchSection from '../../design-system/SSearchSection.vue';
import SPaginator from '../../design-system/SPaginator.vue';
import TableSortingIcon from '../../design-system/TableSortingIcon.vue';
import SGrade from '../../components/SGrade.vue';
import SSearchBadge from '../../design-system/SSearchBadge.vue';
import SBadge from '../../design-system/SBadge.vue';
import {LaravelPaginator} from '../../types/laravel-paginator';
import SBtn from '../../design-system/SBtn.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {usePageParams} from '../../composables/usePageParams';
import AssignmentParticipantDto = App.DTOs.AssignmentParticipantDto;
import {useI18n} from 'vue-i18n';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import SIcon from '../../design-system/SIcon.vue';

type PageParams = {
  orderByDirection?: 'ASC' | 'DESC';
  orderBy?: string;
  search?: string;
  sectionId?: string;
  roleId?: string;
  review: string;
  progress: string;
};

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  sections: {id: string; name: string}[];
  participants: LaravelPaginator<AssignmentParticipantDto>;
  totalTasks: number;
  numTasksToReview: number;
  canViewAnalytics: boolean;
}>();

const params = usePageParams<PageParams>({sectionId: '', review: '', progress: ''});

const {t} = useI18n();

useBreadcrumbs([
  {
    label: props.course.code,
    href: route('courses.show', props.course.id),
  },
  {
    label: t('assignments'),
    href: route('courses.assignments.index', {course: props.course.id}),
  },
  {
    label: props.assignment.name,
    href: route('courses.assignments.show', [props.course.id, props.assignment.id]),
  },
]);

const orderByLabel = computed(() => {
  return {
    lastName: t('lastName'),
    firstName: t('firstName'),
    email: t('email'),
    sections: t('section'),
    numTasksSubmitted: t('submitted'),
    numReviewedGrades: t('reviewed'),
  }[params.orderBy ?? 'lastName'];
});

const setOrderBy = (orderBy: string = '') => {
  let orderByDirection: 'ASC' | 'DESC' = 'ASC';

  if (orderBy === params.orderBy) {
    orderByDirection = params.orderByDirection === 'ASC' ? 'DESC' : 'ASC';
  }

  submitSearch({orderBy, orderByDirection});
};

const submitSearch = (params: Partial<PageParams>) => {
  router.visit(
    route('courses.assignments.students.index', {
      ...getRouteParams(),
      ...params,
    }),
    {
      only: dataThatChanges,
      preserveState: true,
    }
  );
};

const navigateToFirstTaskForStudent = (event: MouseEvent, studentId: number) => {
  const routeToVisit = route('courses.assignments.marking.tasks.first', [
    props.course.id,
    props.assignment.id,
    studentId,
  ]);

  // Open in new tab if ctrl or cmd key is pressed
  if (event.ctrlKey || event.metaKey) {
    window.open(routeToVisit, '_blank');
  } else {
    // Otherwise, just use the router to click through.
    router.visit(routeToVisit);
  }
};

const getRouteParams = () => {
  return {
    course: props.course.id,
    assignment: props.assignment.id,
    ...params,
  };
};

const dataThatChanges = ['participants'];

const statusList = [
  {key: 'notStarted', label: t('notStarted')},
  {key: 'inProgress', label: t('inProgress')},
  {key: 'complete', label: t('complete')},
];

type MaybeMissingNumber = number | null | undefined;
const progressStatus = (num: MaybeMissingNumber, total: MaybeMissingNumber) => {
  num = num ?? 0;
  total = total ?? 0;

  if (num === 0) {
    return 'gray';
  }
  if (num >= total) {
    return 'green';
  }
  return 'blue';
};
</script>
<style scss>
.filter-pill {
  @apply flex items-center gap-2 leading-tight border rounded-full px-4 py-1.5 mb-3;
}

.filter-pill:not(.white) {
  @apply bg-gray-150 border-gray-300/50 text-gray-800;
}

.filter-pill.white {
  @apply bg-white border-gray-300/50 text-gray-800;
}

.filter-pill-button {
  @apply text-blue-500 w-fit inline-block opacity-70 hover:opacity-100 py-1 px-1.5 -my-1 -ml-1 -mr-2.5 transition-opacity ease-out duration-100;
}
</style>
<i18n>
{
  "en": {
    "page.heading": "Student Grades",
    "tableHeaders": {
      "submitted": "Submitted",
      "reviewed": "Reviewed"
    },
    "grades": "Grades",
    "reviewStatus": "Review Status",
    "viewAnalytics": "View Analytics",
    "exportGrades": "Export Grades",
    "exportTaskStates": "Export Task States",
    "goToLastReviewed": "Go to last reviewed task",
    "all": "All",
    "showingResults": "Showing results for",
    "displayingXofYResults": "Displaying {x} of {y} results.",
    "noResultsToDisplay": "No Results to Display",
    "notStarted": "Not Started",
    "inProgress": "In Progress",
    "complete": "Complete",
    "section": "Section",
    "studentProgress": "Student Progress",
    "submitted": "Submitted",
    "reviewed": "Reviewed",
    "clearOrdering": "Clear ordering"
  },
  "fr": {
    "page.heading": "Notes des étudiants",
    "tableHeaders": {
      "submitted": "Soumis",
      "reviewed": "Revu"
    },
    "grades": "Notes",
    "reviewStatus": "Statut de révision",
    "viewAnalytics": "Afficher des analyses",
    "exportGrades": "Exporter des notes",
    "exportTaskStates": "Exporter les états de tâche",
    "goToLastReviewed": "Aller à la dernière tâche revu",
    "all": "Tout",
    "showingResults": "Affichage des résultats pour",
    "displayingXofYResults": "Affichage de {x} sur {y} résultats.",
    "noResultsToDisplay": "Pas de résultats à afficher",
    "notStarted": "Non commencé",
    "inProgress": "En cours",
    "complete": "Terminé",
    "section": "Section",
    "studentProgress": "Progrès de l'étudiant",
    "submitted": "Soumis",
    "reviewed": "Révisé",
    "clearOrdering": "Effacer le tri"
  }
}
</i18n>
