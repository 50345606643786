<template>
  <div class="relative">
    <prosemirror-menu
      v-if="!readOnly"
      :items="menuView.items"
      @click="(item: MenuViewItem) => menuView.handleClick(item)"
      class="sticky top-0"
    />
    <ProsemirrorEditor
      v-model="doc"
      :class="!readOnly ? 'border border-t-0 rounded-bg bg-white px-3.5 py-2.5 shadow-inner' : ''"
      output="html"
      :schema="taskRichTextEditor"
      :plugins="plugins"
      :read-only="readOnly"
    />
  </div>
</template>
<script setup lang="ts">
import {MenuView, MenuViewItem} from '../prosemirror-editor/views/MenuView';
import ProsemirrorEditor from '../prosemirror-editor/ProsemirrorEditor.vue';
import ProsemirrorMenu from '../prosemirror-editor/components/ProsemirrorMenu.vue';
import {VueLinkMenuView} from '../prosemirror-editor/decorations/LinkDecorations';
import {taskRichTextEditor} from './schema/task-rich-text-editor';
import {VueNodeViewPlugin} from '../prosemirror-editor/plugins/VueNodeViewPlugin';
import VariableNode from './views/VariableNode.vue';
import NumberVariableDto = App.DTOs.Tasks.Variables.NumberVariableDto;
import {TaskState} from '../../types/entities/tasks';

const {variables, taskState} = defineProps<{
  readOnly?: boolean;
  variables?: NumberVariableDto[];
  taskState?: TaskState | null;
}>();

const doc = defineModel<any>();

const menuView = new MenuView(taskRichTextEditor, []);
const linkMenu = new VueLinkMenuView();
const plugins = [
  menuView.toPlugin(),
  linkMenu.toPlugin(),
  new VueNodeViewPlugin('variable', VariableNode, {variables, taskState}),
];
</script>
