<template>
  <Head :title="`Admin - Users - ${user.email}`"></Head>
  <div class="page-block gap-5">
    <s-page-header :heading="`${user.firstName} ${user.lastName}`">
      <template #actions>
        <s-btn
          color="secondary"
          icon="calendar-text-outline"
          :href="route('admin.users.activity.index', {user: user.id})"
        >
          Activity Log
        </s-btn>
        <s-btn
          color="secondary"
          icon="account-switch"
          :href="route('admin.act-as.show', {email: user.email})"
        >
          Act As
        </s-btn>
        <s-btn color="blue-light" icon="pencil" :href="route('admin.users.edit', user.id)">
          Edit
        </s-btn>
      </template>
    </s-page-header>
    <div class="flex flex-col flex-wrap align-start gap-1">
      <div class="flex flex-wrap align-start gap-x-6">
        <s-label-value class="min-w-[6rem]" label="Stemble ID">{{ user.id }}</s-label-value>
        <s-label-value class="min-w-[6rem]" label="Email">
          <span
            v-if="isSupported"
            class="cursor-pointer group text-lg flex-1 min-w-0 min-h-0 overflow-hidden truncate flex gap-1 items-center text-blue-500 hover:text-blue-400 active:text-blue-600 transition-all ease-out duration-100"
            @click.stop="copy(user.email)"
          >
            <s-icon name="content-copy" size="16" />
            <span class="flex-1 min-w-0 relative">
              <span
                :class="copied ? 'opacity-0' : 'opacity-100'"
                class="flex-1 min-w-0 truncate underline underline-offset-2 decoration-blue-100 group-hover:decoration-blue-200 active:decoration-blue-300 decoration-2 font-medium tracking-slight transition-all ease-out duration-100"
              >
                {{ user.email }}
              </span>
              <span
                :class="copied ? 'opacity-50' : 'opacity-0'"
                class="absolute left-0 italic transition-all ease-out duration-100"
              >
                {{ $t('copyEmail.success') }}
              </span>
            </span>
          </span>
          <span
            v-else
            class="text-lg flex flex-1 min-w-0 min-h-0 overflow-hidden truncate items-center"
          >
            {{ user.email }}
          </span>
        </s-label-value>
        <s-label-value class="min-w-[6rem]" label="Phone">
          <span :class="!user.phoneNumber && 'opacity-30'">{{ user.phoneNumber || 'N/A' }}</span>
        </s-label-value>
      </div>
      <div class="flex flex-wrap align-start gap-x-6 gap-y-3">
        <s-label-value class="min-w-[6rem]" label="Student ID">
          <span :class="!user.studentId && 'opacity-30'">{{ user.studentId || 'N/A' }}</span>
        </s-label-value>
        <s-label-value class="min-w-[6rem]" label="Active">
          <span v-if="user.isActive">
            <strong class="text-green-600">Yes</strong>
            <span class="italic opacity-50">&ndash; This user has signed up.</span>
          </span>
          <span v-else>
            <strong class="text-red-600">No</strong>
            <span class="italic opacity-50">&ndash; This user has not signed up yet.</span>
          </span>
        </s-label-value>
        <s-label-value class="min-w-[6rem]" label="Institution">
          <span v-if="institution">
            {{ institution.name }}
          </span>
          <span v-else>Not set</span>
        </s-label-value>
      </div>
    </div>
    <div>
      <h2 class="heading lg mb-3">Courses</h2>
      <div class="opacity-50 italic" v-if="!courses.length">No courses to display.</div>
      <div v-else class="grid grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))] justify-start gap-4">
        <div class="card padded sm flex-1" v-for="course in courses" :key="course.id">
          <span class="font-medium font-xs leading-tight opacity-50">
            {{ course.institution.name }} - {{ course.code }}
          </span>
          <div
            v-if="
              userCourseRoles.find((userCourseRole) => {
                return userCourseRole.courseId == course.id;
              })?.isActive
            "
            class="flex items-center gap-1 text-green-600 font-medium"
          >
            <s-icon class="opacity-70 flex-none" name="check-circle" size="18" />
            {{ t('active') }}
          </div>
          <div v-else class="flex items-center gap-1 text-red-600 font-medium">
            <s-icon class="opacity-70 flex-none" name="close-circle" size="18" />
            {{ t('inactive') }}
          </div>
          <h3 class="text-xl mb-2">{{ course.name }}</h3>
          <div class="flex flex-wrap md:flex-nowrap items-center gap-x-1 leading-tight mb-3">
            <span class="flex items-center gap-1 font-medium font-xs opacity-50 whitespace-nowrap">
              <s-icon class="opacity-70" name="calendar-blank" width="18" height="18" />
              Start Date &ndash;
            </span>
            <span class="font-bold opacity-70 whitespace-nowrap">
              {{ monthDayYear(course.startDate) }}
            </span>
          </div>
          <div class="flex flex-wrap gap-3 justify-between">
            <div class="flex flex-wrap gap-3">
              <s-btn
                :href="route('courses.participants.show', [course.id, user.id])"
                size="sm"
                color="white"
                icon="account-multiple"
              >
                View roles in course
              </s-btn>
              <s-btn
                v-if="
                  userCourseRoles.find((userCourseRole) => {
                    return userCourseRole.courseId == course.id;
                  })?.isActive
                "
                @click="
                  router.put(route('admin.users.updateCourseRoles', [user.id, course.id]), {
                    isActive: false,
                  })
                "
                size="sm"
                color="red-light"
                icon="account-cancel"
              >
                Deactivate Roles
              </s-btn>
              <s-btn
                v-else
                @click="
                  router.put(route('admin.users.updateCourseRoles', [user.id, course.id]), {
                    isActive: true,
                  })
                "
                size="sm"
                color="green-light"
                icon="check"
              >
                Activate Roles
              </s-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 class="heading lg mb-3 mt-2">Payment Requests</h2>
      <div class="opacity-50 italic" v-if="!paymentRequests.length">
        No payment requests to display.
      </div>
      <div v-else class="flex flex-wrap justify-start gap-4">
        <div
          v-for="paymentRequest in paymentRequests"
          :key="paymentRequest.id"
          class="card padded w-auto flex-1 min-w-[14rem] md:min-w-[18rem]"
        >
          <div class="flex flex-wrap items-center mb-3 gap-3">
            <div class="flex flex-wrap font-medium text-lg leading-tight flex-1 md:min-w-[16rem]">
              <span class="opacity-50">
                {{ paymentRequest.course.institution.name }}&nbsp;&ndash;&nbsp;
              </span>
              <s-link :href="route('admin.courses.show', paymentRequest.course.id)" class="link">
                {{ paymentRequest.course.code }}
              </s-link>
            </div>
            <div class="flex items-end gap-2">
              <div v-if="!paymentRequest.paidOn">
                <s-btn
                  v-if="!paymentRequest.exemptedOn"
                  size="sm"
                  color="white"
                  icon="account-cancel"
                  @click="setStatus(paymentRequest, 'exempt')"
                >
                  {{ t('actions.setAsExempt') }}
                </s-btn>
                <s-btn
                  v-else
                  size="sm"
                  color="white"
                  icon="cash-clock"
                  @click="setStatus(paymentRequest, 'requested')"
                >
                  {{ t('actions.setAsRequested') }}
                </s-btn>
              </div>
              <div v-if="!paymentRequest.exemptedOn">
                <s-btn
                  v-if="paymentRequest?.paidOn || paymentRequest?.exemptedOn"
                  size="sm"
                  color="white"
                  icon="cash-remove"
                  @click="setStatus(paymentRequest, 'unpaid')"
                >
                  {{ t('actions.setAsUnpaid') }}
                </s-btn>
                <s-btn
                  v-else
                  size="sm"
                  color="primary"
                  icon="cash-check"
                  @click="setStatus(paymentRequest, 'paid')"
                >
                  {{ t('actions.setAsPaid') }}
                </s-btn>
              </div>

              <payment-request-status-badge :payment-request="paymentRequest" />
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 align-start gap-x-6 gap-y-2">
            <s-label-value label="Created On">
              <s-date :date="paymentRequest.createdAt" />
            </s-label-value>
            <s-label-value label="Due Date">
              <s-date :date="paymentRequest.dueDate || paymentRequest.course.paymentDueDate" />
            </s-label-value>
            <s-label-value label="Paid On">
              <s-date :date="paymentRequest.paidOn" />
            </s-label-value>
            <s-label-value label="Exempted On">
              <s-date :date="paymentRequest.exemptedOn" />
            </s-label-value>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 class="heading lg mb-3 mt-2">Stemble Invitations</h2>
      <div class="opacity-50 italic" v-if="!userInvitations.length">No invitations to display.</div>
      <div v-else class="flex flex-wrap items-start justify-start gap-4">
        <div
          v-for="invitation in userInvitations"
          :key="invitation.id"
          class="card padded w-auto grow-0 shrink min-w-[14rem] flex flex-col items-start"
        >
          <div class="flex flex-wrap align-start gap-x-6 gap-y-1 mb-3">
            <s-label-value label="Created">
              {{ monthDayYear(invitation.createdAt) }}
            </s-label-value>
            <s-label-value label="Expires">
              {{ monthDayYear(invitation.expiresAt) }}
            </s-label-value>
          </div>
          <s-link :href="route('admin.user-invites.show', invitation.id)" class="btn secondary md">
            View Invitation
          </s-link>
        </div>
      </div>
    </div>
    <section>
      <h2 class="heading lg">Features</h2>
      <table class="table">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Serving</th>
            <th>Override</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="feature in features" :key="feature.key">
            <td>{{ feature.label }}</td>
            <td>
              <span
                v-if="userFeatures[feature.key]"
                class="flex items-center gap-1 text-green-600 font-medium"
              >
                <s-icon name="check-circle" size="20" class="opacity-70" />
                Enabled
              </span>
              <span v-else class="flex items-center gap-1 text-red-600 font-medium">
                <s-icon name="close-circle" size="20" class="opacity-70" />
                Disabled
              </span>
            </td>
            <td class="flex gap-2">
              <s-select-field
                :id="feature.key"
                :model-value="getFeatureOverrideValue(feature.key)"
                @update:model-value="
                  router.put(route('admin.users.features.update', [user.id, feature.key]), {
                    value: Boolean(~~$event),
                  })
                "
              >
                <option :value="1">Enabled</option>
                <option :value="0">Disabled</option>
                <option v-if="!getFeatureOverride(feature.key)" value="default">–- None --</option>
              </s-select-field>
              <s-tooltip v-if="getFeatureOverride(feature.key)">
                <template #tooltip>
                  Delete override.
                  <br />
                  Reset to default value.
                </template>

                <s-btn
                  color="danger-white"
                  as="button"
                  :href="route('admin.users.features.destroy', [user.id, feature.key])"
                  method="delete"
                  class="w-fit"
                >
                  <s-icon name="trash-can" size="20" />
                </s-btn>
              </s-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section>
      <h2 class="heading lg mb-3">Danger Zone</h2>
      <div>
        <s-btn
          v-if="!user.isSuperUser"
          color="danger"
          @click.prevent="router.post(route('admin.users.super-user-access.store', [user.id]))"
          icon="shield-check"
        >
          <strong>GRANT</strong>
          Super User Access
        </s-btn>
        <s-btn
          v-else
          color="danger-white"
          @click.prevent="router.delete(route('admin.users.super-user-access.destroy', [user.id]))"
          icon="shield-off"
        >
          <strong>REVOKE</strong>
          Super User Access
        </s-btn>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import {useClipboard} from '@vueuse/core';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SLink from '../../../components/SLink.vue';
import {User} from '../../../types/entities/user';
import {PaymentRequest} from '../../../types/entities/payment-request';
import PaymentRequestStatusBadge from '../../../components/payment-requests/PaymentRequestStatusBadge.vue';
import {Course} from '../../../types/entities/course';
import {route} from 'ziggy-js';
import {UserInvitation} from '../../../types/entities/user-invitation';
import {monthDayYear} from '../../../format/dates';
import {Head, router} from '@inertiajs/vue3';
import SBtn from '../../../design-system/SBtn.vue';
import FeatureData = App.DTOs.FeatureData;
import SIcon from '../../../design-system/SIcon.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import STooltip from '../../../design-system/STooltip.vue';
import {useI18n} from 'vue-i18n';
import {Institution} from '../../../types/entities/institution';
import SDate from '../../../design-system/SDate.vue';

type PaymentRequestWithCourse = PaymentRequest & {course: Course};

const props = defineProps<{
  user: User & {isSuperUser: boolean};
  institution: Institution;
  courses: Course[];
  paymentRequests: PaymentRequestWithCourse[];
  userInvitations: UserInvitation[];
  features: FeatureData[];
  userFeatures: {
    [key: string]: string | boolean;
  };
  userFeatureOverrides: {
    name: string;
    value: boolean;
  }[];
  userCourseRoles: {
    courseId: number;
    isActive: boolean;
  }[];
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const {text, copy, copied, isSupported} = useClipboard();

const getFeatureOverride = (key: string) => {
  return props.userFeatureOverrides.find((o) => o.name === key);
};

const getFeatureOverrideValue = (featureKey: string) => {
  const override = getFeatureOverride(featureKey);

  if (override) {
    return override.value ? 1 : 0;
  }

  return 'default';
};

function setStatus(paymentRequest: PaymentRequest, status: string) {
  if (
    window.confirm(
      t('actions.confirm-action', {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        action: status,
      })
    )
  ) {
    router.put(route('admin.paymentRequests.update', {paymentRequest: paymentRequest.id}), {
      status,
    });
  }
}
</script>
<i18n>
{
  "en": {
    "actions": {
      "setAsPaid": "Set as paid",
      "setAsUnpaid": "Set as unpaid",
      "setAsExempt": "Set as exempt of payment",
      "setAsRequested": "Set payment as requested",
      "confirm-action": "Are you sure you want to set the payment request as {action} for {firstName} {lastName}"
    }
  }
}
</i18n>
