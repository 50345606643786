<template>
  <Head :title="t('page.title', {assignment: assignment.name})"></Head>
  <edit-assignment-header
    :course="course"
    :assignment="assignment"
    :can="{
      update: can.updateAssignment,
    }"
  />
  <form @submit.prevent="updateAssignmentTasks()" class="flex-1 flex flex-col">
    <s-page-block size="lg" class="flex-1">
      <div class="flex flex-wrap gap-3 items-end justify-between mb-2">
        <s-badge-group>
          <s-badge>
            <s-icon name="clipboard-edit-outline" size="18" class="opacity-70" />
            <strong class="!font-black">{{ form.assignmentTasks.length }}</strong>
            {{ t('task', form.assignmentTasks.length) }}
          </s-badge>
          <s-badge>
            <s-icon name="trophy-outline" size="18" class="opacity-70" />
            <strong class="!font-black">
              {{ assignmentTotalPoints }}
            </strong>
            {{ t('form.totalPoints', assignmentTotalPoints) }}
          </s-badge>
        </s-badge-group>
        <div class="flex flex-wrap flex-grow justify-end gap-x-2 gap-y-3">
          <s-btn
            type="button"
            @click="
              router.visit(
                route('web.courses.assignments.tasks.first', {
                  course: route().params.course,
                  assignment: props.assignment.id,
                })
              )
            "
            color="light"
            icon="magnify"
          >
            {{ t('actions.preview') }}
          </s-btn>
          <s-btn type="button" color="light" icon="plus" @click="addPooledTask()">
            {{ t('actions.addTaskPool') }}
          </s-btn>

          <s-btn type="button" @click.prevent="openTaskModal()" color="blue-light" icon="plus">
            {{ t('actions.addTasks') }}
          </s-btn>
        </div>
      </div>
      <div
        class="w-[calc(100%_+_2.5rem)] lg:w-[calc(100%_+_3.5rem)] overflow-auto -my-3 -mx-5 lg:-mx-7 py-3 px-5 lg:px-7"
      >
        <table class="table dense min-w-full max-w-full h-px">
          <thead class="">
            <tr>
              <th class="text-left whitespace-nowrap w-0">
                {{ t('table.order') }}
              </th>
              <th class="text-left whitespace-nowrap min-w-56">
                <s-tooltip>
                  <template #tooltip>
                    {{ t('table.previewTooltip') }}
                  </template>
                  <span class="flex items-center gap-1">
                    {{ t('table.title') }}
                    <s-icon name="help-circle" size="18" class="opacity-70 text-blue-400" />
                  </span>
                </s-tooltip>
              </th>
              <th class="text-left">
                {{ t('table.type') }}
              </th>
              <th class="text-left !pr-5 w-0">
                <s-tooltip>
                  <template #tooltip>
                    {{ t('table.reviewTooltip') }}
                  </template>
                  <span class="flex items-center gap-1">
                    {{ t('table.review') }}
                    <s-icon name="help-circle" size="18" class="opacity-70 text-blue-400" />
                  </span>
                </s-tooltip>
              </th>
              <th class="text-left">
                <s-tooltip>
                  <template #tooltip>
                    {{ t('table.maxAttemptsTooltip') }}
                  </template>
                  {{ t('table.maxAttempts') }}
                </s-tooltip>
              </th>
              <th class="text-left">
                {{ t('table.points') }}
              </th>
              <th class="w-0"></th>
            </tr>
          </thead>
          <tbody ref="listWrapperRef" class="rounded-lg shadow">
            <tr v-if="form.assignmentTasks?.length < 1">
              <td colspan="7" class="!text-center !py-3 !bg-gray-50 italic text-gray-400">
                {{ t('page.noTasks') }}
              </td>
            </tr>

            <tr
              v-for="(taskAssignment, index) in form.assignmentTasks"
              :key="taskAssignment.id ?? taskAssignment.tempKey"
            >
              <td class="w-0 !pl-2">
                <button
                  type="button"
                  class="handle text-gray-600 hover:text-blue-500 flex-none flex items-center justify-start gap-1 cursor-grab group/handle py-1 pl-1 pr-2 transition-all ease-out duration-150"
                >
                  <span
                    class="flex-none opacity-30 group-hover/handle:opacity-100 grid grid-cols-1 grid-rows-1"
                  >
                    <span
                      class="transition-all ease-out duration-150 group-hover/handle:opacity-0 row-start-1 col-start-1 grid grid-cols-1 grid-rows-1"
                    >
                      <s-icon
                        name="dots-vertical"
                        size="20"
                        class="-translate-x-[0.1875rem] row-start-1 col-start-1"
                      />
                      <s-icon
                        name="dots-vertical"
                        size="20"
                        class="translate-x-[0.1875rem] row-start-1 col-start-1"
                      />
                    </span>
                    <s-icon
                      name="unfold-more-horizontal"
                      size="20"
                      class="text-blue-500 row-start-1 col-start-1 transition-all ease-out duration-150 opacity-0 group-hover/handle:opacity-100"
                    />
                  </span>
                  <span class="font-medium">{{ index + 1 }}</span>
                </button>
              </td>

              <template
                v-if="taskAssignment.usePoolingUi || taskAssignment.taskPool.tasks.length > 1"
              >
                <td colspan="2" class="!pr-0">
                  <s-placeholder
                    v-if="taskAssignment.taskPool.tasks.length === 0"
                    class="!py-1 font"
                  >
                    <span class="text-sm">{{ t('page.noTasks') }}</span>
                    <s-tooltip
                      v-if="hasError('taskPool.taskIds', index)"
                      :text="getErrorMessage('taskPool.taskIds', index)"
                    >
                      <div
                        role="alert"
                        class="flex items-center justify-start gap-1 mt-0.5 text-sm font-medium leading-tight text-red-600"
                      >
                        <s-icon name="warning" size="16" class="opacity-70" />
                        <span class="sr-only">
                          {{ getErrorMessage('taskPool.taskIds', index) }}
                        </span>
                      </div>
                    </s-tooltip>
                  </s-placeholder>
                  <table v-else class="w-full task-pool-table">
                    <tbody>
                      <tr v-for="task in taskAssignment.taskPool.tasks" :key="task.id">
                        <td class="text-left">
                          <button
                            type="button"
                            @click.prevent="openTaskPreview(task, taskAssignment)"
                            class="text-gray-600 hover:text-blue-500 transition-color ease-out duration-150"
                          >
                            <s-latex :content="task.title" />
                          </button>
                        </td>
                        <td class="text-left xl:w-[16.5rem]">
                          <display-task-types :taskTypes="task.taskTypes">
                            <task-badge
                              v-if="task.scoped"
                              color="blue-light"
                              :label="t('customMadeTask')"
                              icon="flask-outline"
                            />
                            <task-badge
                              v-if="task.isAiGraded"
                              color="green"
                              :label="t('aiGraded')"
                              icon="auto-fix"
                            />
                          </display-task-types>
                        </td>
                        <td class="text-left h-full w-[3.5rem] !p-0 align-stretch">
                          <button
                            id="delete"
                            type="button"
                            @click.prevent="tryToRemoveTask(taskAssignment, task)"
                            class="inline-table h-full w-full py-3 pl-3.5 pr-4 text-red-500/60 hover:text-red-500 hover:bg-gray-300/15 transition border-l border-gray-100"
                            :aria-label="t('form.removeTask')"
                          >
                            <s-icon name="trash-can-outline" size="20" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="task-pool-add-row flex justify-center pt-2">
                    <s-btn
                      type="button"
                      class="task-pool-add-button"
                      color="light"
                      icon="plus"
                      size="sm"
                      @click.prevent="openTaskModal(taskAssignment)"
                    >
                      {{ t('actions.addTasksToPool') }}
                    </s-btn>
                  </div>
                </td>
                <td class="h-full w-0 !px-0">
                  <span class="flex items-center justify-center">
                    <s-checkbox
                      :id="`review-${taskAssignment.taskPool.tasks[0]?.id}`"
                      v-model="taskAssignment.reviewRequired"
                      color="green"
                    />
                  </span>
                </td>
              </template>

              <template v-else>
                <td class="text-left w-full xl:w-auto">
                  <button
                    type="button"
                    @click.prevent="
                      openTaskPreview(getFirstTaskFromPool(taskAssignment), taskAssignment)
                    "
                    class="text-gray-600 hover:text-blue-500 transition-color ease-out duration-150"
                  >
                    <s-latex :content="getFirstTaskFromPool(taskAssignment).title" />
                  </button>
                </td>
                <td class="text-left w-0 xl:w-[20rem]">
                  <display-task-types :taskTypes="getFirstTaskFromPool(taskAssignment).taskTypes">
                    <task-badge
                      v-if="getFirstTaskFromPool(taskAssignment).scoped"
                      color="blue-light"
                      :label="t('customMadeTask')"
                      icon="flask-outline"
                    />
                    <task-badge
                      v-if="getFirstTaskFromPool(taskAssignment).isAiGraded"
                      color="green"
                      :label="t('aiGraded')"
                      icon="auto-fix"
                    />
                  </display-task-types>
                </td>
                <td class="h-full w-0 !px-0">
                  <span class="flex items-center justify-center">
                    <s-checkbox
                      :id="`review-${getFirstTaskFromPool(taskAssignment).id}`"
                      v-model="taskAssignment.reviewRequired"
                      color="green"
                    />
                  </span>
                </td>
              </template>

              <td class="text-left w-0">
                <s-inline-input-field
                  id="maxAttempts"
                  v-model.number="taskAssignment.maxAttempts"
                  size="sm"
                  class="w-20"
                  type="number"
                  placeholder="∞"
                  min="1"
                  :error="getErrorMessage('maxAttempts', index)"
                ></s-inline-input-field>
              </td>
              <td class="text-left w-0">
                <s-inline-input-field
                  id="pointsInput"
                  v-model.number="taskAssignment.pointValue"
                  size="sm"
                  class="w-20"
                  type="number"
                  min="0"
                  :error="getErrorMessage('pointValue', index)"
                ></s-inline-input-field>
              </td>

              <td class="text-left h-full w-0 !p-0 align-stretch">
                <button
                  id="delete"
                  type="button"
                  @click.prevent="tryToRemoveTask(taskAssignment)"
                  class="inline-table h-full w-full py-3 pl-3.5 pr-4 text-red-500/60 hover:text-red-500 hover:bg-gray-300/15 transition border-l border-gray-100"
                  :aria-label="t('form.removeTask')"
                >
                  <s-icon name="trash-can-outline" size="20" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </s-page-block>
    <s-form-footer
      :cancelRoute="
        route('courses.assignments.show', {
          course: course.id,
          assignment: assignment.id,
        })
      "
      :formProcessing="form.processing"
      :formIsDirty="form.isDirty"
    />
  </form>
  <create-task-assignment-drawer
    v-model:visible="showTaskModal"
    :form="form"
    :assignment-tasks="assignmentTasks"
    :types="types"
    :assignment="assignment"
    :modifying-task-assignment="modifyingTaskAssignment"
  />

  <preview-task-modal :task="taskToPreview" @close="taskToPreview = null">
    <div class="grid grid-cols-2">
      <s-btn
        type="button"
        :disabled="!hasPreviousTask"
        @click="previousTask()"
        color="blue-light"
        icon="chevron-left"
        class="!rounded-r-none"
      >
        {{ t('actions.previous') }}
      </s-btn>
      <s-btn
        type="button"
        :disabled="!hasNextTask"
        @click="nextTask()"
        color="blue-light"
        icon="chevron-right"
        class="!rounded-l-none !border-l-0"
        iconEnd
      >
        {{ t('actions.next') }}
      </s-btn>
    </div>
    <s-btn
      v-if="taskAssignmentToPreview"
      type="button"
      @click="tryToRemoveTask(taskAssignmentToPreview, taskToPreview)"
      color="red"
      icon="trash-can"
    >
      {{ t('actions.remove') }}
    </s-btn>
  </preview-task-modal>

  <s-modal
    v-if="taskAssignmentToTryAndRemove"
    v-model:open="showRemoveConfirmationModal"
    :title="t('form.removeConfirmationTitle')"
    :confirm="{
      label: t('actions.remove'),
      icon: 'trash-can',
      color: 'red',
    }"
    @confirm="tryToRemoveTask(taskAssignmentToTryAndRemove, taskToTryAndRemove)"
    @close="showRemoveConfirmationModal = false"
    cancellable
  >
    <template #content>
      <p>
        <i18n-t keypath="form.removeConfirmation"></i18n-t>
      </p>
    </template>
  </s-modal>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue';
import {Head, useForm, router} from '@inertiajs/vue3';
import {useSortable} from '@vueuse/integrations/useSortable';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import SIcon from '../../design-system/SIcon.vue';
import SBtn from '../../design-system/SBtn.vue';
import SPlaceholder from '../../design-system/SPlaceholder.vue';
import SBadge from '../../design-system/SBadge.vue';
import SBadgeGroup from '../../design-system/SBadgeGroup.vue';
import STooltip from '../../design-system/STooltip.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SModal from '../../design-system/SModal.vue';
import SFormFooter from '../../design-system/SFormFooter.vue';
import SLatex from '../../components/SLatex.vue';
import CreateTaskAssignmentDrawer from './Components/CreateTaskAssignmentDrawer.vue';
import DisplayTaskTypes from './Components/DisplayTaskTypes.vue';
import PreviewTaskModal from './Components/PreviewTaskModal.vue';
import TaskBadge from './Components/TaskBadge.vue';
import TaskTypeDto = App.DTOs.Tasks.TaskTypeDto;
import SCheckbox from '../../design-system/SCheckbox.vue';
import SInlineInputField from '../../design-system/SInlineInputField.vue';
import assignmentTasksTranslations from './assignmentTasks.json';
import EditAssignmentHeader from '../../layouts/EditAssignmentHeader.vue';
import TaskIndexDto = App.DTOs.Tasks.TaskIndexDto;
import {CreateTaskAssignmentDto, taskAssignmentFactory} from './task-assignment-factory';
import {useFlash} from '../../composables/useFlash';
import {useLeavingFormConfirmation} from '../../composables/useLeavingFormConfirmation';

const flash = useFlash();
const props = defineProps<{
  assignmentTasks: CreateTaskAssignmentDto[];
  assignment: Assignment;
  course: Course;
  types: TaskTypeDto[];
  can: {
    updateAssignment: boolean;
  };
}>();

const listWrapperRef = ref<HTMLElement | null>(null);
const assignmentTotalPoints = computed(() => {
  return form.assignmentTasks.reduce((total, taskAssignment) => {
    return total + (taskAssignment.pointValue || 0);
  }, 0);
});
const modifyingTaskAssignment = ref<CreateTaskAssignmentDto | null>(null);

const taskAssignmentToTryAndRemove = ref<CreateTaskAssignmentDto | null>(null);
const taskToTryAndRemove = ref<TaskIndexDto | null>(null);

const showRemoveConfirmationModal = ref<boolean>(false);

const taskToPreview = ref<TaskIndexDto | null>(null);
const taskAssignmentToPreview = ref<CreateTaskAssignmentDto | null>(null);

const form = useForm({
  assignmentTasks: props.assignmentTasks,
});

// Add form leaving confirmation
useLeavingFormConfirmation(form);

useSortable(listWrapperRef, () => form.assignmentTasks, {
  handle: '.handle',
  animation: 150,
  ghostClass: 'sortable-ghost',
  dragClass: 'sortable-drag',
});

const showTaskModal = ref<boolean>(false);

const addPooledTask = () => {
  const taskAssignment = taskAssignmentFactory();
  taskAssignment.usePoolingUi = true;
  form.assignmentTasks.push(taskAssignment);
};

const openTaskModal = (taskAssignment?: CreateTaskAssignmentDto) => {
  modifyingTaskAssignment.value = taskAssignment ?? null;
  showTaskModal.value = true;
};

function removeTaskAssignment(taskAssignment: CreateTaskAssignmentDto) {
  form.assignmentTasks = form.assignmentTasks.filter((item) => item !== taskAssignment);
}

function removeTaskFromPool(taskAssignment: CreateTaskAssignmentDto, task: TaskIndexDto) {
  taskAssignment.taskPool.taskIds = taskAssignment.taskPool.taskIds.filter(
    (item) => item !== task.id
  );
  taskAssignment.taskPool.tasks = taskAssignment.taskPool.tasks.filter((item) => item !== task);
}

function tryToRemoveTask(taskAssignment: CreateTaskAssignmentDto, task?: TaskIndexDto | null) {
  if (!showRemoveConfirmationModal.value) {
    showRemoveConfirmationModal.value = true;
    taskAssignmentToTryAndRemove.value = taskAssignment;
    taskToTryAndRemove.value = task ?? null;
  } else {
    if (taskToTryAndRemove.value && taskAssignment.usePoolingUi) {
      removeTaskFromPool(taskAssignment, taskToTryAndRemove.value);
    } else {
      removeTaskAssignment(taskAssignment);
    }

    showRemoveConfirmationModal.value = false;
  }
}

function updateAssignmentTasks() {
  flash?.value.dismiss('success');
  flash?.value.dismiss('error');

  form.post(
    route('courses.assignments.tasks.store', {
      course: props.course.id,
      assignment: props.assignment.id,
    }),
    {
      onError: (errors) => {
        flash?.value?.set('error', t('errors.tasksNotSaved'));
      },
    }
  );
}

const getFirstTaskFromPool = (taskAssignment: CreateTaskAssignmentDto): TaskIndexDto => {
  return taskAssignment.taskPool.tasks[0];
};

const getErrorMessage = (key: string, index: number): string => {
  const errors = form.errors as any;
  return errors[`assignmentTasks.${index}.${key}`] ?? '';
};

const hasError = (key: string, index: number) => {
  return !!getErrorMessage(key, index);
};

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
  messages: assignmentTasksTranslations,
});

const allTasks = computed(() => {
  return form.assignmentTasks.flatMap((taskAssignment) => taskAssignment.taskPool.tasks);
});

const getCurrentTaskIndex = computed(() => {
  return allTasks.value.findIndex((task) => task.id === taskToPreview.value?.id);
});

const hasPreviousTask = computed(() => {
  return allTasks.value.length && getCurrentTaskIndex.value > 0;
});

const hasNextTask = computed(() => {
  return allTasks.value.length && getCurrentTaskIndex.value < allTasks.value.length - 1;
});

function nextTask() {
  if (hasNextTask.value) {
    taskToPreview.value = allTasks.value[getCurrentTaskIndex.value + 1] || null;
  }
}

function previousTask() {
  if (hasPreviousTask.value) {
    taskToPreview.value = allTasks.value[getCurrentTaskIndex.value - 1] || null;
  }
}

function openTaskPreview(task: TaskIndexDto, taskAssignment: CreateTaskAssignmentDto) {
  taskAssignmentToPreview.value === taskAssignment
    ? (taskAssignmentToPreview.value = null)
    : (taskAssignmentToPreview.value = taskAssignment);
  taskToPreview.value === task ? (taskToPreview.value = null) : (taskToPreview.value = task);
}
</script>

<style>
.table .task-pool-table {
  tbody {
    tr {
      td {
        @apply first:pl-4;
      }

      .table-add-row-cell {
        @apply !p-0;
      }

      .table-add-row-button {
        @apply rounded-b w-full py-2 px-3.5 text-blue-500/70 bg-gradient-to-b from-gray-100 to-gray-50 hover:text-blue-500 hover:bg-gray-300/15 transition border-l border-gray-100;
      }
    }

    tr:not(:first-child) {
      td {
        @apply !border-t border-gray-200;
      }
    }
  }
}

.sortable-ghost {
  @apply bg-gray-50 border border-gray-200 rounded-lg shadow-sm;
}

.sortable-drag {
  @apply bg-white border border-gray-200 rounded-lg shadow-md;
}
</style>

<i18n>
{
  "en": {
    "aiGraded": "AI Graded",
    "customMadeTask": "Custom",
    "actions.addTasksToPool": "Add Tasks to Pool",
    "actions.addTaskPool": "Add Task Pool",
    "table.maxAttempts": "Attempts",
    "table.maxAttemptsTooltip": "Maximum student attempts; additional attempts may be granted. Leave blank for unlimited.",
    "table.previewTooltip": "Click to preview the task",
    "errors": {
      "tasksNotSaved": "Changes were not saved."
    }
  },
  "fr": {
    "aiGraded": "Noté par IA",
    "customMadeTask": "Personnalisée",
    "actions.addTasksToPool": "Ajouter des tâches à la banque de tâches",
    "actions.addTaskPool": "Ajouter une banque de tâches",
    "table.maxAttempts": "Tentatives",
    "table.maxAttemptsTooltip": "Nombre maximum de tentatives par étudiant; des tentatives supplémentaires peuvent être accordées. Laissez vide pour illimité.",
    "table.previewTooltip": "Cliquer pour prévisualiser la tâche",
    "errors": {
      "tasksNotSaved": "Les modifications n'ont pas été enregistrées."
    }
  }
}
</i18n>
