import {Command, Plugin, PluginView} from 'prosemirror-state';
import {reactive, Reactive} from 'vue';
import {EditorView} from 'prosemirror-view';
import {toggleMark} from 'prosemirror-commands';
import {Schema} from 'prosemirror-model';
import {isHeading} from '../utils/isHeading';
import {isMarked} from '../utils/isMarked';
import {toggleBlockType} from '../commands/toggleBlockType';
import {isLinkSelected} from '../utils/link-utils';
import {insertImage} from '../commands/insertImage';
import {toggleList} from '../commands/toggleList';
import {inList} from '../queries/inList';

export type MenuViewItem = {
  icon: string;
  label: string;
  command: Command;
  available?: boolean;
  active?: boolean;
  checkActive?: (state: EditorView['state']) => boolean;
};

/**
 * -----------------------------------------------------------------------------
 * MenuView
 * -----------------------------------------------------------------------------
 *
 * This PluginView is responsible for managing a ref for the menu items
 * and updating their availability based on the current state of the editor.
 *
 */
export class MenuView implements PluginView {
  schema: Schema;
  items: Reactive<MenuViewItem[]>;
  editorView: EditorView | null = null;

  constructor(schema: Schema, items: MenuViewItem[]) {
    this.schema = schema;

    if (schema.nodes.heading) {
      items.push({
        label: 'H1',
        icon: '',
        command: toggleBlockType(schema.nodes.heading, {level: 2}),
        checkActive: isHeading(2),
      });

      items.push({
        label: 'H2',
        icon: '',
        command: toggleBlockType(schema.nodes.heading, {level: 3}),
        checkActive: isHeading(3),
      });
    }

    if (schema.marks.strong) {
      items.push({
        label: '',
        icon: 'format-bold',
        command: toggleMark(schema.marks.strong),
        checkActive: isMarked(schema.marks.strong),
      });
    }

    if (schema.marks.em) {
      items.push({
        label: '',
        icon: 'format-italic',
        command: toggleMark(schema.marks.em),
        checkActive: isMarked(schema.marks.em),
      });
    }

    if (schema.marks.link) {
      items.push({
        label: '',
        icon: 'link-variant',
        command: toggleMark(schema.marks.link, {href: ''}, {removeWhenPresent: true}),
        checkActive: isLinkSelected,
      });
    }

    if (schema.nodes.image) {
      items.push({
        label: '',
        icon: 'image',
        command: insertImage,
      });
    }

    if (schema.nodes.bullet_list) {
      items.push({
        label: '',
        icon: 'format-list-bulleted',
        command: toggleList(schema.nodes.bullet_list),
        checkActive: inList(schema.nodes.bullet_list),
      });
    }

    if (schema.nodes.ordered_list) {
      items.push({
        label: '',
        icon: 'format-list-numbered',
        command: toggleList(schema.nodes.ordered_list),
        checkActive: inList(schema.nodes.ordered_list),
      });
    }

    this.items = reactive(items);
  }

  /**
   * Triggers the command associated with the menu item.
   *
   * @param item
   */
  handleClick(item: MenuViewItem) {
    if (!this.editorView) return;

    item.command(this.editorView.state, this.editorView.dispatch, this.editorView);

    this.editorView.focus();
  }

  /**
   * Updates the availability of the menu items based on the current state of the editor.
   */
  update() {
    this.items.forEach((item) => {
      if (!this.editorView) return item;

      /**
       * When a `Command` is called without a `dispatch` function, it simply a boolean value
       * indicating whether the command can be executed or not.
       */
      item.available = item.command(this.editorView.state, undefined, this.editorView);

      item.active = item.checkActive ? item.checkActive(this.editorView.state) : false;

      return item;
    });
  }

  toPlugin = () => {
    return new Plugin({
      view: (editorView) => {
        this.editorView = editorView;
        return this;
      },
    });
  };
}
