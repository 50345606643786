<template>
  <Head :title="`Admin - ${course.name}`"></Head>
  <s-page-block>
    <s-page-header class="mb-3">
      <template #heading>
        <span>{{ course.code }}</span>
      </template>
      <template #actions>
        <s-btn
          :href="
            route('admin.courses.assignments.import', {
              course: course.id,
            })
          "
          color="primary"
          icon="import"
        >
          Import Assignments
        </s-btn>
      </template>
    </s-page-header>

    <div class="flex flex-col lg:grid lg:grid-cols-[2fr_3fr] gap-y-6 gap-x-10">
      <div class="flex flex-col gap-6">
        <s-card padding="sm">
          <template #heading>
            <h2 class="text-xl leading-tight text-pretty">Course Info</h2>
          </template>
          <template #actions>
            <s-btn icon="open-in-app" :href="'/courses/' + course.id" external color="white">
              View
            </s-btn>
            <s-btn icon="pencil" :href="route('courses.edit', course.id)">Edit</s-btn>
          </template>
          <div class="flex flex-col gap-3">
            <s-label-value label="Institution">
              {{ course.institution.name }}
            </s-label-value>
            <div class="grid grid-cols-2 gap-3">
              <s-label-value label="Start Date">
                {{ monthDayYear(course.startDate) }}
              </s-label-value>
              <s-label-value label="End Date">
                {{ monthDayYear(course.endDate) }}
              </s-label-value>
            </div>
          </div>
        </s-card>

        <s-card padding="sm">
          <template #heading>
            <h2 class="text-xl leading-tight text-pretty">Payment Details</h2>
          </template>
          <template #actions>
            <!-- Do not allow editing of payment information for courses with a parent. Payment is configured on parent. -->
            <s-btn
              v-if="!course.parentCourseId"
              icon="pencil"
              :href="route('admin.courses.edit', course.id)"
            >
              Edit
            </s-btn>
          </template>
          <div v-if="!course.parentCourseId" class="flex flex-col gap-3">
            <s-label-value label="Payment Model">
              <s-badge v-if="course.paymentModel" class="uppercase mt-0.5" color="blue-light">
                {{ course.paymentModel }}
              </s-badge>
              <s-badge v-else color="gray" icon="alert" class="mt-0.5 whitespace-nowrap">
                Not set
              </s-badge>
            </s-label-value>
            <div v-if="showPaymentDetails" class="grid grid-cols-[auto_auto] gap-3">
              <s-label-value label="In App Payment">
                <s-badge v-if="course.inAppPaymentEnabled" color="green" class="mt-0.5">
                  Enabled
                </s-badge>
                <s-badge v-else color="gray" class="mt-0.5">Disabled</s-badge>
              </s-label-value>
              <s-label-value label="Payment Due Date">
                <div v-if="course.paymentDueDate">
                  {{ timeIgnorantMonthDayYear(course.paymentDueDate) }}
                </div>
                <s-badge v-else color="red" icon="alert" class="mt-0.5 whitespace-nowrap">
                  Not set
                </s-badge>
              </s-label-value>
              <template v-if="course.inAppPaymentEnabled">
                <s-label-value label="Price">
                  <span v-if="price">
                    ${{ ~~price.unit_amount_decimal / 100 }} {{ price.currency }}
                  </span>
                  <s-badge v-else color="red" icon="alert" class="mt-0.5 whitespace-nowrap">
                    Not set
                  </s-badge>
                </s-label-value>
                <s-label-value v-if="coupon" label="Discount">
                  <span v-if="coupon.amount_off">
                    ${{ coupon.amount_off }} {{ price.currency }}
                  </span>
                  <span v-else-if="coupon.percent_off">{{ coupon.percent_off }}% off</span>
                  <s-badge v-else color="red" icon="alert" class="mt-0.5 whitespace-nowrap">
                    Not set
                  </s-badge>
                </s-label-value>
                <s-label-value v-if="coupon" label="Discounted Price">
                  ${{ discountedPrice }} {{ price.currency }}
                </s-label-value>
                <s-label-value label="Stripe Price Id">
                  <span v-if="course.priceId" class="whitespace-break-spaces">
                    {{ course.priceId }}
                  </span>
                  <s-badge v-else color="red" icon="alert" class="mt-0.5 whitespace-nowrap">
                    Not set
                  </s-badge>
                </s-label-value>
                <s-label-value label="Stripe Coupon Id">
                  <div v-if="course.couponId">{{ course.couponId }}</div>
                  <s-badge v-else color="gray" class="mt-0.5 whitespace-nowrap">Not set</s-badge>
                </s-label-value>
              </template>
            </div>
          </div>

          <p class="italic" v-else>Configured on parent course</p>
        </s-card>
      </div>
      <div class="flex flex-col gap-6">
        <s-card padding="sm">
          <template #heading>
            <h2 class="text-xl leading-tight text-pretty">Participants</h2>
          </template>
          <template #actions>
            <s-btn
              icon="account-group"
              color="white"
              :href="route('courses.participants.index', [course.id])"
            >
              View Participants
            </s-btn>
            <s-btn
              icon="file-delimited-outline"
              color="blue-light"
              :href="route('admin.courses.data_dump', course.id)"
              external
            >
              Dump Response Data as CSV
            </s-btn>
          </template>
          <div class="flex flex-col gap-4">
            <div class="flex flex-wrap gap-x-2 gap-y-3">
              <s-btn-link
                icon="currency-usd"
                :href="route('admin.courses.payment-requests.index', course.id)"
              >
                View Payment Requests
              </s-btn-link>
            </div>
            <s-stats-section
              :stats="[
                {label: 'Total', value: count.students},
                {label: 'Inactive', value: count.students - count.activeStudents},
                {label: 'Unregistered', value: count.students - count.registeredStudents},
              ]"
              class="mb-1"
            />
          </div>
        </s-card>

        <s-card>
          <template #heading>
            <div class="lg:flex-1 flex items-center gap-2">
              <h2 class="text-xl leading-tight text-pretty">Sections</h2>
              <s-badge color="gray" size="sm">{{ sections.length }} Total</s-badge>
            </div>
          </template>
          <template #actions>
            <s-btn
              icon="table-arrow-right"
              :href="route('admin.courses.sections.create', course.id)"
              color="white"
            >
              Add By CSV
            </s-btn>
            <s-btn icon="pencil" :href="route('courses.sections.index', [course.id])">Edit</s-btn>
          </template>
          <template #content>
            <s-table
              v-if="sections.length"
              density="denser"
              class="!rounded-t-none -mt-px max-h-[400px] overflow-y-auto"
            >
              <template #body>
                <tr v-for="section in sections">
                  <td>
                    <span class="px-2 md:px-4">{{ section.name }}</span>
                  </td>
                </tr>
              </template>
            </s-table>
            <div
              v-else
              class="bg-gray-200/40 shadow-inset w-full flex justify-center items-center py-6 rounded-b-lg overflow-hidden"
            >
              <p class="text-base font-medium text-gray-400 italic">No sections to display</p>
            </div>
          </template>
        </s-card>
      </div>
    </div>
  </s-page-block>
</template>

<script setup lang="ts">
import {Course} from '../../../types/entities/course';
import {route} from 'ziggy-js';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {monthDayYear, timeIgnorantMonthDayYear} from '../../../format/dates';
import {Head} from '@inertiajs/vue3';
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SBtnLink from '../../../design-system/SBtnLink.vue';
import STable from '../../../design-system/STable.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import {computed} from 'vue';
import {CourseSection} from '../../../types/entities/course-section';
import SStatsSection from '../../../design-system/SStatsSection.vue';
import SPlaceholder from '../../../design-system/SPlaceholder.vue';
import SBadge from '../../../design-system/SBadge.vue';
import SCard from '../../../design-system/SCard.vue';

const props = defineProps<{
  course: Course;
  sections: CourseSection[];
  price: {unit_amount_decimal: string; currency: string};
  coupon: {amount_off: string; percent_off: string};
  count: {
    students: number;
    activeStudents: number;
    registeredStudents: number;
  };
}>();

const discountedPrice = computed(() => {
  if (!props.price || !props.coupon) return;

  const amount = ~~props.price.unit_amount_decimal / 100;
  const amountOff = ~~props.coupon.amount_off;
  const percentOff = ~~props.coupon.percent_off;

  return amount - amountOff - (amount * percentOff) / 100;
});

const showPaymentDetails = computed(() => {
  return ['student_subscription', 'access_code_only', null].includes(props.course.paymentModel);
});

useBreadcrumbs([{label: 'Courses', href: route('admin.courses.index')}]);
</script>
