<template>
  <dialog
    ref="dialog"
    @click="handleDialogClick"
    @cancel.prevent="handleCancel"
    class="bg-transparent [&::backdrop]:bg-gray-900/80 [&::backdrop]:animate-[fadeIn_0.15s_ease-out_forwards]"
    :class="$attrs.class"
  >
    <slot v-bind="{close: attemptClose, forceClose: close, confirming: confirmingClose}" />
  </dialog>
  <slot v-if="confirmingClose" name="confirmClose" v-bind="{close, cancel: dontClose}" />
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';

const {modal} = defineProps<{
  modal?: boolean;
}>();

const slots = defineSlots<{
  default(props: {close(): void; forceClose(): void}): any;
  confirmClose?(props: {close(): void; cancel(): void}): any;
}>();

const emit = defineEmits(['close']);

const open = defineModel<boolean>('open', {default: true});

const dialog = ref<HTMLDialogElement>();
const confirmingClose = ref(false);

/**
 * Watch the `open` prop to control the dialog's visibility.
 *
 * When opening:
 * - If modal=true, shows as a modal dialog that blocks interaction with the rest of the page
 * - If modal=false, shows as a regular dialog that allows interaction with the page
 *
 * When closing:
 * - Closes the dialog using the native close() method
 *
 * The setTimeout with 0ms delay to ensure the dialog element has been created before
 * trying to call the `show` and `showModal` methods.
 */
watch(
  open,
  (isOpen) => {
    setTimeout(() => {
      if (isOpen) {
        if (modal) {
          // @ts-ignore The typescript types are incorrect here.
          dialog.value?.showModal();
        } else {
          // @ts-ignore The typescript types are incorrect here.
          dialog.value?.show();
        }
      } else {
        // @ts-ignore The typescript types are incorrect here.
        dialog.value?.close();
      }
    }, 0);
  },
  {immediate: true}
);

/**
 * Note: This must NOT call e.preventBubble() as it will prevent some inputs (notably checkboxes)
 * from working correctly within the dialog.
 *
 * @param event MouseEvent from someone clicking in the dialog.
 */
const handleDialogClick = (event: MouseEvent) => {
  if (event.target === dialog.value) {
    attemptClose();
  }
};

const handleCancel = () => {
  attemptClose();
};

const attemptClose = () => {
  if (slots.confirmClose) {
    confirmingClose.value = true;
  } else {
    close();
  }
};

const dontClose = () => {
  confirmingClose.value = false;
};

const close = () => {
  confirmingClose.value = false;
  // @ts-ignore The typescript types are incorrect here.
  dialog.value?.close();
  open.value = false;
  emit('close');
};
</script>
