<template>
  <div class="@container w-full flex flex-col gap-3">
    <div class="grid grid-cols-1 @sm:grid-cols-[1fr_auto] @2xl:grid-cols-[2fr_3fr_auto] gap-4">
      <div class="@sm:col-span-2 @2xl:col-span-1">
        <s-input-field label="Name" v-model="name" id="calculationName" :error="errors?.name" />
      </div>
      <s-input-field
        label="Expression"
        v-model="expression"
        id="calculationExpression"
        :error="errors?.expression ?? errors?.self"
      />
      <div class="flex-0 w-40">
        <s-input-field
          label="Points"
          v-model="points"
          type="number"
          id="calculationPoints"
          :error="errors?.points"
        />
      </div>
    </div>
    <s-textarea-field
      label="Feedback"
      v-model="feedback"
      id="calculationFeedback"
      :error="errors?.feedback"
    />
    <div class="flex flex-wrap gap-4">
      <div class="min-w-52 flex-1">
        <s-input-field
          label="Expected Significant Figures"
          v-model="sigFigs"
          type="number"
          id="calculationSigFigs"
          :error="errors?.sigFigs"
        />
      </div>
      <div class="min-w-52 flex-1">
        <s-input-field
          label="Sig Figs Point Deduction"
          v-model="sigFigsPointDeduction"
          type="number"
          id="calculationSigFigsPointDeduction"
          tooltip="Will deduct 1 point by default."
          :error="errors?.sigFigsPointDeduction"
        />
      </div>
    </div>
    <s-textarea-field
      label="Sig Figs Feedback"
      v-model="sigFigsFeedback"
      id="calculationSigFigsFeedback"
      tooltip="Will provide a default message if left blank."
      :error="errors?.sigFigsFeedback"
    />
    <tolerance-input :nullable="true" v-model="tolerance" :errors="errors?.tolerance" />
  </div>
</template>

<script setup lang="ts">
import SInputField from '../../../../../design-system/SInputField.vue';
import STextareaField from '../../../../../design-system/STextareaField.vue';
import {useVModels} from '@vueuse/core';
import ToleranceInput from '../../../SinglePartCalculation/ToleranceInput.vue';

const props = defineProps<{
  name: string;
  expression: string;
  points: number;
  feedback?: string | null;
  sigFigs?: number | null;
  sigFigsPointDeduction?: number | null;
  sigFigsFeedback?: string | null;
  tolerance?: App.DTOs.Tasks.CalculationToleranceDto | null;
  errors?: Record<string, any>;
}>();

const {
  name,
  expression,
  feedback,
  points,
  sigFigs,
  sigFigsPointDeduction,
  sigFigsFeedback,
  tolerance,
} = useVModels(props);
</script>

<style scoped></style>
