import {Command} from 'prosemirror-state';
import {NodeType} from 'prosemirror-model';
import {liftListItem, wrapInList} from 'prosemirror-schema-list';
import {inListItem} from '../queries/inListItem';
import {getCurrentList} from '../queries/getCurrentList';

export const toggleList = (listType: NodeType): Command => {
  const wrap = wrapInList(listType);
  const lift = liftListItem(listType.schema.nodes.list_item);
  return (state, dispatch, view) => {
    if (!inListItem(state)) {
      return wrap(state, dispatch, view);
    }

    let {node, pos} = getCurrentList(state);

    if (!node || pos === null) {
      return false;
    }

    if (node.type === listType) {
      return lift(state, dispatch);
    }

    if (!listType.validContent(node.content)) {
      return false;
    }

    // Switching from a different list.
    if (dispatch) {
      const tr = state.tr;
      tr.setNodeMarkup(pos, listType);
      dispatch(tr);
    }

    return true;
  };
};
