import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import RichTextUiConfig = App.Entities.Tasks.Ui.RichTextUiConfig;

export const richTextComponentType = defineComponentType<RichTextUiConfig, null>({
  translationKey: 'Rich Text',
  icon: 'text',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'RichText',
    name: 'Rich Text',
    html: '',
    part: null,
  }),
  componentGraderFactory: () => null,
});
