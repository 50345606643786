import {Task, TaskResponse} from '../../../types/entities/tasks';
import {computed, Ref} from 'vue';
import {useI18n} from 'vue-i18n';

export interface UseSubmitButtonStateOptions {
  task: Task;
  storedResponse: TaskResponse | null;
  noMoreAttempts?: boolean;
}

export function useSubmitButtonState(
  options: Ref<UseSubmitButtonStateOptions>,
  isDirty: Ref<boolean>
) {
  const {t} = useI18n({
    inheritLocale: true,
  });

  const isStoreSubmitDisabled = computed(() => {
    return (
      // Cannot submit an already submitted response
      (options.value.storedResponse &&
        options.value.storedResponse.submittedAt &&
        !isDirty.value) ||
      options.value.noMoreAttempts ||
      options.value.task.taskType === 'WatchVideoTask' ||
      (options.value.task.taskType === 'DynamicQuestion' && !options.value.task.content.hasGrader)
    );
  });

  const submitTooltipText = computed(() => {
    const isVideo = options.value.task.taskType === 'WatchVideoTask';
    const isDynamicQuestion = options.value.task.taskType === 'DynamicQuestion';

    if (options.value.noMoreAttempts) {
      return t('useSubmitButtonState.noMoreAttempts');
    }

    if (options.value.storedResponse && options.value.storedResponse.submittedAt) {
      if (isVideo) {
        return t('useSubmitButtonState.alreadyWatchedVideo');
      }
      return t('useSubmitButtonState.alreadySubmitted');
    }

    if (isVideo) {
      return t('useSubmitButtonState.video');
    }

    if (isDynamicQuestion) {
      return t('useSubmitButtonState.notGraded');
    }

    return null;
  });

  return {isStoreSubmitDisabled, submitTooltipText};
}
