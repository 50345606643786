<template>
  <div class="page-block">
    <Head title="Admin" />
    <h1 class="heading xl mb-2">{{ t('admin') }}</h1>
    <p class="text-xl leading-tight">{{ t('welcome') }}</p>
    <ul class="list-disc list-inside mt-4">
      <template v-for="item in items" :key="item.href">
        <li v-if="item.type === 'link'">
          <s-link :href="item.href" class="link">{{ item.label }}</s-link>
        </li>
        <s-divider v-else-if="item.type === 'divider'" class="my-2" />
      </template>
    </ul>
  </div>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {items} from '../../config/admin-menu';
import SLink from '../../components/SLink.vue';
import SDivider from '../../design-system/SDivider.vue';

const {t} = useI18n();
</script>
<i18n>
{
  "en": {
    "admin": "Admin",
    "welcome": "Welcome to the admin dashboard!"
  },
  "fr": {
    "admin": "Admin",
    "welcome": "Bienvenue au tableau de bord de l'administrateur !"
  }
}
</i18n>
