<template>
  <details class="flex-1 min-w-0 flex flex-col shrink min-h-0 list-none" open>
    <summary
      class="relative list-none text-sm leading-tight w-full flex justify-between items-center gap-2"
    >
      <p class="text-gray-700 font-bold flex-1">
        <span v-if="attachments.length">
          {{ attachments?.length }}
          <span class="font-medium text-gray-700">
            {{ t('attachments.uploadCount', attachments?.length) }}
          </span>
        </span>
        <span v-else class="text-sm leading-tight font-medium italic rounded-full text-gray-600">
          {{ t('attachments.noFiles') }}
        </span>
      </p>
      <span v-if="attachments.length">
        <span
          class="hide-button mb-1 select-none text-blue-500 cursor-pointer flex items-center gap-1 opacity-50 hover:opacity-80 active:opacity-100 transition-opacity ease-out duration-150"
        >
          <s-icon name="close" class="opacity-70" size="16" />
          {{ t('attachments.hide') }}
        </span>
        <span
          class="show-button absolute select-none right-0 top-0 text-blue-500 cursor-pointer flex items-center gap-1 opacity-50 hover:opacity-80 active:opacity-100 transition-opacity ease-out duration-150"
        >
          <s-icon name="eye" class="opacity-70" size="16" />
          {{ t('attachments.show') }}
        </span>
      </span>
    </summary>
    <div class="shrink min-h-0 max-h-[14rem] pt-2.5 pb-1.5 flex flex-col">
      <ul
        class="scroll-shadow shrink min-h-0 w-full flex flex-col divide-y divide-gray-200 rounded-md overflow-hidden border border-gray-200 bg-gray-50 shadow-inset overflow-y-auto"
        v-if="attachments.length"
      >
        <s-response-attachment-list-item
          v-for="attachment in attachments"
          :key="attachment.id"
          :attachment="attachment"
          :deletable="!!showDelete"
          :only-on-delete="propsToReloadOnDelete || []"
          @show="viewAttachment"
        />
      </ul>
    </div>
    <div
      v-if="errorMessages?.length"
      class="mt-2 rounded-lg overflow-hidden shadow font-medium text-base flex items-stretch"
    >
      <div class="w-2 bg-red-500"></div>
      <div
        class="flex-1 px-3 py-2 border-2 rounded-r-lg flex items-center gap-2 bg-red-100 border-red-200 text-red-700 flex items-center gap-1"
      >
        <s-icon name="alert" size="20" class="text-red-500" />
        <ul class="flex flex-col text-base leading-tight">
          <li v-for="error in errorMessages">{{ error }}</li>
        </ul>
      </div>
    </div>
  </details>

  <s-modal
    v-if="attachmentUrl && (isImage || isPdf)"
    v-model:open="showModel"
    :title="attachments.find((item) => item.id === activeAttachment)?.filename || t('modal.title')"
    width="md"
  >
    <template #content>
      <a
        :href="attachmentUrl"
        target="_blank"
        class="grid grid-rows-1 grid-cols-1 card border border-gray-200 cursor-pointer"
        :open="activeAttachment"
      >
        <div class="p-4 bg-gray-150 col-span-full row-span-full">
          <div class="w-full h-full flex-1 flex items-center justify-center">
            <div class="loader"></div>
          </div>
        </div>
        <img
          v-if="isImage"
          :src="attachmentUrl"
          role="presentation"
          class="min-w-full w-full h-auto relative top-0 col-span-full row-span-full"
        />
        <object
          v-if="isPdf"
          :key="activeAttachmentKey"
          :data="attachmentUrl"
          style="aspect-ratio: 8.5 / 11"
          class="min-w-full w-full relative top-0 col-span-full row-span-full"
        ></object>
      </a>
    </template>
  </s-modal>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {computed, ref, watch} from 'vue';
import SModal from '../design-system/SModal.vue';
import SIcon from '../design-system/SIcon.vue';
import SResponseAttachmentListItem from './SResponseAttachmentListItem.vue';
import {inlineViewableImageMimeTypes, inlineViewableImageTypeExtensions} from '../util/images';
import {useI18n} from 'vue-i18n';
import {notNullish} from '../util/type-guards';

const props = defineProps<{
  errors?: Record<string, string>;
  attachments: {
    id?: number;
    filename: string;
    mimeType?: string | null;
  }[];
  responseId?: number | null;
  showDelete?: boolean;
  propsToReloadOnDelete?: string[];
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

watch(
  () => props.responseId,
  () => {
    // reset attachments on change of the provided entity id
    activeAttachment.value = false;
    attachmentUrl.value = '';
    attachmentMimeType.value = '';
    attachmentExtension.value = '';
  }
);

const errorMessages = computed(() => {
  if (!props.errors) {
    return [];
  }

  // find any error keys that start with attachments, and then return their associated values.
  return Object.keys(props.errors || {})
    .filter((key) => key.startsWith('attachments'))
    .map((key) => props.errors?.[key])
    .filter(notNullish);
});

const activeAttachment = ref<number | boolean>(false);
const activeAttachmentKey = computed(() => {
  return typeof activeAttachment.value === 'number'
    ? `attachment-${activeAttachment.value}`
    : 'no-attachment';
});
const attachmentUrl = ref('');
const attachmentMimeType = ref('');
const attachmentExtension = ref('');
const showModel = ref(false);

const viewAttachment = (attachment: any) => {
  // Reset these values.
  showModel.value = false;
  attachmentUrl.value = '';
  attachmentMimeType.value = '';
  attachmentExtension.value = '';

  if (!attachment) {
    return;
  }

  activeAttachment.value = attachment.id;
  attachmentMimeType.value = attachment.mimeType;
  attachmentExtension.value = attachment.filename.split('.').reverse().shift().toLowerCase();

  // Because Safari won't follow a redirect in an object tag, we need to handle PDF viewing differently for Safari.
  // This is slower for the user, which is why we selectively look for this case before using this hack.
  const currentlyInSafari =
    navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');

  if (isPdf.value && currentlyInSafari) {
    getSignedUrlFromApiAndLoadAttachmentModal(attachment.id);
  } else {
    attachmentUrl.value = route('task-response-attachments.show', {
      attachment: attachment.id,
      inline: '1',
    });
    showModel.value = true;
  }
};

/**
 * This function will call our API to get the signed URL for the attachment.
 * We then use that to display the attachment in a modal.
 * @param attachmentId
 */
const getSignedUrlFromApiAndLoadAttachmentModal = (attachmentId: number) => {
  fetch(
    route('api.v1.task-response-attachments.signed-url', {
      attachment: attachmentId,
      inline: '1',
    })
  )
    .then((response) => response.json())
    .then((data) => {
      attachmentUrl.value = data.signedUrl;
      showModel.value = true;
    });
};

const isImage = computed(() => {
  if (attachmentMimeType.value) {
    return inlineViewableImageMimeTypes.includes(attachmentMimeType.value);
  }

  // Fall back to extensions if we don't have a mime type saved.
  return inlineViewableImageTypeExtensions.includes(attachmentExtension.value);
});

const isPdf = computed(() => {
  if (attachmentMimeType.value) {
    return attachmentMimeType.value === 'application/pdf';
  }

  // Fallback to extension check
  return attachmentExtension.value === 'pdf';
});
</script>
<style scoped>
details[open] .show-button,
details:not([open]) .hide-button {
  opacity: 0;
}

details:not([open]) .show-button,
details[open] .hide-button {
  opacity: 1;
}
</style>
<style scoped>
.loader {
  --loader-color: #76cde8;

  @apply block w-8 h-8 animate-spin rounded-full mix-blend-darken after:absolute after:block after:inset-[5px] after:rounded-full after:bg-white;
  background: conic-gradient(from 180deg at 50% 50%, transparent 0deg, var(--loader-color) 360deg);
}
</style>
<i18n>
{
  "en": {
    "attachments": {
      "uploadCount": "Upload | Uploads",
      "noFiles": "No files uploaded.",
      "hide": "Hide",
      "show": "Show"
    },
    "modal": {
      "title": "File Viewer"
    }
  },
  "fr": {
    "attachments": {
      "uploadCount": "Upload | Uploads",
      "noFiles": "Aucun fichier téléchargé.",
      "hide": "Masquer",
      "show": "Afficher"
    },
    "modal": {
      "title": "Visionneuse de fichiers"
    }
  }
}
</i18n>
