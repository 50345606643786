<template>
  <button
    @click="showLegend = !showLegend"
    class="btn block min-w-[8rem]"
    :class="buttonClass"
    aria-hidden="true"
    ref="wrapperRef"
  >
    {{ showLegend ? $t('Hide Legend') : $t('Show Legend') }}
  </button>
  <Teleport to="body">
    <TransitionRoot
      appear
      :show="showLegend"
      as="template"
      enter="transition-opacity duration-100 ease-out"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150 ease-in"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div aria-hidden="true" ref="legendRef" :style="floatingStyles" class="z-50">
        <div
          class="card pb-5 pt-6 pl-6 pr-10 z-[100] select-none flex flex-col gap-1.5 transition-all ease-out duration-150"
        >
          <span class="flex items-center gap-2 whitespace-nowrap">
            <span class="inline-block rounded-full shadow-card w-4 h-4 bg-green-500"></span>
            <span>{{ $t('correct') }}</span>
          </span>
          <span class="flex items-center gap-2 whitespace-nowrap">
            <span class="inline-block rounded-full shadow-card w-4 h-4 bg-blue-400"></span>
            <span>{{ $t('partiallyCorrect') }}</span>
          </span>
          <span class="flex items-center gap-2 whitespace-nowrap">
            <span class="inline-block rounded-full shadow-card w-4 h-4 bg-red-400"></span>
            <span>{{ $t('incorrect') }}</span>
          </span>
          <span class="flex items-center gap-2 whitespace-nowrap">
            <span class="inline-block rounded-full shadow-card w-4 h-4 bg-gray-600"></span>
            <span>{{ $t('ungraded') }}</span>
          </span>
          <span class="flex items-center gap-2 whitespace-nowrap">
            <span class="inline-block rounded-full shadow-card w-4 h-4 bg-gray-400"></span>
            <span>
              {{ $t('draft') }}
              <em>({{ $t('ungraded') }})</em>
            </span>
          </span>
          <span class="flex items-center gap-2 whitespace-nowrap">
            <s-icon name="pencil" class="opacity-70 -mx-0.5" size="20" />
            <span>{{ $t('containsDraft') }}</span>
          </span>
          <span class="flex items-center gap-2 whitespace-nowrap">
            <span class="inline-block rounded-full shadow-card w-4 h-4 bg-gray-150"></span>
            <span>{{ $t('incomplete') }}</span>
          </span>
        </div>
      </div>
    </TransitionRoot>
  </Teleport>
</template>

<script setup lang="ts">
import {TransitionRoot} from '@headlessui/vue';
import {computed, defineProps, ref} from 'vue';
import {useFloating, autoUpdate, offset, shift, flip} from '@floating-ui/vue';
import SIcon from '../../../design-system/SIcon.vue';

const props = defineProps<{
  class?: string;
}>();

const showLegend = ref(false);
const wrapperRef = ref<HTMLElement | null>(null);
const legendRef = ref<HTMLElement | null>(null);
const middleware = ref([offset(8), shift(), flip()]);

const {floatingStyles} = useFloating(wrapperRef, legendRef, {
  placement: 'bottom-end',
  middleware,
  whileElementsMounted: autoUpdate,
});

const buttonClass = computed(() =>
  [
    showLegend.value ? 'blue transition-all ease-out duration-150' : 'white',
    props.class ?? '',
  ].join(' ')
);
</script>
