<template>
  <s-modal
    :title="t('modal.title')"
    :tagline="data.assignmentName"
    v-model:open="visible"
    :confirm="{
      label: t('apply'),
      icon: 'floppy',
    }"
    @confirm="$emit('confirm')"
    cancellable
    width="md"
  >
    <div class="flex flex-col divide-y divide-gray-200">
      <!-- Grade Release Date Form -->
      <div :class="modalSlateClasses">
        <div class="flex flex-col gap-1">
          <label :class="fieldsetLabel">{{ t('quizMode') }}</label>
          <p class="text-sm text-gray-700 leading-tight -mt-0.5 mb-1">
            {{ t('gradesAndFeedbackWillBeHiddenUntilTheGradeReleaseDate') }}
            <br />
            {{ t('EachTaskIsGradedBasedOnTheMostRecentAttemptBeforeTheDueDate') }}
          </p>

          <s-toggle v-model="data.quizMode.enabled" />
        </div>
        <div v-if="data.quizMode.enabled" class="mt-3">
          <s-button-toggle-field
            id="newDateType"
            v-model="data.quizMode.releaseDateType"
            :label="t('gradeReleaseDate')"
            :options="[
              {
                value: null,
                label: t('notSet'),
              },
              {
                value: 'relative',
                label: t('relative'),
              },
              {
                value: 'fixed',
                label: t('fixed'),
              },
            ]"
          />

          <div v-if="data.quizMode.releaseDateType === 'relative'" class="w-full">
            <p class="text-sm text-gray-700 leading-tight mt-3.5 mb-1" v-html="t('relativeTo')"></p>
            <div class="w-full grid grid-cols-2 lg:grid-cols-3 justify-items-stretch gap-4 mt-4">
              <span class="col-span-2 lg:col-span-1">
                <s-input-field
                  id="releaseRelativeDaysValue"
                  v-model.number="data.quizMode.relativeReleaseDate.days"
                  type="number"
                  :suffix="t('time.days')"
                />
              </span>
              <s-input-field
                id="releaseRelativeHoursValue"
                v-model.number="data.quizMode.relativeReleaseDate.hours"
                type="number"
                :suffix="t('time.hours')"
              />
              <s-input-field
                id="releaseRelativeMinutesValue"
                v-model.number="data.quizMode.relativeReleaseDate.minutes"
                type="number"
                :suffix="t('time.minutes')"
              />
            </div>
          </div>

          <div
            v-if="data.quizMode.releaseDateType === 'fixed'"
            class="flex items-center justify-between w-full gap-3 mt-4"
          >
            <div class="flex-1">
              <s-input-field
                v-model="data.quizMode.fixedReleaseDate.date"
                type="date"
                id="fixedGradeReleaseDate"
              />
            </div>
            <div class="flex-1">
              <s-input-field
                v-model="data.quizMode.fixedReleaseDate.time"
                type="time"
                id="fixedGradeReleaseTime"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Help Items -->
      <fieldset :class="modalSlateClasses">
        <s-button-toggle-field
          id="hideHelpItems"
          v-model="data.hideHelpItems"
          :label="t('helpItems')"
          :options="[
            {
              value: true,
              label: t('hide'),
            },
            {
              value: false,
              label: t('show'),
            },
          ]"
        />
      </fieldset>

      <fieldset v-if="features.assignmentTimelimits" :class="modalSlateClasses">
        <s-button-toggle-field
          id="timelimitEnabled"
          v-model="data.timelimit.enabled"
          :label="t('timeLimit')"
          :options="[
            {
              value: false,
              label: t('no'),
            },
            {
              value: true,
              label: t('yes'),
            },
          ]"
        />
        <div v-if="data.timelimit.enabled" class="w-full flex gap-4 mt-4">
          <s-input-field
            id="localHours"
            v-model.number="localData.hours"
            type="number"
            :suffix="t('time.hours')"
            :aria-label="t('extensionHours')"
            min="0"
          />
          <s-input-field
            id="localMinutes"
            v-model.number="localData.minutes"
            type="number"
            :suffix="t('time.minutes')"
            :aria-label="t('extensionMinutes')"
            min="0"
          />
        </div>
      </fieldset>

      <div :class="modalSlateClasses">
        <s-button-toggle-field
          id="pastDueSubmissions"
          v-model="data.preventPastDueSubmissions"
          :label="t('lateSubmissions')"
          :options="[
            {
              value: true,
              label: t('prevent'),
            },
            {
              value: false,
              label: t('allow'),
            },
          ]"
        />

        <div v-if="!data.preventPastDueSubmissions" class="mt-3">
          <!-- Late Submission Cutoff Date -->
          <s-button-toggle-field
            id="pastDueCutoff"
            v-model="data.lateResponsePenaltyPolicy.submissionCutoff.cutoffDateType"
            :label="t('cutoffDate')"
            :options="[
              {
                value: null,
                label: t('noCutoff'),
              },
              {
                value: 'relative',
                label: t('relative'),
              },
              {
                value: 'fixed',
                label: t('fixed'),
              },
            ]"
          />

          <!-- Late Submission RELATIVE Cutoff Date -->
          <div
            v-if="data.lateResponsePenaltyPolicy.submissionCutoff.cutoffDateType === 'relative'"
            class="w-full"
          >
            <p class="text-sm text-gray-700 leading-tight mt-3.5 mb-1" v-html="t('relativeTo')"></p>
            <div class="w-full grid grid-cols-2 lg:grid-cols-3 justify-items-stretch gap-4 mt-4">
              <span class="col-span-2 lg:col-span-1">
                <s-input-field
                  id="cutoffRelativeDaysValue"
                  v-model.number="
                    data.lateResponsePenaltyPolicy.submissionCutoff.relativeCutoffDate.days
                  "
                  type="number"
                  :suffix="t('time.days')"
                />
              </span>
              <s-input-field
                id="cutoffRelativeHoursValue"
                v-model.number="
                  data.lateResponsePenaltyPolicy.submissionCutoff.relativeCutoffDate.hours
                "
                type="number"
                :suffix="t('time.hours')"
              />
              <s-input-field
                id="cutoffRelativeMinutesValue"
                v-model.number="
                  data.lateResponsePenaltyPolicy.submissionCutoff.relativeCutoffDate.minutes
                "
                type="number"
                :suffix="t('time.minutes')"
              />
            </div>
          </div>
          <!-- Late Submission FIXED Cutoff Date -->
          <div
            v-if="data.lateResponsePenaltyPolicy.submissionCutoff.cutoffDateType === 'fixed'"
            class="flex items-center justify-between w-full gap-3 mt-4"
          >
            <div class="flex-1">
              <s-input-field
                v-model="data.lateResponsePenaltyPolicy.submissionCutoff.fixedCutoffDate.date"
                type="date"
                id="latePolicyFixedCutoffDate"
              />
            </div>
            <div class="flex-1">
              <s-input-field
                v-model="data.lateResponsePenaltyPolicy.submissionCutoff.fixedCutoffDate.time"
                type="time"
                id="latePolicyFixedCutoffDateTime"
              />
            </div>
          </div>

          <div class="mt-3">
            <s-button-toggle-field
              id="pastDueSubmissionsPenalty"
              v-model="data.lateResponsePenaltyPolicy.enabled"
              :label="t('penaltyPolicy')"
              :options="[
                {
                  value: false,
                  label: t('noPenalty'),
                },
                {
                  value: true,
                  label: t('custom'),
                },
              ]"
            />
          </div>

          <template v-if="data.lateResponsePenaltyPolicy.enabled">
            <div class="flex flex-wrap gap-x-4 gap-y-3 mt-3">
              <div class="flex items-end">
                <div class="flex-1">
                  <s-input-field
                    :label="t('penaltyValue')"
                    id="penaltyValue"
                    v-model.number="data.lateResponsePenaltyPolicy.penalty.value"
                    type="number"
                    :min="0"
                    class="rounded-r-none"
                  />
                </div>
                <s-select-field
                  id="penaltyType"
                  v-model="data.lateResponsePenaltyPolicy.penalty.type"
                  class="flex-0 rounded-l-none border-l-0 !bg-gray-100"
                >
                  <option value="absolute">{{ t('points') }}</option>
                  <option value="relative">{{ t('%') }}</option>
                </s-select-field>
              </div>

              <div class="flex items-end">
                <div class="flex-1">
                  <s-input-field
                    :label="t('labels.timeInterval')"
                    id="penaltyTimeFrameValue"
                    v-model.number="data.lateResponsePenaltyPolicy.frequency.value"
                    class="rounded-r-none"
                    type="number"
                    :min="1"
                  />
                </div>
                <s-select-field
                  id="penaltyTimeFrame"
                  v-model="data.lateResponsePenaltyPolicy.frequency.type"
                  class="flex-0 rounded-l-none border-l-0 !bg-gray-100"
                >
                  <option value="minutes">{{ t('time.minutes') }}</option>
                  <option value="hours">{{ t('time.hours') }}</option>
                  <option value="days">{{ t('time.days') }}</option>
                </s-select-field>
              </div>
            </div>
          </template>

          <div class="mt-4 bg-white rounded-l-sm rounded-r-lg shadow flex">
            <div class="w-1.5 shrink-0 bg-orange-400 rounded-l-sm"></div>
            <div
              class="flex-1 flex items-center gap-2 px-3 py-2 rounded-r-lg border border-gray-100"
            >
              <s-icon name="alert" class="text-orange-500 opacity-70" />
              <p class="text-base text-gray-700 leading-snug text-pretty">
                <s-penalty-policy :lateResponsePenaltyPolicy="data.lateResponsePenaltyPolicy" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </s-modal>
</template>
<script setup lang="ts">
import SModal from '../../../design-system/SModal.vue';
import SIcon from '../../../design-system/SIcon.vue';
import SInputField from '../../../design-system/SInputField.vue';
import SToggle from '../../../design-system/SToggle.vue';
import SButtonToggleField from '../../../design-system/SButtonToggleField.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import {ref, watch} from 'vue';
import {useVModel} from '@vueuse/core';
import SPenaltyPolicy, {LateResponsePenaltyPolicy} from '../../../components/SPenaltyPolicy.vue';
import {useI18n} from 'vue-i18n';

export type PolicyModalData = {
  assignmentName: string;
  /* Quiz Mode */
  quizMode: {
    enabled: boolean;
    releaseDateType: 'fixed' | 'relative' | null;
    fixedReleaseDate: {
      date: string;
      time: string;
    };
    relativeReleaseDate: {
      days: number;
      hours: number;
      minutes: number;
    };
  };
  /* Past Due Submission */
  preventPastDueSubmissions: boolean;
  /* Hide Help Items*/
  hideHelpItems: boolean;
  /* Timelimit */
  timelimit: {
    enabled: boolean;
    minutes: number | null;
  };
  /* Late Submission Penalty Policy */
  lateResponsePenaltyPolicy: LateResponsePenaltyPolicy;
};

const props = defineProps<{
  visible: boolean;
  data: PolicyModalData;
  features: {
    assignmentTimelimits: boolean;
  };
}>();

const visible = useVModel(props, 'visible');

const {t} = useI18n();

const modalSlateClasses =
  'px-4 md:px-5 lg:px-6 pt-2.5 pb-4 md:pt-3 md:pb-5 first:pt-0 bg-gradient-to-t from-gray-100 via-transparent to-transparent';
const fieldsetLabel = 'font-bold text-gray-700 leading-tight';

watch(
  () => props.data.preventPastDueSubmissions,
  (newValue) => {
    // If we are preventing past due submissions, we should turn off having a late response penalty policy.
    // The two policies are currently incompatible.
    if (newValue) {
      props.data.lateResponsePenaltyPolicy.enabled = false;
    }
  }
);

const localData = ref({
  hours: props.data.timelimit.minutes ? Math.floor(props.data.timelimit.minutes / 60) : 0,
  minutes: props.data.timelimit.minutes ? props.data.timelimit.minutes % 60 : 0,
});

watch(
  props.data.timelimit,
  (newValue) => {
    if (newValue.minutes) {
      localData.value.hours = Math.floor(newValue.minutes / 60);
      localData.value.minutes = newValue.minutes % 60;
    }
  },
  {deep: true}
);

watch(
  localData,
  (newValue) => {
    props.data.timelimit.minutes = Math.floor(newValue.hours * 60) + Math.floor(newValue.minutes);
  },
  {deep: true}
);
</script>
<i18n>
{
  "en": {
    "modal": {
      "title": "Policy Settings"
    },
    "labels": {
      "timeInterval": "Time Interval"
    },
    "apply": "Apply",
    "quizMode": "Quiz Mode",
    "gradesAndFeedbackWillBeHiddenUntilTheGradeReleaseDate": "Grades and feedback will be hidden until the grade release date",
    "EachTaskIsGradedBasedOnTheMostRecentAttemptBeforeTheDueDate": "Each task is graded based on the most recent attempt before the due date",
    "gradeReleaseDate": "Grade Release Date",
    "notSet": "Not Set",
    "relative": "Relative",
    "fixed": "Fixed",
    "relativeTo": "Relative to <strong>Current</strong> <em>Due Date</em>, stored as a fixed date. Changes to the <em>Due Date</em> will <strong>not</strong> affect this value.",
    "lateSubmissions": "Late Submissions",
    "prevent": "Prevent",
    "allow": "Allow",
    "noCutoff": "No Cutoff",
    "penaltyPolicy": "Penalty Policy",
    "noPenalty": "No Penalty",
    "custom": "Custom",
    "helpItems": "Help Items",
    "penaltyValue": "Penalty Value",
    "extensionHours": "Extension Hours",
    "extensionMinutes": "Extension Minutes"
  },
  "fr": {
    "modal": {
      "title": "Paramètres de la politique"
    },
    "labels": {
      "timeInterval": "Intervalle de temps"
    },
    "apply": "Appliquer",
    "quizMode": "Mode Quiz",
    "gradesAndFeedbackWillBeHiddenUntilTheGradeReleaseDate": "Les notes et les commentaires seront cachés jusqu'à la date de publication des notes",
    "EachTaskIsGradedBasedOnTheMostRecentAttemptBeforeTheDueDate": "Chaque tâche est notée en fonction de la tentative la plus récente avant la date limite",
    "gradeReleaseDate": "Date de publication des notes",
    "notSet": "Non défini",
    "relative": "Relative",
    "fixed": "Fixe",
    "relativeTo": "Par rapport à la <strong>date d'échéance</strong> <em>actuelle</em>, enregistrée comme une date fixe. Les modifications de la <em>date d'échéance</em> n'affecteront <strong>pas</strong> cette valeur.",
    "lateSubmissions": "Soumissions tardives",
    "prevent": "Empêcher",
    "allow": "Autoriser",
    "noCutoff": "Pas de date limite",
    "penaltyPolicy": "Politique de pénalité",
    "noPenalty": "Aucune pénalité",
    "custom": "Personnalisé",
    "helpItems": "Éléments d'Aide",
    "penaltyValue": "Valeur de la pénalité",
    "extensionHours": "Heures de prolongation",
    "extensionMinutes": "Minutes de prolongation"
  }
}
</i18n>
