<template>
  <Head :title="`${t('page.description')} - ${assignment.name} - ${course.code}`"></Head>
  <div class="page-block max-w-screen-md gap-3">
    <s-page-header :heading="t('page.title')">
      <template #actions>
        <button @click="goBack" class="btn md white">
          <s-icon name="arrow-left" class="opacity-70" width="18" height="18" />
          {{ t('page.back') }}
        </button>
      </template>
    </s-page-header>
    <i18n-t keypath="page.description" tag="p" class="text-lg mb-2">
      <template v-slot:assignment>
        <span class="font-semibold">{{ assignment.name }}</span>
      </template>
      <template v-slot:name>
        <span class="font-semibold">{{ user.firstName }} {{ user.lastName }}</span>
      </template>
    </i18n-t>

    <form @submit.prevent="createAdjustment" class="flex flex-col gap-5 rounded-lg w-full">
      <div class="flex">
        <div class="card px-6 lg:px-7 py-4 lg:py-5 flex items-center gap-5">
          <SLabelValue :label="t('labels.originalGrade')" class="text-gray-600">
            {{ oldGradeDisplay }}
          </SLabelValue>
          <SLabelValue
            :label="t('labels.newGrade')"
            :class="form.type === 'deduction' ? 'text-red-700' : 'text-green-700'"
          >
            <strong>{{ newGradeDisplay }}</strong>
          </SLabelValue>
        </div>
      </div>

      <s-button-toggle
        id="gradeAdjustmentType"
        :options="[
          {value: 'deduction', label: t('gradeAdjustmentType.deduction')},
          {value: 'addition', label: t('gradeAdjustmentType.addition')},
        ]"
        v-model="form.type"
      />
      <s-input-field
        id="gradeAdjustment"
        :label="t('labels.adjustment')"
        :description="t('descriptions.adjustment')"
        v-model.number="form.adjustment"
        class="max-w-[12rem]"
        type="number"
        min="0"
        max="100"
      />
      <s-textarea-field id="reason" :label="t('labels.reason')" v-model="form.reason" />

      <div class="flex justify-end gap-3 mt-2">
        <button @click.prevent="goBack" class="btn white md flex items-center justify-center gap-2">
          <s-icon name="close" class="opacity-70" width="18" height="18" />
          {{ t('form.cancel') }}
        </button>
        <button type="submit" class="btn primary md" :disabled="form.processing">
          <s-icon v-if="form.processing" name="loading" class="opacity-70 animate-spin" size="18" />
          <s-icon v-else name="plus" class="opacity-70" width="18" height="18" />
          {{ t('form.submit') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import {router, useForm} from '@inertiajs/vue3';
import {computed} from 'vue';
import {route} from 'ziggy-js';
import {User} from '../../types/entities/user';
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLabelValue from '../../design-system/SLabelValue.vue';
import STextareaField from '../../design-system/STextareaField.vue';
import SInputField from '../../design-system/SInputField.vue';
import SButtonToggle from '../../design-system/SButtonToggle.vue';
import {AssignmentGrade} from '../../types/entities/assignment-grade';
import {useI18n} from 'vue-i18n';
import {Head} from '@inertiajs/vue3';
import SIcon from '../../design-system/SIcon.vue';

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const {course, assignment, user, grade} = defineProps<{
  course: Course;
  assignment: Assignment;
  user: User;
  grade: AssignmentGrade;
}>();

const form = useForm({
  type: 'deduction',
  adjustment: '0',
  reason: '',
});

const pointsAvailable = grade?.pointsAvailable || 1;

const originalGrade = computed(() => {
  if (!grade) return 0;
  return (grade.originalNumerator / grade.originalDenominator) * 100;
});

const oldGradeDisplay = computed(() => {
  if (course.gradeDisplay === 'PERCENTAGE') {
    return originalGrade.value.toFixed(1).replace('.0', '') + '%';
  }
  if (course.gradeDisplay === 'FRACTION')
    return `${Math.round(pointsAvailable * originalGrade.value) / 100}/${pointsAvailable}`;
  return '';
});

const newGradeDisplay = computed(() => {
  let newPercent;
  if (course.gradeDisplay === 'FRACTION') {
    newPercent =
      form.type === 'deduction'
        ? grade.pointsReceived - grade.pointsAvailable * (+form.adjustment / 100)
        : grade.pointsReceived + grade.pointsAvailable * (+form.adjustment / 100);
    return Math.max(newPercent, 0).toFixed(2) + '/' + grade.pointsAvailable;
  }
  newPercent =
    form.type === 'deduction'
      ? originalGrade.value - +form.adjustment
      : originalGrade.value + form.adjustment;
  return Math.min(Math.max(+newPercent, 0), 100).toFixed(1).toString() + '%';
});

const goBack = () => {
  window.history.back();
};

const createAdjustment = () =>
  router.post(route('assignment_grades.adjustments.store', route().params), {
    type: 'relative',
    value: form.type === 'deduction' ? -form.adjustment : form.adjustment,
    reason: form.reason,
  });
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Assignment Grade Override",
      "description": "Override {assignment} grade for {name}."
    },
    "labels": {
      "adjustment": "Percentage Adjustment",
      "reason": "Reason For Adjustment",
      "newGrade": "New Grade",
      "originalGrade": "Original Grade"
    },
    "descriptions": {
      "details": "Grade adjusted by {name}:",
      "adjustment": "Percentage of total grade"
    },
    "gradeAdjustmentType": {
      "deduction": "Deduction",
      "addition": "Addition"
    },
    "form": {
      "submit": "Create Grade Override"
    }
  },
  "fr": {
    "page": {
      "title": "Remplacement de Note de Devoir",
      "description": "Remplacer la note de {assignment} pour {name}."
    },
    "labels": {
      "adjustment": "Ajustement de Pourcentage",
      "reason": "Raison de l'Ajustement",
      "newGrade": "Nouvelle Note",
      "originalGrade": "Note Originale"
    },
    "descriptions": {
      "details": "Note Ajustée {name}:",
      "adjustment": "Pourcentage de la note totale"
    },
    "gradeAdjustmentType": {
      "deduction": "Déduction",
      "addition": "Addition"
    },
    "form": {
      "submit": "Créer un Remplacement de Note"
    }
  }
}
</i18n>
