<template>
  <div class="relative">
    <prosemirror-menu
      v-if="!readOnly"
      :items="menuView.items"
      @click="(item: MenuViewItem) => menuView.handleClick(item)"
      class="sticky top-0"
    />
    <prosemirror-editor
      v-model="doc"
      :class="!readOnly ? 'border border-t-0 rounded-bg bg-white px-4 py-3 shadow-inner' : ''"
      output="json"
      :schema="assignmentOverviewSchema"
      :plugins="plugins"
      :read-only="readOnly"
    />
  </div>
</template>
<script setup lang="ts">
import {assignmentOverviewSchema} from './schema/assignment-overview-schema';
import {MenuView, MenuViewItem} from '../prosemirror-editor/views/MenuView';
import ProsemirrorEditor from '../prosemirror-editor/ProsemirrorEditor.vue';
import ProsemirrorMenu from '../prosemirror-editor/components/ProsemirrorMenu.vue';
import {VueLinkMenuView} from '../prosemirror-editor/decorations/LinkDecorations';

const {readOnly} = defineProps<{
  readOnly?: boolean;
}>();

const doc = defineModel<any>();

const menuView = new MenuView(assignmentOverviewSchema, []);
const plugins = [menuView.toPlugin()];
if (!readOnly) {
  const linkMenu = new VueLinkMenuView();
  plugins.push(linkMenu.toPlugin());
}
</script>
