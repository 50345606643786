<template>
  <div v-if="assignment && timelimitInMinutes && student" class="flex flex-col gap-3">
    <timer v-if="timer?.isRunning" :timer="timer" class="w-full" />
    <s-label-value v-else :label="t('totalTimeLimit')" class="flex-1 whitespace-nowrap font-bold">
      <span class="">
        {{ minutesToHumanReadable(studentTimelimitInMinutes) }}
      </span>
    </s-label-value>
    <div v-if="canGrantTimerExtensions" class="flex flex-wrap gap-3">
      <s-badge size="custom" class="px-3.5 py-0.5 text-sm" :color="studentTimerStatusBadgeColor">
        <s-icon
          v-if="studentTimerStatus === 'Complete'"
          name="check"
          size="18"
          class="opacity-70 -ml-0.5"
        />
        {{ studentTimerStatus }}
      </s-badge>
      <div class="flex flex-wrap gap-3 justify-end flex-1">
        <s-link
          :href="
            route('courses.assignments.students.timer_extensions.index', {
              course: course.id,
              assignment: assignment.id,
              user: student.id,
            })
          "
          class="link text-sm flex items-center gap-1"
        >
          <s-icon name="timer-outline" size="16" />
          {{ t('viewTimerExtensions') }}
        </s-link>
        <s-btn
          :href="
            route('courses.assignments.students.timer_extensions.create', {
              course: course.id,
              assignment: assignment.id,
              user: student.id,
              _query: {
                redirect: getCurrentPath(),
              },
            })
          "
        >
          <s-icon name="timer-plus-outline" size="16" />
          {{ t('grantTimerExtension') }}
        </s-btn>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SIcon from '../../../design-system/SIcon.vue';
import SLink from '../../../components/SLink.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {Assignment} from '../../../types/entities/assignment';
import {Course} from '../../../types/entities/course';
import {User} from '../../../types/entities/user';
import {route} from 'ziggy-js';
import SBadge from '../../../design-system/SBadge.vue';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import {useI18n} from 'vue-i18n';
import {minutesToHumanReadable} from '../../../format/dates';
import Timer from './Timer.vue';

const props = defineProps<{
  assignment?: Assignment;
  timelimitInMinutes: number | null | undefined;
  course: Course;
  canGrantTimerExtensions?: boolean;
  student?: User;
  timer?: UserAssignmentTimerDto | null;
}>();

const studentTimelimitInMinutes = computed(() => {
  if (!props.timelimitInMinutes) {
    return 0;
  }

  // During run
  const unclaimedExtensionMinutes =
    props.timer?.extensions.reduce((sum, extension) => {
      return sum + (extension.claimed ? 0 : extension.minutes);
    }, 0) ?? 0;

  // After
  if (props.timer?.hasFinished) {
    return unclaimedExtensionMinutes;
  }

  // Before start
  return props.timelimitInMinutes + unclaimedExtensionMinutes;
});

const studentTimerStatus = computed(() => {
  if (!props.timelimitInMinutes) return 'N/A';

  if (!props.timer || !props.timer.startedAt) {
    return 'Not Started';
  }

  if (props.timer.startedAt && !props.timer.hasFinished) {
    return 'In Progress';
  }

  return 'Complete';
});

const studentTimerStatusBadgeColor = computed(() => {
  switch (studentTimerStatus.value) {
    case 'Not Started':
      return 'gray';
    case 'In Progress':
      return 'blue';
    case 'Complete':
      return 'green';
    default:
      return 'gray';
  }
});

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const getCurrentPath = () => {
  return window.location.pathname;
};
</script>
<i18n>
{
  "en": {
    "totalTimeLimit": "Time Available",
    "viewTimerExtensions": "View Timer Extensions",
    "grantTimerExtension": "Grant Timer Extension"
  },
  "fr": {
    "totalTimeLimit": "Temps disponible",
    "viewTimerExtensions": "Voir les prolongations de temps",
    "grantTimerExtension": "Accorder une prolongation de temps"
  }
}
</i18n>
