import {EditorState} from 'prosemirror-state';
import {inListItem} from './inListItem';

export const getCurrentList = (state: EditorState) => {
  if (!inListItem(state)) {
    return {node: null, pos: null};
  }

  let {$from} = state.selection;

  for (let depth = $from.depth; depth > 0; depth--) {
    let node = $from.node(depth);
    if (node.type.name === 'bullet_list' || node.type.name === 'ordered_list') {
      return {node, pos: $from.before(depth)};
    }
  }

  return {node: null, pos: null};
};
