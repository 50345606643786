<template>
  <!-- eslint-disable -->
  <div class="card padded">
    <h2 class="heading md">Generate Synthetic Student Response Samples</h2>
    <p>
      This will sample different levels of student responses to this task to test out grading
      performance and feedback quality.
    </p>

    <div class="flex items-center mt-4">
      <s-btn
        color="primary"
        @click.prevent="generateSyntheticStudentData"
        :disabled="fetchingSyntheticResponse"
        class="mr-4 ml-4"
      >
        Generate
      </s-btn>
      <template v-if="syntheticResponses && syntheticResponses?.length > 0">
        <s-btn
          color="secondary"
          icon="chevron-left"
          size="sm"
          @click="prevQuestion"
          :disabled="currentQuestionIndex === 0"
        >
          Previous
        </s-btn>

        <span class="mx-2">
          Question {{ currentQuestionIndex + 1 }} of {{ syntheticResponses?.length }}
        </span>

        <s-btn
          class="mr-8"
          color="secondary"
          icon="chevron-right"
          size="sm"
          @click="nextQuestion"
          :disabled="currentQuestionIndex >= syntheticResponses?.length - 1"
        >
          Next
        </s-btn>
      </template>
      <label for="levels" class="mr-2">Number of Levels:</label>
      <input
        id="levels"
        type="number"
        v-model="numberOfLevels"
        max="6"
        min="2"
        step="1"
        class="border rounded p-1"
      />
    </div>

    <div v-if="currentQuestion" class="mt-4">
      <h3 class="font-bold">Question:</h3>
      <p class="mb-4 mt-1">{{ currentQuestion }}</p>
    </div>

    <div v-if="fetchingSyntheticResponse">Loading...</div>
    <div v-if="syntheticResponses">
      <ul>
        <li
          v-for="(response, sampleIndex) in syntheticResponses[currentQuestionIndex].responses"
          :key="sampleIndex"
          class="card padded mb-2"
        >
          {{ response.studentWriting }}
          <br />
          <div class="flex justify-between items-center mt-2">
            <b>
              Grade Estimate:
              {{
                formatGradeEstimate(
                  sampleIndex,
                  syntheticResponses[currentQuestionIndex].responses.length
                )
              }}
            </b>
            <s-btn
              color="secondary"
              icon="clipboard"
              size="sm"
              @click="copyToClipboard(response.studentWriting)"
            >
              Copy
            </s-btn>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, Ref, ref} from 'vue';
import axios from 'axios';
import {route} from 'ziggy-js';
import SBtn from '../../../design-system/SBtn.vue';
import {useForm} from '@inertiajs/vue3';
import {Task, TaskState} from '../../../types/entities/tasks';
import SyntheticAiResponsesDto = App.DTOs.SyntheticAiResponsesDto;

const props = defineProps<{
  task: Task;
  taskState?: TaskState;
}>();

const syntheticResponses: Ref<SyntheticAiResponsesDto[] | null> = ref(null);
const fetchingSyntheticResponse = ref(false);
const currentQuestionIndex = ref(0);
const numberOfLevels = ref(3);

const currentQuestion = computed(() => {
  if (syntheticResponses.value && syntheticResponses.value.length > 0) {
    return syntheticResponses.value[currentQuestionIndex.value].aiQuestion;
  }
  return null;
});

const form = useForm({
  taskId: props.task?.id,
  taskStateId: props.taskState?.id,
  responseData: {},
});

async function generateSyntheticStudentData() {
  fetchingSyntheticResponse.value = true;

  try {
    const response = await axios.post(
      route('api.v1.tasks.responses.synthesize', {
        ...route().params,
        task: props.task.id,
        requestedQuestionIndex: currentQuestionIndex.value,
        requestedNumberOfLevels: numberOfLevels.value,
      }),
      form.data()
    );

    if (!syntheticResponses.value) {
      syntheticResponses.value = response.data;
    } else {
      syntheticResponses.value[currentQuestionIndex.value] =
        response.data[currentQuestionIndex.value];
    }
  } finally {
    fetchingSyntheticResponse.value = false;
  }
}

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

function formatGradeEstimate(sampleIndex: number, responsesLength: number): string {
  const interval = 0.5 / (responsesLength - 1);
  const grade = 0.5 + interval * sampleIndex;

  return `${Math.round(grade * 100)}%`;
}

function prevQuestion() {
  if (currentQuestionIndex.value > 0) {
    currentQuestionIndex.value--;
  }
}

function nextQuestion() {
  if (
    syntheticResponses.value &&
    currentQuestionIndex.value < syntheticResponses.value.length - 1
  ) {
    currentQuestionIndex.value++;
  }
}
</script>
