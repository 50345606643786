<template>
  <s-field v-bind="$props">
    <template v-slot="a11yProps">
      <s-button-toggle
        v-bind="{...a11yProps, ...$attrs}"
        :id="id"
        v-model="model"
        :options="options"
        :class="buttonToggleClass"
      />
    </template>
  </s-field>
</template>

<script setup lang="ts">
import SField from './SField.vue';
import SButtonToggle from './SButtonToggle.vue';

defineProps<{
  id: string;
  label?: string;
  description?: string;
  tooltip?: string;
  options: {value: string | number | boolean | null; label: string}[];
  error?: string;
  required?: boolean;
  buttonToggleClass?: string;
}>();

const model = defineModel<string | number | boolean | null>();
</script>
