<template>
  <s-link
    :href="
      route('courses.assignments.show', {
        course: route().params.course,
        assignment: assignment.id,
      })
    "
    class="@container/card group"
  >
    <s-card padding="none">
      <div class="flex flex-col xl:grid xl:grid-cols-[1fr_auto] justify-between">
        <div class="px-4 md:px-5 lg:px-6 py-2.5 @xl:py-4 flex flex-col gap-3 w-full">
          <div class="flex flex-col @xl:flex-row items-start justify-between gap-y-3 gap-x-4">
            <h4
              class="text-xl leading-tight transition-all ease-out duration-150 group-hover:text-blue-600"
            >
              {{ assignment.name }}
            </h4>
            <div class="flex items-center gap-2 text-sm -mt-0.5 @xl:-mb-1">
              <s-tooltip v-if="visibilityConfig">
                <template #tooltip>
                  {{ visibilityConfig.tooltip }}
                </template>
                <s-badge
                  v-if="visibilityConfig"
                  :color="visibilityConfig.color"
                  class="whitespace-nowrap"
                >
                  <s-icon
                    :name="visibilityConfig.icon"
                    size="18"
                    class="text-grey-500 opacity-70"
                  />
                  {{ t(visibilityConfig.text) }}
                </s-badge>
              </s-tooltip>
              <s-badge v-if="assignment.isReviewRequired" color="blue" class="whitespace-nowrap">
                <s-icon name="alert" size="18" class="text-blue-500 opacity-70" />
                {{ t('badges.reviewRequired') }}
              </s-badge>
              <s-badge color="white" class="whitespace-nowrap">
                {{ t('badges.totalPoints', {points: assignment.pointsAvailable}) }}
              </s-badge>
            </div>
          </div>
          <div
            class="grid gap-y-2 gap-x-6 items-start"
            :class="{
              'grid-cols-[1fr_1fr_auto_auto]':
                assignment.courseLikeAssignmentsInfo.timelimitEnabled,
              'grid-cols-[1fr_1fr_auto]': !assignment.courseLikeAssignmentsInfo.timelimitEnabled,
            }"
          >
            <SLabelValue class="flex-1">
              <template v-slot:label>
                <s-tooltip
                  position="top"
                  v-if="assignment.courseLikeAssignmentsInfo.multipleStartDates"
                >
                  <template #tooltip>
                    {{
                      t('tooltips.earliestStartDate', {
                        numberOfSections: assignment.courseLikeAssignmentsInfo.numberOfSections,
                      })
                    }}
                  </template>
                  <span class="flex items-center gap-1">
                    <s-icon name="warning" class="opacity-70 text-blue-500" size="18" />
                    {{ t('startDate') }}
                  </span>
                </s-tooltip>
                <template v-else>{{ t('startDate') }}</template>
              </template>
              <span v-if="assignment.courseLikeAssignmentsInfo.earliestStartDate">
                <div class="leading-tighter">
                  {{
                    monthDayYear(assignment.courseLikeAssignmentsInfo.earliestStartDate, {
                      time: false,
                    })
                  }}
                </div>
                <span
                  class="text-sm text-gray-600 leading-none font-bold uppercase tracking-slight"
                >
                  {{ timeOfDay(assignment.courseLikeAssignmentsInfo.earliestStartDate) }}
                </span>
              </span>
              <s-badge v-else color="red" icon="alert" size="sm">
                {{ t('badges.notAssigned') }}
              </s-badge>
            </SLabelValue>
            <SLabelValue class="flex-1">
              <template v-slot:label>
                <s-tooltip v-if="assignment.courseLikeAssignmentsInfo.multipleDueDates">
                  <template #tooltip>
                    {{
                      t('tooltips.lastestDueDate', {
                        numberOfSections: assignment.courseLikeAssignmentsInfo.numberOfSections,
                      })
                    }}
                  </template>
                  <span class="flex items-center gap-1">
                    <s-icon
                      name="warning"
                      class="opacity-70 text-blue-500"
                      width="18"
                      height="18"
                      aria-hidden="true"
                    />
                    {{ t('dueDate') }}
                  </span>
                </s-tooltip>
                <template v-else>{{ t('dueDate') }}</template>
              </template>

              <span v-if="assignment.courseLikeAssignmentsInfo.latestDueDate">
                <div class="capitalize leading-tighter">
                  {{
                    monthDayYear(assignment.courseLikeAssignmentsInfo.latestDueDate, {time: false})
                  }}
                </div>
                <span
                  class="text-sm text-gray-600 leading-none font-bold uppercase tracking-slight"
                >
                  {{ timeOfDay(assignment.courseLikeAssignmentsInfo.latestDueDate) }}
                </span>
              </span>
              <s-badge v-else color="red" icon="alert" size="sm">
                {{ t('badges.notAssigned') }}
              </s-badge>
            </SLabelValue>
            <SLabelValue
              v-if="assignment.courseLikeAssignmentsInfo.timelimitEnabled"
              :label="t('timeLimit')"
            >
              <!-- TODO: Deal with multiple different time limits -->
              {{
                assignment.courseLikeAssignmentsInfo.highestTimelimitInMinutes
                  ? minutesToHumanReadable(
                      assignment.courseLikeAssignmentsInfo.highestTimelimitInMinutes
                    )
                  : t('notSet')
              }}
            </SLabelValue>

            <s-dropdown-menu
              :items="[
                {
                  href: route('courses.assignments.settings.edit', {
                    course: route().params.course,
                    assignment: assignment.id,
                  }),
                  icon: 'cog',
                  label: t('actions.edit'),
                },
                {
                  onClick: duplicateAssignment,
                  show: assignmentSpecificPermissions.canDuplicate,
                  icon: 'content-copy',
                  label: t('actions.duplicate'),
                },
                {
                  onClick: () => {
                    showDeleteConfirmationModal = true;
                    assignmentToDelete = assignment;
                  },
                  show: assignmentSpecificPermissions.canDelete,
                  icon: 'trash-can',
                  label: t('actions.delete'),
                },
              ]"
            >
              <template #trigger="{open}">
                <button class="group/button flex items-center justify-center py-0.5 px-2 -mx-3">
                  <s-icon
                    v-if="!open"
                    name="dots-vertical"
                    class="text-gray-600 opacity-50 transition group-hover/button:opacity-100 group-hover/button:text-blue-500"
                    size="20"
                    aria-hidden="true"
                  />
                  <s-icon
                    v-else
                    name="close"
                    class="text-gray-600 opacity-50 transition group-hover/button:opacity-100 group-hover/button:text-blue-500"
                    size="20"
                    aria-hidden="true"
                  />
                  <span class="sr-only">
                    {{ open ? t('actions.closeDropdownMenu') : t('actions.openDropdownMenu') }}
                  </span>
                </button>
              </template>
            </s-dropdown-menu>
          </div>
        </div>
        <div
          class="overflow-hidden rounded-b-lg xl:rounded-none xl:rounded-r-lg bg-gradient-to-b xl:bg-gradient-to-r from-gray-400/10 xl:from-gray-300/10 via-gray-200/10 to-gray-200/10 border-t-[0.5px] xl:border-t-0 xl:border-l-[0.5px] border-gray-200 grid m-[-0.5px] xl:min-w-[24rem]"
          :class="
            links.length === 4
              ? 'grid-cols-[auto_auto] @lg/card:grid-cols-[auto_auto]'
              : 'grid-cols-[repeat(auto-fit,_minmax(8rem,_1fr))]'
          "
        >
          <template v-for="link in links">
            <s-link
              :href="link.route"
              class="text-sm lg:text-base px-4 py-2.5 hover:bg-white/70 text-center underline underline-offset-2 decoration-transparent hover:decoration-blue-200/50 active:decoration-blue-300 decoration-2 font-medium text-blue-500 hover:text-blue-400 active:text-blue-600 tracking-slight transition-all ease-out duration-100 flex items-center justify-center gap-1.5 border border-gray-200 m-[-0.5px]"
            >
              <s-icon :name="link.icon" />
              {{ link.name }}
            </s-link>
          </template>
        </div>
      </div>
    </s-card>
  </s-link>
  <s-modal
    v-model:open="showDeleteConfirmationModal"
    :title="t('deleteModal.title', {assignmentName: assignmentToDelete?.name})"
    :message="t('deleteModal.message')"
    :confirm="{
      label: 'Delete',
      icon: 'trash-can',
      color: 'red',
    }"
    @confirm="deleteAssignment"
    cancellable
  />
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {route} from 'ziggy-js';
import SLink from '../../../components/SLink.vue';
import SModal from '../../../design-system/SModal.vue';
import SDropdownMenu from '../../../design-system/SDropdownMenu.vue';
import SCard from '../../../design-system/SCard.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SBadge from '../../../design-system/SBadge.vue';
import STooltip from '../../../design-system/STooltip.vue';
import {minutesToHumanReadable, monthDayYear, timeOfDay} from '../../../format/dates';
import {router} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';
import StaffCourseAssignmentRowDto = App.DTOs.CourseAssignments.StaffCourseAssignmentRowDto;
import CourseAssignmentPermissionsDto = App.DTOs.CourseAssignments.CourseAssignmentPermissionsDto;

const props = defineProps<{
  assignment: StaffCourseAssignmentRowDto;
  assignmentSpecificPermissions: CourseAssignmentPermissionsDto;
  canGrantAssignmentExtensions: boolean;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const links = computed(() => {
  let links = [
    {
      name: t('links.preview'),
      route: route('web.courses.assignments.tasks.first', {
        course: route().params.course,
        assignment: props.assignment.id,
      }),
      icon: 'eye',
    },
    {
      name: t('links.grades'),
      route: route('courses.assignments.students.index', {
        course: route().params.course,
        assignment: props.assignment.id,
      }),
      icon: 'table-check',
    },
    {
      name: t('links.show'),
      route: route('courses.assignments.show', {
        course: route().params.course,
        assignment: props.assignment.id,
      }),
      icon: 'format-list-bulleted',
    },
  ];

  if (props.canGrantAssignmentExtensions) {
    links.push({
      name: t('extensions'),
      route: route('courses.assignments.extension.index', {
        course: route().params.course,
        assignment: props.assignment.id,
      }),
      icon: 'calendar-arrow-right',
    });
  }

  return links;
});

const showDeleteConfirmationModal = ref(false);
const assignmentToDelete = ref<StaffCourseAssignmentRowDto | null>(null);
const deleteAssignment = () => {
  showDeleteConfirmationModal.value = false;
  assignmentToDelete.value = null;

  router.delete(
    route('courses.assignments.delete', {
      course: route().params.course,
      assignment: props.assignment.id,
    })
  );
};
const duplicateAssignment = () => {
  router.post(
    route('courses.assignments.copy', {
      course: route().params.course,
      assignment: props.assignment.id,
    })
  );
};

const visibilityConfig = computed(() => {
  const visibilityMap: Record<
    string,
    {
      color: 'blue-light' | 'green';
      text: string;
      tooltip: string;
      icon: string;
    }
  > = {
    visible: {
      color: 'green',
      text: 'badges.visible',
      tooltip: t('tooltips.visibility.visible'),
      icon: 'eye',
    },
    partial: {
      color: 'blue-light',
      text: 'badges.partiallyVisible',
      icon: 'eye-minus',
      tooltip: t('tooltips.visibility.partial'),
    },
    hidden: {
      icon: 'eye-off',
      color: 'blue-light',
      text: 'badges.notVisible',
      tooltip: t('tooltips.visibility.hidden'),
    },
  };
  return visibilityMap[props.assignment.visibility];
});
</script>
<i18n>
{
  "en": {
    "notSet": "Not Set",
    "badges": {
      "reviewRequired": "Needs Review",
      "totalPoints": "{points} Total Points",
      "notAssigned": "Not Assigned",
      "visible": "Visible",
      "notVisible": "Not Visible",
      "partiallyVisible": "Partially Visible"
    },
    "links": {
      "preview": "Preview",
      "show": "Overview",
      "grades": "Grades",
      "extensions": "Extensions"
    },
    "tooltips": {
      "earliestStartDate": "This is the earliest start date of the
      { numberOfSections } assigned sections",
      "lastestDueDate": "This is the latest due date of the
      { numberOfSections } assigned sections",
      "visibility": {
        "visible": "All students who are assigned this assignment can now see it.",
        "partial": "Only some of the sections of students who are assigned this assignment can now see it.",
        "hidden": "This assignment isn't visible to any students yet."
      }
    },
    "deleteModal": {
      "title": "Delete {assignmentName}",
      "message": "Are you sure you want to delete this assignment? This action cannot be undone."
    }
  },
  "fr": {
    "notSet": "Non défini",
    "badges": {
      "reviewRequired": "Besoin de révision",
      "totalPoints": "{points} Points en total",
      "notAssigned": "Non attribué",
      "visible": "Visible",
      "notVisible": "Non visible",
      "partiallyVisible": "Partiellement visible"
    },
    "links": {
      "preview": "Aperçu",
      "show": "Voir",
      "grades": "Notes",
    },
    "tooltips": {
      "earliestStartDate": "C'est la date de début la plus tôt des
      { numberOfSections } sections assignées",
      "lastestDueDate": "C'est la date de fin la plus tardive des
      { numberOfSections } sections assignées",
      "visibility": {
        "visible": "Tous les étudiants qui sont assignés à cet
        exercice peuvent maintenant le voir.",
        "partial": "Seules certaines sections d'étudiants qui sont
        assignées à cet exercice peuvent maintenant le voir.",
        "hidden": "Cet exercice n'est pas encore visible pour
        les étudiants."
      }
    },
    "deleteModal": {
      "title": "Supprimer {assignmentName}",
      "message": "Êtes-vous sûr de vouloir supprimer cet exercice?
      Cette action ne peut pas être annulée."
    }
  }
}
</i18n>
