<template>
  <s-icon
    :name="activityIconMap[activityTypeName]"
    :class="activityColorMap[activityTypeName]"
    size="24"
  />
</template>
<script setup lang="ts">
import SIcon from '../../../design-system/SIcon.vue';

defineProps<{
  activityTypeName: string;
}>();

const activityIconMap: {[key: string]: string} = {
  NAVIGATED_TO_ASSIGNMENT_TASK: 'arrow-right-thick',
  TASK_RESPONSE_SUBMIT_ATTEMPTED: 'send',
  TASK_RESPONSE_SUBMIT_SUCCEEDED: 'check-decagram',
  TASK_RESPONSE_SUBMIT_FAILED: 'alert-octagram',
  TASK_RESPONSE_DRAFT_STORE_ATTEMPTED: 'pencil',
  TASK_RESPONSE_DRAFT_STORE_SUCCEEDED: 'check-circle',
  TASK_RESPONSE_DRAFT_STORE_FAILED: 'alert-circle',
  TASK_RESPONSE_DRAFT_DELETED: 'trash-can',
  LAB_SIM_ITEM_TASK_COMPLETED: 'beaker',
  LOGIN: 'login',
  LOGOUT: 'logout',
};

const activityColorMap: {[key: string]: string} = {
  NAVIGATED_TO_ASSIGNMENT_TASK: 'text-blue-400',
  TASK_RESPONSE_SUBMIT_ATTEMPTED: 'text-blue-400',
  TASK_RESPONSE_SUBMIT_SUCCEEDED: 'text-green-500',
  TASK_RESPONSE_SUBMIT_FAILED: 'text-red-500',
  TASK_RESPONSE_DRAFT_STORE_ATTEMPTED: 'text-gray-400',
  TASK_RESPONSE_DRAFT_STORE_SUCCEEDED: 'text-green-500',
  TASK_RESPONSE_DRAFT_STORE_FAILED: 'text-red-500',
  TASK_RESPONSE_DRAFT_DELETED: 'text-red-500',
  LAB_SIM_ITEM_TASK_COMPLETED: 'text-green-500',
  LOGIN: 'text-blue-400',
  LOGOUT: 'text-orange-500',
};
</script>
