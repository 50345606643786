<template>
  <Head :title="`${t('page.title')} - ${course.code}`"></Head>
  <s-page-block-scrollable class="@container">
    <template #header>
      <s-page-header :heading="t('page.title')" class="mb-4">
        <template #actions>
          <s-btn
            icon="table"
            :href="
              route('courses.grades.download', {
                course: props.course.id,
                search: searchText,
                sectionId,
                orderBy,
                orderByDirection,
              })
            "
            external
            target="_blank"
          >
            {{ t('actions.exportGrades') }}
          </s-btn>
        </template>
      </s-page-header>
      <s-search-section
        route-name="courses.grades.index"
        :label="t('search.byNameOrEmail')"
        :paginatedResults="participantsAndAssignmentsGrades.data.length || 0"
        :totalResults="participantsAndAssignmentsGrades.total || 0"
        class="mb-4"
      >
        <template #filters>
          <s-select-field
            id="section"
            :label="t('section')"
            v-model="sectionId"
            @change="filterChange()"
          >
            <option value="">{{ t('allSections') }}</option>
            <option v-for="section in orderedSections" :key="section.id" :value="`${section.id}`">
              {{ section.name }}
            </option>
          </s-select-field>
          <s-select-field
            id="sort"
            :label="t('table.orderBy')"
            v-model="orderById"
            @change="sortChange()"
          >
            <option value="">{{ t('default') }}</option>
            <option v-for="option in sortOptions" :key="option.id" :value="`${option.id}`">
              {{ option.name }}
            </option>
          </s-select-field>
        </template>
        <template #badges>
          <s-search-badge
            v-if="orderBy"
            @clear="setOrderBy('')"
            :clearLabel="t('table.clearOrdering')"
          >
            {{ t('table.orderedBy') }}
            <strong class="text-blue-600 font-bold">
              {{
                orderBy === 'lastName'
                  ? t('lastName')
                  : orderBy === 'firstName'
                    ? t('firstName')
                    : t('email')
              }},
            </strong>
            <strong class="text-blue-600 font-bold">
              {{
                (route().params as PageParams).orderByDirection === 'ASC'
                  ? t('table.az')
                  : t('table.za')
              }}
            </strong>
          </s-search-badge>
        </template>
      </s-search-section>
    </template>
    <div
      class="shrink overflow-y-scroll relative w-full m-auto z-[1] overflow-auto rounded-lg shadow-card grades-table-wrapper"
    >
      <table
        class="grades-table"
        :style="{
          'min-width': `${assignedAssignments.length * 175 + 400}px`,
        }"
      >
        <thead class="">
          <tr>
            <th
              class="text-left text-gray-600 truncate align-bottom"
              :style="{
                // Set the column width based on the longest student name or email, within limits
                width: `${Math.max(Math.min(longestStudentNameOrEmail, 45), 12)}ch`,
              }"
            >
              <span class="flex items-center gap-1">{{ t('roleNames.Student') }}</span>
            </th>
            <th
              class="text-left text-gray-600 align-bottom"
              :style="{
                // Set the column width based on the longest section name, within limits
                width: `${Math.max(Math.min(longestSectionName, 35), 10)}ch`,
              }"
            >
              {{ t('section') }}
            </th>
            <!-- Header row with dynamic column names -->
            <th
              v-for="(column, columnIndex) in assignedAssignments"
              :key="columnIndex"
              class="text-right text-gray-600 align-bottom"
              :class="{
                'cursor-zoom-in min-w-[11rem] max-w-[11rem]': expandedColumn !== columnIndex,
                'cursor-zoom-out w-0 min-w-[11rem]': expandedColumn === columnIndex,
              }"
              @click="expandedColumn = expandedColumn === columnIndex ? null : columnIndex"
              :title="column.name"
            >
              <p
                class=""
                :class="{
                  'block truncate line-clamp-3 whitespace-normal': expandedColumn !== columnIndex,
                  'whitespace-nowrap': expandedColumn === columnIndex,
                }"
              >
                {{ column.name }}
              </p>
            </th>
          </tr>
        </thead>
        <tbody class="rounded-lg shadow">
          <tr v-if="participantsAndAssignmentsGrades.data.length < 1">
            <td
              :colspan="2 + assignedAssignments?.length"
              class="italic text-center !py-5 text-gray-400"
            >
              {{ t('table.noResults') }}
            </td>
          </tr>
          <tr v-for="participant in participantsAndAssignmentsGrades.data">
            <td class="text-left">
              <div class="flex flex-col gap-0.5 whitespace-nowrap">
                <s-link
                  :href="route('courses.participants.show', [course.id, participant.id])"
                  class="student-link"
                >
                  {{ participant.firstName }}
                  <strong class="font-bold">{{ participant.lastName }}</strong>
                </s-link>
                <span class="text-sm leading-tight text-gray-600">{{ participant.email }}</span>
              </div>
            </td>
            <td class="text-left whitespace-nowrap">
              {{ participant.sectionNames }}
            </td>
            <!-- Loop through each item's properties to populate columns -->
            <td
              v-for="(assignment, columnIndex) in assignedAssignments"
              :key="columnIndex"
              class="text-right"
            >
              <s-link
                :href="
                  route('courses.assignments.marking.tasks.first', {
                    course: course.id,
                    assignment: assignment.id,
                    user: participant.id,
                    _query: {sectionFilter: sectionId},
                  })
                "
                class="link"
              >
                <s-grade
                  :grade="findParticipantAssignmentGrade(participant, assignment)"
                  :grade-display="course.gradeDisplay"
                  tooltip
                ></s-grade>
              </s-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <s-paginator
        :paginator="participantsAndAssignmentsGrades"
        :only="dataThatChangesOnPageChange"
        preserve-state
        class="mt-5"
      />
    </template>
  </s-page-block-scrollable>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import {Head, router} from '@inertiajs/vue3';
import SLink from '../../components/SLink.vue';
import {route} from 'ziggy-js';
import {computed, ref} from 'vue';
import {Course} from '../../types/entities/course';
import SPageBlockScrollable from '../../design-system/SPageBlockScrollable.vue';
import SSelectField from '../../design-system/SSelectField.vue';
import SSearchBadge from '../../design-system/SSearchBadge.vue';
import SGrade from '../../components/SGrade.vue';
import SSearchSection from '../../design-system/SSearchSection.vue';
import {LaravelPaginator} from '../../types/laravel-paginator';
import SPaginator from '../../design-system/SPaginator.vue';
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;
import SPageHeader from '../../design-system/SPageHeader.vue';
import SBtn from '../../design-system/SBtn.vue';
import {usePageParams} from '../../composables/usePageParams';

type PageParams = {
  orderByDirection?: 'ASC' | 'DESC';
  orderBy?: string;
  search?: string;
  sectionId?: string;
  roleId?: string;
};

type Participant = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  sectionNames: string;
  grades: AssignmentGradeDto[];
};

const props = defineProps<{
  course: Course;
  sections: {id: number; name: string}[];
  assignedAssignments: {id: number; name: string}[];
  participantsAndAssignmentsGrades: LaravelPaginator<Participant>;
}>();

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});

let {
  search: searchText,
  sectionId,
  orderBy,
  orderByDirection,
} = usePageParams<PageParams>({
  sectionId: '',
  orderBy: '',
  orderByDirection: 'ASC',
});

const setOrderBy = (field: string) => {
  if (!field) {
    orderBy = '';
    orderByDirection = 'ASC';
  } else if (field === orderBy) {
    orderByDirection = orderByDirection === 'ASC' ? 'DESC' : 'ASC';
  } else {
    orderBy = field;
    orderByDirection = 'ASC';
  }
  submit();
};

const orderedSections = props.sections;

const sortOptions: {id: string; orderBy: string; direction: 'ASC' | 'DESC'; name: string}[] = [
  {
    id: 'lastNameAsc',
    orderBy: 'lastName',
    direction: 'ASC',
    name: `${t('lastName')} ${t('table.az')}`,
  },
  {
    id: 'lastNameDesc',
    orderBy: 'lastName',
    direction: 'DESC',
    name: `${t('lastName')} ${t('table.za')}`,
  },
  {
    id: 'firstNameAsc',
    orderBy: 'firstName',
    direction: 'ASC',
    name: `${t('firstName')} ${t('table.az')}`,
  },
  {
    id: 'firstNameDesc',
    orderBy: 'firstName',
    direction: 'DESC',
    name: `${t('firstName')} ${t('table.za')}`,
  },
  {id: 'emailAsc', orderBy: 'email', direction: 'ASC', name: `${t('email')} ${t('table.az')}`},
  {id: 'emailDesc', orderBy: 'email', direction: 'DESC', name: `${t('email')} ${t('table.za')}`},
];

const orderById = ref(
  sortOptions.find((option) => option.orderBy === orderBy && option.direction === orderByDirection)
    ?.id || ''
);

const sortChange = () => {
  const selectedSort = sortOptions.find((option) => option.id === orderById.value);
  if (selectedSort) {
    orderBy = selectedSort.orderBy;
    orderByDirection = selectedSort.direction;
    submit();
  } else {
    orderBy = '';
    orderByDirection = 'ASC';
    submit();
  }
};

const filterChange = () => {
  submit();
};

const submit = () => {
  router.visit(
    route('courses.grades.index', {
      course: props.course.id,
      search: searchText,
      sectionId,
      orderBy,
      orderByDirection,
    })
  );
};

const clearSearchParams = computed(() => {
  return {
    ...route().params,
    search: undefined,
  };
});

const dataThatChangesOnPageChange = ['participantsAndAssignmentsGrades'];

const longestStudentNameOrEmail = computed(() => {
  return Math.max(
    ...props.participantsAndAssignmentsGrades.data.map((item) => {
      const nameLength = `${item.firstName} ${item.lastName}`.length;
      const emailLength = item.email ? item.email.length : 0; // Handle cases where email might be undefined
      return Math.max(nameLength, emailLength);
    })
  );
});

const longestSectionName = computed(() => {
  return Math.max(
    ...props.participantsAndAssignmentsGrades.data.map((item) => {
      return item.sectionNames.length;
    })
  );
});

const expandedColumn = ref<number | null>(null);

function findParticipantAssignmentGrade(participant: Participant, assignment: {id: number}) {
  return participant.grades.find((item) => item.assignmentId === +assignment.id);
}
</script>
<style scss>
.grades-table-wrapper {
  /* https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ */
  background: /* Shadow Cover RIGHT */
    linear-gradient(to left, white 1rem, rgba(255, 255, 255, 0)) center right,
    /* Shadow RIGHT */ linear-gradient(to left, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) center
      right,
    /* Shadow Cover BOTTOM */ linear-gradient(to top, white 1rem, rgba(255, 255, 255, 0)) bottom
      center,
    /* Shadow BOTTOM */ linear-gradient(to top, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) bottom
      center,
    white;
  background-repeat: no-repeat;
  background-size:
    3rem 100%,
    1rem 100%,
    100% 3rem,
    100% 1rem,
    100% 100%;
  background-attachment: local, scroll, local, scroll, local;
}

.grades-table {
  @apply border-separate border-spacing-0 w-full;

  thead {
    th {
      @apply shadow-sm relative px-2 first:pl-4 last:pr-4 md:px-3 py-3 leading-[1.125] bg-gray-50 sticky top-0 z-[10] border-y border-r border-gray-200 first:z-[20] first:left-0 first:rounded-tl-lg first:border-l last:rounded-tr-lg last:border-r;
    }
  }

  tbody {
    th,
    td {
      @apply relative px-2 first:pl-4 last:pr-4 md:px-3 py-3 leading-[1.125] border-r first:border-x border-gray-200 first:sticky first:left-0 first:z-[5] first:bg-white border-b border-gray-200;
    }

    tr {
      &:nth-child(even) td {
        @apply bg-gray-300/15 first:bg-gray-50;
      }

      &:last-child {
        td {
          @apply first:rounded-bl-lg last:rounded-br-lg;
        }
      }
    }
  }
}

.student-link {
  @apply underline underline-offset-2 decoration-transparent hover:decoration-blue-200/50 active:decoration-blue-300 decoration-2 font-normal text-blue-600 hover:text-blue-500 active:text-blue-700 tracking-slight transition-all ease-out duration-100 text-lg leading-tight truncate;
}
</style>
<i18n>
{
  "en": {
    "page": {
      "title": "Course Grades"
    }
  },
  "fr": {
    "page": {
      "title": "Notes du cours"
    }
  }
}
</i18n>
