<template>
  <div
    :class="[
      wrapper
        ? 'sticky bottom-0 py-2.5 pb-4 md:py-4 md:pb-5 border-t border-gray-150 bg-white shadow-soft z-30'
        : '',
    ]"
  >
    <div
      :class="[
        'flex flex-wrap justify-between gap-2',
        wrapper ? 'px-5 lg:px-7' : '',
        sizeClasses[size],
      ]"
    >
      <s-btn
        type="button"
        color="white"
        class="flex-1 max-w-[16rem]"
        :href="cancelRoute"
        external
        icon="close"
      >
        {{ t('form.cancel') }}
      </s-btn>
      <div class="flex-grow flex gap-3 justify-end">
        <s-badge
          v-if="formIsDirty"
          color="yellow"
          size="custom"
          class="animate-slide-in-up px-5 py-2 text-sm leading-none font-bold"
        >
          <s-icon name="alert" size="18" class="opacity-70" />
          <span class="hidden md:inline-block">
            {{ t('form.unsavedChanges') }}
          </span>
        </s-badge>
        <s-btn
          :icon="submitIcon || 'floppy'"
          :processing="formProcessing"
          type="submit"
          class="flex-1 max-w-[16rem]"
        >
          {{ submitLabel || t('form.save') }}
        </s-btn>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import SIcon from './SIcon.vue';
import SBtn from './SBtn.vue';
import SBadge from './SBadge.vue';

const {size = 'lg', wrapper = true} = defineProps<{
  cancelRoute: string;
  formProcessing: boolean;
  formIsDirty: boolean;
  size?: 'full' | 'lg' | 'md' | 'sm';
  wrapper?: boolean;
  submitLabel?: string;
  submitIcon?: string;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const sizeClasses: Record<string, string> = {
  full: '',
  lg: 'max-w-screen-xl mx-auto',
  md: 'max-w-screen-lg mx-auto',
  sm: 'max-w-screen-sm mx-auto',
};
</script>
