import {Node, NodeSpec} from 'prosemirror-model';

export const gdoc_embed_spec: NodeSpec = {
  attrs: {
    src: {default: null},
  },
  group: 'block',
  draggable: true,
  parseDOM: [
    {
      tag: 'iframe.gdoc-embed',
      getAttrs(dom) {
        return {
          src: dom.getAttribute('src'),
        };
      },
    },
  ],
  toDOM(node: Node) {
    return [
      'div',
      {
        class: 'gdoc-embed-wrapper',
      },
      [
        'iframe',
        {
          class: 'gdoc-embed',
          src: node.attrs.src,
          width: '100%',
          height: '600px',
          frameborder: '0',
          allowfullscreen: 'true',
        },
      ],
    ];
  },
};
