<template>
  <s-field id="richTextContent" label="Content" :error="errors?.content">
    <task-rich-text-editor
      id="richTextContent"
      label="Content"
      :model-value="html"
      :error="errors?.html"
      :variables
      @update:modelValue="emit('update:html', $event)"
    />
  </s-field>
</template>

<script setup lang="ts">
import TaskRichTextEditor from '../../../../editors/task-rich-text-editor/TaskRichTextEditor.vue';
import SField from '../../../../design-system/SField.vue';
import NumberVariableDto = App.DTOs.Tasks.Variables.NumberVariableDto;

const {variables} = defineProps<{
  html: string;
  errors?: Record<string, any>;
  variables?: NumberVariableDto[];
}>();

const emit = defineEmits<{
  (evt: 'update:html', text: string): void;
}>();
</script>
