<template>
  <Head :title="`Admin - Task Feedbacks - ${task.title}`"></Head>
  <div class="page-block gap-5">
    <s-page-header :heading="`Task Feedbacks - ${task.title}`">
      <template #actions>
        <s-btn
          :href="route('admin.tasks.feedbacks.create', {task: task.id})"
          color="primary"
          icon="plus"
        >
          Create Feedback
        </s-btn>
      </template>
    </s-page-header>
    <div class="space-y-4">
      <s-placeholder v-if="feedbacks.length === 0" class="!py-1 font">
        <span class="text-sm">There are no feedbacks to display.</span>
      </s-placeholder>
      <s-card v-for="feedback in feedbacks" :key="feedback.id" :heading="feedback.key">
        <template #actions>
          <div class="flex gap-2">
            <s-btn
              type="button"
              color="primary"
              size="sm"
              icon="pencil"
              :href="route('admin.tasks.feedbacks.edit', {task: task.id, feedback: feedback.id})"
            />
            <s-btn
              type="button"
              color="danger"
              size="sm"
              icon="trash-can-outline"
              @click="openDeleteConfirmation(feedback)"
            />
          </div>
        </template>

        <div class="space-y-2">
          <div>
            <span class="font-medium">Short Content:</span>
            <p class="text-gray-600">{{ feedback.shortContent }}</p>
          </div>
          <div>
            <span class="font-medium">Content:</span>
            <p class="text-gray-600 whitespace-pre-wrap">{{ feedback.content }}</p>
          </div>
        </div>
      </s-card>
    </div>

    <s-modal
      v-model:open="showDeleteModal"
      size="sm"
      title="Confirm Delete"
      :confirm="{
        label: 'Delete Feedback',
        icon: 'trash-can',
        color: 'red',
      }"
      @confirm="handleDelete"
      @close="showDeleteModal = false"
      cancellable
    >
      <template #content>
        <p>Are you sure you want to delete this feedback?</p>
      </template>
    </s-modal>
  </div>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {router} from '@inertiajs/vue3';
import SBtn from '../../../design-system/SBtn.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Head} from '@inertiajs/vue3';
import {Task} from '../../../types/entities/tasks';
import TaskFeedbackDto = App.DTOs.Entities.Tasks.TaskFeedbackDto;
import {ref} from 'vue';
import SCard from '../../../design-system/SCard.vue';
import SModal from '../../../design-system/SModal.vue';
import SPlaceholder from '../../../design-system/SPlaceholder.vue';

const props = defineProps<{
  task: Task;
  feedbacks: TaskFeedbackDto[];
}>();

const showDeleteModal = ref(false);
const feedbackToDelete = ref<TaskFeedbackDto | null>(null);

const openDeleteConfirmation = (feedback: TaskFeedbackDto) => {
  feedbackToDelete.value = feedback;
  showDeleteModal.value = true;
};

const handleDelete = () => {
  if (!feedbackToDelete.value) {
    return;
  }

  return router.delete(
    route('admin.tasks.feedbacks.destroy', {
      task: props.task.id,
      feedback: feedbackToDelete.value.id,
    })
  );
};
</script>
<style scoped></style>
