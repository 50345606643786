import {InertiaForm, router} from '@inertiajs/vue3';
import {onUnmounted} from 'vue';
import {useI18n} from 'vue-i18n';

/**
 * Confirms if the user wants to leave the page when the form is dirty.
 *
 * ### Actions that trigger the confirmation:
 * - Clicking on a link (inertia or external)
 * - Refreshing the page
 * - Entering a new URL in the address bar
 * - Closing the tab or the browser
 *
 * ### Actions that DO NOT trigger the confirmation:
 * There are several limitations due to the way Inertia's `router.on('before')` works.
 * - Submitting the form
 * - Pressing the back or forward buttons in the browser
 * - Pressing CMD/CTRL + [  or CMD/CTRL + ] to navigate through the history
 */
export function useLeavingFormConfirmation(form: InertiaForm<any>) {
  const {t} = useI18n({
    useScope: 'global',
    inheritLocale: true,
  });

  let message = t('confirmLeavingForm');

  // Handle browser navigation (refresh, close, etc)
  const beforeUnload = (e: BeforeUnloadEvent) => {
    if (form.isDirty) {
      // Note: Setting returnValue is deprecated but still required for browser compatibility
      // Modern browsers standardized on showing a generic message regardless of this value
      e.returnValue = message;
      return message;
    }
  };

  window.addEventListener('beforeunload', beforeUnload);

  // Handle Inertia navigation
  const removeListener = router.on('before', (event) => {
    const method = event.detail.visit.method.toLowerCase();
    const isFormSubmission = ['post', 'put', 'patch', 'delete'].includes(method);

    if (!isFormSubmission && form.isDirty && !confirm(message)) {
      event.preventDefault();
    }
  });

  onUnmounted(() => {
    window.removeEventListener('beforeunload', beforeUnload);
    removeListener();
  });
}
