<template>
  <s-btn color="primary">Primary (Default)</s-btn>
  <s-btn color="primary-light">Primary Light</s-btn>
  <s-btn color="primary-white">Primary White</s-btn>
  <s-btn color="blue">Blue</s-btn>
  <s-btn color="blue-light">Blue Light</s-btn>
  <s-btn color="secondary">Secondary</s-btn>
  <s-btn color="white">White</s-btn>
  <s-btn color="success">Success</s-btn>
  <s-btn color="success-white">Success White</s-btn>
  <s-btn color="green">Green</s-btn>
  <s-btn color="green-light">Green Light</s-btn>
  <s-btn color="light">Light</s-btn>
  <s-btn color="gray">Gray</s-btn>
  <s-btn color="ghost">Ghost</s-btn>
  <s-btn color="ghost-red">Ghost Red</s-btn>
  <s-btn color="link">Link</s-btn>
  <s-btn color="link-red">Link Red</s-btn>
  <s-btn color="danger">Danger</s-btn>
  <s-btn color="error">Error</s-btn>
  <s-btn color="red">Red</s-btn>
  <s-btn color="red-light">Red Light</s-btn>
  <s-btn color="error-white">Error White</s-btn>
  <s-btn color="danger-white">Danger White</s-btn>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

const description = `Color is a powerful way to communicate the meaning of a button.

Use color to indicate the primary action, secondary action, or a destructive action.`;

export const options: ExampleOptions = {
  description,
};
</script>
