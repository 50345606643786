<template>
  <Head :title="`Admin - Import Assignments - ${course.name}`"></Head>
  <s-page-block size="md">
    <s-page-header
      :overline="course.institution.name"
      :heading="`${course.code} - ${course.name} - Import Assignments`"
      class="mb-4"
    >
      <template #actions>
        <s-btn
          color="blue-light"
          icon="arrow-right"
          iconEnd
          class="w-fit"
          :href="route('courses.assignments.index', {course: course.id})"
        >
          Go to Assignments
        </s-btn>
      </template>
    </s-page-header>

    <form
      @submit.prevent="
        form.post(
          route('admin.courses.assignments.copy', {
            course: course.id,
            sourceCourse: params.fromCourseId,
          })
        )
      "
    >
      <s-card>
        <div class="stack vertical gap-4">
          <s-select-field
            id="fromInstitution"
            label="From Institution"
            :model-value="params.fromInstitutionId ?? course.institution.id"
            @update:modelValue="selectInstitution"
          >
            <option v-for="institution in availableInstitutions" :value="institution.id">
              {{ institution.name }}
            </option>
          </s-select-field>

          <s-select-field
            id="fromInstitution"
            label="From Course"
            :model-value="params.fromCourseId"
            @update:modelValue="selectCourse"
          >
            <option v-for="course in availableCourses" :value="course.id">
              {{ course.code }} -
              {{ shortMonthYear(course.startDate.date) }}
            </option>
          </s-select-field>

          <div v-if="params.fromCourseId" class="min-w-0 flex flex-col items-stretch gap-1 pb-1">
            <s-label>Choose Assignments to Copy?</s-label>
            <s-button-toggle
              id="chooseAssignments"
              name="chooseAssignments"
              :options="[
                {value: false, label: 'No'},
                {value: true, label: 'Yes'},
              ]"
              v-model="form.chooseAssignments"
            />
          </div>
          <s-multi-select-field
            v-if="form.chooseAssignments"
            v-model="form.assignmentsToImport"
            id="assignedSections"
            label="Assignments to Import"
            emptyLabel="Please Select the Assignments to Import"
            :options="availableAssignments"
            id-key="id"
            value-key="id"
          >
            <template #selected="{selected}: {selected: ImportSourceAssignment[]}">
              <span class="comma-separated">
                <span v-for="option in selected">
                  {{ option.name }}
                </span>
              </span>
            </template>

            <template #option="{option}: {option: ImportSourceAssignment}">
              <div class="flex justify-between w-full @container/task-select">
                <span class="flex-1 truncate">
                  {{ option.name }}
                </span>
              </div>
            </template>
          </s-multi-select-field>
        </div>
        <template #footer>
          <div class="flex justify-between flex-wrap gap-x-2 gap-y-3">
            <s-btn
              color="secondary"
              icon="close"
              :href="route('admin.courses.show', course.id)"
              type="button"
            >
              Cancel
            </s-btn>
            <s-btn
              v-if="params.fromCourseId"
              type="submit"
              icon="floppy"
              class="w-fit"
              :processing="form.processing"
            >
              Import Assignments
            </s-btn>
          </div>
        </template>
      </s-card>
    </form>
  </s-page-block>
</template>

<script setup lang="ts">
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Course} from '../../../types/entities/course';
import {route} from 'ziggy-js';
import {router, useForm} from '@inertiajs/vue3';
import {shortMonthYear} from '../../../format/dates';
import {LaravelDate} from '../../../types/laravel-date';
import SCard from '../../../design-system/SCard.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {Head} from '@inertiajs/vue3';
import SSelectField from '../../../design-system/SSelectField.vue';
import {usePageParams} from '../../../composables/usePageParams';
import {Institution} from '../../../types/entities/institution';
import SLabel from '../../../design-system/SLabel.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SMultiSelectField from '../../../design-system/SMultiSelectField.vue';

type ImportSourceCourse = {
  id: number;
  code: string;
  startDate: LaravelDate;
};

type ImportSourceAssignment = {
  id: number;
  name: string;
};

const props = defineProps<{
  course: Course;
  availableInstitutions: Institution[];
  availableCourses: ImportSourceCourse[];
  availableAssignments: ImportSourceAssignment[];
}>();

type PageParams = {
  fromInstitutionId?: number;
  fromCourseId?: number;
};

const params = usePageParams<PageParams>();

const form = useForm<{
  chooseAssignments: boolean;
  assignmentsToImport: number[];
}>({
  chooseAssignments: false,
  assignmentsToImport: [],
});

const selectInstitution = (institutionId: number) => {
  router.visit(
    route('admin.courses.assignments.import', {
      course: props.course.id,
      _query: {
        fromInstitutionId: institutionId,
        fromCourseId: undefined,
      },
    })
  );
};

const selectCourse = (courseId: number) => {
  router.visit(
    route('admin.courses.assignments.import', {
      course: props.course.id,
      _query: {
        fromInstitutionId: params.fromInstitutionId,
        fromCourseId: courseId,
      },
    })
  );
};
</script>
<style scoped></style>
