<template>
  <Head :title="`${t('heading')} - ${user.email} - ${course.code}`"></Head>
  <s-page-block-scrollable>
    <template #header>
      <s-page-header :heading="t('heading')" class="mb-4"></s-page-header>
    </template>

    <fieldset class="grid md:grid-cols-2 lg:grid-cols-3 gap-3 mb-4">
      <s-multi-select
        id="activityTypes"
        :label="t('search.activityTypes')"
        :model-value="selectedActivityTypes"
        value-key="value"
        label-key="description"
        :empty-label="t('search.activityTypes')"
        :options="translatedActivityTypes"
        @update:model-value="
          params.activityTypes = $event;
          debouncedSubmitSearch({activityTypes: $event});
        "
        class="md:col-span-2 lg:col-span-1"
      >
        <template #option="{option}">
          <div class="flex items-center gap-1">
            <activity-icon :activity-type-name="option.name" />
            <span>{{ option.description }}</span>
          </div>
        </template>
      </s-multi-select>

      <s-combobox
        :model-value="selectedAssignmentId"
        :displayValue="(assignment?: any) => assignment?.name"
        :options="allAssignments"
        id-key="id"
        :emptyLabel="t('allAssignments')"
        :filter-by="['name']"
        @update:modelValue="submitSearch({assignmentId: $event, taskId: undefined})"
      />

      <s-tooltip :disabled="!!selectedAssignmentId" :text="t('selectAssignmentFirst')">
        <s-combobox
          :model-value="selectedTaskId"
          :displayValue="taskDisplayName"
          :options="allTasks"
          id-key="id"
          :emptyLabel="t('allTasks')"
          @update:modelValue="submitSearch({taskId: $event})"
          :disabled="!selectedAssignmentId"
        />
      </s-tooltip>
    </fieldset>

    <UserActivityLogTable :user-activities="userActivities.data" />
    <template #footer>
      <s-paginator :paginator="userActivities" :only="reloadProps" />
    </template>
  </s-page-block-scrollable>
</template>
<script setup lang="ts">
import {Head, router} from '@inertiajs/vue3';
import {LaravelPaginator} from '../../types/laravel-paginator';
import SPaginator from '../../design-system/SPaginator.vue';
import STooltip from '../../design-system/STooltip.vue';
import {Course} from '../../types/entities/course';
import SPageBlockScrollable from '../../design-system/SPageBlockScrollable.vue';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import SPageHeader from '../../design-system/SPageHeader.vue';
import UserActivityLogDto = App.DTOs.UserActivityLogDto;
import ActivityIcon from './Index/ActivityIcon.vue';
import ShowParticipantDto = App.DTOs.CourseParticipant.ShowParticipantDto;
import SMultiSelect from '../../design-system/SMultiSelect.vue';
import {usePageParams} from '../../composables/usePageParams';
import {computed, watch} from 'vue';
import IntegerEnumDto = App.DTOs.Users.IntegerEnumDto;
import {useCancellableDebounceFn} from '../../composables/useCancellableDebounceFn';
import SCombobox from '../../design-system/SCombobox.vue';
import UserActivityLogTable from '../../components/user-activity-logs/UserActivityLogTable.vue';

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const props = defineProps<{
  user: ShowParticipantDto;
  course: Course;
  assignments: {id: number; name: string}[];
  tasks: {id: number; title: string; orderingIndex: number}[];
  userActivities: LaravelPaginator<UserActivityLogDto>;
  activityTypes: IntegerEnumDto[];
}>();

type PropKeys = keyof typeof props;
const reloadProps: PropKeys[] = ['userActivities', 'tasks'];

const allAssignments = computed(() => [
  {
    id: undefined,
    name: t('allAssignments'),
  },
  ...props.assignments,
]);

const allTasks = computed(() => [
  {
    id: undefined,
    title: '',
    orderingIndex: 0,
  },
  ...props.tasks,
]);

const taskDisplayName = (task: {id?: number; title: string; orderingIndex: number}) => {
  if (!task.id) {
    return t('allTasks');
  }

  return `${task.orderingIndex}: ${task.title}`;
};

const translatedActivityTypes = computed<{value: number; name: string; description: string}[]>(
  () => {
    return props.activityTypes.map((activityType) => ({
      ...activityType,
      description: t(`activities.titles.${activityType.name}`),
    }));
  }
);

type PageParams = {
  activityTypes?: string[];
  assignmentId?: string;
  taskId?: string;
};

const params = usePageParams<PageParams>();

const selectedAssignmentId = computed(() => {
  return params.assignmentId ? parseInt(params.assignmentId) : undefined;
});

const selectedTaskId = computed(() => {
  return params.taskId ? parseInt(params.taskId) : undefined;
});

const selectedActivityTypes = computed(() => {
  return params.activityTypes?.map((x) => parseInt(x));
});

const getRouteParams = () => {
  return {
    ...params,
  };
};

const submitSearch = (params: Partial<PageParams>) => {
  router.visit(
    route('courses.participants.activities.index', {
      user: props.user.id,
      course: props.course.id,
      // Get current route params
      ...getRouteParams(),
      // Empty page when changing filters
      page: undefined,
      // Merge in latest changes.
      ...params,
    }),
    {
      only: reloadProps,
    }
  );
};

const {debounced: debouncedSubmitSearch} = useCancellableDebounceFn(submitSearch, 1500);
</script>
<i18n>
{
  "en": {
    "heading": "Activity Log",
    "search": {
      "activityTypes": "Activity Types"
    },
    "activities": {
      "titles": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Submit Attempted",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Submit Succeeded",
        "TASK_RESPONSE_SUBMIT_FAILED": "Submit Failed",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Draft Save Attempted",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Draft Response Save Succeeded",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Draft Response Save Failed",
        "TASK_RESPONSE_DRAFT_DELETED": "Deleted Draft",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigated to Task",
        "LAB_SIM_ITEM_TASK_COMPLETED": "Completed Lab Sim Item Task",
        "LOGIN": "Login",
        "LOGOUT": "Logout"
      }
    },
    "allAssignments": "All Assignments",
    "allTasks": "All Tasks",
    "selectAssignmentFirst": "Select an assignment first"
  },
  "fr": {
    "heading": "Journal d'activités",
    "search": {
      "activityTypes": "Types d'activités"
    },
    "activities": {
      "titles": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Tentative d'envoi",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Envoi réussi",
        "TASK_RESPONSE_SUBMIT_FAILED": "Envoi échoué",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Tentative de sauvegarde du brouillon",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Sauvegarde du brouillon réussie",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Sauvegarde du brouillon échouée",
        "TASK_RESPONSE_DRAFT_DELETED": "Brouillon supprimé",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigué vers la tâche",
        "LAB_SIM_ITEM_TASK_COMPLETED": "TODO...",
        "LOGIN": "Connexion",
        "LOGOUT": "Déconnexion"
      }
    },
    "allAssignments": "Tous les devoirs",
    "allTasks": "Toutes les tâches",
    "selectAssignmentFirst": "Sélectionnez d'abord un devoir"
  }
}
</i18n>
