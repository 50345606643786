<template>
  <Head title="Admin - Assignments"></Head>
  <div class="page-block gap-5">
    <s-page-header heading="Assignments" />
    <search class="mb-4 flex flex-wrap gap-x-3 gap-y-4 items-end justify-between">
      <div class="text-sm">
        <s-combobox
          v-model="params.institutionName"
          :displayValue="(institution?: any) => institution?.name"
          :options="allInstitutions"
          id-key="name"
          emptyLabel="All Institutions"
          nullable
          :filter-by="['name']"
          @update:modelValue="submitSearch({institutionName: $event})"
        />
      </div>
      <div v-if="params.institutionName" class="text-sm">
        <s-combobox
          v-model="params.courseId"
          :displayValue="formatCourseSelectForDisplay"
          :options="allCourses"
          id-key="id"
          emptyLabel="All Courses"
          nullable
          :filter-by="['name']"
          @update:modelValue="submitSearch({courseId: $event})"
        />
      </div>
      <div class="flex-1"></div>
      <s-search-form route-name="admin.assignments.index" label="Search by assignment name" />
    </search>
    <div
      v-if="params.institutionName"
      class="flex gap-x-2 flex-wrap items-center justify-between mb-2"
    >
      <s-badge>
        <p class="text muted">
          Showing results for
          <strong class="text-blue-600">{{ params.institutionName }}</strong>
          <strong v-if="params.courseId" class="text-blue-600">
            - {{ courses.find((course) => course.id == params.courseId)?.name }}
          </strong>
        </p>
        <s-link class="filter-pill-button" :href="route('admin.assignments.index')" preserve-state>
          <s-icon name="close" width="18" height="18" />
          <span class="sr-only">Clear search</span>
        </s-link>
      </s-badge>
    </div>

    <section class="stack vertical gap-3">
      <s-link
        v-for="assignment in assignments?.data"
        :href="route('admin.assignments.show', {assignment: assignment.id})"
        class="card padded stack horizontal align-between valign-center gap-3"
      >
        <div class="stack vertical gap-2 w-full">
          <div>
            <div class="text-sm text-slate-600">
              {{ assignment.institutionName }} | {{ assignment.courseName }} ({{
                monthDaySometimesYear(assignment.courseStartDate.date)
              }}
              - {{ monthDaySometimesYear(assignment.courseEndDate.date) }})
            </div>
            <div class="text-lg">
              <strong>{{ assignment.courseCode }}:</strong>
              {{ assignment.name }}
            </div>
          </div>
        </div>
        <div class="stack horizontal gap-4">
          <s-icon name="chevron-right" />
        </div>
      </s-link>
      <s-paginator :paginator="assignments" />
    </section>
  </div>
</template>
<script setup lang="ts">
import {LaravelPaginator} from '../../../types/laravel-paginator';
import SLink from '../../../components/SLink.vue';
import {route} from 'ziggy-js';
import SPaginator from '../../../design-system/SPaginator.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Head, router} from '@inertiajs/vue3';
import SIcon from '../../../design-system/SIcon.vue';
import {Course} from '../../../types/entities/course';
import {usePageParams} from '../../../composables/usePageParams';
import {Institution} from '../../../types/entities/institution';
import SSearchForm from '../../../design-system/SSearchForm.vue';
import SCombobox from '../../../design-system/SCombobox.vue';
import SBadge from '../../../design-system/SBadge.vue';
import {computed} from 'vue';
import {monthDaySometimesYear, shortMonthYear} from '../../../format/dates';
import {LaravelDate} from '../../../types/laravel-date';

const props = defineProps<{
  assignments: LaravelPaginator<{
    id: number;
    courseName: string;
    courseCode: string;
    courseStartDate: LaravelDate;
    courseEndDate: LaravelDate;
    name: string;
    institutionName: string;
  }>;
  courses: Course[];
  institutions: Institution[];
}>();
type PageParams = {
  courseId?: number;
  institutionName?: string;
  taskId?: number;
};

const params = usePageParams<PageParams>();
const allInstitutions = computed(() => [
  {
    id: undefined,
    name: 'All Institutions',
  },
  ...props.institutions,
]);

const allCourses = computed(() => [
  {
    id: undefined,
    name: 'All Courses',
  },
  ...props.courses,
]);

const formatCourseSelectForDisplay = (course: Course | {id: undefined; name: string}): string => {
  if (!course.id) {
    return course.name;
  }

  return `${course.code} (${shortMonthYear(course.startDate)})`;
};

const getRouteParams = () => {
  return {
    ...params,
  };
};
const submitSearch = (params: Partial<PageParams>) => {
  router.visit(
    route('admin.assignments.index', {
      ...params,
      ...getRouteParams(),
    })
  );
};
</script>
