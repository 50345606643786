<template>
  <form
    v-if="currentLink"
    @submit.prevent="submit()"
    id="thing"
    :class="['bg-gray-50 rounded border shadow-lg p-4 flex flex-col gap-3']"
  >
    <s-input-field id="href" :label="t('url')" v-model="form.href" />
    <s-toggle-field id="openInNewTab" :label="t('openInNewTab')" v-model="form.openInNewTab" />
    <s-btn color="primary" size="sm" class="w-full">{{ t('form.save') }}</s-btn>
  </form>
</template>

<script setup lang="ts">
import {EditorView} from 'prosemirror-view';
import {computed} from 'vue';
import {Mark} from 'prosemirror-model';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';
import {useForm} from '@inertiajs/vue3';
import {getLinkRange} from '../utils/getLinkRange';
import SToggleField from '../../../design-system/SToggleField.vue';

const {pos, view} = defineProps<{
  pos: number | null;
  view: EditorView;
}>();

const emit = defineEmits(['close']);

const currentLink = computed<Mark | null>(() => {
  if (pos === null) {
    return null;
  }

  return (
    view.state.doc
      .resolve(pos)
      .marks()
      .find((mark) => mark.type.name === 'link') || null
  );
});

const form = useForm(() => {
  return {
    href: currentLink.value?.attrs.href || '',
    openInNewTab: currentLink.value?.attrs.openInNewTab,
  };
});

function submit() {
  updateLink(form.href, form.openInNewTab);

  emit('close');
}

const {t} = useI18n();

function updateLink(href: string, openInNewTab: boolean) {
  if (currentLink.value === null || pos === null) {
    return;
  }

  const {state, dispatch} = view;
  const linkRange = getLinkRange(state, pos);

  if (!linkRange) {
    return;
  }

  const {tr, schema} = state;

  tr.removeMark(linkRange.from, linkRange.to, schema.marks.link);
  tr.addMark(linkRange.from, linkRange.to, schema.marks.link.create({href, openInNewTab}));

  dispatch(tr);
}
</script>
<i18n>
{
  "en": {
    "url": "URL",
    "openInNewTab": "Open in new tab"
  },
  "fr": {
    "url": "URL",
    "openInNewTab": "Ouvrir dans un nouvel onglet"
  }
}
</i18n>
