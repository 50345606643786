import {v4 as uuidV4} from 'uuid';
import {LoadedTaskPoolDto} from '../../types/entities/task-assignment';
import TaskAssignmentDto = App.DTOs.TaskAssignmentDto;

export type CreateTaskAssignmentDto = TaskAssignmentDto & {
  taskPool: LoadedTaskPoolDto;
  tempKey?: string;
};

export function taskAssignmentFactory(): CreateTaskAssignmentDto {
  return {
    id: null,
    usePoolingUi: null,
    tempKey: uuidV4(),
    orderingIndex: 0,
    reviewRequired: false,
    maxAttempts: null,
    taskPool: {
      id: null,
      tasks: [],
      taskIds: [],
    },
    pointValue: 1,
  };
}
