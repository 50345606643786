<template>
  <div class="flex flex-col gap-1 shrink min-w-0 flex-1">
    <div class="flex gap-3 flex-wrap items-center justify-between py-0.5">
      <response-listbox
        v-if="taskResponses.length"
        :draft-task-ids="draftTaskIds"
        :task-responses="taskResponses"
        v-model="selectedResponse"
        :student-assignment-task-grades="studentAssignmentTaskGrades"
        :attempted-task-ids="attemptedTaskIds"
        :due-date="dueDate"
        :assignment-extension="assignmentExtension"
        :task-number="taskNumber"
        :task-weight="taskWeight"
        :attempt-number="attemptNumber"
        :response-is-late="responseIsLate"
        :is-effective="isTaskResponseEffective"
        :response="response"
        position="top"
      />
      <div v-else-if="showNoResponsesBadge">
        <s-badge>{{ t('noResponsesYet') }}</s-badge>
      </div>
      <div
        v-if="maxAttempts"
        class="w-full flex flex-wrap items-stretch justify-end gap-x-2 gap-y-3"
      >
        <s-tooltip wrapper-class="h-full flex">
          <template #tooltip>
            <template v-if="noMoreAttempts">
              <span class="font-bold">{{ t('noMoreAttempts') }}</span>
              <span class="mx-1">&ndash;</span>
            </template>
            <span class="font-bold">
              <i18n-t keypath="attemptsCount">
                <template #count>{{ numTaskAttempts }}</template>
              </i18n-t>
            </span>
            <i18n-t keypath="outOfMaxAttempts">
              <template #max>{{ maxAttempts + additionalAttempts }}</template>
            </i18n-t>
            <span v-if="additionalAttempts" class="italic">
              <i18n-t keypath="attemptsBreakdown">
                <template #base>{{ maxAttempts }}</template>
                <template #additional>{{ additionalAttempts }}</template>
              </i18n-t>
            </span>
            <div v-if="additionalAttempts">
              <i18n-t keypath="additionalAttemptsGranted">
                <template #count>{{ additionalAttempts }}</template>
              </i18n-t>
            </div>
            <span class="block text-sm opacity-80 italic">
              {{ t('clickToViewDetails') }}
            </span>
          </template>
          <s-btn
            :color="noMoreAttempts ? 'red-light' : 'light'"
            size="sm"
            :href="
              route('courses.assignments.students.assignmentTasks.attempts-grants.index', {
                course: assignment.course.id,
                assignment: assignment.id,
                user: student.id,
                assignmentTask: taskNumber,
              })
            "
          >
            <s-icon
              :name="noMoreAttempts ? 'alert' : 'information'"
              size="18"
              class="opacity-70 -ml-1"
              :class="noMoreAttempts ? 'text-orange-500' : 'text-blue-500'"
              aria-hidden="true"
            />
            <span class="flex items-center gap-0.5">
              <i18n-t keypath="attemptsDisplay">
                <template #current>{{ numTaskAttempts }}</template>
                <template #max>{{ maxAttempts + additionalAttempts }}</template>
              </i18n-t>
            </span>
          </s-btn>
        </s-tooltip>
        <div class="flex gap-2">
          <s-btn
            :href="
              route('courses.assignments.students.assignmentTasks.attempts-grants.create', {
                course: assignment.course.id,
                assignment: assignment.id,
                user: student.id,
                assignmentTask: taskNumber,
              })
            "
            color="secondary"
            icon="plus"
            size="sm"
          >
            {{ t('grantAdditionalAttempt') }}
          </s-btn>
        </div>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {Grade} from '../../../types/entities/base-grade';
import {Task, TaskResponse} from '../../../types/entities/tasks';
import SBadge from '../../../design-system/SBadge.vue';
import ResponseListbox from './ResponseListbox.vue';
import {useVModel} from '@vueuse/core';
import {formatDistance, monthDaySometimesYear} from '../../../format/dates';
import {getResponseTimestamp, isResponseLate} from '../../../util/response-timestamps';
import {AssignmentExtension} from '../../../types/entities/assignment-extension';
import SIcon from '../../../design-system/SIcon.vue';
import {isTaskResponseEffective as checkTaskResponseEffective} from './task-response-state-utils';
import {useI18n} from 'vue-i18n';
import SBtn from '../../../design-system/SBtn.vue';
import STooltip from '../../../design-system/STooltip.vue';
import {route} from 'ziggy-js';

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
  | 'submittedBy'
  | 'user'
>;

const props = defineProps<{
  dueDate: string | null | undefined;
  assignmentExtension?: AssignmentExtension | null;

  assignmentTasks: {
    id: number;
    orderingIndex: number;
    pointValue: number;
  }[];

  // Student
  attemptedTaskIds: number[];
  draftTaskIds: number[];

  // Task
  task: Task;
  taskResponses: PartialTaskResponse[];
  studentAssignmentTaskGrades: Grade[];

  // Response
  response: TaskResponse | null;
  selectedResponse: PartialTaskResponse | null;
  showNoResponsesBadge: boolean;

  // Attempts
  maxAttempts: number | null;
  additionalAttempts: number;
  numTaskAttempts: number;
  noMoreAttempts: boolean;
  assignment: {
    id: number;
    course: {
      id: number;
    };
  };
  student: {
    id: number;
  };
}>();

const selectedResponse = useVModel(props, 'selectedResponse');

const taskNumber = computed(() => {
  return props.assignmentTasks.findIndex((task) => task.id === props.task.id) + 1;
});

const currentTask = computed(() => props.assignmentTasks[taskNumber.value - 1]);

const taskWeight = computed(() => currentTask.value?.pointValue || 0);

const attemptNumber = computed(() => {
  if (!props.response) {
    return 1;
  }

  return (
    props.taskResponses.length -
    props.taskResponses.findIndex((response) => response.id === props.response?.id)
  );
});

const responseIsLate = computed(() =>
  isResponseLate(props.dueDate, props.response, props.assignmentExtension)
);

function isTaskResponseEffective(responseId: number) {
  const grade = props.studentAssignmentTaskGrades
    .filter((grade) => grade.responseId === responseId)
    .sort((a, b) => b.id - a.id)[0];

  return grade?.isEffective ?? false;
}
</script>
<i18n>
{
  "en": {
    "responseXofY": "Response {x} of {y}",
    "activeGrade": "Active Grade",
    "late": "late",
    "submittedBy": "Submitted by",
    "onBehalfOf": "on behalf of",
    "on": "on",
    "noResponsesYet": "No responses yet",
    "noMoreAttempts": "No More Attempts",
    "attemptsCount": "{count} attempts made",
    "outOfMaxAttempts": " out of a maximum of {max}",
    "attemptsBreakdown": " ({base} base + {additional} additional)",
    "attemptsDisplay": "{current} of {max} Attempts Made",
    "grantAdditionalAttempt": "Grant Additional Attempt",
    "additionalAttemptsGranted": "{count} additional attempts granted",
    "clickToViewDetails": "Click to view details"
  },
  "fr": {
    "responseXofY": "Réponse {x} sur {y}",
    "activeGrade": "Note active",
    "late": "en retard",
    "submittedBy": "Soumis par",
    "onBehalfOf": "au nom de",
    "on": "le",
    "noResponsesYet": "Aucune réponse pour le moment",
    "noMoreAttempts": "Plus de tentatives possibles",
    "attemptsCount": "{count} tentatives effectuées",
    "outOfMaxAttempts": " sur un maximum de {max}",
    "attemptsBreakdown": " ({base} de base + {additional} supplémentaires)",
    "attemptsDisplay": "{current}/{max} Tentatives effectuées",
    "grantAdditionalAttempt": "Accorder une tentative supplémentaire",
    "additionalAttemptsGranted": "{count} tentatives supplémentaires accordées",
    "clickToViewDetails": "Cliquez pour voir les détails"
  }
}
</i18n>
