<template>
  <Head :title="`${t('page.title')} - ${course.code}`" />
  <s-page-block>
    <s-page-header :heading="t('page.title')" class="mb-3" />
    <div class="pb-5 flex flex-wrap-reverse gap-x-3 gap-y-4 items-end justify-between">
      <s-search-section route-name="courses.assignments.index" :label="t('search.placeholder')" />
      <div class="flex-1 flex-wrap flex items-center justify-end gap-3">
        <s-btn
          v-if="canViewAnalytics && courseAnalyticsEnabled"
          :href="route('courses.analytics.tasks', {course: course.id})"
          color="secondary"
          icon="poll"
        >
          {{ t('actions.viewAnalytics') }}
        </s-btn>
        <s-key-tooltip keys="n" v-if="canCreateAssignments">
          <s-btn icon="plus" :href="route('courses.assignments.create', course.id)">
            {{ t('actions.create') }}
          </s-btn>
        </s-key-tooltip>
      </div>
    </div>
    <section class="@container flex flex-col gap-5">
      <s-placeholder v-if="assignments.data.length === 0">
        <template v-if="route().params.search">
          {{ t('search.noResults') }}
        </template>
        <template v-else>
          {{ t('page.noAssignments') }}
        </template>
      </s-placeholder>
      <div v-else class="flex flex-col gap-4">
        <StaffAssignmentCard
          v-for="assignment in assignments?.data"
          :key="assignment.id"
          :course="course"
          :assignment="assignment"
          :assignmentSpecificPermissions="getAssignmentSpecificPermissions(assignment.id)"
          :canGrantAssignmentExtensions="props.canGrantAssignmentExtensions"
        />
      </div>
      <s-paginator :paginator="assignments" preserve-state :only="['assignments']" />
    </section>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {LaravelPaginator} from '../../types/laravel-paginator';
import {route} from 'ziggy-js';
import SPaginator from '@/design-system/SPaginator.vue';
import SPageHeader from '@/design-system/SPageHeader.vue';
import SPlaceholder from '@/design-system/SPlaceholder.vue';
import StaffAssignmentCard from './Index/StaffAssignmentCard.vue';
import {Course} from '../../types/entities/course';
import SSearchSection from '../../design-system/SSearchSection.vue';
import SKeyTooltip from '../../design-system/SKeyTooltip.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import {useKeyboardShortcuts} from '../../composables/useKeyboardShortcut';
import {router} from '@inertiajs/vue3';
import StaffCourseAssignmentRowDto = App.DTOs.CourseAssignments.StaffCourseAssignmentRowDto;
import SBtn from '../../design-system/SBtn.vue';
import CourseAssignmentPermissionsDto = App.DTOs.CourseAssignments.CourseAssignmentPermissionsDto;
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  course: Course;
  assignments: LaravelPaginator<StaffCourseAssignmentRowDto>;
  assignmentSpecificPermissions: CourseAssignmentPermissionsDto[];
  canGrantAssignmentExtensions: boolean;
  canCreateAssignments: boolean;
  canViewAnalytics?: boolean;
  courseAnalyticsEnabled: boolean;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const keyboardShortcuts = useKeyboardShortcuts();
const getAssignmentSpecificPermissions = (assignmentId: number): CourseAssignmentPermissionsDto => {
  return (
    props.assignmentSpecificPermissions.find(
      (permission) => permission.assignmentId === assignmentId
    ) ?? {
      assignmentId: assignmentId,
      canDelete: false,
      canDuplicate: false,
    }
  );
};

if (props.canCreateAssignments) {
  keyboardShortcuts.register({
    key: 'n',
    label: t('actions.create'),
    onPress() {
      router.visit(route('courses.assignments.create', props.course.id));
    },
  });
}
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Assignments",
      "noAssignments": "You do not have any assignments at the moment."
    },
    "actions": {
      "create": "New Assignment",
      "viewAnalytics": "View Analytics"
    },
    "search": {
      "placeholder": "Search assignments by title",
      "noResults": "No assignments found for your search."
    }
  },
  "fr": {
    "page": {
      "title": "Devoirs",
      "noAssignments": "Vous n'avez pas de devoirs pour le moment."
    },
    "actions": {
      "create": "Nouveau devoir",
      "viewAnalytics": "Voir les analyses"
    },
    "search": {
      "placeholder": "Rechercher des devoirs par titre",
      "noResults": "Aucun devoir trouvé pour votre recherche."
    }
  }
}
</i18n>
