<template>
  <Head
    :title="
      t('page.title', {
        student: student.firstName + ' ' + student.lastName,
        task: t('taskX', {number: taskNumber}),
        assignment: assignment.name,
        course: course.code,
      })
    "
  ></Head>
  <s-page-block size="max-w-screen-md mx-auto">
    <s-page-header
      class="mb-3"
      :description="
        t('page.description', {
          student: student.firstName + ' ' + student.lastName,
          usedAttempts,
          totalAttempts: maxAttempts + additionalAttempts,
          maxAttempts,
          additionalAttempts,
        })
      "
    >
      <template #heading>
        <span>
          <i18n-t keypath="page.heading">
            <template #student>
              <span class="font-bold">{{ student.firstName + ' ' + student.lastName }}</span>
              <span class="">{{ ' (' + student.email + ')' }}</span>
            </template>
            <template #task>
              <span class="font-bold">{{ t('taskX', {number: taskNumber}) }}</span>
            </template>
          </i18n-t>
        </span>
      </template>
    </s-page-header>
    <form
      @submit.prevent="
        form.post(
          route('courses.assignments.students.assignmentTasks.attempts-grants.store', {
            course: assignment.course.id,
            assignment: assignment.id,
            user: student.id,
            assignmentTask: taskNumber,
          })
        )
      "
    >
      <s-card>
        <s-input-field id="count" v-model="form.count" :label="t('labels.count')" type="number" />
        <template #footer>
          <div class="flex justify-between">
            <s-btn
              v-if="canGoBack"
              type="button"
              color="white"
              class="flex-1 max-w-[16rem]"
              icon="arrow-left"
              @click="goBack"
            >
              {{ t('actions.back') }}
            </s-btn>
            <span v-else></span>
            <s-btn icon="plus" class="flex-1 max-w-[16rem]">
              {{ t('actions.grantAdditional', form.count) }}
            </s-btn>
          </div>
        </template>
      </s-card>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import {User} from '../../types/entities/user';
import {Task} from '../../types/entities/tasks';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {useForm} from '@inertiajs/vue3';
import SInputField from '../../design-system/SInputField.vue';
import SCard from '../../design-system/SCard.vue';
import SBtn from '../../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';

defineProps<{
  course: Course;
  assignment: Assignment;
  student: User;
  task: Task;
  taskNumber: number;
  maxAttempts: number;
  additionalAttempts: number;
  usedAttempts: number;
}>();

const form = useForm({
  count: 1,
});

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const canGoBack = window.history.length > 1;

const goBack = () => {
  if (canGoBack) {
    window.history.back();
  }
};
</script>
<i18n>
{
  "en": {
    "page.title": "Grant Additional Attempts for {student} for {task} - {assignment} - {course}",
    "page.heading": "Grant {student} Additional Attempts for {task}",
    "page.description": "{student} has used {usedAttempts} attempts out of {totalAttempts} total attempts ({maxAttempts} base + {additionalAttempts} additional).",
    "labels.count": "How many attempts do you want to grant?",
    "taskX": "Task {number}",
    "actions.grantAdditional": "Grant Additional Attempt | Grant Additional Attempts"
  },
  "fr": {
    "page.title": "Accorder des tentatives supplémentaires à {student} pour {task} - {assignment} - {course}",
    "page.heading": "Accorder des tentatives supplémentaires à {student} pour {task}",
    "page.description": "{student} a utilisé {usedAttempts} tentatives sur {totalAttempts} tentatives ({maxAttempts} base + {additionalAttempts} supplémentaires).",
    "labels.count": "Combien de tentatives voulez-vous accorder?",
    "taskX": "Tâche {number}",
    "actions.grantAdditional": "Accorder une tentative supplémentaire | Accorder des tentatives supplémentaires"
  }
}
</i18n>
