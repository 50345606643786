import {DOMParser, Node, Schema} from 'prosemirror-model';

/**
 * Parses the content coming from the server into a ProseMirror Node.
 */
export function parseContent(
  schema: Schema,
  docJson: object | Node | string | null | undefined
): Node {
  docJson = docJson || '';
  if (docJson instanceof Node) {
    return docJson;
  }

  if (typeof docJson === 'string') {
    const container = document.createElement('div');
    container.innerHTML = `<div>${docJson}</div>`;
    return DOMParser.fromSchema(schema).parse(container);
  }

  if (Array.isArray(docJson)) {
    return schema.nodeFromJSON({type: 'doc', content: removeEmptyTextNodes(docJson)});
  }

  // @ts-ignore
  docJson.content = removeEmptyTextNodes(docJson.content);

  return schema.nodeFromJSON(docJson);
}

/**
 * Removes empty text nodes from the JSON.
 *
 * Note: It's unclear how this happens. It would be good to write tests to make sure it does not.
 *
 * @param nodesJson
 */
function removeEmptyTextNodes(nodesJson: any[]) {
  return nodesJson.filter((node) => {
    if (node.content) {
      node.content = removeEmptyTextNodes(node.content);
    }
    if (node.type !== 'text') return true;

    return node.text !== null;
  });
}
