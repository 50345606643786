import {InputRule, textblockTypeInputRule} from 'prosemirror-inputrules';
import {Schema} from 'prosemirror-model';

/**
 * InputRules for creating heading and subheadings using Markdown syntax.
 *
 * - `#[SPACE]` becomes a Heading `h2`
 * - `##[SPACE]` becomes a Subheading `h3`
 */
export const headingRules: (schema: Schema) => InputRule[] = (schema) => {
  if (!schema.nodes.heading) {
    return [];
  }

  return [
    // Markdown Syntax
    textblockTypeInputRule(/^(##?) $/, schema.nodes.heading, (match) => {
      return {
        level: match[1].length + 1,
      };
    }),
  ];
};
