import {Command} from 'prosemirror-state';
import {Attrs, NodeType} from 'prosemirror-model';
import {canReplaceSelection} from '../queries/canReplaceSelection';

/**
 * Creates a Command that replaces the current at the current selection.
 *
 * The new node will be of the given node type and will have the given attributes.
 *
 * @param nodeType
 * @param attrs
 */
export const insertNode = (nodeType: NodeType, attrs: Attrs | null = null): Command => {
  return (state, dispatch) => {
    const node = nodeType.create(attrs);

    if (!canReplaceSelection(state, node)) {
      return false;
    }

    if (dispatch) {
      dispatch(state.tr.replaceSelectionWith(node));
    }

    return true;
  };
};
