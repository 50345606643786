<template>
  <s-unstyled-tooltip :id :disabled="disabled" :class="tooltipClass" :wrapper-class="wrapperClass">
    <slot />
    <template #tooltip>
      <span class="drop-shadow-sm">
        <slot name="tooltip">
          {{ text }}
        </slot>
      </span>
    </template>
  </s-unstyled-tooltip>
</template>
<script setup lang="ts">
import {defineProps, computed} from 'vue';
import SUnstyledTooltip from './SUnstyledTooltip.vue';

const {
  disabled,
  color = 'blue',
  size = 'sm',
  wrapperClass = '',
} = defineProps<{
  id?: string;
  text?: string;
  disabled?: boolean;
  color?: 'blue' | 'white';
  size?: 'sm' | 'md';
  wrapperClass?: string;
}>();

const colorClasses = {
  blue: 'bg-gradient-to-tl from-blue-800 to-blue-700 border-blue-800 text-white text-sm font-medium',
  white: 'bg-gradient-to-tl from-gray-50 to-white border border-gray-200',
};

const sizeClasses = {
  sm: 'px-3 py-1.5 shadow-lg',
  md: 'px-4 py-3 shadow-xl',
};

const tooltipClass = computed(() => {
  return ['border rounded-md whitespace-normal', colorClasses[color], sizeClasses[size]];
});
</script>
