<template>
  <s-btn @click="open = !open">{{ open ? 'Close' : 'Open' }}</s-btn>

  <n-modal v-model:open="open" modal>
    <template #default="{close}">
      <SCard heading="Basic Modal">
        <p>
          This here is a modal dialog. Modal's block the user from interacting with the rest of the
          page until the dialog is closed.
        </p>
        <p>You can exit this modal by clicking outside the modal, or pressing the Escape key.</p>
      </SCard>
    </template>
  </n-modal>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
import {ref} from 'vue';
import SCard from '../../../../design-system/SCard.vue';
import NModal from '../../../../components/SDialog.vue';

const open = ref(false);
</script>
