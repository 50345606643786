<template>
  <s-tooltip v-if="showRandomVariables && variablesInText.length">
    <!-- TODO: Maybe add a little floating icon to indicate that the text is interpolated -->
    <s-latex
      :content="interpolatedText"
      class="rounded outline-dotted outline-2 outline-cyan-200 bg-cyan-50"
    />

    <template #tooltip>
      <blockquote class="mb-1">
        {{ text }}
      </blockquote>

      <s-table class="text-gray-800">
        <template #header>
          <tr>
            <th>{{ t('variable') }}</th>
            <th>{{ t('assignedValue') }}</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="variable in variablesInText" :key="variable.key">
            <td>{{ variable.key }}</td>
            <td>{{ variable.value }}</td>
          </tr>
        </template>
      </s-table>
    </template>
  </s-tooltip>

  <s-latex v-else :content="interpolatedText" />
</template>
<script setup lang="ts">
import {TaskState} from '../types/entities/tasks';
import {computed, inject, Ref, ref, watch} from 'vue';
import {formatLatexNumberValue} from './formatLatexNumberValue';
import SLatex from '../components/SLatex.vue';
import STooltip from '../design-system/STooltip.vue';
import STable from '../design-system/STable.vue';
import {useI18n} from 'vue-i18n';

const {text, taskState} = defineProps<{
  text: string | null | undefined;
  taskState: TaskState | null | undefined;
}>();

const showRandomVariables = inject<Ref<boolean>>('showRandomVariables', ref(false));

const variablesInText = computed(() => {
  if (!text || !taskState) {
    return [];
  }

  return taskState?.symbols
    .map((key, index) => ({key, value: formatLatexNumberValue(taskState.values[index].content)}))
    .filter((variable) => text.match(new RegExp(`{{ *${variable.key} *}}`)));
});

const interpolatedText = computed(() => {
  let result = text;

  if (!result) {
    return '';
  }

  variablesInText.value.forEach(({key, value}) => {
    result = result!.replace(new RegExp(`{{ *${key} *}}`, 'g'), value);
  });

  return result;
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "variable": "Variable",
    "assignedValue": "Student's Value"
  },
  "fr": {
    "variable": "Variable",
    "assignedValue": "Valeur de l'élève"
  }
}
</i18n>
