<template>
  <div ref="editorEl" class="whitespace-pre-wrap pm-wrap" :class="{editing: !readOnly}"></div>
</template>
<script setup lang="ts">
import {onMounted, ref, toRaw, watch} from 'vue';

// Prosemirror
import {Node, Schema} from 'prosemirror-model';
import {Plugin} from 'prosemirror-state';

// Config
import {docSchema} from './schema/doc-schema';
import {initializePlugins} from './config/initializePlugins';

import '@benrbray/prosemirror-math/dist/prosemirror-math.css';
import 'katex/dist/katex.min.css';
import {createState} from './config/createState';
import {createEditorView} from './config/createEditorView';

const props = defineProps<{
  schema?: Schema;
  plugins?: Plugin[];
  readOnly?: boolean;
  output?: 'json' | 'html' | 'node';
}>();

const model = defineModel<Node | string | undefined>();
const editorEl = ref<HTMLElement | null>(null);

onMounted(() => {
  /**
   * Make sure the editor element is available
   */
  const el = editorEl.value;
  if (!el) {
    return;
  }

  const value = toRaw(model.value);

  /**
   * Pick the schema
   */
  const schema = props.schema || docSchema;

  /**
   * Initialize Plugins based on Schema
   */
  const plugins = initializePlugins(schema, props.plugins);

  /**
   * Initialize State
   */
  const state = createState(schema, value, plugins);

  const view = createEditorView({
    el,
    state,
    editable: !props.readOnly,
    output: props.output || 'json',
    onChange(value) {
      model.value = value;
    },
  });

  /**
   * Re-initialize State if the model value is changed externally.
   */
  watch(model, (newValue) => {
    if (typeof newValue === 'string') {
      return;
    }

    if (view.hasFocus()) {
      return;
    }

    view.updateState(createState(schema, newValue, plugins));
  });
});
</script>
<style>
.pm-wrap {
  h1,
  h2 {
    @apply text-3xl leading-tight mb-3;

    &:not(:first-child) {
      @apply mt-6;
    }
  }

  h3 {
    @apply text-xl font-medium leading-tight mb-3;

    &:not(:first-child) {
      @apply mt-6;
    }
  }

  blockquote {
    @apply relative border rounded-r border-blue-50 bg-gradient-to-br from-blue-50 to-white pl-5 pr-3 py-4 text-base mt-4 mb-5;

    &:before {
      content: '';
      @apply absolute -left-px -top-px -bottom-px w-[5px] bg-blue-200;
    }
  }

  blockquote blockquote,
  blockquote blockquote blockquote blockquote {
    @apply from-white/70 to-blue-50/50;
  }

  blockquote blockquote blockquote {
    @apply from-blue-50 to-white;
  }

  p {
    @apply mb-2;
  }

  ul {
    @apply list-disc pl-4 py-1 mb-3;
  }

  ol {
    @apply list-decimal pl-4 py-1 mb-3;
  }

  li {
    @apply mb-2;
  }

  a {
    @apply text-blue-500 underline;
  }

  code {
    @apply bg-gray-200 text-gray-800 px-1 rounded-sm;
  }

  *:last-child {
    margin-bottom: 0;
  }

  .gdoc-embed {
    @apply rounded-sm border bg-white;
  }

  .gdoc-embed-wrapper {
    &:not(:first-child) {
      @apply mt-3;
    }
    &:not(:last-child) {
      @apply mb-4;
    }
  }
}

.pm-wrap.editing {
  .gdoc-embed-wrapper {
    @apply border rounded p-3 cursor-grab;
  }
  .gdoc-embed-wrapper:hover:not(:has(.gdoc-embed:hover)) {
    @apply bg-gray-50;
  }
}

.ProseMirror {
  &:focus {
    @apply outline-none;
  }
}
</style>
