import {
  chainCommands,
  deleteSelection,
  joinBackward,
  selectNodeBackward,
  toggleMark,
} from 'prosemirror-commands';
import {redo, undo} from 'prosemirror-history';
import {liftListItem, sinkListItem, splitListItem, wrapInList} from 'prosemirror-schema-list';
import {insertMathCmd, mathBackspaceCmd} from '@benrbray/prosemirror-math';
import {Schema} from 'prosemirror-model';
import {Command} from 'prosemirror-state';
import {toggleBlockType} from '../commands/toggleBlockType';

/**
 * Keymaps
 *
 * Maps key bindings to commands.
 *
 * @see https://prosemirror.net/docs/ref/#keymap
 */
export const createKeymapBindings = (docSchema: Schema) => {
  /**
   * ------------------------------------------------------------
   * History
   * ------------------------------------------------------------
   */
  let keymap: Record<string, Command> = {
    'Mod-z': undo,
    'Mod-y': redo,
    Backspace: chainCommands(deleteSelection, mathBackspaceCmd, joinBackward, selectNodeBackward),
  };

  /**
   * ------------------------------------------------------------
   * Heading & Subheading
   * ------------------------------------------------------------
   */
  if (docSchema.nodes.heading) {
    keymap['Mod-Alt-1'] = toggleBlockType(docSchema.nodes.heading, {level: 2});
    keymap['Mod-Alt-2'] = toggleBlockType(docSchema.nodes.heading, {level: 3});
  }

  /**
   * ------------------------------------------------------------
   * Marks
   * ------------------------------------------------------------
   */
  if (docSchema.marks.strong) {
    keymap['Mod-b'] = toggleMark(docSchema.marks.strong);
  }

  if (docSchema.marks.em) {
    keymap['Mod-i'] = toggleMark(docSchema.marks.em);
  }

  if (docSchema.marks.link) {
    keymap['Mod-k'] = toggleMark(docSchema.marks.link, {href: '', openInNewTab: true});
  }

  /**
   * ------------------------------------------------------------
   * List Keymaps: Follows same pattern as Google Docs.
   * ------------------------------------------------------------
   */
  if (docSchema.nodes.ordered_list) {
    keymap['Mod-Shift-7'] = wrapInList(docSchema.nodes.ordered_list);
  }

  if (docSchema.nodes.bullet_list) {
    keymap['Mod-Shift-8'] = wrapInList(docSchema.nodes.bullet_list);
  }

  if (docSchema.nodes.list_item) {
    keymap['Shift-Tab'] = liftListItem(docSchema.nodes.list_item);
  }

  if (docSchema.nodes.list_item) {
    keymap['Enter'] = splitListItem(docSchema.nodes.list_item);
  }

  if (docSchema.nodes.list_item) {
    keymap['Tab'] = sinkListItem(docSchema.nodes.list_item);
  }

  /**
   * ------------------------------------------------------------
   * Math Keymaps
   * ------------------------------------------------------------
   */
  if (docSchema.nodes.math_inline) {
    keymap['Mod-Space'] = insertMathCmd(docSchema.nodes.math_inline);
  }

  return keymap;
};
