<template>
  <s-page-block class="flex flex-col gap-4">
    <s-page-header :heading="t('page.heading')" :description="t('page.description')" />
    <template v-if="!timelimitEnabled">
      <p>{{ t('help.timelimits-not-enabled') }}</p>
      <s-btn
        icon="cog"
        class="w-fit"
        :href="
          route('courses.assignments.settings.edit', {
            course: course.id,
            assignment: assignment.id,
          })
        "
      >
        {{ t('link.go-to-settings') }}
      </s-btn>
    </template>
    <s-placeholder v-else-if="!extensions.data.length">
      <p>
        {{ t('table.empty') }}
      </p>
    </s-placeholder>
    <template v-else>
      <table class="table dense">
        <thead>
          <tr>
            <th class="!pt-0">{{ t('time.minutes') }}</th>
            <th class="!pt-0">{{ t('table.header.student') }}</th>
            <th class="!pt-0">{{ t('table.header.grantedBy') }}</th>
            <th class="!pt-0">{{ t('table.header.status') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="extension in extensions.data">
            <td>{{ extension.minutes }}</td>
            <td>{{ extension.timer.user.fullName }}</td>
            <td>{{ extension.grantedBy.fullName }}</td>
            <td>
              <div class="flex">
                <s-badge v-if="extension.claimed" color="green" size="sm">
                  {{ t('table.status.claimed') }}
                </s-badge>
                <s-badge v-else color="gray" size="sm">
                  {{ t('table.status.unclaimed') }}
                </s-badge>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <s-paginator :paginator="extensions" />
    </template>
  </s-page-block>
</template>
<script setup lang="ts">
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SPaginator from '../../design-system/SPaginator.vue';
import {LaravelPaginator} from '../../types/laravel-paginator';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import {useI18n} from 'vue-i18n';
import SBadge from '../../design-system/SBadge.vue';
import SBtn from '../../design-system/SBtn.vue';
import {route} from 'ziggy-js';
import UserAssignmentTimerExtensionDto = App.DTOs.UserAssignmentTimerExtensionDto;
import SPlaceholder from '../../design-system/SPlaceholder.vue';

defineProps<{
  course: Course;
  assignment: Assignment;
  extensions: LaravelPaginator<
    UserAssignmentTimerExtensionDto & {
      timer: {user: {fullName: string}};
      grantedBy: {fullName: string};
    }
  >;
  timelimitEnabled: boolean;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "page.heading": "Timelimit Extensions",
    "page.description": "Grant students extra time from the marking screen.",
    "help.timelimits-not-enabled": "There is no timelimit for this assignment. Visit the assignment settings to add a timelimit.",
    "link.go-to-settings": "Go to Assignment Settings",
    "table.header.student": "Student",
    "table.header.grantedBy": "Granted By",
    "table.header.status": "Status",
    "table.status.claimed": "Claimed",
    "table.status.unclaimed": "Unclaimed",
    "table.empty": "No timelimit extensions have been granted for this assignment."
  },
  "fr": {
    "page.heading": "",
    "page.description": "",
    "help.timelimits-not-enabled": "",
    "link.go-to-settings": "",
    "table.header.student": "",
    "table.header.grantedBy": "",
    "table.header.status": "",
    "table.status.claimed": "",
    "table.status.unclaimed": "",
    "table.empty": ""
  }
}
</i18n>
