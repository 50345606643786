<template>
  <s-modal
    :title="configuration.title"
    :tagline="configuration.tagline"
    v-model:open="visible"
    :confirm="{
      label: 'Apply',
      icon: 'floppy',
    }"
    @confirm="onApply"
    cancellable
  >
    <s-modal-content class="flex flex-col gap-4">
      <div v-if="configuration.mode === 'both'" class="">
        <s-button-toggle
          name="newDateType"
          :options="[
            {value: 'relative', label: t('relative')},
            {value: 'fixed', label: t('fixed')},
          ]"
          v-model="dateModalType"
          id=""
        />
      </div>

      <div
        v-if="dateModalType === 'relative' && configuration.mode !== 'fixed'"
        class="flex flex-col"
      >
        <p class="text-base text-gray-700 leading-tight -mt-0.5 mb-1">
          <i18n-t keypath="durationDescription">
            <template #date>
              <strong>{{ t('startDate').toLowerCase() }}</strong>
            </template>
          </i18n-t>
        </p>
        <div class="w-full grid grid-cols-2 lg:grid-cols-3 justify-items-stretch gap-4 mt-1.5">
          <span class="col-span-2 lg:col-span-1">
            <s-input-field
              id="relativeDaysValue"
              v-model.number="relativeDaysValue"
              type="number"
              :suffix="t('time.days')"
            />
          </span>
          <s-input-field
            id="relativeHoursValue"
            v-model.number="relativeHoursValue"
            type="number"
            :suffix="t('time.hours')"
          />
          <s-input-field
            id="relativeMinutesValue"
            v-model.number="relativeMinutesValue"
            type="number"
            :suffix="t('time.minutes')"
          />
        </div>

        <!-- Only display this panel if we are editing one value. It doesn't make sense otherwise. -->
        <div
          class="flex gap-3 justify-between mt-4"
          v-if="configuration.recordsToModifyOnApply.length === 1"
        >
          <div class="flex flex-col gap-0.5 whitespace-nowrap text-gray-700 flex-1">
            <s-label>{{ t('startDate') }}</s-label>
            <span class="text-lg leading-tight">
              {{ format(configuration.relativeToValue, 'EEEE, MMM do') }}
            </span>
            <span class="text-sm leading-tight uppercase tracking-slight font-bold">
              {{ timeOfDay(configuration.relativeToValue) }}
            </span>
          </div>
          <div class="flex flex-col gap-0.5 whitespace-nowrap text-green-600 flex-1">
            <s-label class="text-green-600">{{ configuration.label }}</s-label>
            <span class="text-lg leading-tight">
              {{ format(relativeDateForDisplay, 'EEEE, MMM do') }}
            </span>
            <span class="text-sm leading-tight uppercase tracking-slight font-bold">
              {{ timeOfDay(relativeDateForDisplay) }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="dateModalType === 'fixed'" class="flex flex-col gap-1">
        <s-label>{{ configuration.label }}</s-label>
        <div class="flex items-center justify-between w-full gap-3">
          <div class="flex-1">
            <s-input-field v-model="formDateString" type="date" id="dateModalDateId" />
          </div>
          <div class="flex-1">
            <s-input-field v-model="formTimeString" type="time" id="dateModalTimeId" />
          </div>
        </div>
      </div>
    </s-modal-content>
  </s-modal>
</template>

<script setup lang="ts">
import SModal from '../../../design-system/SModal.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SInputField from '../../../design-system/SInputField.vue';
import SLabel from '../../../design-system/SLabel.vue';
import {computed, ref, watch} from 'vue';
import {addDays, addHours, addMinutes} from 'date-fns';
import {format, getDifferenceInDaysHoursMinutes, timeOfDay} from '../../../format/dates';
import {useVModel} from '@vueuse/core';
import SModalContent from '../../../design-system/SModalContent.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  visible: boolean;
  confirmIcon: string;
  confirmText: string;
  configuration: DateModalConfiguration;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const visible = useVModel(props, 'visible');

// Watch for changes to the configuration, which are set by the parent on launch of the modal
watch(
  () => props.configuration,
  (newValue) => {
    initializeStateFromConfiguration();
  }
);

const determineInitialMode = (configuredMode: DateModalModes): 'relative' | 'fixed' => {
  if (configuredMode === 'relative') {
    return 'relative';
  }

  return 'fixed';
};

const dateModalType = ref<'relative' | 'fixed'>(determineInitialMode(props.configuration.mode));

// Placeholder values for the form fields
const formDateString = ref<string>();
const formTimeString = ref<string>();
const relativeDaysValue = ref<number>(0);
const relativeHoursValue = ref<number>(0);
const relativeMinutesValue = ref<number>(0);

// Computed value for when we are displaying a relative date, transformed by the relative form values
const relativeDateForDisplay = computed(() => {
  // This is only here to satisfy the type checker. It should never be called.
  if (props.configuration.mode === 'fixed') {
    return new Date();
  }

  let date: Date;
  date = addDays(new Date(props.configuration.relativeToValue), relativeDaysValue.value);
  if (relativeHoursValue.value) {
    date = addHours(date, relativeHoursValue.value);
  }
  if (relativeMinutesValue.value) {
    date = addMinutes(date, relativeMinutesValue.value);
  }

  return date;
});

// The computed result that will be emitted if the user confirms the modal
const result = computed<DateModalResult>(() => {
  if (dateModalType.value === 'relative') {
    return {
      mode: 'relative',
      days: relativeDaysValue.value,
      hours: relativeHoursValue.value,
      minutes: relativeMinutesValue.value,
    };
  }

  return {
    mode: 'fixed',
    date: new Date(`${formDateString.value}T${formTimeString.value}`),
  };
});

const onApply = () => {
  // Execute the callback.
  props.configuration.callbackToExecuteOnApply(
    result.value,
    props.configuration.recordsToModifyOnApply
  );
};

const setInitialMode = () => {
  dateModalType.value = determineInitialMode(props.configuration.mode);
};
const setFormDateValues = () => {
  formDateString.value = format(props.configuration.value, 'yyyy-MM-dd');
  formTimeString.value = format(props.configuration.value, 'HH:mm');
};
const setRelativeDateValues = () => {
  if (props.configuration.mode === 'relative' || props.configuration.mode === 'both') {
    const {days, hours, minutes} = getDifferenceInDaysHoursMinutes(
      props.configuration.relativeToValue,
      props.configuration.value
    );

    relativeDaysValue.value = days;
    relativeHoursValue.value = hours;
    relativeMinutesValue.value = minutes;
  }
};

const initializeStateFromConfiguration = () => {
  setInitialMode();
  setFormDateValues();
  setRelativeDateValues();
};

initializeStateFromConfiguration();
</script>
<i18n>
{
  "en": {
    "relative": "Relative",
    "fixed": "Fixed",
    "durationDescription": "Duration relative to current {date}. Created as a fixed due date."
  },
  "fr": {
    "relative": "Relative",
    "fixed": "Fixe",
    "durationDescription": "Durée relative à la {date} actuelle. Créé comme une nouvelle date fixe."
  }
}
</i18n>
<style scoped></style>
