<template>
  <section aria-labelledby="assignment-info" class="stack vertical">
    <h2
      v-if="name"
      id="assignment-info"
      class="flex-1 flex justify-between gap-3 flex-wrap items-center text-sm font-bold tracking-wide leading-none text-gray-600 mb-1.5"
    >
      {{ name }}
    </h2>
    <div class="flex flex-wrap gap-2 mb-1">
      <s-label-value class="flex-1 whitespace-nowrap">
        <template #label>
          <span
            class="flex items-center gap-1"
            :class="{
              'text-green-700': extendedStartDate,
            }"
          >
            <s-icon
              v-if="extendedStartDate"
              name="calendar-arrow-right"
              size="20"
              class="text-green-500 -my-1"
              aria-hidden="true"
            />
            {{ t('started') }}
          </span>
        </template>
        <template v-if="startDate">
          <s-date v-if="!extendedStartDate" :date="startDate" />
          <button
            v-else
            class="relative group flex items-center gap-1"
            @click.stop.prevent="overlayOpen = true"
          >
            <s-tooltip>
              <template #tooltip>
                <strong class="text-sm leading-tight">
                  {{ t('extensionGrantedMessage') }}
                </strong>
                <br />
                {{ t('modifiedStartDateMessage') }}
              </template>
              <s-date :date="extendedStartDate" class="font-medium text-green-700 text-left" />
            </s-tooltip>
          </button>
        </template>
        <div v-else class="flex my-0.5">
          <s-badge color="red" icon="alert" size="sm">{{ t('notAssigned') }}</s-badge>
        </div>
      </s-label-value>
      <div class="flex-1">
        <s-label-value class="flex-1 whitespace-nowrap">
          <template #label>
            <span
              class="flex items-center gap-1"
              :class="{
                'text-green-700': extendedDueDate,
              }"
            >
              <s-icon
                v-if="extendedDueDate"
                name="calendar-arrow-right"
                size="18"
                class="text-green-500 -my-1"
              />
              {{ t('dueOn') }}
            </span>
          </template>
          <template v-if="dueDate">
            <s-date v-if="!extendedDueDate" :date="dueDate" />
            <button
              v-else
              class="relative group flex items-center gap-1"
              @click.stop.prevent="overlayOpen = true"
            >
              <s-tooltip>
                <template #tooltip>
                  <strong class="text-sm leading-tight">
                    {{ t('extensionGrantedMessage') }}
                  </strong>
                  <br />
                  {{ t('modifiedDueDateMessage') }}
                </template>
                <s-date :date="extendedDueDate" class="font-medium text-green-700 text-left" />
              </s-tooltip>
            </button>
            <div class="py-0.5">
              <s-link
                v-if="extendedDueDate && extendedDueDateId"
                :href="
                  route('courses.assignments.extension.edit', [
                    course.id,
                    assignment?.id,
                    extendedDueDateId,
                  ])
                "
                class="link text-sm flex items-center gap-1"
              >
                <s-icon name="calendar-plus-outline" size="16" />
                {{ t('editDueDateExtension') }}
              </s-link>
              <s-link
                v-else
                :href="
                  route('courses.assignments.extension.create', {
                    course: course.id,
                    assignment: assignment?.id,
                    _query: {
                      redirect: getCurrentPath(),
                      user: student?.id,
                    },
                  })
                "
                class="link text-sm flex items-center gap-1"
              >
                <s-icon name="calendar-plus-outline" size="16" />
                {{ t('grantDueDateExtension') }}
              </s-link>
            </div>
          </template>
          <div v-else class="flex my-0.5">
            <s-badge color="red" icon="alert" size="sm">{{ t('notAssigned') }}</s-badge>
          </div>
        </s-label-value>
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <s-label-value :label="t('assignmentGrade')" class="flex-1">
        <s-grade :grade="grade" tooltip :grade-display="course.gradeDisplay"></s-grade>
      </s-label-value>
      <s-label-value :label="t('totalMarks')" class="flex-1">{{ totalMarks }}</s-label-value>
    </div>
    <div v-if="$slots.actions" class="mt-2">
      <slot name="actions" />
    </div>
  </section>
  <s-modal v-model:open="overlayOpen" :title="t('assignmentExtended')" confirmable>
    <template #content>
      <p class="mb-4">{{ t('extensionGrantedMessage') }}</p>
      <div v-if="extendedStartDate" class="flex gap-2 justify-between flex-wrap mb-3">
        <s-label-value :label="t('originalStartDate')" class="flex-1">
          <s-date :date="startDate" />
        </s-label-value>
        <s-label-value class="flex-1 text-green-700" :label="t('modifiedStartDate')">
          <s-date :date="extendedStartDate" class="font-bold" />
        </s-label-value>
      </div>
      <div v-if="extendedDueDate" class="flex gap-2 justify-between flex-wrap">
        <s-label-value :label="t('originalDueDate')" class="flex-1">
          <s-date :date="dueDate" />
        </s-label-value>
        <s-label-value class="flex-1 text-green-700" :label="t('modifiedDueDate')">
          <s-date :date="extendedDueDate" class="font-bold" />
        </s-label-value>
      </div>
    </template>
  </s-modal>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue';
import {isDatePast} from '../../../format/dates';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SModal from '../../../design-system/SModal.vue';
import SGrade from '../../../components/SGrade.vue';
import SIcon from '../../../design-system/SIcon.vue';
import STooltip from '../../../design-system/STooltip.vue';
import SDate from '../../../design-system/SDate.vue';
import {Assignment} from '../../../types/entities/assignment';
import {Course} from '../../../types/entities/course';
import {User} from '../../../types/entities/user';
import SBadge from '../../../design-system/SBadge.vue';
import {route} from 'ziggy-js';
import SLink from '../../../components/SLink.vue';
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  assignment?: Assignment;
  name?: string;
  course: Course;
  startDate?: string;
  dueDate?: string;
  extendedStartDate?: string | null;
  extendedDueDate?: string | null;
  totalMarks: number;
  grade?: AssignmentGradeDto;
  canGrantTimerExtensions?: boolean;
  canViewGrades?: boolean;
  student?: User;
  timer?: UserAssignmentTimerDto | null;
  extendedDueDateId?: number | null;
}>();

const overlayOpen = ref(false);

const isPastDueDate = computed(() => {
  const activeDueDate = props.extendedDueDate || props.dueDate;
  if (!activeDueDate) return false;
  return isDatePast(activeDueDate);
});

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const getCurrentPath = () => {
  return window.location.pathname;
};
</script>
<i18n>
{
  "en": {
    "started": "Started",
    "notAssigned": "Not Assigned",
    "dueOn": "Due On",
    "extensionGrantedMessage": "This student has been granted an extension for this assignment.",
    "modifiedStartDateMessage": "The modified start date is indicated.",
    "modifiedDueDateMessage": "The modified due date is indicated.",
    "editDueDateExtension": "Edit Due Date Extension",
    "grantDueDateExtension": "Grant Due Date Extension",
    "assignmentGrade": "Assignment Grade",
    "totalMarks": "Total Marks",
    "assignmentExtended": "Assignment Extended",
    "originalStartDate": "Original Start Date",
    "modifiedStartDate": "Modified Start Date",
    "originalDueDate": "Original Due Date",
    "modifiedDueDate": "Modified Due Date"
  },
  "fr": {
    "started": "Commencé",
    "notAssigned": "Non attribué",
    "dueOn": "À rendre le",
    "extensionGrantedMessage": "Cet étudiant a obtenu une prolongation pour ce devoir.",
    "modifiedStartDateMessage": "La date de début modifiée est indiquée.",
    "modifiedDueDateMessage": "La date d'échéance modifiée est indiquée.",
    "editDueDateExtension": "Modifier la prolongation de la date d'échéance",
    "grantDueDateExtension": "Accorder une prolongation de la date d'échéance",
    "assignmentGrade": "Note du devoir",
    "totalMarks": "Notes totales",
    "assignmentExtended": "Devoir prolongé",
    "originalStartDate": "Date de début originale",
    "modifiedStartDate": "Date de début modifiée",
    "originalDueDate": "Date d'échéance originale",
    "modifiedDueDate": "Date d'échéance modifiée"
  }
}
</i18n>
