<template>
  <div class="justify-end">
    <s-btn icon="pencil" @click.prevent="openBuilderModal" color="secondary">
      {{ t('fields.edit') }}
    </s-btn>
  </div>

  <s-modal
    v-model:open="showBuilderModal"
    width="xl"
    :title="t('title')"
    @confirm="confirmModalAction"
    @cancel="cancelBuilderModal"
  >
    <s-modal-content>
      <s-field
        id="llmModel"
        :label="t('fields.llmModel')"
        :error="errors?.modelId"
        :tooltip="t('modelSelection_hint')"
        class="mb-4"
      >
        <s-select-field id="llmModel" v-model="llmConfig.modelId">
          <option v-for="metadata in sortedModels" :key="metadata.id" :value="metadata.id">
            <template v-if="metadata.model === 'gpt-4o-2024-08-06'">
              {{ `[${metadata.llmProvider}] ${metadata.model}` + ' **' + t('default') + '**' }}
            </template>
            <template v-else>
              {{ `[${metadata.llmProvider}] ${metadata.model}` }}
            </template>
          </option>
        </s-select-field>
      </s-field>

      <!-- Model Metadata Section -->
      <template v-if="selectedModel">
        <div class="mb-4 p-2 border rounded-lg bg-gray-50 text-sm text-gray-700">
          <p>
            <strong>{{ t('fields.inputTokenCost') }}:</strong>
            {{
              '$' +
              selectedModel.inputTokenPricingPerMillionTokens.toFixed(2) +
              ' ' +
              t('fields.tokenUnits')
            }}
          </p>
          <p>
            <strong>{{ t('fields.outputTokenCost') }}:</strong>
            {{
              '$' +
              selectedModel.outputTokenPricingPerMillionTokens.toFixed(2) +
              ' ' +
              t('fields.tokenUnits')
            }}
          </p>
          <p>
            <strong>{{ t('fields.maxInputContextWindow') }}:</strong>
            {{ selectedModel.maxInputContextWindow }}
          </p>
          <p>
            <strong>{{ t('fields.maxOutputTokens') }}:</strong>
            {{ selectedModel.maxOutputTokens }}
          </p>
          <p>
            <strong>{{ t('fields.useImages') }}:</strong>
            {{
              selectedModel.supportsAiImageGrading
                ? t('options.supportsImages.yes')
                : t('options.supportsImages.no')
            }}
          </p>
        </div>

        <div class="flex justify-between">
          <s-field
            id="maxOutputTokens"
            class="ml-2"
            :tooltip="t('maxOutputTokens_hint')"
            :label="t('fields.maxOutputTokens')"
            :error="errors?.maxOutputTokens"
          >
            <s-input-field
              id="maxOutputTokens"
              type="number"
              min="400"
              :max="selectedModel.maxOutputTokens"
              step="1"
              v-model="llmConfig.maxOutputTokens"
              :error="errors?.maxOutputTokens"
            />
          </s-field>
          <s-field
            v-if="selectedModel.supportsTemperature"
            id="temperature"
            class="ml-4"
            :label="t('fields.temperature')"
            :error="errors?.temperature"
            :tooltip="t('temperature_hint')"
          >
            <s-input-field
              id="temperature"
              type="number"
              min="0"
              max="1"
              step="0.01"
              v-model="llmConfig.temperature"
              :error="errors?.temperature"
            />
          </s-field>
          <s-field
            v-if="selectedModel.supportsTopP"
            id="topP"
            class="ml-4"
            :label="t('fields.topP')"
            :error="errors?.topP"
            :tooltip="t('topP_hint')"
          >
            <s-input-field
              id="topP"
              type="number"
              min="0"
              max="1"
              step="0.01"
              v-model="llmConfig.topP"
              :error="errors?.topP"
            />
          </s-field>
        </div>
      </template>
    </s-modal-content>
  </s-modal>
</template>

<script setup lang="ts">
import {useVModel} from '@vueuse/core';
import {computed, defineEmits, defineProps, ref} from 'vue';
import SField from '../../../design-system/SField.vue';
import {useI18n} from 'vue-i18n';
import SInputField from '../../../design-system/SInputField.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import {LlmMetadata} from '../../../types/entities/llmMetadata';
import SBtn from '../../../design-system/SBtn.vue';
import SModal from '../../../design-system/SModal.vue';
import {usePage} from '@inertiajs/vue3';
import SModalContent from '../../../design-system/SModalContent.vue';
import OpenAiRequestConfigDto = App.DTOs.OpenAiRequestConfigDto;

const props = defineProps<{
  modelValue: OpenAiRequestConfigDto;
  modelsMetadata: LlmMetadata[];
  errors?: Record<string, any>;
}>();

const page = usePage<{
  loggedInUser: {
    isSuperUser: boolean;
  };
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const defaultLlmConfig: OpenAiRequestConfigDto = {
  modelId: 1,
  maxOutputTokens: 4500,
  temperature: 0.0,
  topP: 1.0,
  jsonResponse: true,
  jsonSchema: null,
};

const showBuilderModal = ref(false);
const tempLlmConfig = ref<OpenAiRequestConfigDto>({...defaultLlmConfig, ...props.modelValue});
const emit = defineEmits(['update:modelValue']);
const llmConfig = useVModel(props, 'modelValue');

const openBuilderModal = () => {
  tempLlmConfig.value = {...llmConfig.value};
  showBuilderModal.value = true;
};

const confirmModalAction = () => {
  showBuilderModal.value = false;
};

const cancelBuilderModal = () => {
  llmConfig.value = {...tempLlmConfig.value};
  showBuilderModal.value = false;
};

const canUserSelectModel = (llmMetadata: LlmMetadata) => {
  // Models that are not recommended for general use by non Stemble staff
  if (llmMetadata.model.includes('o1') || llmMetadata.model.includes('o3')) {
    return page.props.loggedInUser.isSuperUser;
  }
  return true;
};

const sortedModels = computed(() => {
  return props.modelsMetadata.filter(canUserSelectModel).sort((a, b) => {
    if (a.llmProvider !== b.llmProvider) {
      return a.llmProvider.localeCompare(b.llmProvider);
    }
    return a.model.localeCompare(b.model);
  });
});

const selectedModel = computed<LlmMetadata | null>(() => {
  if (!llmConfig.value.modelId) return null;
  return sortedModels.value.find((m) => Number(m.id) == llmConfig.value.modelId) || null;
});
</script>
<i18n>
{
  "en": {
    "title": "LLM Options Configuration",
    "default": "Default",
    "modelSelection_hint": "Choose a model based on the intended application and required output quality. Note that reasoning models like the o1 series are significantly slower, expensive and are not recommended for general use. https://platform.openai.com/docs/models#model-endpoint-compatibility",
    "maxOutputTokens_hint": "An upper bound for the number of tokens that can be generated for a completion, including visible output tokens and reasoning tokens. https://platform.openai.com/docs/api-reference/chat/create#chat-create-max_completion_tokens",
    "temperature_hint": "What sampling temperature to use, between 0 and 1. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic. We generally recommend altering this or top_p but not both. https://platform.openai.com/docs/api-reference/chat/create#chat-create-temperature",
    "topP_hint": "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered. We generally recommend altering this or temperature but not both. https://platform.openai.com/docs/api-reference/chat/create#chat-create-top_p",
    "fields": {
      "edit": "Adjust LLM Options",
      "llmModel": "Model",
      "maxOutputTokens": "Max Output Tokens",
      "maxInputContextWindow": "Max Input Context Window",
      "inputTokenCost": "Input Token Cost",
      "outputTokenCost": "Output Token Cost",
      "useImages": "Supports Images",
      "tokenUnits": "per 1M tokens",
      "temperature": "Temperature",
      "topP": "Top P"
    },
    "options": {
      "supportsImages": {
        "yes": "Yes",
        "no": "No"
      }
    }
  },
  "fr": {
    "title": "Configuration des options LLM",
    "default": "Défaut",
    "modelSelection_hint": "Sélectionnez un modèle en fonction de l'application de champ attendue et de la qualité de sortie souhaitée. Notez que les modèles de raisonnement comme la série o1 sont significativement plus lents, coûteux et ne sont pas recommandés pour une utilisation générale. https://platform.openai.com/docs/models#model-endpoint-compatibility",
    "maxOutputTokens_hint": "Une borne supérieure pour le nombre de jetons qui peuvent être générés pour une complétion, y compris les jetons de sortie visibles et les jetons de raisonnement. https://platform.openai.com/docs/api-reference/chat/create#chat-create-max_completion_tokens",
    "temperature_hint": "Quelle température d'échantillonnage utiliser, entre 0 et 1. Des valeurs plus élevées comme 0,8 rendront la sortie plus aléatoire, tandis que des valeurs plus faibles comme 0,2 la rendront plus ciblée et déterministe. Nous recommandons généralement de modifier cela ou top_p mais pas les deux. https://platform.openai.com/docs/api-reference/chat/create#chat-create-temperature",
    "topP_hint": "Une alternative à l'échantillonnage avec la température, appelée échantillonnage par noyau (nucleus sampling), où le modèle prend en compte les résultats des tokens représentant une masse de probabilité de top_p. Ainsi, une valeur de 0,1 signifie que seuls les tokens représentant les 10 % supérieurs de la masse de probabilité sont pris en compte. Nous recommandons généralement de modifier soit ce paramètre, soit la température, mais pas les deux à la fois. https://platform.openai.com/docs/api-reference/chat/create#chat-create-top_p",
    "fields": {
      "edit": "Ajuster les options LLM",
      "llmModel": "Modèle",
      "maxOutputTokens": "Nombre maximal de jetons de complétion",
      "maxInputContextWindow": "Fenêtre de contexte d'entrée maximale",
      "inputTokenCost": "Coût du jeton d'entrée",
      "outputTokenCost": "Coût du jeton de sortie",
      "useImages": "Prend en charge les images",
      "tokenUnits": "par 1M jetons",
      "temperature": "Température",
      "topP": "Top P"
    },
    "options": {
      "supportsImages": {
        "yes": "Oui",
        "no": "Non"
      }
    }
  }
}
</i18n>
