<template>
  <!-- Shown when editing the task. -->
  <template v-if="view.editable">
    <task-variable-chip v-if="variable" :variable="variable" />
    <template v-else>
      <s-tooltip>
        <template #tooltip>
          <i18n-t keypath="tooltips.invalid-variable">
            <template #x>
              <code>{{ node.attrs.name }}</code>
            </template>
          </i18n-t>
        </template>
        <span
          id="invalid-variable"
          class="border border-red-500 text-red-500 bg-red-100 rounded-md p-1"
        >
          {{ node.attrs.name }}
        </span>
      </s-tooltip>
    </template>
  </template>
  <!-- Shown to Students -->
  <template v-else>
    <task-text :text="text" :task-state="taskState" />
  </template>
</template>
<script setup lang="ts">
import TaskVariableChip from '../../../chips/TaskVariableChip.vue';
import NumberVariableDto = App.DTOs.Tasks.Variables.NumberVariableDto;
import {computed} from 'vue';
import TaskText from '../../../text/TaskText.vue';
import {TaskState} from '../../../types/entities/tasks';
import STooltip from '../../../design-system/STooltip.vue';
import {useI18n} from 'vue-i18n';
import {VueNodeViewProps} from '../../prosemirror-editor/views/VueNodeView';

const {node, variables} = defineProps<
  VueNodeViewProps & {
    variables?: NumberVariableDto[];
    taskState?: TaskState;
  }
>();

const variable = computed(() => {
  return variables?.find((v) => v.name === node.attrs.name);
});

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const text = computed(() => `\${{ ${node.attrs.name} }}$`);
</script>
<i18n>
{
  "en": {
    "tooltips.invalid-variable": "{x} is a not a valid variable for this task."
  },
  "fr": {
    "tooltips.invalid-variable": "{x} n'est pas une variable valide pour cette tâche."
  }
}
</i18n>
