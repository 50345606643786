import {NodeType} from 'prosemirror-model';
import {EditorState} from 'prosemirror-state';
import {inListItem} from './inListItem';
import {getCurrentList} from './getCurrentList';

export const inList = (listType: NodeType) => {
  return (state: EditorState): boolean => {
    if (!inListItem(state)) {
      return false;
    }

    const {node} = getCurrentList(state);

    if (!node) {
      return false;
    }

    return node.type === listType;
  };
};
