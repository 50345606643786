<template>
  <s-field v-bind="props">
    <template v-slot="a11yProps">
      <s-textarea v-bind="{...$attrs, ...a11yProps}" v-model="model" />
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="tooltip || $slots.tooltip" #tooltip>
      <slot name="tooltip" />
    </template>
  </s-field>
</template>
<script setup lang="ts">
import {VNode} from 'vue';
import SField from './SField.vue';
import STextarea from './STextarea.vue';

const model = defineModel<string | number | null | undefined>();

const props = defineProps<{
  id: string;
  label: string;
  description?: string;
  tooltip?: string | VNode | null;
  error?: string;
  required?: boolean;
}>();
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
