import {Plugin} from 'prosemirror-state';

/**
 * A Plugin which intercepts the paste event for text.
 *
 */
export const pasteGdocEmbedPlugin = new Plugin({
  props: {
    handlePaste(view, event) {
      let text = event.clipboardData?.getData('text/plain') || '';

      if (!isGdocEmbedUrl(text)) {
        console.log('Not a Gdoc Embed URL', text);
        return false;
      }

      const {schema, tr} = view.state;
      const {from} = view.state.selection;

      const embedNode = schema.nodes.gdoc_embed.create({src: text});

      // Paste the plain text
      tr.replaceSelectionWith(embedNode);

      view.dispatch(tr);

      return true; // Prevent default pasting behavior
    },
  },
});

function isGdocEmbedUrl(str: string) {
  try {
    const url = new URL(str);

    if (url.hostname !== 'docs.google.com') {
      return false;
    }

    const pathParts = url.pathname.split('/');
    if (pathParts.length < 2 || pathParts[1] !== 'document') {
      return false;
    }

    return url.pathname.includes('/pub');
  } catch (e) {
    return false;
  }
}
