<template>
  <TransitionRoot appear :show="internalOpen" as="template">
    <Dialog as="div" :open="internalOpen" class="relative z-50" @close="attemptCancel">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-start justify-center py-5 md:py-7 px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full transform overflow-hidden origin-top text-left align-middle transition-all card"
              :class="widthClasses[width]"
            >
              <slot name="header" :close="attemptCancel">
                <div
                  v-if="title || $slots.title"
                  class="px-4 md:px-5 lg:px-6 pb-2.5 pt-5 flex gap-2 items-start justify-between"
                >
                  <div class="flex-1 -mt-0.5">
                    <p v-if="tagline" class="text-sm font-bold text-gray-600 leading-tight">
                      {{ tagline }}
                    </p>
                    <DialogTitle as="h3" class="text-xl font-medium text-gray-700">
                      <slot name="title">{{ title }}</slot>
                    </DialogTitle>
                  </div>
                  <button
                    @click.prevent="attemptCancel"
                    :aria-label="t('actions.close')"
                    class="transition-opacity ease-out duration-150 opacity-50 hover:opacity-80 active:opacity-100 p-2 -mt-2 -mr-2"
                  >
                    <s-icon name="close" size="18" />
                  </button>
                </div>
              </slot>
              <slot>
                <s-modal-content v-if="message || $slots.content">
                  <slot name="content" :message="message">
                    <p class="text-lg text-pretty">
                      {{ message }}
                    </p>
                  </slot>
                </s-modal-content>
              </slot>
              <div
                v-if="displayActions"
                class="rounded-b-lg px-4 md:px-5 lg:px-6 py-2.5 pb-4 md:py-4 md:pb-5 bg-gray-150 border-t border-gray-250"
              >
                <div
                  class="flex-1 flex flex-wrap items-start gap-3 pt-0.5"
                  :class="!!cancelAction ? 'justify-between' : 'justify-end'"
                >
                  <div v-if="!!cancelAction" class="flex-1">
                    <s-btn
                      class="w-full"
                      :color="cancelAction.color"
                      :icon="cancelAction.icon"
                      :disabled="internalProcessing || cancelAction.disabled"
                      @click.prevent="attemptCancel"
                    >
                      {{ cancelAction.label }}
                    </s-btn>
                  </div>
                  <template v-if="actions && actions.length">
                    <s-btn
                      v-for="action in actions"
                      class="flex-1 justify-self-end"
                      :color="action.color || defaultAction.color"
                      :icon="action.icon"
                      :disabled="internalProcessing || action.disabled"
                      :processing="internalProcessing"
                      @click.prevent="doConfirm"
                    >
                      {{ action.label || defaultAction.label }}
                    </s-btn>
                  </template>
                  <s-btn
                    v-if="!!confirmAction"
                    class="flex-1 justify-self-end"
                    :color="confirmAction.color"
                    :icon="confirmAction.icon"
                    :disabled="internalProcessing || confirmAction.disabled"
                    :processing="internalProcessing"
                    @click.prevent="doConfirm"
                  >
                    {{ confirmAction.label }}
                  </s-btn>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <template v-if="$slots.confirmClose && confirmingCancel">
    <slot name="confirmClose" :close="doCancel" :cancel="dontCancel" />
  </template>
</template>

<script lang="ts" setup>
import {
  computed,
  defineProps,
  withDefaults,
  defineEmits,
  getCurrentInstance,
  ref,
  useSlots,
} from 'vue';
import {useVModel} from '@vueuse/core';
import {Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild} from '@headlessui/vue';
import SBtn from './SBtn.vue';
import {Color as ColorOptions} from './SBtn.vue';
import SModalContent from './SModalContent.vue';
import SIcon from './SIcon.vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({
  inheritLocale: true,
});

interface ModalAction {
  label?: string;
  icon?: string;
  color?: ColorOptions;
  disabled?: boolean;
}

const defaultAction: ModalAction = {
  label: t('okay'),
  color: 'primary',
  icon: 'check',
};

const defaultCancelAction: ModalAction = {
  label: t('cancel'),
  color: 'white',
  icon: 'close',
};

interface ModalProps {
  open: boolean;
  title?: string;
  tagline?: string;
  message?: string;
  width?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  cancel?: ModalAction;
  cancellable?: boolean;
  confirm?: ModalAction;
  confirmable?: boolean;
  actions?: ModalAction[];
  processing?: boolean;
}

const props = withDefaults(defineProps<ModalProps>(), {
  width: 'sm',
});

const emit = defineEmits(['update:open', 'update:processing', 'confirm', 'cancel', 'action']);
const internalOpen = useVModel(props, 'open', emit);
const internalProcessing = useVModel(props, 'processing', emit);

const slots = useSlots();

const confirmingCancel = ref(false);

const attemptCancel = () => {
  if (slots.confirmClose) {
    confirmingCancel.value = true;
  } else {
    doCancel();
  }
};

const doConfirm = () => {
  emit('confirm');
  close();
};

const doCancel = () => {
  confirmingCancel.value = false;
  emit('cancel');
  close();
};

const dontCancel = () => {
  confirmingCancel.value = false;
};

const close = () => {
  internalOpen.value = false;
};

const widthClasses = {
  sm: 'max-w-prose',
  md: 'max-w-screen-md',
  lg: 'max-w-screen-lg',
  xl: 'max-w-screen-xl',
  '2xl': 'max-w-screen-2xl',
};

const confirmAction = computed(() => {
  if (typeof props.confirm === 'object') {
    return {
      ...defaultAction,
      ...props.confirm,
    };
  }
  if (props.confirmable || !!getCurrentInstance()?.vnode.props?.['onConfirm']) {
    return defaultAction;
  }
  return false;
});

const cancelAction = computed(() => {
  if (typeof props.cancel === 'object') {
    return {
      ...defaultCancelAction,
      ...props.cancel,
    };
  }
  if (props.cancellable || !!getCurrentInstance()?.vnode.props?.['onCancel']) {
    return defaultCancelAction;
  }
  return false;
});

const displayActions = computed(
  () => !!props.actions || !!confirmAction.value || !!cancelAction.value
);
</script>
