import {NodeSpec} from 'prosemirror-model';

export let hard_break_spec: NodeSpec = {
  inline: true,
  group: 'inline',
  selectable: false,
  parseDOM: [{tag: 'br'}],
  toDOM() {
    return ['br'];
  },
};
